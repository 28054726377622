import React, {FC, useEffect, useState} from 'react'
import {ErrorMessage, Field, FormikProps} from 'formik'
import InputMask from 'react-input-mask'
import {CreateCashbox} from "@providers/filial/_models";
import moment from "moment";
import AsyncSelect from "react-select/async";
import {customStyles} from "@metronic/assets/ts/select.style";
import {useIntl} from "react-intl";

interface IProps extends FormikProps<CreateCashbox> {
    handleChange(arg: any): void,

    setFieldValue(arg: string, val: any): void

    values: any
    error: any
    type: { id: number, name: string }[] | []
    setSelfEmployee: (val: boolean) => void,
    selfEmployee: boolean
}

const Step2: FC<IProps> = (props) => {

    const [type, setType] = useState<any[]>([])
    const intl = useIntl()
    const {setSelfEmployee, selfEmployee} = props

    useEffect(() => {
        props.setFieldValue('type', undefined)
    }, [props.error])

    useEffect(() => {
        setType(props.type.map(item => ({value: item.id, label: item.name})))
    }, [type])


    function validateSite(value: string) {
        let error;
        if (value && !value.match(/[a-z]\.[a-z]/)) {
            error = intl.formatMessage({id: 'invalid_site'});
        }
        return error;
    }

    const filterOptions = (inputValue: string, values: any[]) => {
        return values.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    const loadOptionsType = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {
        callback(filterOptions(inputValue, type))
    }

    return (
        <div className='w-100'>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: "cashbox__name"})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='name'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='name'/>
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: "legal_name"})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='legal_name'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='legal_name'/>
                </div>
            </div>

            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'gnk_spic'})}</label>
                <Field
                    type='text'
                    name='gnk_spic'
                    value={props.values['gnk_spic']}
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.setFieldValue('gnk_spic', e.currentTarget.value.replace(/[^\d]/g, ''))
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='gnk_spic'/>
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'min_amount'})}</label>
                <Field
                    type='text'
                    name='min_amount'
                    placeholder=''
                    value={props.values['min_amount']}
                    className='form-control form-control-lg form-control-solid'

                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.setFieldValue('min_amount', e.currentTarget.value.replace(/[^\d]/g, ''))
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='min_amount'/>
                </div>
            </div>

            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'max_amount'})}</label>
                <Field
                    type='text'
                    name='max_amount'
                    value={props.values['max_amount']}
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.setFieldValue('max_amount', e.currentTarget.value.replace(/[^\d]/g, ''))
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='max_amount'/>
                </div>
            </div>


            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'url'})}</label>
                <Field
                    type='text'
                    name='url'
                    value={props.values['url']}
                    className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='url'/>
                </div>
            </div>


            {/*<FieldArray*/}
            {/*    name="request_fields"*/}
            {/*    render={arrayHelpers=>{*/}
            {/*        return (*/}
            {/*            //@ts-ignore*/}
            {/*            <div>{props.values.request_fields&&props.values.request_fields.length>0?(*/}
            {/*                //@ts-ignore*/}
            {/*                props.values.request_fields.map((req, index)=>{*/}
            {/*                    return (*/}
            {/*                        <div key={index}>*/}
            {/*                            <button*/}
            {/*                                type="button"*/}
            {/*                                className={`btn btn-sm btn-primary font-weight-bold me-3 mb-10 ${index>0&&'d-none'}`}*/}
            {/*                                onClick={() => arrayHelpers.insert(index+1, {*/}

            {/*                                    order: index+1,*/}
            {/*                                    name: 'amount',*/}
            {/*                                    value: "static_amount_value",*/}
            {/*                                    required: true,*/}
            {/*                                    field_type: `static`,*/}
            {/*                                    field_control: `field_control_${index+1}`*/}
            {/*                                })}*/}
            {/*                            >*/}
            {/*                                <KTSVG*/}
            {/*                                    path='/media/icons/duotune/general/gen041.svg'*/}
            {/*                                    className='svg-icon-4 me-1'*/}
            {/*                                /> {intl.formatMessage({id:'ADD_BUTTON'})}*/}
            {/*                            </button>*/}
            {/*                            <div className='position-relative'>*/}
            {/*                                <Field name={`request_fields.${index}.order`} value={index} type='hidden' />*/}
            {/*                                <Field name={`request_fields.${index}.read_only`} value={true} type='hidden' />*/}
            {/*                                <Field name={`request_fields.${index}.field_type`}  type='hidden' />*/}
            {/*                                <Field name={`request_fields.${index}.field_control`} value={`field_control-${index}`} type='hidden' />*/}

            {/*                                <div>*/}
            {/*                                    <label className='form-label mb-3 required'>{intl.formatMessage({id: 'request.field.name'})}</label>*/}
            {/*                                    <Field*/}
            {/*                                        type='text'*/}
            {/*                                        name={`request_fields.${index}.name`}*/}
            {/*                                        className='form-control form-control-lg form-control-solid'*/}
            {/*                                    />*/}
            {/*                                    <div className='text-danger mt-2'>*/}
            {/*                                        <ErrorMessage name={`request_fields.${index}.name`} />*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}


            {/*                                <div>*/}
            {/*                                    <div className="form-check form-check-solid fv-row">*/}
            {/*                                        <input  onChange={(e) => {*/}
            {/*                                            props.setFieldValue('request_fields',*/}
            {/*                                                props.values.request_fields.map((item:any, indexCo:number)=>{*/}
            {/*                                                if(indexCo===index){*/}
            {/*                                                    item.required = e.target.checked*/}
            {/*                                                }*/}
            {/*                                                return item*/}
            {/*                                            }))*/}

            {/*                                        }} name={`request_fields.${index}.required`}*/}
            {/*                                                checked={props.values.request_fields[index]['required']||false} className="form-check-input" type="checkbox"*/}
            {/*                                               value={props.values.request_fields[index]['required']} />*/}
            {/*                                        <label*/}
            {/*                                            className="form-check-label fw-semibold ps-2 fs-6">{intl.formatMessage({id: "required_request.field"})}</label>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}

            {/*                                <button*/}
            {/*                                    type="button"*/}
            {/*                                    style={{top:-15}}*/}
            {/*                                    className='btn btn-sm btn-icon btn-danger me-3 position-absolute end-0'*/}
            {/*                                    onClick={() => arrayHelpers.remove(index)}*/}
            {/*                                >*/}
            {/*                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />*/}
            {/*                                </button>*/}

            {/*                            </div>*/}


            {/*                        </div>*/}
            {/*                    )*/}
            {/*                })*/}
            {/*            ):(*/}
            {/*                <button className='btn btn-sm btn-primary me-3' type="button" onClick={() => arrayHelpers.push({*/}
            {/*                    order: 0,*/}
            {/*                    name: '',*/}
            {/*                    required: true,*/}
            {/*                    field_type: 'field_type_0',*/}
            {/*                    field_control: 'field_control_0',*/}
            {/*                })}>*/}

            {/*                    {intl.formatMessage({id:'Add a request'})}*/}
            {/*                </button>*/}
            {/*            )}</div>*/}
            {/*        )*/}
            {/*    }}></FieldArray>*/}


            {/*<FieldArray*/}
            {/*    name="response_fields"*/}
            {/*    render={arrayHelpers=>{*/}
            {/*        return (*/}
            {/*            //@ts-ignore*/}
            {/*            <div>{props.values.response_fields&&props.values.response_fields.length>0?(*/}
            {/*                //@ts-ignore*/}
            {/*                props.values.response_fields.map((res, index)=>{*/}
            {/*                    return (*/}
            {/*                        <div key={index} className='pt-5 mt-3' style={{borderTop:'1px solid var(--kt-gray-300)'}}>*/}
            {/*                            <button*/}
            {/*                                type="button"*/}
            {/*                                className={`btn btn-sm btn-primary font-weight-bold me-3 mb-10 ${index>0&&'d-none'}`}*/}
            {/*                                onClick={() => arrayHelpers.insert(index, {*/}
            {/*                                    field_name: '',*/}
            {/*                                    order: index*/}
            {/*                                })}*/}
            {/*                            >*/}
            {/*                                <KTSVG*/}
            {/*                                    path='/media/icons/duotune/general/gen041.svg'*/}
            {/*                                    className='svg-icon-4 me-1'*/}
            {/*                                /> {intl.formatMessage({id:'ADD_BUTTON'})}*/}
            {/*                            </button>*/}
            {/*                            <div className='position-relative'>*/}
            {/*                                <Field name={`response_fields.${index}.order`} value={index} type='hidden' />*/}

            {/*                                <div>*/}
            {/*                                    <label className='form-label mb-3 required'>{intl.formatMessage({id: 'response.field_name'})}</label>*/}
            {/*                                    <Field*/}
            {/*                                        type='text'*/}
            {/*                                        name={`response_fields.${index}.field_name`}*/}
            {/*                                        className='form-control form-control-lg form-control-solid'*/}
            {/*                                    />*/}
            {/*                                    <div className='text-danger mt-2'>*/}
            {/*                                        <ErrorMessage name={`response_fields.${index}.field_name`} />*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}


            {/*                                <button*/}
            {/*                                    type="button"*/}
            {/*                                    style={{top:-15}}*/}
            {/*                                    className='btn btn-sm btn-icon btn-danger me-3 position-absolute end-0'*/}
            {/*                                    onClick={() => arrayHelpers.remove(index)}*/}
            {/*                                >*/}
            {/*                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />*/}
            {/*                                </button>*/}
            {/*                            </div>*/}


            {/*                        </div>*/}
            {/*                    )*/}
            {/*                })*/}
            {/*            ):(*/}
            {/*                <button className='btn btn-sm btn-primary me-3 mt-3' type="button" onClick={() => arrayHelpers.push({*/}
            {/*                    field_name: '',*/}
            {/*                    order: 0*/}
            {/*                })}>*/}
            {/*                    {intl.formatMessage({id:'Add a response'})}*/}
            {/*                </button>*/}
            {/*            )}</div>*/}
            {/*        )*/}
            {/*    }}></FieldArray>*/}


        </div>
    )
}

export {Step2}
