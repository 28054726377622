import {instantse} from "@providers/request";

export const Auth = {
    check(phone: string) {
        return instantse.post('/v1/auth/check', {phone: phone})
            .then((response) => response)
    },

    login(phone: string) {
        return instantse.post('/v1/auth/login/otp', {phone: phone})
            .then((response) => response)
    },

    resetPassword(phone: string) {
        return instantse.post('/v1/auth/reset', {phone: phone})
            .then((response) => response)
    },

    //This is the same method that is used in account/requests. I put it here because of convenience
    putPassword(password: string) {
        return instantse.put('/v1/account/password', {password: password})
            .then((response) => response)
    },

    loginPassword(phone: string, password: string) {
        return instantse.post('/v1/auth/login/password', {phone: phone, password: password})
            .then((response) => response)
    },

    registration(phone: string) {
        return instantse.post('/v1/auth/register', {phone: phone})
            .then((response) => response)
    },

    confirm(id: number, code: string) {
        return instantse.post(`/v1/auth/${id}/confirm`, {code: code})
            .then((response) => response)
    },


    getInvite(code: string) {
        return instantse.get(`/v1/auth/invite/${code}`)
            .then((response) => response)
    },

    postInvite(code: string) {
        return instantse.post(`/v1/auth/invite/${code}`)
            .then((response) => response)
    },

    resend(id: string) {
        return instantse.post(`/v1/auth/${id}/resend`)
            .then((response) => response)
    }


}