import {makeAutoObservable, toJS} from "mobx";

class Filial{
    filial = []
    filialOne = {}

    constructor() {
        makeAutoObservable(this)
    }

    setFilial(data){
        this.filial = data
    }

    get getFilial(){
        return toJS(this.filial)
    }

    setFilialOne(data){
        this.filialOne = data
    }

    get getFilialOne(){
        return toJS(this.filialOne)
    }
}



export const filial = new Filial()