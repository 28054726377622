import {instantse} from "@providers/request";
import {IEposSend} from "@providers/filial/_models";


export const Filial = {
    getFilialById(company_id:string,branch_id:string){
        return instantse.get(`/v1/company/${company_id}/cashbox/${branch_id}`)
            .then(result=>result)
    },
    putFilial(company_id:string, branch_id:string,data:any){
        return instantse.put(`/v1/company/${company_id}/cashbox/${branch_id}`,data, {
            // headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(result=>result)
    },
    changeStatusRequest(company_id:number, branch_id:number,data:any){
        return instantse.put(`/v1/company/${company_id}/cashbox/${branch_id}/status`,data)
            .then(result=>result)
    },
    deleteFilial(company_id:string,branch_id:string){
        return instantse.delete(`/v1/company/${company_id}/cashbox/${branch_id}`)
            .then(result=>result)
    },
    postFilial(company_id:string,data:any){
        return instantse.post(`/v1/company/${company_id}/cashbox`,data, {
            // headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(result=>result)
    },
    postFilialFiles(company_id:string,data:any,branch_id:string){
        return instantse.post(`/v1/company/${company_id}/cashbox/${branch_id}/upload`,data, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(result=>result)
    },
    getFilial(company_id:string){
        return instantse.get(`/v1/company/${company_id}/cashbox`)
            .then(result=>result)
    },
    eposesCrud(data: IEposSend) {
        const {
            company_id,
            branch_id,
            eposes
        } = data
        return instantse.put(`/v1/company/${company_id}/cashbox/${branch_id}/epos`, {eposes: eposes})
    }
}