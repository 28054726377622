import {instantse} from "@providers/request";
import {TransactionsCompanyReq, TransactionsGeneralRequest} from "@providers/adminTransactions/_models";

export const TransactionsInfo = {
    getGeneralTransactions(data:TransactionsGeneralRequest){
        return instantse.post('/v1/gs/company/transactions/general', data)
            .then(result=>result)
    },
    getTransactionsCompany(data:TransactionsCompanyReq){
        return instantse.post('/v1/gs/company/transactions', data)
            .then(result=>result)
    },
    getCompanyStatistic(data:any){
        return instantse.post('/v1/gs/company/statistics', data)
            .then(result=>result)
    },

    revertTransactions(data:any, companyId:string){
        return instantse.post(`/v1/company/${companyId}/transactions/revert`, data)
            .then(result=>result)
    }

}