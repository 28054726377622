/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, ReactEventHandler, useEffect} from 'react'
import {Field, ErrorMessage, FormikProps} from 'formik'
import {CreateCompany} from "@providers/company/_models";
import InputMask from "react-input-mask";
import moment from "moment";
import {useIntl} from "react-intl";
import {KTSVG} from "@metronic/helpers";
import {useNavigate, useParams} from "react-router-dom";


interface IProps extends FormikProps<CreateCompany> {
    handleChange(arg: any): void;
    values:any
    setFieldValue(name:string, val:any):void;

}

const Step1: FC<IProps> = (props) => {
    const intl = useIntl()
    const navigate = useNavigate()




    return (
        <div className='w-100'>
            <div className="pb-14">
                <h1 className="fw-bold text-dark">{intl.formatMessage({id: 'agent-step1'})}</h1>
            </div>


            <div className='row'>
                <div className='col-lg-6'>
                    <Field
                        type='radio'
                        className='btn-check'
                        name='type'
                        value='INDIVIDUAL'
                        id='kt_create_account_form_account_type_personal'
                        onChange={(e:any)=>{
                            navigate('/agents/add/individual')
                            props.handleChange(e)
                        }}
                    />
                    <label
                        className='radio-button-custom btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-start mb-10'
                        htmlFor='kt_create_account_form_account_type_personal'
                    >
                        <div className="mb-10 me-10">
                            <div className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="radio" checked={props.values.type==='INDIVIDUAL'} />
                            </div>
                        </div>

                        <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>{intl.formatMessage({id:'agent-step1.label'})}</span>
                            <span className='text-gray-400 fw-bold fs-6'>
                             Лицо без образования юридического лица.
                            </span>
                        </span>
                    </label>
                </div>

                <div className='col-lg-6'>
                    <Field
                        type='radio'
                        className='btn-check'
                        name='type'
                        value='COMPANY'
                        id='kt_create_account_form_account_type_corporate'
                        onChange={(e:any)=>{
                            navigate('/agents/add/company')
                            props.handleChange(e)
                        }}
                    />
                    <label
                        className='radio-button-custom btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-start'
                        htmlFor='kt_create_account_form_account_type_corporate'
                    >
                        <div className="mb-10 me-10">
                            <div className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="radio" value="" checked={props.values.type==='COMPANY'} onChange={(e)=>{
                                    console.log(props.values.type)
                                }}/>
                            </div>
                        </div>

                        <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>{intl.formatMessage({id:'agent-step1.label2'})}</span>
                            <span className='text-gray-400 fw-bold fs-6'>
                                Самозанятые, ИП, ООО и другие формы юридического лица.
                            </span>
                        </span>
                    </label>
                </div>

            </div>


        </div>
    )
}

export {Step1}
