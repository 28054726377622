/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * _modals (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useParams} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import InviteWrapper from "../modules/auth/Invite";
import {employeeStore} from "@store/employee";
import {observer} from "mobx-react";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = observer(() => {
  const {currentUser} = useAuth()
  const token = localStorage.getItem('kt-auth-react-v')

  return (
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            <Route path='invite/:code' element={<InviteWrapper />} />

            {token ? (
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/companies' />} />
                </>
            ) : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
  )
})

export {AppRoutes}
