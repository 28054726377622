export const enum Roles {
    user= 'USER',
    admin= 'ADMIN',
    moderator= 'MODERATOR',
    superadmin= 'SUPER_ADMIN',

}

export type AdminResponse = {
    "id"?: number,
    "first_name": string,
    "last_name": string,
    "middle_name": string,
    "has_password": boolean,
    "password_changed_date": string,
    "phone": string,
    "role": Roles
}


export type AdminRequest = {
    "phone"?: string,
    "role": Roles,
    "first_name"?: string,
    "last_name"?: string,
    "middle_name"?: string
}


