import React, {FC, useEffect, useMemo, useState} from 'react'
import {PageTitle} from "@metronic/layout/core";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {inits, SettingsMerchantSchema} from "./CompanyAboutHelper";
import Accordion from 'react-bootstrap/Accordion';
import {CreateCompany} from "@providers/company/_models";
import {Company} from "@providers/company/_request";
import {Navigate, useNavigate, useOutletContext, useParams} from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import {company} from "@store/company";
import InputMask from "react-input-mask";
import AsyncSelect from "react-select/async";
import {customStyles, customStyles2} from "@metronic/assets/ts/select.style";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {isDemo, ReplacePhone, roleRouting, roleRoutingAdmin} from "@utils/index";
import {observer} from "mobx-react";
import {Modal} from "react-bootstrap";
import {Tooltip} from "antd";
import {employeeStore} from "@store/employee";
import {OWNER} from "@utils/constants";
import {KTCard, KTCardBody} from "@metronic/helpers";
import {DropImages} from "@metronic/partials/DropImages";
import * as Yup from "yup";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";


const Index: FC = observer(() => {
    const schemaCreate = SettingsMerchantSchema()
    const [createSettingsMerchantSchema, setCreatingMerchantSchema] = useState(schemaCreate)
    const {companyId} = useParams()
    const [initValues, setInitValues] = useState<CreateCompany>(inits)
    const [deactivate, setDeactivate] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [alert, setAlert] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [type, setType] = useState<any[]>([])
    const [fields, setFields] = useState<{ id: number, name: string, children: [] }[]>([])
    const [parentField, setParentField] = useState(0)
    const [childFields, setChildFields] = useState<any[]>([])
    const [phoneNumber, setPhoneNumber] = useState('')
    const [defaultChildField, setDefaultChildField] = useState({})
    const [fieldsChild, setFieldsChild] = useState<any[]>([])
    const intl = useIntl()
    const [formShow, setFormShow] = useState(0)
    const [selfEmployee, setSelfEmployee] = useState(false)

    const data = useMemo(() => company.getCompany, [company.getCompany])


    const {setError} = useErrors()




    useEffect(() => {
        if (companyId) {
            getData()
        }
    }, [])

    useEffect(()=>{
        if(selfEmployee){
            schemaCreate.shape({
                pinfl: Yup.string()
                    .min(14, 'Не достаточно символов')
                    .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            })

        }else{
            schemaCreate.shape({
                oked: Yup.string()
                    .min(5, 'Не достаточно символов'),
                legal_name: Yup.string()
                    .required('Обязательное поле')
                    .max(50, 'Слишком много символов').required('Обязательное поле'),
                brand_name: Yup.string()
                    .max(50, 'Слишком много символов'),
                nds: Yup.string()
                    .min(12, 'Не достаточно символов'),
            })


        }

        setCreatingMerchantSchema(schemaCreate)

    }, [selfEmployee])


    useEffect(() => {
        if (parentField) {
            const children = fields.find((item: any) => item.value === parentField)?.children || []
            setChildFields(children.map((item: any) => ({value: item.id, label: item.name})))
        }
    }, [parentField])

    useEffect(() => {
        setDefaultChildField(childFields.find((item: any) => item.value === initValues.sub_field_of_activity))
    }, [childFields])

    useEffect(() => {
        if (companyId) {

            if (fields.length === 0) return

            Company.getById(companyId)
                .then(response => {
                    if (response?.data?.result) {
                        setSelfEmployee(response?.data?.result?.type===-1)

                        fields.forEach((item: any) => {
                            if (isDemo()) {
                                const parent = item.children.find((child: any) => child.id === response?.data?.result?.field_of_activity)
                                if (parent) {
                                    setParentField(item?.value ?? 0)
                                    setInitValues({
                                        ...response?.data?.result,
                                        field_of_activity: item?.value ?? 0,
                                        sub_field_of_activity: response?.data?.result?.field_of_activity
                                    })
                                } else {
                                    const hasParentField = item.value === response?.data?.result?.field_of_activity
                                    if (hasParentField) {
                                        setParentField(item?.value ?? 0)
                                        setInitValues({
                                            ...response?.data?.result,
                                            field_of_activity: item?.value ?? 0,
                                            sub_field_of_activity: item.children[0].id ?? response?.data?.result?.field_of_activity
                                        })
                                    }
                                }
                            } else {
                                const parent = item.children.find((child: any) => child.id === response?.data?.result?.field_of_activity)
                                if (parent) {
                                    setParentField(item?.value ?? 0)
                                    setInitValues({
                                        ...response?.data?.result,
                                        field_of_activity: item?.value ?? 0,
                                        sub_field_of_activity: response?.data?.result?.field_of_activity
                                    })
                                }
                            }
                        })

                        setPhoneNumber(response?.data?.result?.phone)
                    }
                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
        }
    }, [fields])


    const filterOptions = (inputValue: string, values: any[]) => {
        return values.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }


    const loadOptionsTypes = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {
        callback(filterOptions(inputValue, type))
    }

    const loadOptionsFields = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {
        callback(filterOptions(inputValue, fields))
    }

    const loadOptionsFieldsChild = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {
        callback(filterOptions(inputValue, childFields))
    }

    const getData = async () => {
        await companyTypes()
        await companyFields()

    }


    const onSub = async (values: CreateCompany) => {
        if (isDemo()) return
        setLoading(true)
        await updateCompany(values)
        setLoading(false)
    }


    const updateCompany = async (values: CreateCompany) => {
        const checkValue = values.phone.replace(/ /g, '').replace('+', '')
        const replace_values: any = {field_of_activity: +values.sub_field_of_activity, phone: checkValue, type: values?.type}


        const isNotAdded = ['sub_field_of_activity', 'phone', 'type', 'logo_file', 'license_file', 'ceo_passport_file']


        for(let key in values){
            //@ts-ignore
            if(values[key]&&values[key].length&&!isNotAdded.includes(key)){
                //@ts-ignore
                replace_values[key] = values[key]
            }
        }

        const files: any = {
            logo_file: values.logo_file,
            license_file: values.license_file,
            ceo_passport_file: values.ceo_passport_file,
        }



        if(!selfEmployee){
            delete replace_values['pinfl']
        }



        const formData = new FormData()

        for (let val in files) {

            if (files[val]) {
                if (val === 'logo_file') {
                    if (typeof files[val] === 'object') {
                        formData.append(val, files[val])
                    }
                } else {
                    formData.append(val, files[val])
                }

            }
        }

        // await Company.editCompany(companyId ?? '', formData)
        await Company.editCompany(companyId ?? '', replace_values)
            .then(() => Company.createCompanyFiles(formData, companyId ?? "0"))
            .then(() => {
                window.scroll({top:0, behavior: 'smooth'})
                setAlert('Компания успешно отредактирована')
                setFormShow(0)
                setInitValues({...initValues, ...values})
                setTimeout(() => {
                    setAlert(null)
                }, 3000)
                Company.getCompany()
                    .then(result => company.setCompany(result?.data?.result || []))
                    .catch(err => setError({
                        status: err?.response?.status,
                        message: err?.response?.data?.message,
                        isError: true
                    }))
            }).catch(err => {
                setFormShow(0)
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })


    }

    const deleteItem = (companyId: string) => {
        if (deactivate) {
            Company.deleteCompany(+companyId)
                .then(() => {
                    company.setCompany(company.getCompany.filter(item => item.id !== +companyId))
                    setAlert('Компания успешно удалена')
                    const newData = data.filter(item => Number(item.id) !== Number(companyId ?? -1))
                    if (newData.length == 1) {
                        setTimeout(() => {
                            navigate(`/${newData[0].id}/dashboard-merchant`)
                        }, 1500)
                    } else {
                        setTimeout(() => {
                            navigate('/companies')
                        }, 1500)
                    }
                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
        }
    }

    const companyTypes = async () => {
        await Company.getCompanyTypes()
            .then((response) => {
                setType((response?.data?.result || []).map((item: any) => ({value: item.id, label: item.name})))
            }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
    }

    const companyFields = async () => {
        await Company.getCompanyFields()
            .then((response) => {
                setFields((response?.data?.result || []).map((item: any) => ({
                    value: item.id,
                    label: item.name,
                    children: item.children
                })))
            }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
    }


    return (
        <div>

            <div className='d-flex flex-row-fluid flex-center position-relative'>
                {alert && (
                    <Alert className='position-fixed' style={{zIndex: "4", top: "20%"}} key={'success'}
                           variant={'success'}>
                        {alert}
                    </Alert>
                )}
                <Formik enableReinitialize={true} validationSchema={createSettingsMerchantSchema}
                        initialValues={initValues} onSubmit={onSub}>
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            handleReset,
                            setFieldValue
                        } = props


                        return (
                            <Form className=' w-100'>

                                <KTCard>
                                    <div className='card-header d-flex py-5 px-10 align-items-center'>
                                        <h3 className='mb-0'>{intl.formatMessage({id: "title_company_settings"})}</h3>
                                        {
                                            formShow!==1?(<button onClick={() => {
                                                setFormShow(1)

                                            }} type='button' className='btn btn-md btn-primary'>
                                  <span className='indicator-label'>
                                    {intl.formatMessage({id: "EDIT_BUTTON"})}
                                  </span>
                                            </button>):''
                                        }
                                    </div>
                                    <KTCardBody>
                                        {
                                            formShow===1?(<div className='w-100'>

                                                <div className='d-flex'>
                                                    <label
                                                        className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "logo_file"})}</label>
                                                    <div className='d-flex align-items-center col-lg-9'>
                                                        <div className='logo-form-upload mb-3 w-126px h-126px'>
                                                            {initValues?.logo_file ?
                                                                <img className='w-100 h-100' src={Company.getFiles(initValues?.logo_file)}/> :
                                                                <img className='w-100 h-100' src="https://via.placeholder.com/150/FAFAFA/000000/?text=Пусто"/>}
                                                        </div>

                                                        <DropImages {...props} fieldName='logo_file' />

                                                    </div>
                                                </div>
                                                <div className='w-100 mt-5'>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "name"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='name'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='name'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        !selfEmployee?(<>  <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "legal_name"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='legal_name'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='legal_name'/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                            <div className='mb-10 w-100'>
                                                                <div className='d-flex'>
                                                                    <label
                                                                        className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "brand_name"})}</label>
                                                                    <div className='col-lg-9'>
                                                                        <Field
                                                                            type='text'
                                                                            className='form-control form-control-lg'
                                                                            name='brand_name'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex'>
                                                                    <div className='col-lg-3'/>
                                                                    <div className='text-danger mt-2'>
                                                                        <ErrorMessage name='brand_name'/>
                                                                    </div>
                                                                </div>
                                                            </div></>):''
                                                    }



                                                    <div className='w-100 mb-10'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label required col-lg-3'>{intl.formatMessage({id: "field_of_activity"})}</label>
                                                            <div className='col-lg-9'>
                                                                {fields.length && <Field
                                                                    component={AsyncSelect}
                                                                    name='field_of_activity'
                                                                    styles={customStyles2}
                                                                    value={fields.find((item: any) => item.value === parentField)}
                                                                    defaultOptions={fields}
                                                                    loadOptions={loadOptionsFields}
                                                                    onChange={(e: any) => {
                                                                        setFieldValue('field_of_activity', e.value)
                                                                        setFieldValue('sub_field_of_activity', undefined)
                                                                        setParentField(+e.value)
                                                                        setDefaultChildField({})
                                                                    }}
                                                                />}
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='field_of_activity'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-100 mb-10'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label required col-lg-3'>{intl.formatMessage({id: "sub_field_of_activity"})}</label>
                                                            <div className='col-lg-9'>
                                                                {
                                                                    // childFields.length && <Field
                                                                    <Field
                                                                        component={AsyncSelect}
                                                                        name='sub_field_of_activity'
                                                                        cacheOptions
                                                                        styles={customStyles2}
                                                                        value={childFields.find(item=>item.value===values.sub_field_of_activity)||null}
                                                                        defaultOptions={childFields}
                                                                        loadOptions={loadOptionsFieldsChild}
                                                                        placeholder={intl.formatMessage({id: "select"})}
                                                                        onChange={(e: any) => {
                                                                            setFieldValue('sub_field_of_activity', e.value)
                                                                        }}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='sub_field_of_activity'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='w-100 mb-10'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label required col-lg-3'>{intl.formatMessage({id: "type"})}</label>

                                                            <div className='col-lg-9'>
                                                                {type.length && <Field
                                                                    component={AsyncSelect}
                                                                    name='type'
                                                                    cacheOptions
                                                                    value={type.find(item => item.value === values.type)}
                                                                    styles={customStyles2}
                                                                    defaultOptions={type}
                                                                    loadOptions={loadOptionsTypes}
                                                                    onChange={(e: any) => {
                                                                        setFieldValue('type', e.value)
                                                                    }}
                                                                />}
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='type'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "address"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='address'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='address'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "postal_code"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='postal_code'
                                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                        if (e.currentTarget.value.match(/^\d{0,6}$/)) handleChange(e)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='postal_code'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='mb-10'>
                                                        <div className='d-flex w-100'>
                                                            <label
                                                                className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: "email"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='email'
                                                                    onChange={(e:any)=>{

                                                                        if(!e.currentTarget.value.length||!(/[А-я,Ё,ё]/ig.test(e.currentTarget.value))){
                                                                            props.handleChange(e)
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='email'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "phone"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    component={InputMask}
                                                                    value={ReplacePhone(values.phone)}
                                                                    mask="+\9\9\8 99 999 99 99"
                                                                    className='form-control form-control-lg'
                                                                    name='phone'
                                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                        const value = e.currentTarget.value || '';
                                                                        setFieldValue('phone', value)

                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='phone'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>):(<div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>Брендовое название</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.name}</p>
                                                </div>
                                                {
                                                    !selfEmployee?(<>
                                                        <div className='d-flex align-items-center mb-5'>
                                                            <p className='mb-0 text-gray-600 fs-6 col-lg-4'>Юридическое название</p>
                                                            <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.legal_name}</p>
                                                        </div>
                                                        <div className='d-flex align-items-center mb-5'>
                                                            <p className='mb-0 text-gray-600 fs-6 col-lg-4'>Название торговой марки</p>
                                                            <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.brand_name}</p>
                                                        </div>
                                                    </>):''
                                                }

                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>Сфера деятельности</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{childFields.find(item=>item.value===initValues.sub_field_of_activity)?.label}</p>
                                                </div>



                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>Юридический адрес</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.address}</p>
                                                </div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>Индекс</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.postal_code}</p>
                                                </div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>Email</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.email}</p>
                                                </div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>Номер телефона</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.phone}</p>
                                                </div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>Форма компании</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{type.find(item => item.value === initValues.type)?.label}</p>
                                                </div>
                                            </div>)
                                        }
                                    </KTCardBody>
                                    {
                                        formShow===1?( <div className='card-footer py-5 px-10 d-flex justify-content-end'>
                                            <button className='btn btn-light me-3' onClick={()=>{
                                                setFormShow(0)
                                                setInitValues(initValues)
                                            }}>{intl.formatMessage({id:'Cancel'})}</button>
                                            <button className='btn btn-primary' type='submit' onClick={()=>{
                                                let err_message = ''
                                                if (Object.keys(errors).length) {
                                                    for (let err in errors) {
                                                        //@ts-ignore
                                                        err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                    }
                                                    setError({status: '', message: err_message, isError: true})
                                                }
                                            }}>{intl.formatMessage({id:'SAVE_EDITION'})}</button>
                                        </div>):''
                                    }
                                </KTCard>

                                <KTCard className='mt-5'>
                                    <div className='card-header d-flex py-5 px-10 align-items-center'>
                                        <h3 className='mb-0'>{intl.formatMessage({id: "title2_company_settings"})}</h3>
                                        {
                                            formShow!==2?(<button onClick={() => {
                                                setFormShow(2)

                                            }} type='button' className='btn btn-md btn-primary'>
                                  <span className='indicator-label'>
                                    {intl.formatMessage({id: "EDIT_BUTTON"})}
                                  </span>
                                            </button>):''
                                        }
                                    </div>
                                    <KTCardBody>
                                        {
                                            formShow===2?(<div className='w-100'>
                                                <div className='w-100'>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "ceo_first_name"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='ceo_first_name'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='ceo_first_name'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "ceo_last_name"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='ceo_last_name'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='ceo_last_name'/>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "ceo_passport_id"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='ceo_passport_id'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='ceo_passport_id'/>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "ceo_passport_date"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='date'
                                                                    className='form-control form-control-lg'
                                                                    name='ceo_passport_date'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='ceo_passport_date'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex'>
                                                        <label
                                                            className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "ceo_passport_file"})}</label>
                                                        <div className='d-flex align-items-center col-lg-9'>
                                                            <div className='logo-form-upload mb-3 w-126px h-126px'>
                                                                {initValues?.ceo_passport_file ?
                                                                    <img className='w-100 h-100' src={Company.getFiles(initValues?.ceo_passport_file)}/> :
                                                                    <img className='w-100 h-100' src="https://via.placeholder.com/150/FAFAFA/000000/?text=Пусто"/>}
                                                            </div>

                                                            <DropImages {...props} fieldName='ceo_passport_file' />

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>):(<div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'ceo_first_name'})}</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.ceo_first_name}</p>
                                                </div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'ceo_last_name'})}</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.ceo_last_name}</p>
                                                </div>

                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'ceo_passport_id'})}</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.ceo_passport_id}</p>
                                                </div>


                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'ceo_passport_date'})}</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.ceo_passport_date}</p>
                                                </div>



                                            </div>)
                                        }
                                    </KTCardBody>
                                    {
                                        formShow===2?( <div className='card-footer py-5 px-10 d-flex justify-content-end'>
                                            <button className='btn btn-light me-3' onClick={()=>{
                                                setFormShow(0)
                                                setInitValues(initValues)
                                            }}>{intl.formatMessage({id:'Cancel'})}</button>
                                            <button className='btn btn-primary' type='submit' onClick={()=>{
                                                let err_message = ''
                                                if (Object.keys(errors).length) {
                                                    for (let err in errors) {
                                                        //@ts-ignore
                                                        err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                    }
                                                    setError({status: '', message: err_message, isError: true})
                                                }
                                            }}>{intl.formatMessage({id:'SAVE_EDITION'})}</button>
                                        </div>):''
                                    }
                                </KTCard>

                                <KTCard className='mt-5'>
                                    <div className='card-header d-flex py-5 px-10 align-items-center'>
                                        <h3 className='mb-0'>{intl.formatMessage({id: "settings_business"})}</h3>
                                        {
                                            formShow!==3?(<button onClick={() => {
                                                setFormShow(3)

                                            }} type='button' className='btn btn-md btn-primary'>
                                  <span className='indicator-label'>
                                    {intl.formatMessage({id: "EDIT_BUTTON"})}
                                  </span>
                                            </button>):''
                                        }
                                    </div>
                                    <KTCardBody>
                                        {
                                            formShow===3?(<div className='w-100'>
                                                <div className='mb-10 w-100'>
                                                    <div className='d-flex'>
                                                        <label
                                                            className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "ikpu"})}</label>
                                                        <div className='col-lg-9'>
                                                            <Field
                                                                type='text'
                                                                className='form-control form-control-lg'
                                                                name='ikpu'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div className='col-lg-3'/>
                                                        <div className='text-danger mt-2'>
                                                            <ErrorMessage name='ikpu'/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-100'>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "inn"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='inn'
                                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                        if (e.currentTarget.value.match(/^\d{0,9}$/)) handleChange(e)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='w-100'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='inn'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        selfEmployee?(<div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "pinfl"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='pinfl'
                                                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                            if (e.currentTarget.value.match(/^\d{0,14}$/)) props.handleChange(e)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='pinfl'/>
                                                                </div>
                                                            </div>
                                                        </div>):(<>
                                                            <div className='mb-10 w-100'>
                                                                <div className='d-flex'>
                                                                    <label
                                                                        className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: "oked"})}</label>
                                                                    <div className='col-lg-9'>
                                                                        <Field
                                                                            type='text'
                                                                            className='form-control form-control-lg'
                                                                            name='oked'
                                                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                                if (e.currentTarget.value.match(/^\d{0,5}$/)) handleChange(e)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='d-flex'>
                                                                    <div className='col-lg-3'/>
                                                                    <div className='text-danger mt-2'>
                                                                        <ErrorMessage name='oked'/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='mb-10 w-100'>
                                                                <div className='d-flex'>
                                                                    <label
                                                                        className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: "nds"})}</label>
                                                                    <div className='col-lg-9'>
                                                                        <Field
                                                                            type='text'
                                                                            className='form-control form-control-lg'
                                                                            name='nds'
                                                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                                if (e.currentTarget.value.match(/^\d{0,12}$/)) handleChange(e)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='d-flex'>
                                                                    <div className='col-lg-3'/>
                                                                    <div className='text-danger mt-2'>
                                                                        <ErrorMessage name='nds'/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>)
                                                    }





                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "mfo"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='mfo'
                                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                        if (e.currentTarget.value.match(/^\d{0,5}$/)) handleChange(e)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='mfo'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "bank"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='bank'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='bank'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "bank_account_id"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='bank_account_id'
                                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                        if (e.currentTarget.value.match(/^\d{0,20}$/)) handleChange(e)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='bank_account_id'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: "bank_filial"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='bank_filial'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='bank_filial'/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex'>
                                                        <label
                                                            className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "license_file"})}</label>
                                                        <div className='d-flex align-items-center col-lg-9'>
                                                            <div className='logo-form-upload mb-3 w-126px h-126px'>
                                                                {initValues?.license_file ?
                                                                    <img className='w-100 h-100' src={Company.getFiles(initValues?.license_file)}/> :
                                                                    <img className='w-100 h-100' src="https://via.placeholder.com/150/FAFAFA/000000/?text=Пусто"/>}
                                                            </div>

                                                            <DropImages {...props} fieldName='license_file' />

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>):(<div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'ikpu'})}</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.ikpu}</p>
                                                </div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'inn'})}</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.inn}</p>
                                                </div>

                                                {
                                                    !selfEmployee?(<>
                                                        <div className='d-flex align-items-center mb-5'>
                                                            <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'nds'})}</p>
                                                            <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.nds}</p>
                                                        </div><div className='d-flex align-items-center mb-5'>
                                                        <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'oked'})}</p>
                                                        <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.oked}</p>
                                                    </div></>):<div className='d-flex align-items-center mb-5'>
                                                        <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'pinfl'})}</p>
                                                        <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.pinfl}</p>
                                                    </div>
                                                }

                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'mfo'})}</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.mfo}</p>
                                                </div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'bank'})}</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.bank}</p>
                                                </div>


                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'bank_account_id'})}</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.bank_account_id}</p>
                                                </div>
                                                <div className='d-flex align-items-center mb-5'>
                                                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'bank_filial'})}</p>
                                                    <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues.bank_filial}</p>
                                                </div>

                                            </div>)
                                        }
                                    </KTCardBody>
                                    {
                                        formShow===3?( <div className='card-footer py-5 px-10 d-flex justify-content-end'>
                                            <button className='btn btn-light me-3' onClick={()=>{
                                                setFormShow(0)
                                                setInitValues(initValues)
                                            }}>{intl.formatMessage({id:'Cancel'})}</button>
                                            <button className='btn btn-primary' type='submit' onClick={()=>{
                                                let err_message = ''
                                                if (Object.keys(errors).length) {
                                                    for (let err in errors) {
                                                        //@ts-ignore
                                                        err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                    }
                                                    setError({status: '', message: err_message, isError: true})
                                                }
                                            }}>{intl.formatMessage({id:'SAVE_EDITION'})}</button>
                                        </div>):''
                                    }
                                </KTCard>


                                {
                                    employeeStore.getRole===OWNER?( <div className="card mt-10">
                                        <div className="card-header border-0 cursor-pointer" role="button"
                                             data-bs-toggle="collapse" data-bs-target="#kt_account_deactivate"
                                             aria-expanded="true" aria-controls="kt_account_deactivate">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bold m-0">{intl.formatMessage({id: "DELETE_COMPANY"})}</h3>
                                            </div>
                                        </div>
                                        <div id="kt_account_settings_deactivate" className="collapse show">
                                            <div id="kt_account_deactivate_form" className="form">
                                                <div className="card-body border-top p-9">
                                                    <div
                                                        className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                                              <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20"
                                                                      rx="10" fill="currentColor"/>
																<rect x="11" y="14" width="7" height="2" rx="1"
                                                                      transform="rotate(-90 11 14)"
                                                                      fill="currentColor"/>
																<rect x="11" y="17" width="2" height="2" rx="1"
                                                                      transform="rotate(-90 11 17)"
                                                                      fill="currentColor"/>
															</svg>
														</span>
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-semibold">
                                                                <h4 className="text-gray-900 fw-bold">{intl.formatMessage({id: "DELETE_COMPANY1"})}</h4>
                                                                <div
                                                                    className="fs-6 text-gray-700">{intl.formatMessage({id: "WARNING_COMPANY"})}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-check form-check-solid fv-row">
                                                        <input onChange={(e) => {
                                                            setDeactivate(e.target.checked)
                                                        }} name="deactivate" className="form-check-input" type="checkbox"
                                                               value="" id="deactivate"/>
                                                        <label
                                                            className="form-check-label fw-semibold ps-2 fs-6">{intl.formatMessage({id: "confirm"})}</label>
                                                    </div>
                                                </div>
                                                <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                    {
                                                        isDemo()?(<Tooltip zIndex={1} color='var(--kt-body-bg)'
                                                                           title={() => <span
                                                                               style={{color: 'var(--kt-text-gray-600)'}}>{intl.formatMessage({id: "DEMO_DISABLED"})}</span>}>
                                                            <div>
                                                                <button
                                                                    id="kt_account_deactivate_account_submit"
                                                                    type="button"
                                                                    className="btn btn-danger fw-semibold"
                                                                    disabled={!deactivate || isDemo()}>
                                                                    {intl.formatMessage({id: "DELETE_COMPANY"})}
                                                                </button>
                                                            </div>
                                                        </Tooltip>):(<button
                                                            id="kt_account_deactivate_account_submit"
                                                            type="button"
                                                            className="btn btn-danger fw-semibold"
                                                            disabled={!deactivate}
                                                            onClick={() => {
                                                                setShowDeleteModal(true)
                                                            }}>{intl.formatMessage({id: "DELETE_COMPANY"})}
                                                        </button>)
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>) :''
                                }

                            </Form>
                        )
                    }}
                </Formik>
            </div>

            <DeleteCompanyModal onCancel={() => setShowDeleteModal(false)}
                                companyName={(data.find((item: any) => item.id === (companyId !== undefined ? +companyId : 0))?.name)}
                                setIsLoading={(val: boolean) => setLoading(val)}
                                onDelete={() => {
                                    setShowDeleteModal(false)
                                    deleteItem(companyId ?? '')
                                }}
                                shouldShow={showDeleteModal}/>
        </div>
    )
})

type DeleteCompanyModal = {
    onCancel: Function
    companyName: string,
    setIsLoading: (val: boolean) => void,
    onDelete: Function,
    shouldShow: boolean
}

export const DeleteCompanyModal: FC<DeleteCompanyModal> = ({
                                                               onCancel,
                                                               companyName,
                                                               setIsLoading,
                                                               onDelete,
                                                               shouldShow
                                                           }) => {
    const [deleteField, setDeleteField] = useState<string>("")
    const [hasError, setHasError] = useState<boolean>(false)
    const {setError} = useErrors()
    const handleClose = () => {
        onCancel()
    }
    const intl = useIntl()

    const handleDelete = async () => {

        if (deleteField.replace(/\s+/g, ' ').trim() !== companyName.replace(/\s+/g, ' ').trim()) {
            setHasError(true)
            return
        } else {
            setHasError(false)
        }
        try {
            setIsLoading(true)
            onDelete()
        } catch (error: Error | any) {
            setError({
                status: error?.response?.status,
                message: error?.response?.data?.message,
                isError: true
            })
        } finally {
            setIsLoading(false)
        }
    }


    return <Modal show={shouldShow} onHide={handleClose}>
        <Modal.Header closeButton>
            <h2>{intl.formatMessage({id: "CHECK_DELETE"})}</h2>
        </Modal.Header>
        <Modal.Body>
            <div className='fs-3'>{intl.formatMessage({id: "DELETE_APPROVE"})}</div>
            <div className='mt-5'>
                <label
                    className='form-label mb-3 required'>{intl.formatMessage({id: "name"})}</label>
                <input
                    onChange={(event => {
                        setDeleteField(event.target.value)
                    })}
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                    {hasError && <span>{intl.formatMessage({id: "DELETE_COMPANY_APPROVE_ERROR"})}</span>}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
            <button type="button" className="swal2-confirm btn btn-secondary" aria-label=""
                    style={{display: 'inline-block'}} onClick={handleClose}>
                {intl.formatMessage({id: "CANCEL_BUTTON"})}
            </button>
            <button onClick={handleDelete} className="swal2-confirm btn btn-danger" aria-label=""
                    style={{display: 'inline-block'}}>
                {intl.formatMessage({id: "delete"})}
            </button>
        </Modal.Footer>
    </Modal>
}

export default function SettingMerch() {

    //@ts-ignore
    const {role} = useOutletContext()
    const intl = useIntl()



    if (roleRouting('settings-merchant', role)||roleRoutingAdmin('page', role, 'settings-merchant')) {
        return (
            <>
                <ErrorProvider>
                    <PageTitleCustom title={intl.formatMessage({id:'merchant_setting'})} breadCrumbs={[]}/>
                    <Index/>
                </ErrorProvider>

            </>
        )
    }

    return <Navigate to='/error/404'/>


}