import {useNavigate} from "react-router-dom";

import {KTSVG} from '@metronic/helpers'
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import {Statistic} from "@providers/statistic/_request";
import {Company} from "@providers/company/_request";
import {useErrors} from "@providers/context/ErrorsProvider";
import {UsersListSearchComponent} from "./UsersListSearchComponent";



const UsersListToolbar = observer(() => {

    const [isLoading, setIsLoading] = useState(false)
    const {setError} = useErrors()

    const navigate = useNavigate()
    const openAddUserModal = () => {
        navigate('/companies/add')
    }
    const intl = useIntl()




    return (
        <div className='mb-9 d-flex justify-content-between'>
            <div className='d-flex justify-content-start' data-kt-user-table-toolbar='base'>
                {/*<UsersListFilter />*/}

                <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
                    <KTSVG path='/media/icons/duotune/general/gen041.svg' className='svg-icon-2' />
                    {intl.formatMessage({id:'add-company-button'})}
                </button>
            </div>
            {/*<UsersListSearchComponent />*/}
        </div>
    )
})

export {UsersListToolbar}
