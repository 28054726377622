/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, ReactEventHandler, useEffect} from 'react'
import {Field, ErrorMessage, FormikProps} from 'formik'
import {CreateCompany} from "@providers/company/_models";
import InputMask from "react-input-mask";
import moment from "moment";
import {useIntl} from "react-intl";


interface IProps extends FormikProps<CreateCompany> {
    handleChange(arg: any): void;
    values:any
    setFieldValue(name:string, val:any):void;

}

const Step1: FC<IProps> = (props) => {
    const intl = useIntl()


    return (
        <div className='w-100'>
            <div className="pb-14">
                <h1 className="fw-bold text-dark">{intl.formatMessage({id: 'INFO_CEO'})}</h1>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_first_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='ceo_first_name'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))
                            props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='ceo_first_name' />
                </div>
            </div>
            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_last_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='ceo_last_name'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))
                            props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='ceo_last_name' />
                </div>
            </div>
            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_passport_id'})}</label>
                <Field
                    type='text'
                    placeholder='AA1234567'
                    className='form-control form-control-lg form-control-solid'
                    name='ceo_passport_id'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        const passportRegExp = new RegExp(/^(([aA-zZ]\D{0,1})([0-9]{0,7}))$/)
                        if(passportRegExp.test(e.currentTarget.value)||!e.currentTarget.value.length){
                            props.handleChange(e)
                            props.setFieldValue('ceo_passport_id', e.currentTarget.value.replace(/^[aA-zZ]{2}/, (match)=>match.toUpperCase()))
                        }
                    }}

                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='ceo_passport_id' />
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_passport_date'})}</label>
                <Field
                    type='date'
                    max={moment().subtract(1, 'days').format('YYYY-MM-DD')}
                    className='form-control form-control-lg form-control-solid'
                    name='ceo_passport_date'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='ceo_passport_date' />
                </div>
            </div>
            <div className='mb-8 fv-row'>
                <label className='form-label mb-3'>{intl.formatMessage({id:'email'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='email'
                    onChange={(e:any)=>{
                        if(!e.currentTarget.value.length||!(/[А-я,Ё,ё]/ig.test(e.currentTarget.value))){
                            props.handleChange(e)
                        }
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='email' />
                </div>
            </div>
            <div className='mb-12 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'phone'})}</label>
                <Field
                    type='text'
                    placeholder='+998 90 999 99 99'
                    component={InputMask}
                    mask="+\9\9\8 99 999 99 99"
                    className='form-control form-control-lg form-control-solid'
                    name='phone'
                    value={props.values['phone']}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const value = e.currentTarget.value || '';
                        props.setFieldValue('phone', value)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='phone' />
                </div>
            </div>
        </div>
    )
}

export {Step1}
