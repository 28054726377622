import {useLocation, useParams} from "react-router-dom";
import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {isDemo, isProd, NumReplace} from "@utils/index";
import {demoColumns, usersColumns, usersColumnsCompany} from "../../../payments/transactions/_modals/columns/_columns";
import {sessionStore} from "@store/session";
import {ColumnInstance, Row, useTable} from "react-table";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {KTCardBody, KTSVG, useDebounce} from "@metronic/helpers";
import {CANCELED, DECLINED, HUMO, statuses_const, statuses_platforms, SUCCESSFUL, UZCARD} from "@utils/constants";
import {subtractMonths, toIsoStringHelper} from "../../../autopay/dateHelper";
import moment from "moment/moment";
import {transactions} from "@store/transactions/transactions";
import {Filial} from "@providers/filial/_request";
import {filial} from "@store/filial";
import {Statistic} from "@providers/statistic/_request";
import {IStatistic} from "@providers/statistic/_modals";
import {Modal} from "react-bootstrap";
import {FilterDropDown} from "../../../payments/transactions/_modals/FilterDropDown";
import {ModSession} from "@providers/session/_modals";
import {CustomHeaderColumn} from "../../../payments/transactions/_modals/columns/CustomHeaderColumn";
import {CustomRow} from "../../../payments/transactions/_modals/columns/CustomRow";
import {Pagination} from "../../../../modules/Pagination";
import {KTCard} from "@metronic/helpers";
import {observer} from "mobx-react";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";


interface CustomizedState {
    statuses?: Array<string>
    ppc_types?: Array<string>
    from_date?: string
    to_date?: string
}

type IProps = {
    general: any
}


export const Transactions = observer((props: IProps) => {

    const location = useLocation();
    const {general} = props
    const state = location.state as CustomizedState;
    const [searchText, setSearchText] = useState('')
    const [searchTextController, setSearchTextController] = useState('')

    const [sortByCategory, setSortByCategory] = useState('CREATED_AT')
    const [sortFromMax, setSortFromMax] = useState(true)
    const [transactionFiscalData, setTransactionFiscalData] = useState([])
    const [transactionFiscalDataModalShow, setTransactionFiscalDataModalShow] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const data = useMemo(() => transactions.getTransactions, [transactions.getTransactions])
    const [cashboxes, setCashboxes] = useState([])
    const columns = useMemo(() => usersColumnsCompany, [sessionStore.getSession])
    const initialState = {hiddenColumns: []}
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow, setHiddenColumns} = useTable({
        columns,
        data, initialState
    })
    const [filtersCount, setFiltersCount] = useState(3)

    const [loaded, setLoaded] = useState<boolean>(false)
    const {id} = useParams()
    const {setError} = useErrors()
    const intl = useIntl()

    const [pagination, setPagination] = useState<any>(null)
    const [filters, setFilters] = useState<any>({
        statuses: state?.statuses ?? ['INIT', 'FAILED', 'REVERT', 'APPROVED'],
        ppc_types: state?.ppc_types ?? [HUMO, UZCARD, 'VISA', 'MASTERCARD'],
        from_date: state?.from_date ?? toIsoStringHelper(moment('2000-01-01').startOf('day').toDate()),
        to_date: state?.to_date ?? toIsoStringHelper(moment().endOf('day').toDate())

    })
    const [showFilter, setShowFilter] = useState(false)
    const sortBy = useMemo(() => transactions.getTransactionSortBy, [transactions.getTransactionSortBy])


    useEffect(() => {

        if (cashboxes.length) {
            transactions.setFilters(filters)
            setSortByCategory(sortBy.sortBy)
            setSortFromMax(sortBy.sortFromMax)
            getSortedTransactions({pagination}, sortBy.sortBy, sortBy.sortFromMax)
        }
    }, [sortBy, searchText, cashboxes, filters])

    useEffect(() => {
        getFilial()
    }, [])

    useEffect(() => {
        if (cashboxes.length) {

            // setPagination(null)

        }

    }, [cashboxes, filters])


    const getFilial = async () => {
        if (id) {
            await Filial.getFilial(id)
                .then(result => {
                    filial.setFilial(result?.data?.result)
                    setCashboxes((result?.data?.result || []).map((item: any) => item.id))
                }).catch(err => {
                    setError({
                        status: err?.response?.status,
                        message: err?.response?.data?.message,
                        isError: true
                    })
                }).finally(() => setIsLoading(false))
        }
    }

    const getTransactionFiscalChequeData = async (transactionId: string) => {
        try {
            const {request: {response: res}} = await Statistic.getTransactionFiscalCheque(id!, transactionId)
            const result = JSON.parse(res).result
            setTransactionFiscalData(result)
            setTransactionFiscalDataModalShow(true)
        } catch (err: any) {
            if (err?.response?.data?.message === "Receipt of this transaction is not found") {
                setError({status: err?.response?.stat, message: "Фискальный чек не существует", isError: true})
            } else {
                setError({status: err?.response?.stat, message: err?.response?.data?.message, isError: true})
            }
        }
    }

    const getSortedTransactions = async (params: any = {}, cat: any, max: boolean) => {

        if (id) {
            const dataRequest: IStatistic = {
                cashboxes: cashboxes,
                page: params.pagination?.currentPage ?? 1,
                count: 20,
                statuses: filters.statuses,
                ppc_types: filters.ppc_types,
                from_date: filters.from_date,
                to_date: filters.to_date,
                sort_by: cat,
                sort_order: max ? "ASC" : "DESC"
            }

            if (searchText.trim() !== "") {
                dataRequest.query = searchText.replace(/\s+/g, '')
            }
            setIsLoading(true)

            await Statistic.getTransactions(id, dataRequest)
                .then(result => {
                    const hiddenColumns = []
                    const fls = filial.getFilial
                    transactions.setPagination({...result?.data?.meta, currentPage: dataRequest.page})
                    setPagination({...result?.data?.meta, currentPage: dataRequest.page})
                    if (isProd()) {
                        hiddenColumns.push("platform")
                    }
                    if (result?.data?.result.length === 0) {
                        hiddenColumns.push("cashbox_id")
                    }
                    setHiddenColumns(hiddenColumns)
                    let data = result?.data?.result || []
                    data.forEach((item: any) => {
                        item.cashbox_id = fls.find(value => value.id === item.cashbox_id).name
                    })
                    if (data.length > 0) {
                        data.map((item: any, index: number) => {
                            if (dataRequest.page && dataRequest.page > 2) {
                                item['num'] = ((dataRequest.page - 1) * 20) + index + 1
                            } else if (dataRequest.page == 2) {
                                item['num'] = 21 + index
                            } else {
                                item['num'] = index + 1
                            }
                            return item
                        })
                    }
                    transactions.setTransactionsData(data)

                }).catch(err => {
                    if (err?.response) {
                        setError({
                            status: err?.response?.status,
                            message: err?.response?.data?.message,
                            isError: true
                        })
                    }
                }).finally(() => {
                    setIsLoading(false)
                    setLoaded(true)
                })
        }
    }


    const getTransaction = async (params: any = {}) => {

        if (id) {
            const dataRequest: IStatistic = {
                cashboxes: cashboxes,
                statuses: filters.statuses,
                ppc_types: filters.ppc_types,
                from_date: filters.from_date,
                to_date: filters.to_date,
                page: params?.pagination?.currentPage ?? 1,
                count: 20,
                sort_by: sortByCategory,
                sort_order: sortFromMax ? "ASC" : "DESC"
            }

            if (searchText.trim() !== "") {
                dataRequest.query = searchText.replace(/\s+/g, '')
            }

            await Statistic.getTransactions(id, dataRequest)
                .then(result => {
                    const hiddenColumns = []
                    const fls = filial.getFilial
                    setPagination({...result?.data?.meta, currentPage: dataRequest.page})
                    if (isProd()) {
                        hiddenColumns.push("platform")
                    }
                    if (result?.data?.result.length === 0) {
                        hiddenColumns.push("branch_id")
                    }
                    setHiddenColumns(hiddenColumns)
                    let data = result?.data?.result || []
                    data.forEach((item: any) => {
                        item.branch_id = fls.find(value => value.id === item.branch_id).name
                    })
                    if (data.length > 0) {
                        data.map((item: any, index: number) => {
                            if (dataRequest.page && dataRequest.page > 2) {
                                item['num'] = ((dataRequest.page - 1) * 20) + index + 1
                            } else if (dataRequest.page == 2) {
                                item['num'] = 21 + index
                            } else {
                                item['num'] = index + 1
                            }
                            return item
                        })
                    }
                    // setData(result?.data?.result || [])
                    transactions.setTransactionsData(data)


                }).catch(err => setError({
                    status: err?.response?.status,
                    message: err?.response?.data?.message,
                    isError: true
                })).finally(() => {
                    setIsLoading(false)
                    setLoaded(true)
                })

            setIsLoading(false)
        }

        setIsLoading(false)
    }


    if (!loaded) return <UsersListLoading/>


    return (
        <>
            {isLoading && <UsersListLoading/>}
            <form onSubmit={(e) => {
                e.preventDefault()
                setSearchText(searchTextController)
            }} className='d-flex align-items-center position-relative my-1 mb-5'>

                <input
                    style={{border: "none", borderRadius: "6px", height: "44px"}}
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control pe-5'
                    placeholder='Поиск по ID или сумме'
                    value={searchTextController}
                    onChange={(e) => setSearchTextController(e.target.value)}
                />
                {searchTextController.length > 0 &&
                    <span className='position-absolute end-0 me-8 pe-8' onClick={() => {
                        setSearchTextController("")
                        setSearchText("")
                    }}>
                            <KTSVG
                                path='/media/icons/duotune/general/gen034.svg'
                                className='svg-icon-1 text-hover-primary'
                            />
                        </span>}
                <button type="submit"
                        style={{borderTopRightRadius: "6px", borderBottomRightRadius: "6px", width: "44px"}}
                        className='border-0 text-hover-secondary cursor-pointer position-absolute end-0 bg-primary h-100 d-flex justify-content-center align-items-center'>
                    <KTSVG
                        path='/media/icons/duotune/general/gen021.svg'
                        className='svg-icon-1 text-white'
                    />
                </button>
            </form>
            <KTCard>
                <KTCardBody className='py-4'>

                    <div className='table-responsive'>
                        <div className='d-flex'>
                            <div className='filter_buttons_group my-4 col-lg-6'>

                                <div>
                                    <a
                                        href='#'
                                        className='badge bg-light bg-active-primary fw-medium text-primary px-4 py-2'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                        onClick={() => setShowFilter(!showFilter)}
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen031.svg'
                                            className='svg-icon-5 text-primary me-1'
                                        />
                                        {filtersCount > 1 ? "Применено" : "Применен"} {filtersCount} {filtersCount > 1 ? "фильтра" : filtersCount === 1 ? "фильтр" : "фильтров"}
                                    </a>
                                </div>

                                <Modal size="sm" show={showFilter} onHide={() => setShowFilter(false)}>
                                    <FilterDropDown
                                        show={showFilter} filters={filters} updateFilter={(val: any) => {
                                        setShowFilter(false)

                                        let count = 1
                                        if (val.ppc_types.length > 0) count++
                                        if (val.statuses.length > 0) count++
                                        setFiltersCount(count)
                                        setFilters(val)
                                    }} handleClose={() => setShowFilter(false)}/>
                                </Modal>

                                {!isProd() && <span className="badge px-4 py-2 mt-3"
                                                    style={{
                                                        border: "1px solid #F5F8FA",
                                                        color: "rgba(76, 76, 102, 1)"
                                                    }}><span
                                    style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'PLATFORMS'})}: &nbsp;</span>
                                    {
                                        statuses_platforms.length >= 7 ? 'Все' : <ArrayToSpanFormatter
                                            items={statuses_platforms.map(item => item.toUpperCase())}/>
                                    }
                            </span>}
                                {filters.statuses.length > 0 && <span className="badge px-4 py-2 ms-4"
                                                                      style={{
                                                                          border: "1px solid #F5F8FA",
                                                                          color: "rgba(76, 76, 102, 1)"
                                                                      }}><span
                                    style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'STATUS'})}: &nbsp;</span>
                                    {<ArrayToSpanFormatter
                                        items={statuses_const.every((item: any) => filters.statuses.includes(item.toUpperCase())) ? [intl.formatMessage({id: "all"})] : filters.statuses.map((item: any) => intl.formatMessage({id: item.toUpperCase()}))}/>}</span>}
                                {filters.ppc_types.length > 0 && <span className="badge px-4 py-2 ms-4"
                                                                       style={{
                                                                           border: "1px solid #F5F8FA",
                                                                           color: "rgba(76, 76, 102, 1)"
                                                                       }}><span
                                    style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'Cards'})}: &nbsp;</span>
                                    {<ArrayToSpanFormatter
                                        items={filters.ppc_types.map((item: any) => intl.formatMessage({id: item.toUpperCase()}))}/>}</span>}

                            </div>

                            <div className='col-lg-6' style={{textAlign: 'right'}}>
                                <span className='fs-6 text-gray-400'>Транзакции: <span
                                    className='text-gray-800 fs-3 fw-bold me-5'>{general?.count || 0}</span></span>
                                <span className='fs-6 text-gray-400'>Сумма: <span
                                    className='text-gray-800 fs-3 fw-bold me-5'>{NumReplace(general?.amount ? (general?.amount / 100) + '' : '0')} UZS</span></span>
                            </div>
                        </div>

                        <table
                            id='kt_table_users'
                            className='table fw-normal align-middle table-row-bordered fs-6 gy-5 dataTable no-footer table-hover cursor-pointer'
                            {...getTableProps()}
                        >
                            <thead>
                            <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                                {headers.map((column: ColumnInstance<ModSession>) => {
                                        if (column.id === "branch_id" && cashboxes.length <= 1) return
                                        if (isProd() && column.id === "platform") return
                                        return (
                                            <CustomHeaderColumn key={column.id} column={column}/>
                                        )
                                    }
                                )}
                            </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-normal' {...getTableBodyProps()}>
                            {rows.length > 0 ? (
                                rows.map((row: Row<ModSession>, i) => {
                                    prepareRow(row)
                                    return <CustomRow row={row} key={`row-${i}-${row.id}`}
                                                      getTransactionFiscalChequeData={(val) => getTransactionFiscalChequeData(val)}
                                                      onClick={() => {
                                                      }}/>
                                })
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            {intl.formatMessage({id: 'NO_MATCHING'})}
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                    {!isLoading && pagination ?
                        <Pagination infoText='Транзакций' withInfo={true} position={'end'} pagination={pagination}
                                    updatePage={(val: any) => {
                                        getTransaction({pagination: val})
                                    }}/> : ''}
                    {isLoading && ''}

                    <TransactionFiscalDataModal onCancel={() => setTransactionFiscalDataModalShow(false)}
                                                shouldShow={transactionFiscalDataModalShow}
                                                data={transactionFiscalData}/>
                </KTCardBody>
            </KTCard>
        </>
    )
})


type ArrayToSpanFormatter = {
    items: Array<string>
}

const ArrayToSpanFormatter = ({items}: ArrayToSpanFormatter) => {
    let data
    if (items.length > 3) {
        const newItems = items.slice(0, 3)
        data = newItems.join(", ") + ` и еще + ${items.length - newItems.length}`
    } else {
        data = items.join(", ")
    }
    return <span>{data}</span>
}


type TransactionFiscalDataModal = {
    onCancel: Function
    shouldShow: boolean
    data: any
}

const TransactionFiscalDataModal: FC<TransactionFiscalDataModal> = ({shouldShow, onCancel, data}) => {
    const intl = useIntl()

    const handleClose = () => {
        onCancel()
    }

    return <Modal show={shouldShow} onHide={handleClose}>
        <Modal.Header closeButton>
            <h2>{intl.formatMessage({id: 'TRANSACTION_DATA'})}</h2>
        </Modal.Header>
        <Modal.Body>
            {
                (data || []).map((item: any) => {
                    return (
                        <div key={item.transaction_id} className='mt-3'>
                            <div className="mb-3 w-100">
                                <div className='h2'>{intl.formatMessage({id: 'TRANSACTION_ID'})}:</div>
                                <div className="ms-4 fw-semibold fs-4">{item.transaction_id}</div>
                            </div>
                            <div className="mb-3 w-100">
                                <div className='h2'>{intl.formatMessage({id: 'FISCAL_SIGN'})}:</div>
                                <div className="ms-4 fw-semibold fs-4">{item.fiscal_sign}</div>
                            </div>
                            <div className="mb-3 w-100">
                                <div className='h2'>{intl.formatMessage({id: 'TERMINAL_ID'})}:</div>
                                <div className="ms-4 fw-semibold fs-4">{item.terminal_id}</div>
                            </div>
                            <div className="w-100 d-flex justify-content-center flex-column mt-8">
                                {/*<div className='h2'>{intl.formatMessage({id: 'QR_CODE_URL'})}:</div>*/}
                                {/*<img*/}
                                {/*    src={data.qr_code_url}*/}
                                {/*    alt="QR-CODE" className="w-100 text-center"/>*/}
                                <a href={item.qr_code_url} target='_blank'
                                   className="w-100 text-break text-center">{intl.formatMessage({id: 'QR_CODE_URL'})}</a>
                            </div>
                        </div>
                    )
                })
            }
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
            <button type="button" className="swal2-confirm btn btn-secondary" aria-label=""
                    style={{display: 'inline-block'}} onClick={handleClose}>
                OK
            </button>
        </Modal.Footer>
    </Modal>
}

