import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {DatePicker, Drawer, Dropdown, Menu, Button, Space, Typography, MenuProps, Tooltip} from 'antd';
import {useIntl} from "react-intl";
import moment from "moment/moment";
import {useErrors} from "@providers/context/ErrorsProvider";
import "@metronic/assets/sass/style.scss"
import {
    CANCELED,
    DECLINED,
    statuses_card,
    statuses_const,
    SUCCESSFUL
} from "@utils/constants";
import {DownOutlined, MinusOutlined} from '@ant-design/icons';

import {CardsChartWidget} from "../../chart";
import {isDemo, NumReplace} from "@utils/index";
import {toIsoStringHelper} from "../../../autopay/dateHelper";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import localeEn from "antd/es/date-picker/locale/en_US";


type Props = {
    open?: boolean
    dataStatistic: any
    setDataCharts: (val: any) => void
    params: any
    onDateChange: Function
}




const ActivityDrawerAdmin: FC<Props> = ({open, params, setDataCharts, onDateChange, dataStatistic}) => {

    const [from_date, setFromDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'))
    const [to_date, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [loading, setLoading] = useState(true)
    const [dataRender, setDataRender] = useState<any>(null)
    const [currentCard, setCurrentCard] = useState<string[]>([])
    const [currentStatus, setCurrentStatus] = useState<string>('')
    const [statistic_data, setStatistic_data] = useState<any[]>([])
    const intl = useIntl()
    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang


    useEffect(() => {

        if (params && open) {

            if (params?.to_date === params?.from_date) {
                setToDate(params?.to_date)
                setFromDate(moment(params?.from_date).subtract(1, 'week').format('YYYY-MM-DD'))
            } else {
                setToDate(params?.to_date)
                setFromDate(params?.from_date)
            }

            if (params.ppc_types.length > 0) {
                setCurrentCard(params.ppc_types.map((item: string) => item.toUpperCase()))
            }

            setStatistic_data(dataStatistic)
            setCurrentStatus(params.statuses.length>1?'ALL':params.statuses[0])


            getResult()



        }
    }, [params, dataStatistic])




    useEffect(() => {
        if (dataRender) {
            setLoading(false)
        }
    }, [dataRender])


    useEffect(() => {
        if (!loading && params) {
            getResult()
        }
    }, [currentStatus, currentCard])

    const onClose = () => {
        setDataCharts(null);
        setCurrentStatus('')
        setCurrentCard([])
    }


    async function getResult() {

        setLoading(true)

        const data:any = {
            categories: [],
            series: []
        }

        data.series = dataStatistic.map((item:any)=>item.date)


        dataParse(dataStatistic, params.ppc_types, currentStatus!==''?currentStatus.toUpperCase():params.statuses.length>1?'ALL':params.statuses[0])

    }

    function dataParse(data: any[], cards: string[], status: string) {
        const sortedData = data.sort((a, b) => (new Date(a.date.split('/').reverse().join('/')).getTime() < new Date(b.date.split('/').reverse().join('/')).getTime() ? 1 : -1)).reverse()


        let date_arr = sortedData.map(item => (new Date(item.date.split('/').reverse().join('/')).getTime() - new Date().getTimezoneOffset() * 60000))


        const series = cards.map((card: string) => {
            let name, dashboard

            name = `${card} - ${intl.formatMessage({id: status})}`


            dashboard = sortedData.map(item => {
                if (status.toLowerCase() === "all") {
                    return item.details[card.toLowerCase()].amount
                }

                return item.details[card.toLowerCase()].statuses[status.toLowerCase()].amount
            })



            return {name, data: dashboard}
        })



        setDataRender({series, categories: date_arr})


    }

    function getCountStatuses(type: string = 'successful', currency = 'UZS') {
        let result: any = []
        if (statistic_data.length) {

            if (type === 'all') {
                statuses_card.forEach((item: any) => {
                    let sum = 0,
                        count = 0
                    if (statistic_data.length > 1) {
                        sum = statistic_data.reduce((previousValue: any, currentValue: any) => {

                            if (Object.keys(currentValue.details).includes(item)) {
                                if (typeof previousValue === 'object') {
                                    return previousValue.details[item].amount + currentValue?.details[item].amount
                                }

                                return previousValue + currentValue?.details[item].amount
                            }

                            return 0


                        })

                        count = statistic_data.reduce((previousValue: any, currentValue: any) => {

                            if (Object.keys(currentValue.details).includes(item)) {
                                if (typeof previousValue === 'object') {
                                    return previousValue.details[item].count + currentValue?.details[item].count
                                }

                                return previousValue + currentValue?.details[item].count
                            }

                            return 0


                        })
                    } else {
                        if (statistic_data.length && Object.keys(statistic_data[0].details).includes(item)) {
                            sum = statistic_data[0].details[item].amount
                            count = statistic_data[0].details[item].count
                        }

                    }

                    result.push({
                        card: item.toUpperCase(),
                        sum: sum ? NumReplace(sum / 100 + '') + ' ' + currency : 0,
                        count

                    })

                })
            } else {
                statuses_card.forEach((item: any) => {

                    let sum = 0,
                        count = 0

                    if (statistic_data.length > 1) {
                        sum = statistic_data.reduce((previousValue: any, currentValue: any) => {

                            if (Object.keys(currentValue.details).includes(item)) {
                                if (typeof previousValue === 'object') {
                                    return previousValue.details[item].statuses[type].amount + currentValue?.details[item].statuses[type].amount
                                }

                                return previousValue + currentValue?.details[item].statuses[type].amount
                            }

                            return 0

                        })

                        count = statistic_data.reduce((previousValue: any, currentValue: any) => {
                            if (Object.keys(currentValue.details).includes(item)) {

                                if (typeof previousValue === 'object') {
                                    return previousValue.details[item].statuses[type].count + currentValue?.details[item].statuses[type].count
                                }

                                return previousValue + currentValue?.details[item].statuses[type].count
                            }

                            return 0

                        })
                    } else {
                        if (statistic_data.length && Object.keys(statistic_data[0].details).includes(item)) {
                            sum = statistic_data.length ? statistic_data[0].details[item].statuses[type].amount : 0
                            count = statistic_data.length ? statistic_data[0].details[item].statuses[type].count : 0
                        }
                    }


                    result.push({
                        card: item.toUpperCase(),
                        sum: sum ? NumReplace(sum / 100 + '') + ' ' + currency : 0,
                        count

                    })


                })
            }

        } else {

            result = statuses_card.map((item: any) => {
                return {
                    card: item.toUpperCase(),
                    sum: 0,
                    count: 0
                }
            })
        }

        return result

    }


    const sumAmount = (status: string) => {
        if (statistic_data.length) {
            if (statistic_data.length > 1) {
                if (status === 'all') {
                    return statistic_data.reduce((previousValue: any, currentValue: any) => {

                        if (typeof previousValue === 'object') {
                            return previousValue.amount + currentValue.amount
                        }

                        return previousValue + currentValue.amount
                    }) / 100
                }

                return statistic_data.reduce((previousValue: any, currentValue: any) => {
                    if (typeof previousValue === 'object') {
                        return previousValue.statuses[status].amount + currentValue.statuses[status].amount
                    }

                    return previousValue + currentValue.statuses[status].amount
                }) / 100
            }

            return status !== 'all' ? statistic_data[0]?.statuses[status].amount / 100 : statistic_data[0]?.amount / 100


        }
    }





    const handleMenuClick: MenuProps['onClick'] = e => {
        setCurrentStatus(e.key)
    };

    const menu = (
        <Menu
            onClick={handleMenuClick}
            selectable
            defaultSelectedKeys={statuses_const.filter(item => item.toUpperCase() === currentStatus)}
            items={[
                {
                    key: 'all',
                    label: intl.formatMessage({id: "all".toUpperCase()}),
                },
                {
                    key: 'revert',
                    label: intl.formatMessage({id: "revert".toUpperCase()}),
                },
                {
                    key: 'approved',
                    label: intl.formatMessage({id: "approved".toUpperCase()}),
                },
                {
                    key: 'failed',
                    label: intl.formatMessage({id: "failed".toUpperCase()}),
                },
            ]}
        />
    );

    function replaceFormat(date:any, formatFrom:string, formatTo:string){
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }





    return (
        <Drawer width={`70%`} placement="right" closable={false} onClose={onClose} open={open}>

            <div className={`card-header d-flex align-items-center justify-content-between `}
                 style={{padding: "0 24px", marginTop: "15px"}}>
                {
                    params?.statuses?(<h3 className='card-title fw-bolder text-dark'>{intl.formatMessage({id: `transaction-${params?.statuses ? `${params?.statuses.length > 1 ? 'all' : params?.statuses[0].toLowerCase() ?? ''}` : ''}`})} </h3>):''
                }

                <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon'
                        onClick={onClose}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </button>
                </div>
            </div>
            <div className="mt-5" style={{padding: "24px"}}>
                <div>
                    <div className='toolbar-filter'>
                        <div className='row align-items-center mb-3 justify-content-end'>
                            <div className='col-6 d-flex justify-content-start'>
                                {dataRender && dataRender.series && dataRender.series.map((item: { name: string, data: number[] }) => {
                                    const icons: any = {
                                        'uzcard': '/media/svg/Uzcard (2).svg',
                                        'humo': '/media/svg/Humo.svg',
                                        'vm': '/media/svg/vm.svg'
                                    }

                                    return <div className='d-flex align-items-center ms-2'>
                                        <div style={{
                                            width: "24px",
                                            height: "8px",
                                            backgroundColor: item.name.toLowerCase().includes("uzcard") ? "#233C7E" : item.name.toLowerCase().includes("vm")?'#F79E1B':"#BEA645",
                                            marginRight: "8px",
                                            borderRadius: "5px"
                                        }}/>
                                        <div className='bg-lighten'
                                             style={{width: '25px', height: '25px'}}>
                                            <img
                                                src={item.name.toLowerCase().includes("uzcard") ? icons.uzcard : item.name.toLowerCase().includes("vm")?icons.vm: icons.humo}
                                                className='w-100 h-100'
                                                style={{objectFit: 'contain'}}/>
                                        </div>
                                        <p className='mx-2 mb-0 fw-semibold'
                                           style={{color: 'var(--kt-gray-600)'}}>{item.name}</p>
                                    </div>


                                })}
                            </div>
                            <div className='col-6 d-flex justify-content-end'>
                                {
                                    params && params?.statuses.length > 0 && (<div className="pe-7">
                                        <Dropdown overlay={menu}>
                                            <Button className='rounded datepicker_form form-control-lg form-control-solid'>
                                                <Space>
                                                    {intl.formatMessage({id: currentStatus !== ""&&currentStatus ? currentStatus.toUpperCase() : "ALL"})}
                                                    <DownOutlined
                                                        style={{fontSize: 10, display: "flex", justifyContent: "center"}}/>
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    </div>)}
                                <DatePicker.RangePicker
                                    style={{border: "none"}}
                                    separator={<MinusOutlined/>}
                                    locale={lang === 'ru' ? localeRu : localeEn}
                                    key={`${from_date}-${to_date}`}
                                    placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                                    className='datepicker_form form-control-lg form-control-solid'
                                    defaultValue={[replaceFormat(from_date, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                                        replaceFormat(to_date, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                                    format='DD-MM-YYYY'
                                    disabledDate={current => {
                                        return isDemo() || current && current > moment().endOf('day');
                                    }}
                                    //@ts-ignore
                                    onChange={(dates: [any, any], dateStrings: [string, string]) => {
                                        if (dates) {
                                            let from_date = replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                                            let to_date = replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format()

                                            setFromDate(from_date)
                                            setToDate(to_date)
                                            const data = {
                                                branches: params.branches,
                                                // from_date: dateStrings[0],
                                                from_date: toIsoStringHelper(new Date(from_date)),
                                                // to_date: dateStrings[1],
                                                // to_date: new Date(new Date(dateStrings[1]).setTime(new Date(dateStrings[1]).getTime() + 1000 * 60 * 60 * 24 - 1000)).toISOString(),
                                                to_date: toIsoStringHelper(new Date(new Date(to_date).setTime(new Date(to_date).getTime() + (new Date().getTimezoneOffset() * 60000) + (3600000 * 24) - 1000))),
                                                statuses: params.statuses,
                                                ppc_types: currentCard.length ? currentCard : [params?.ppc_types[0].toUpperCase()]
                                            }
                                            // getResult(data)
                                            onDateChange(dates, dateStrings)
                                        }

                                    }}
                                />
                            </div>

                        </div>

                    </div>

                </div>
                <div>
                    {!loading && dataRender ? (
                        <>


                            {dataRender ?
                                <CardsChartWidget key={dataRender?.series.join(',')} className='card-xl-stretch mb-xl-8'
                                                  data={dataRender}/> : !loading &&
                                <h3 className='text-center'>{intl.formatMessage({id: 'no_data_statistic'})}</h3>}

                        </>
                    ) : <div className='mb-5 mt-15 p-3' style={{minHeight: '200px'}}>
                        {!loading && <h3 className='text-center'>{intl.formatMessage({id: 'no_data_statistic'})}</h3>}
                    </div>}
                </div>

                <div className="d-flex justify-content-between row mt-5 gx-3 row g-5 g-xl-8">
                    <ChartMiniWidgetMixed
                        className='col-xl-3 col-lg-3 mb-xl-8' color='dark-blue' data={{
                        status: 'ALL',
                        sum: [`${sumAmount('all') ? NumReplace(sumAmount('all') + '') : 0} UZS`],
                        details: getCountStatuses('all')
                    }}/>

                    <ChartMiniWidgetMixed className='col-xl-3 col-lg-3 mb-xl-8' color='success' data={{
                        status: 'APPROVED',
                        sum: [`${sumAmount('approved') ? NumReplace(sumAmount('approved') + '') : 0} UZS`],
                        details: getCountStatuses('approved')
                    }}/>

                    <ChartMiniWidgetMixed className='col-xl-3 col-lg-3 mb-xl-8' color='warning' data={{
                        status: 'FAILED',
                        sum: [`${sumAmount('failed') ? NumReplace(sumAmount('failed') + '') : 0} UZS`],
                        details: getCountStatuses('failed')
                    }}/>

                    <ChartMiniWidgetMixed className='col-xl-3 col-lg-3 mb-xl-8' color='danger' data={{
                        status: 'REVERT',
                        sum: [`${sumAmount('revert') ? NumReplace(sumAmount('revert') + '') : 0} UZS`],
                        details: getCountStatuses('revert')
                    }}/>
                </div>

            </div>

        </Drawer>
    )
}


type ChartMiniWidgetMixedProps = {
    className: string
    color: string
    data: {
        status: string
        sum: string[]
        details: {
            card: string
            sum: string
            count: number
        }[]

    }
    children?: any
}

export const ChartMiniWidgetMixed: FC<ChartMiniWidgetMixedProps> = ({className, color, children, data}) => {
    const intl = useIntl()


    return <div className={`${className} `}>
        <div className={`card shadow`} style={{padding: "20px"}}>
            <div className={`bg-${color} w-100`} style={{
                height: "8px",
                position: "absolute",
                top: "0",
                left: "0",
                borderRadius: "12px 12px 0 0"
            }}/>
            <div className='card-body p-0 position-relative'>
                <div className='d-flex flex-stack'>
                    <h3 className='m-0 pt-1 fw-bold fs-4'
                        style={{color: "#464E5F",}}>{intl.formatMessage({id: data?.status})}</h3>
                </div>
                {/*<div style={{display: "flex", flexDirection: "column"}}>*/}
                {/*    <div className='d-flex'>*/}
                {/*        <div className=' bg-white' style={{*/}
                {/*            height: "10px",*/}
                {/*            width: "10px",*/}
                {/*            borderRadius: "1.5px",*/}
                {/*            marginRight: "3px",*/}
                {/*            marginBottom: "3px"*/}
                {/*        }}></div>*/}
                {/*        <div style={{*/}
                {/*            height: "10px",*/}
                {/*            width: "10px",*/}
                {/*            borderRadius: "1.5px",*/}
                {/*            backgroundColor: "#DADADA",*/}
                {/*            opacity: "0.5"*/}
                {/*        }}></div>*/}
                {/*    </div>*/}
                {/*    <div className='d-flex'>*/}
                {/*        <div style={{*/}
                {/*            height: "10px",*/}
                {/*            width: "10px",*/}
                {/*            borderRadius: "1.5px",*/}
                {/*            marginRight: "3px",*/}
                {/*            marginBottom: "3px", backgroundColor: "#DADADA", opacity: "0.5"*/}
                {/*        }}></div>*/}
                {/*        <div style={{*/}
                {/*            height: "10px",*/}
                {/*            width: "10px",*/}
                {/*            borderRadius: "1.5px",*/}
                {/*            backgroundColor: "#DADADA",*/}
                {/*            opacity: "0.5"*/}
                {/*        }}></div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={`pt-5 card-rounded w-100`}>
                    {
                        data.sum.map((item, index) => {
                            return (
                                <span key={index} className='fw-bold fs-3 pt-1'>{item}</span>
                            )
                        })
                    }
                    {/*<div className='d-flex flex-stack'>*/}
                    {/*    <h3 className='m-0 pt-1 fw-semibold fs-5'>{intl.formatMessage({id: data?.status})}</h3>*/}
                    {/*</div>*/}
                </div>
                <div className={`card-rounded w-100`} style={{color: "#8B8D99"}}>
                    <span className='fw-bold fs-7 pt-1'>Кол-во: {data.details.reduce(
                        (previousValue, currentValue) => previousValue + currentValue.count, 0)}
                    </span>
                </div>
            </div>
        </div>
    </div>
}

export {ActivityDrawerAdmin}
