import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from "@metronic/layout/core";
import Alert from "react-bootstrap/Alert";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import Accordion from "react-bootstrap/Accordion";
import {useNavigate, useParams} from "react-router-dom";
import {CreateCashbox} from "@providers/filial/_models";
import {Filial} from "@providers/filial/_request";
import {filial} from "@store/filial";
import {FilialSettingsSchema, inits} from "./CompanyAboutHelper";
import InputMask from "react-input-mask";
import {Company} from "@providers/company/_request";
import AsyncSelect from "react-select/async";
import {customStyles2} from "@metronic/assets/ts/select.style";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {isDemo, localErrorsMessage, ReplacePhone, roleRouting, roleRoutingAdmin} from "@utils/index";
import {Tooltip} from "antd";
import {employeeStore} from "@store/employee";
import {observer} from "mobx-react";
import {KTCard, KTCardBody} from "@metronic/helpers";
import {Modal} from "react-bootstrap";
import {DropImages} from "@metronic/partials/DropImages";
import * as Yup from "yup";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import Eposes from "./components/eposes/eposes";
import {divideByHundred, multiplyByHundred} from "@utils/numbersOperations";
import {priceformatter} from "@utils/priceformatter";

const Settings: FC = observer(() => {

    const [currentSchema, setCurrentSchema] = useState(FilialSettingsSchema())
    const {companyId, id} = useParams()
    const [initValues, setInitValues] = useState<CreateCashbox>(inits)
    const [deactivate, setDeactivate] = useState(false)
    const [alert, setAlert] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {setError} = useErrors()
    const [type, setType] = useState<any[]>([])
    const [fields, setFields] = useState<any[]>([])
    const [parentField, setParentField] = useState(0)
    const [childFields, setChildFields] = useState<any[]>([])
    const [defaultChildField, setDefaultChildField] = useState({})
    const [formShow, setFormShow] = useState(0)
    const intl = useIntl()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [statusChecked, setStatusChecked] = useState('')
    const [selfEmployee, setSelfEmployee] = useState(false)
    const [readFile, setReadFile] = useState<any | null>(null)

    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
        if (companyId && id) {
            getData()
        }
    }, [])


    useEffect(() => {
        if (selfEmployee) {
            currentSchema.shape({
                pinfl: Yup.string()
                    .min(14, 'Не достаточно символов')
                    .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            })

        } else {
            currentSchema.shape({
                oked: Yup.string().required('Обязательное поле')
                    .min(5, 'Не достаточно символов').required('Обязательное поле'),
                legal_name: Yup.string()
                    .required('Обязательное поле')
                    .max(50, 'Слишком много символов').required('Обязательное поле'),
                brand_name: Yup.string()
                    .max(50, 'Слишком много символов'),
                nds: Yup.string()
                    .min(12, 'Не достаточно символов'),
            })
        }

        setCurrentSchema(currentSchema)

    }, [selfEmployee])

    useEffect(() => {
        if (parentField) {
            const children = fields.find((item: any) => item.value === parentField)?.children || []
            setChildFields(children.map((item: any) => ({value: item.id, label: item.name})))
        }
    }, [parentField])


    useEffect(() => {
        getFilial()
    }, [fields])

    function setInit(data: any) {
        setStatusChecked(data?.status)
        fields.forEach((item: any) => {
            setInitValues({
                ...data,
                min_amount: divideByHundred(data.min_amount),
                max_amount: divideByHundred(data.max_amount)
            })
        })
    }

    function getFilial() {
        if (companyId && id) {
            Filial.getFilialById(companyId, id)
                .then(response => {
                    if (response?.data?.result) {
                        setInit(response?.data?.result)
                    }
                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            }).finally(() => setLoaded(true))
        }
    }


    const filterOptions = (inputValue: string, values: any[]) => {
        return values.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }


    const getData = async () => {
        await companyTypes()
        await companyFields()
    }


    const onSub = async (values: CreateCashbox) => {
        if (isDemo()) return
        setLoading(true)
        await updateFilial(values)
        setFormShow(0)
        setLoading(false)
    }


    const updateFilial = async (values: CreateCashbox) => {
        const spacesRegEx = /\s/g


        const value_replace: any = {
            max_amount: values.max_amount ? multiplyByHundred(Number(values.max_amount.toString().replace(spacesRegEx, ''))) : 0,
            min_amount: values.min_amount ? multiplyByHundred(Number(values.min_amount.toString().replace(spacesRegEx, ''))) : 0
        }

        const isNotAdded = ['min_amount', 'max_amount']

        for (let key in values) {
            //@ts-ignore
            if (values[key] && values[key].length && !isNotAdded.includes(key)) {
                //@ts-ignore
                value_replace[key] = values[key]
            }
        }



        if (values.request_fields) {
            value_replace['request_fields'] = values.request_fields.map((item) => {
                return {
                    ...item,
                    field_type: 'test',
                    field_control: 'test'
                }
            })
        }


        const files: any = {
            logo_file: values.logo_file
        }


        const formData = new FormData()

        for (let val in files) {
            if (files[val]) {
                formData.append(val, files[val])
            }
        }


        // await Filial.putFilial(companyId ?? '', id ?? '', formData)
        await Filial.putFilial(companyId ?? '', id ?? '', value_replace)
            .then(async () => {
                if (Object.keys(files).length > 0)
                    await Filial.postFilialFiles(companyId ?? "0", formData, id ?? "0")
            })
            .then(() => {
                setAlert('Касса успешно отредактирована')
                updateData()
                if (!roleRoutingAdmin('action', employeeStore.getRole, 'is-admin')) {
                    setTimeout(() => {
                        navigate(`/${companyId}/filial`)
                    }, 3000)
                } else {
                    setInitValues({...value_replace})
                    setTimeout(() => {
                        setAlert(null)
                    }, 3000)

                    window.scroll({
                        top: 0,
                        behavior: 'smooth'
                    })
                }

            }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })

        if (statusChecked !== 'DRAFT' && roleRoutingAdmin('action', employeeStore.getRole, 'change-status')) {
            Filial.changeStatusRequest(companyId ? +companyId : 0, id ? +id : 0, {status: statusChecked})
                .then((result) => {
                    setStatusChecked(result?.data?.result?.status)
                    filial.setFilialOne(result?.data?.result)
                })
                .catch(err => {
                    const message = localErrorsMessage('change-status', 'message-match', err?.response?.data?.message, intl)
                    setError({status: err?.response?.status, message: message, isError: true})
                })
        }
    }


    function updateData() {
        if (companyId && id) {
            Filial.getFilialById(companyId, id)
                .then(result => {
                    filial.setFilialOne(result?.data?.result)
                    setInit(result?.data?.result)
                    setReadFile(null)
                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
        }
    }

    const deleteItem = (companyId: string, id: string) => {
        if (deactivate) {
            Filial.deleteFilial(companyId, id)
                .then(() => {
                    setAlert('Касса успешна удалена')

                    if (roleRoutingAdmin('action', employeeStore.getRole, 'is-admin')) {
                        setTimeout(() => {
                            navigate(`/companies/${companyId}`)
                        }, 1000)
                    } else {
                        setTimeout(() => {
                            navigate(`/${companyId}/cashboxes`)
                        }, 1000)
                    }

                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
        }
    }


    const companyTypes = async () => {
        await Company.getCompanyTypes()
            .then((response) => {
                setType((response?.data?.result || []).map((item: any) => ({value: item.id, label: item.name})))
            })
    }

    const companyFields = async () => {
        await Company.getCompanyFields()
            .then((response) => {
                setFields((response?.data?.result || []).map((item: any) => ({
                    value: item.id,
                    label: item.name,
                    children: item.children
                })))
            })
    }

    if (!loaded) return <UsersListLoading/>

    return (
        <div>
            {loading && <UsersListLoading/>}

            <div className='d-flex flex-row-fluid flex-center position-relative'>
                {alert && (
                    <Alert className='position-fixed' style={{zIndex: "3", top: "16%"}} key={'success'}
                           variant={'success'}>
                        {alert}
                    </Alert>
                )}
                <Formik enableReinitialize={true} validationSchema={currentSchema} initialValues={initValues}
                        onSubmit={onSub}>
                    {(props) => {
                        const {
                            setFieldValue,
                            errors,
                            values,
                            setValues
                        } = props
                        const requisites =
                            [
                                {
                                    label: 'cashbox__name',
                                    value: values.name
                                },
                                {
                                    label: 'legal_name',
                                    value: values.legal_name
                                },
                                {
                                    label: 'gnk_spic',
                                    value: values.gnk_spic
                                },
                                {
                                    label: 'min_amount',
                                    value: values.min_amount ? priceformatter(values.min_amount) : 0
                                },
                                {
                                    label: 'max_amount',
                                    value: values.max_amount ? priceformatter(values.max_amount) : 0
                                }
                            ]

                        return (
                            <Form className='w-100'>


                                <KTCard className='mt-5'>
                                    <div className='card-header d-flex py-5 px-10 align-items-center'>
                                        <h3 className='mb-0'>{intl.formatMessage({id: "settings_business"})}</h3>
                                        {
                                            formShow !== 3 ? (<button onClick={() => {
                                                setFormShow(3)
                                            }} type='button' className='btn btn-md btn-primary'>
                                              <span className='indicator-label'>
                                                {intl.formatMessage({id: "EDIT_BUTTON"})}
                                              </span>
                                            </button>) : ''
                                        }
                                    </div>
                                    <KTCardBody>
                                        {
                                            formShow === 3 ? (<div className='w-100'>
                                                    <div className='w-100'>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "cashbox__name"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='name'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='name'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "legal_name"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='legal_name'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='legal_name'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "gnk_spic"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='gnk_spic'
                                                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                            if (e.currentTarget.value.match(/^\d{0,9}$/)) props.handleChange(e)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='gnk_spic'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {roleRoutingAdmin('action', employeeStore.getRole, 'edit-nds-rate') &&
                                                            <div className='mb-10 w-100'>
                                                                <div className='d-flex'>
                                                                    <label
                                                                        className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: "nds_rate"})}</label>
                                                                    <div className='col-lg-9'>
                                                                        <Field
                                                                            type='number'
                                                                            className='form-control form-control-lg'
                                                                            name='nds_rate'
                                                                            onChange={props.handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex'>
                                                                    <div className='col-lg-3'/>
                                                                    <div className='text-danger mt-2'>
                                                                        <ErrorMessage name='nds_rate'/>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: 'min_amount'})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        name='min_amount'
                                                                        placeholder=''
                                                                        className='form-control form-control-lg'
                                                                        onChange={(e: React.ChangeEvent<any>) => {
                                                                            setFieldValue('min_amount', e.target.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='min_amount'/>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: 'max_amount'})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        name='max_amount'
                                                                        className='form-control form-control-lg'
                                                                        onChange={(e: React.ChangeEvent<any>) => {
                                                                            setFieldValue('max_amount', e.target.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='max_amount'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "url"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='url'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='url'/>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {loaded ? requisites.map(({
                                                                                  label,
                                                                                  value
                                                                              }) => (
                                                        <div key={label} className='d-flex align-items-center mb-5'>
                                                            <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id: label})}</p>
                                                            <p className='mb-0 text-gray-800 fw-bold fs-5'>{value}</p>
                                                        </div>
                                                    )) : null}
                                                    <div className='d-flex align-items-center mb-5'>
                                                        <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id: 'url'})}</p>
                                                        <a href={values.url} target={'_blank'} rel={'noreferrer'}
                                                           className='mb-0 text-gray-800 fw-bold fs-5 text-hover-primary'>{values.url}</a>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </KTCardBody>

                                    {
                                        formShow === 3 ? (
                                            <div className='card-footer py-5 px-10 d-flex justify-content-end'>
                                                <button className='btn btn-light me-3'
                                                        onClick={() => setFormShow(0)}>{intl.formatMessage({id: 'Cancel'})}</button>
                                                <button className='btn btn-primary' type='submit'
                                                        onClick={() => {
                                                            let err_message = ''
                                                            if (Object.keys(errors).length) {
                                                                for (let err in errors) {
                                                                    //@ts-ignore
                                                                    err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                                }
                                                                setError({
                                                                    status: '',
                                                                    message: err_message,
                                                                    isError: true
                                                                })
                                                            }
                                                        }}>{intl.formatMessage({id: 'SAVE_EDITION'})}</button>
                                            </div>) : ''
                                    }
                                </KTCard>

                                {/*<div className='d-flex flex-stack mt-5 justify-content-end'>*/}
                                {/*    <Tooltip zIndex={isDemo() ? 1 : -1} color='var(--kt-body-bg)' title={() => <span*/}
                                {/*        style={{color: 'var(--kt-text-gray-600)'}}>{intl.formatMessage({id: "DEMO_DISABLED"})}</span>}>*/}
                                {/*        <div>*/}
                                {/*            <button onClick={() => {*/}
                                {/*                let err_message = ''*/}
                                {/*                if (Object.keys(errors).length) {*/}
                                {/*                    for (let err in errors) {*/}
                                {/*                        //@ts-ignore*/}
                                {/*                        err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"*/}
                                {/*                    }*/}
                                {/*                    setError({status: '', message: err_message, isError: true})*/}
                                {/*                }*/}

                                {/*            }} type='submit' className='btn btn-lg btn-primary'*/}
                                {/*                    disabled={loading || isDemo()}>*/}
                                {/*  <span className='indicator-label'>*/}
                                {/*    {intl.formatMessage({id: "SAVE_EDITION"})}*/}
                                {/*  </span>*/}
                                {/*            </button>*/}
                                {/*        </div>*/}
                                {/*    </Tooltip>*/}
                                {/*</div>*/}

                            </Form>
                        )
                    }}
                </Formik>
            </div>
            {
                initValues ? <Eposes getFilial={updateData} eposes={initValues.eposes}/> : null
            }
            {
                (roleRoutingAdmin('action', employeeStore.getRole, 'delete-filial') ||
                    roleRouting('filial/delete', employeeStore.getRole)) ? (<div className="card mt-10">
                    <div className="card-header border-0 cursor-pointer" role="button"
                         data-bs-toggle="collapse" data-bs-target="#kt_account_deactivate"
                         aria-expanded="true" aria-controls="kt_account_deactivate">
                        <div className="card-title m-0">
                            <h3 className="fw-bold m-0">{intl.formatMessage({id: "DELETE_CASHBOX"})}</h3>
                        </div>
                    </div>
                    <div id="kt_account_settings_deactivate" className="collapse show">
                        <div id="kt_account_deactivate_form" className="form">
                            <div className="card-body border-top p-9">
                                <div
                                    className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                                              <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20"
                                                                      rx="10" fill="currentColor"/>
																<rect x="11" y="14" width="7" height="2" rx="1"
                                                                      transform="rotate(-90 11 14)"
                                                                      fill="currentColor"/>
																<rect x="11" y="17" width="2" height="2" rx="1"
                                                                      transform="rotate(-90 11 17)"
                                                                      fill="currentColor"/>
															</svg>
														</span>
                                    <div className="d-flex flex-stack flex-grow-1">
                                        <div className="fw-semibold">
                                            <h4 className="text-gray-900 fw-bold">{intl.formatMessage({id: "DELETE_CASHBOX1"})}</h4>
                                            <div
                                                className="fs-6 text-gray-700">{intl.formatMessage({id: "WARNING_CASHBOX"})}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check form-check-solid fv-row">
                                    <input onChange={(e) => {
                                        setDeactivate(e.target.checked)
                                    }} name="deactivate" className="form-check-input" type="checkbox"
                                           value="" id="deactivate"/>
                                    <label
                                        className="form-check-label fw-semibold ps-2 fs-6">{intl.formatMessage({id: "confirm"})}</label>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                {
                                    isDemo() ? (<Tooltip zIndex={1} color='var(--kt-body-bg)'
                                                         title={() => <span
                                                             style={{color: 'var(--kt-text-gray-600)'}}>{intl.formatMessage({id: "DEMO_DISABLED"})}</span>}>
                                        <div>
                                            <button
                                                id="kt_account_deactivate_account_submit"
                                                type="button"
                                                className="btn btn-danger fw-semibold"
                                                disabled={!deactivate || isDemo()}>
                                                {intl.formatMessage({id: "DELETE_CASHBOX"})}
                                            </button>
                                        </div>
                                    </Tooltip>) : (<button
                                        id="kt_account_deactivate_account_submit"
                                        type="button"
                                        className="btn btn-danger fw-semibold"
                                        disabled={!deactivate}
                                        onClick={() => {
                                            setShowDeleteModal(true)
                                        }}>
                                        {intl.formatMessage({id: "DELETE_CASHBOX"})}
                                    </button>)
                                }

                            </div>
                        </div>
                    </div>
                </div>) : ''
            }

            <DeleteCompanyModal onCancel={() => setShowDeleteModal(false)}
                                companyName={(initValues?.name)}
                                setIsLoading={(val: boolean) => setLoading(val)}
                                onDelete={() => {
                                    setShowDeleteModal(false)
                                    deleteItem(companyId ?? '', id ?? '')
                                }}
                                shouldShow={showDeleteModal}/>
        </div>
    )
})

type DeleteCompanyModal = {
    onCancel: Function
    companyName: string,
    setIsLoading: (val: boolean) => void,
    onDelete: Function,
    shouldShow: boolean
}


export const DeleteCompanyModal: FC<DeleteCompanyModal> = ({
                                                               onCancel,
                                                               companyName,
                                                               setIsLoading,
                                                               onDelete,
                                                               shouldShow
                                                           }) => {
    const [deleteField, setDeleteField] = useState<string>("")
    const [hasError, setHasError] = useState<boolean>(false)
    const {setError} = useErrors()


    const handleClose = () => {
        onCancel()
    }

    const intl = useIntl()

    const handleDelete = async () => {

        if (deleteField.replace(/ /g, '').trim() !== companyName.replace(/ /g, '').trim()) {
            setHasError(true)
            return
        } else {
            setHasError(false)
        }
        try {
            setIsLoading(true)
            onDelete()
        } catch (error: Error | any) {
            setError({
                status: error?.response?.status,
                message: error?.response?.data?.message,
                isError: true
            })
        } finally {
            setIsLoading(false)
        }
    }


    return <Modal show={shouldShow} onHide={handleClose}>
        <Modal.Header closeButton>
            <h2>{intl.formatMessage({id: "CHECK_DELETE"})}</h2>
        </Modal.Header>
        <Modal.Body>
            <div className='fs-3'>{intl.formatMessage({id: "DELETE_APPROVE_CASHBOX"})}</div>
            <div className='mt-5'>
                <label
                    className='form-label mb-3 required'>{intl.formatMessage({id: "name"})}</label>
                <input
                    onChange={(event => {
                        setDeleteField(event.target.value)
                    })}
                    type='text'
                    className='form-control form-control-lg'
                />
                <div className='text-danger mt-2'>
                    {hasError &&
                        <span>{intl.formatMessage({id: "DELETE_COMPANY_APPROVE_ERROR_CASHBOX"})}</span>}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
            <button type="button" className="swal2-confirm btn btn-secondary" aria-label=""
                    style={{display: 'inline-block'}} onClick={handleClose}>
                {intl.formatMessage({id: "CANCEL_BUTTON"})}
            </button>
            <button onClick={handleDelete} className="swal2-confirm btn btn-danger" aria-label=""
                    style={{display: 'inline-block'}}>
                {intl.formatMessage({id: "delete"})}
            </button>
        </Modal.Footer>
    </Modal>
}

export default function FilialSettingsMerchant() {
    const intl = useIntl()
    return (
        <ErrorProvider>
            <PageTitle shouldShowDemoWarning={false}>{intl.formatMessage({id: "settings"})}</PageTitle>
            <Settings/>
        </ErrorProvider>
    )
}