import React, {FC} from 'react'
import {useIntl} from "react-intl";

type Props = {
    shouldShow: boolean
}

const TableIcon:FC<Props> = ({shouldShow}) => {

    return (
        <div className="d-flex align-items-center justify-content-start">
            <div className='d-flex flex-column'>
                <p className="text-gray-800 text-hover-primary mb-1 text-left d-flex align-items-start">
                    {shouldShow && <img src='/media/svg/logo-qrcode.svg' style={{objectFit: 'contain'}}/>}
                </p>
            </div>
        </div>
    )
}

export {TableIcon}
