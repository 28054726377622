import {Statistic} from "@providers/statistic/_request";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Filial} from "@providers/filial/_request";
import {useErrors} from "@providers/context/ErrorsProvider";
import {
    APPROVED, FAILED,
    HUMO,
    INIT, REVERT,
    statuses_card,
    statuses_const,
    UZCARD
} from "@utils/constants";
import {useIntl} from "react-intl";
import {isDemo, NumReplace} from "@utils/index";
import moment from "moment";
import {ChartWidgetMixed} from "./_modals/ChartWidgetMixed";
import {ActivityDrawer} from "./_modals/ActivityDrawer";
import {WarningAlert} from "../../modules/alerts/WarningAlert";
import {DatePicker} from "antd";
import {WidgetMixedConnect} from "./_modals/WidgetMixedConnect";
import {toIsoStringHelper} from "../autopay/dateHelper";
import 'moment/locale/ru';
import 'moment/locale/uz-latn';
import 'moment/locale/en-au';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import localeEn from 'antd/es/date-picker/locale/en_US';

import {KTSVG} from "@metronic/helpers";
import {FilterDropDown} from "../payments/transactions/_modals/FilterDropDown";
import {MinusOutlined} from "@ant-design/icons";


interface CustomizedState {
    statuses?: Array<string>
    ppc_types?: Array<string>
    from_date?: string
    to_date?: string
}

export default function Dashboard() {
    const location = useLocation();
    let state = location.state as CustomizedState;
    const [shouldGetFromLocation, setShouldGetFromLocation] = useState<boolean>(state !== null)
    const [loading, setLoading] = useState(true)
    const [loadingBranch, setLoadingBranch] = useState(true)
    const {companyId} = useParams()
    const [cashboxes, setCashboxes] = useState([])
    const {setError} = useErrors()
    const [statistic_data, setStatistic_data] = useState<any[]>([])
    const [currentCard, setCurrentCard] = useState<string[]>([])
    const [renderData, setRenderData] = useState<any | null>(null)
    const [warningAlert, setWarningAlert] = useState<string | null>(null)
    const [filtersDay, setFiltersDay] = useState('month')
    const [dataFilters, setDataFilters] = useState<any>(null)
    const [statisticDate, setStatisticDate] = useState(isDemo()?'2023-01-01':shouldGetFromLocation && state ? state.from_date! : moment().subtract(1, 'month').format('YYYY-MM-DD'))
    const [currentDate, setCurrentDate] = useState('month')
   const [statisticDateTo, setStatisticDateTo] = useState(isDemo()?'2024-01-01':shouldGetFromLocation && state ? state.to_date! : moment().format('YYYY-MM-DD'))
    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState<any>({
        cashboxes,
        statuses: shouldGetFromLocation && state ? state.statuses : [APPROVED, REVERT, FAILED],
        ppc_types: shouldGetFromLocation && state ? state.ppc_types : [HUMO, UZCARD, 'VISA', 'MASTERCARD'],
        from_date: toIsoStringHelper(new Date(statisticDate)),
        to_date: toIsoStringHelper(moment(statisticDateTo).endOf('day').toDate()),
    })
    const intl = useIntl()



    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang

    if (lang === "uz") {
        localeEn.lang.locale = "uz-latn"
    }

    const setInitialFilterDay = () => {
        const today = moment().format('YYYY-MM-DD')
        const to_date = (shouldGetFromLocation ? moment(state.to_date).format('YYYY-MM-DD') : moment(filters.to_date).format('YYYY-MM-DD'))
        const from_date = (shouldGetFromLocation ? moment(state.from_date).format('YYYY-MM-DD') : moment(filters.from_date).format('YYYY-MM-DD'))

        if (state !== null && to_date === today) {
            if (from_date === moment().format('YYYY-MM-DD')) {
                setFiltersDay('day')
            } else if (from_date === moment().subtract(1, 'week').format('YYYY-MM-DD')) {
                setFiltersDay('week')
            } else if (from_date === moment().subtract(1, 'month').format('YYYY-MM-DD')) {
                setFiltersDay('month')
            }
        }
    }


    useEffect(() => {
        window.history.replaceState({}, document.title)

        setInitialFilterDay()

        getFilial()
    }, [companyId])



    useEffect(() => {

        if(!loadingBranch){
            getData(filters)
        }

    }, [cashboxes, statisticDate, statisticDateTo, filters])


    async function getFilial() {
        if (companyId) {
            setLoadingBranch(true)
            await Filial.getFilial(companyId)
                .then(result => {
                    setCashboxes((result?.data?.result || []).map((item: any) => item.id))
                }).catch(err => setError({
                    status: err?.response?.stat,
                    message: err?.response?.data?.message,
                    isError: true
                }))
            setLoadingBranch(false)
        }
    }


    async function getData(values: any = {}, statistic = true) {
        if (companyId) {


            setLoading(true)

            const data = {
                cashboxes,
                statuses: values?.statuses ?? [INIT, APPROVED, REVERT, FAILED],
                ppc_types: (values?.ppc_types ?? filters.ppc_types) ?? [HUMO, UZCARD],
                from_date: toIsoStringHelper(new Date(values?.from_date ?? statisticDate)),
                to_date: toIsoStringHelper(moment(values?.to_date ?? statisticDateTo).endOf('day').toDate()),
            }

            if (!statistic) setDataFilters(data)

            if (statistic) {
                await Statistic.getStatistic(companyId, data)
                    .then(result => {
                        setStatistic_data(result?.data?.result || [])

                        let series: any[] = []

                        statuses_const.forEach((item: string) => {
                            const name = item
                            let data
                            if (result?.data?.result.length) {
                                data = result?.data?.result.map((res: any) => {
                                    return res.statuses[item]?.amount ? res.statuses[item]?.amount / 100 : 0
                                })
                            } else {
                                data = [0]
                            }

                            series.push({name, data})
                        })

                        const categories: any[] = result?.data?.result.map((item: any) => item.date)
                        setRenderData({series, categories})

                    }).catch(err => setError({
                        status: err?.response?.stat,
                        message: err?.response?.data?.message,
                        isError: true
                    }))
            }
            setLoading(false)
        }
    }

    function getCountStatuses(type: string = 'init', currency = 'UZS') {
        let result: any = []
        if (statistic_data.length) {

            if (type === 'all') {
                statuses_card.forEach((item: any) => {
                    let sum = 0,
                        count = 0
                    if (statistic_data.length > 1) {
                        sum = statistic_data.reduce((previousValue: any, currentValue: any) => {

                            if (Object.keys(currentValue.details).includes(item)) {
                                if (typeof previousValue === 'object') {
                                    return previousValue.details[item].amount + currentValue?.details[item].amount
                                }

                                return previousValue + currentValue?.details[item].amount
                            }

                            return 0


                        })

                        count = statistic_data.reduce((previousValue: any, currentValue: any) => {

                            if (Object.keys(currentValue.details).includes(item)) {
                                if (typeof previousValue === 'object') {
                                    return previousValue.details[item].count + currentValue?.details[item].count
                                }

                                return previousValue + currentValue?.details[item].count
                            }

                            return 0


                        })
                    } else {
                        if (statistic_data.length && Object.keys(statistic_data[0].details).includes(item)) {
                            sum = statistic_data[0].details[item].amount
                            count = statistic_data[0].details[item].count
                        }

                    }

                    result.push({
                        card: item.toUpperCase(),
                        sum: sum ? NumReplace(sum / 100 + '') + ' ' + currency : 0,
                        count

                    })

                })
            } else {
                statuses_card.forEach((item: any) => {

                    let sum = 0,
                        count = 0

                    if (statistic_data.length > 1) {
                        sum = statistic_data.reduce((previousValue: any, currentValue: any) => {

                            if (Object.keys(currentValue.details).includes(item)) {
                                if (typeof previousValue === 'object') {

                                    return previousValue.details[item].statuses[type].amount + currentValue?.details[item].statuses[type].amount
                                }

                                return previousValue + currentValue?.details[item].statuses[type].amount
                            }

                            return 0

                        })

                        count = statistic_data.reduce((previousValue: any, currentValue: any) => {
                            if (Object.keys(currentValue.details).includes(item)) {

                                if (typeof previousValue === 'object') {
                                    return previousValue.details[item].statuses[type].count + currentValue?.details[item].statuses[type].count
                                }

                                return previousValue + currentValue?.details[item].statuses[type].count
                            }

                            return 0

                        })
                    } else {
                        if (statistic_data.length && Object.keys(statistic_data[0].details).includes(item)) {
                            sum = statistic_data.length ? statistic_data[0].details[item].statuses[type].amount : 0
                            count = statistic_data.length ? statistic_data[0].details[item].statuses[type].count : 0
                        }
                    }


                    result.push({
                        card: item.toUpperCase(),
                        sum: sum ? NumReplace(sum / 100 + '') + ' ' + currency : 0,
                        count

                    })


                })
            }

        } else {

            result = statuses_card.map((item: any) => {
                return {
                    card: item.toUpperCase(),
                    sum: 0,
                    count: 0
                }
            })
        }

        return result

    }


    const sumAmount = (status: string) => {
        if (statistic_data.length) {
            if (statistic_data.length > 1) {
                if (status === 'all') {
                    return statistic_data.reduce((previousValue: any, currentValue: any) => {

                        if (typeof previousValue === 'object') {
                            return previousValue.amount + currentValue.amount
                        }

                        return previousValue + currentValue.amount
                    }) / 100
                }

                return statistic_data.reduce((previousValue: any, currentValue: any) => {
                    if (typeof previousValue === 'object') {
                        return previousValue.statuses[status].amount + currentValue.statuses[status].amount
                    }

                    return previousValue + currentValue.statuses[status].amount
                }) / 100
            }

            return status !== 'all' ? statistic_data[0]?.statuses[status].amount / 100 : statistic_data[0]?.amount / 100


        }
    }


    const dataChartsRender = async (filter: string[] = [], showBar = false) => {
        const params_filter: any = {}

        if (!filter.find(item => item.match(/тех. поддержку/i))) {

            const from_date = filter.find(item => item.match('from_date'))
            const to_date = filter.find(item => item.match('to_date'))
            params_filter['from_date'] = from_date ? from_date.split('=')[1] : statisticDate
            params_filter['to_date'] = to_date ? to_date.split('=')[1] : statisticDateTo

            filter.forEach(item => {

                let key, values

                if (item.match(/from_date|to_date/)) {
                    const parse = item.split('=')
                    key = parse[0]
                    values = parse[1]
                } else {
                    key = item.replace(/\[.+\]/, '')
                    //@ts-ignore
                    values = item.match(/[^[\]]+(?=])/) ? item.match(/[^[\]]+(?=])/)[0].split(',') : []
                }

                params_filter[key] = values

            })

            await getData(params_filter, !showBar)


        } else {

            setWarningAlert(filter[0])

        }
    }


    function replaceFormat(date:any, formatFrom:string, formatTo:string){
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }


    function countDays(){
        let a = moment(filters.from_date),
            b = moment(filters.to_date)
        return b.diff(a, 'days')
    }


    return (
        <div>
            <div className='toolbar toolbar-filter justify-content-end px-10 align-items-center'>
                <p className='mb-0'>{intl.formatMessage({id: 'filter_by'})}</p>


                <div className='filter_buttons_group px-3'>
                    <div className='ms-1 position-relative'>
                        <a
                            href='#'
                            className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                            onClick={() => setShowFilter(!showFilter)}
                        >
                            <KTSVG
                                path='/media/icons/duotune/general/gen031.svg'
                                className='svg-icon-5 svg-icon-gray-500 me-1'
                            />
                            {intl.formatMessage({id: 'Filter'})}
                        </a>
                        <FilterDropDown disableStatuses={true} show={showFilter} filters={filters}
                                        updateFilter={(val: any) => {
                                            setFilters(val)
                                            setCurrentCard(val?.ppc_types)
                                        }} handleClose={() => setShowFilter(false)}/>
                    </div>

                </div>


                <div className='filter_buttons_group'>
                    <button
                        disabled={isDemo()}
                        className={`btn btn-sm btn-active-light-primary ${filtersDay==='day' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (filtersDay !== 'day') {

                                setShouldGetFromLocation(false)
                                state = {}
                                const from_date = moment().format('YYYY-MM-DD')
                                const to_date = moment().format('YYYY-MM-DD')
                                setStatisticDate(from_date)
                                setStatisticDateTo(to_date)
                                setFiltersDay('day')
                                dataChartsRender([`from_date=${from_date}`, `to_date=${to_date}`])

                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: from_date}
                                    const toDate = {to_date: to_date}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_day'})}
                    </button>
                    <button
                        disabled={isDemo()}
                        className={`btn btn-sm btn-active-light-primary ${filtersDay==='week' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (filtersDay !== 'week') {
                                setShouldGetFromLocation(false)
                                state = {}
                                const from_date = moment().subtract(1, 'week').format('YYYY-MM-DD')
                                const to_date = moment().format('YYYY-MM-DD')
                                setStatisticDate(from_date)
                                setStatisticDateTo(to_date)
                                setFiltersDay('week')
                                dataChartsRender([`from_date=${from_date}`, `to_date=${to_date}`])

                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: from_date}
                                    const toDate = {to_date: to_date}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_week'})}
                    </button>
                    <button
                        className={`btn btn-sm btn-active-light-primary ${filtersDay==='month' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (filtersDay !== 'month') {
                                setShouldGetFromLocation(false)
                                state = {}
                                const from_date = moment().subtract(1, 'month').format('YYYY-MM-DD')
                                const to_date = moment().format('YYYY-MM-DD')

                                setStatisticDate(from_date)
                                setStatisticDateTo(to_date)
                                setFiltersDay('month')
                                dataChartsRender([`from_date=${from_date}`, `to_date=${to_date}`])

                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: from_date}
                                    const toDate = {to_date: to_date}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_month'})}
                    </button>
                </div>
                <div className="mx-4" style={{backgroundColor: "rgba(235, 237, 242, 1)", width: "1px", height: "40%"}}/>
                <div>
                    <DatePicker.RangePicker
                        style={{border: "none"}}
                        separator={<MinusOutlined/>}
                        locale={lang === 'ru' ? localeRu : localeEn}
                        placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                        key={statisticDate}
                        className='datepicker_form form-control-lg form-control-solid'
                        defaultValue={[replaceFormat(statisticDate, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                            replaceFormat(statisticDateTo, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                        format='DD-MM-YYYY'
                        disabledDate={current => {
                            return isDemo() || current && current > moment().endOf('day');
                        }}
                        onKeyDown={(val) => {
                            if (val.code.toLowerCase().includes("key")) {
                                val.preventDefault()
                                val.stopPropagation()
                                return
                            }
                        }}
                        //@ts-ignore
                        onChange={(dates: [any, any], dateStrings: [string, string]) => {
                            if (dates) {
                                setShouldGetFromLocation(false)
                                state = {}
                                let from_date = replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                                let to_date = replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                                setStatisticDate(from_date)
                                setStatisticDateTo(to_date)
                                dataChartsRender([`from_date=${from_date}`,
                                    `to_date=${to_date}`])

                                setFiltersDay('')


                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: toIsoStringHelper(new Date(from_date))}
                                    const toDate = {to_date: toIsoStringHelper(replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').endOf('day').toDate())}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}
                    />
                </div>
            </div>
            <ActivityDrawer open={!!dataFilters} params={dataFilters} setDataCharts={(val: any) => setDataFilters(val)}
                            companyId={companyId ?? ''}
                            onCardSelect={(val: any) => {
                                setCurrentCard(val.ppc_types)
                                setFilters({...filters, ...val})
                            }}
                            onDateChange={(dates: [any, any], dateStrings: [string, string]) => {
                                if (dates) {
                                    setFiltersDay('')
                                    setStatisticDate(replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format())
                                    setStatisticDateTo(replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format())
                                    dataChartsRender([`from_date=${replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format()}`,
                                        `to_date=${replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format()}`])
                                }
                            }}/>
            <WarningAlert isAlert={!!warningAlert} message={warningAlert} setShow={(val: any) => setWarningAlert(val)}/>

            <div className='row g-5 g-xl-8'>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={cashboxes.length > 0}
                                      tooltip={intl.formatMessage({id: 'tooltip_all'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='dark-blue' data={{
                        status: 'ALL',
                        sum: [`${sumAmount('all') ? NumReplace(sumAmount('all') + '') : 0} UZS`],
                        details: getCountStatuses('all')
                    }} cards={currentCard}/>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={cashboxes.length > 0}
                                      tooltip={intl.formatMessage({id: 'tooltip_success'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='success' data={{
                        status: APPROVED,
                        sum: [`${sumAmount('approved') ? NumReplace(sumAmount('approved') + '') : 0} UZS`],
                        details: getCountStatuses('approved')
                    }} cards={currentCard}/>
                </div>

                {/*<div className='col-xl-3 col-lg-4 col-md-4'>*/}
                {/*    <ChartWidgetMixed isAvailable={branches.length > 0}*/}
                {/*                      tooltip={intl.formatMessage({id: 'tooltip_canceled'})}*/}
                {/*                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}*/}
                {/*                      className='card-xl-stretch mb-xl-8' color='dark-blue' data={{*/}
                {/*        status: INIT,*/}
                {/*        sum: [`${sumAmount('init') ? NumReplace(sumAmount('init') + '') : 0} UZS`],*/}
                {/*        details: getCountStatuses('init')*/}
                {/*    }} cards={currentCard}/>*/}
                {/*</div>*/}

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={cashboxes.length > 0}
                                      tooltip={intl.formatMessage({id: 'tooltip_declined'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='warning' data={{
                        status: FAILED,
                        sum: [`${sumAmount('failed') ? NumReplace(sumAmount('failed') + '') : 0} UZS`],
                        details: getCountStatuses('failed')
                    }} cards={currentCard}/>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={cashboxes.length > 0}
                                      tooltip={intl.formatMessage({id: 'tooltip_canceled'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='danger' data={{
                        status: REVERT,
                        sum: [`${sumAmount('revert') ? NumReplace(sumAmount('revert') + '') : 0} UZS`],
                        details: getCountStatuses('revert')
                    }} cards={currentCard}/>
                </div>


                {/*<div className='col-xl-3 col-lg-4 col-md-4'>*/}
                {/*    <WidgetMixedConnect data={{*/}
                {/*        title: intl.formatMessage({id: 'platform_visa'}),*/}
                {/*        sum: '$/€',*/}
                {/*        text_alert: intl.formatMessage({id: 'visa_connect'}),*/}
                {/*        icons_title: intl.formatMessage({id: 'platform_visa'}),*/}
                {/*        icons: ['/media/svg/visa-2.svg', '/media/svg/master-2.svg']*/}
                {/*    }} tooltip='test'*/}
                {/*                        dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}*/}
                {/*                        className='card-xl-stretch mb-xl-8' color='info'/>*/}
                {/*</div>*/}

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <WidgetMixedConnect data={{
                        title: intl.formatMessage({id: 'telegram'}),
                        sum: '0 $',
                        text_alert: intl.formatMessage({id: 'telegram_connect'}),
                        icons_title: intl.formatMessage({id: 'telegram_dash'}),
                        icons: ['/media/svg/visa-2.svg', '/media/svg/master-2.svg', '/media/svg/Humo.svg', '/media/svg/Uzcard (2).svg']
                    }} tooltip='test'
                                        dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                        className='card-xl-stretch mb-xl-8' color='primary'/>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <WidgetMixedConnect data={{
                        title: intl.formatMessage({id: 'platforms'}),
                        sum: 'platforms',
                        icons_title: '',
                        text_alert: intl.formatMessage({id: 'platform'}),
                        icons: ['/media/svg/android.svg', '/media/svg/apple.svg', '/media/svg/site.svg']
                    }} tooltip='test'
                                        dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                        className='card-xl-stretch mb-xl-8' color='brown'/>
                </div>

            </div>

        </div>
    )
}