import React, {FC, useEffect, useMemo, useState} from 'react';
import {MerchantService} from "@providers/autopay/requests/merchant.service";
import {Link, useParams} from "react-router-dom";
import {PageTitle} from "@metronic/layout/core";
import {KTCard, KTCardBody, KTSVG} from "@metronic/helpers";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {isDemo, isProd, NumReplace} from "@utils/index";
import {subtractMonths, toIsoStringHelper} from "../../dateHelper";
import moment from "moment/moment";
import {DatePicker} from "antd";
import {MinusOutlined} from "@ant-design/icons";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import localeEn from "antd/es/date-picker/locale/en_US";
import {Modal} from "react-bootstrap";
import {FilterDropDown} from "./_modals/FilterDropDown";
import TableSort from "@utils/components/dropdowns/TableSortModal";
import {ColumnInstance, Row, useTable} from "react-table";
import { contractColumnsGet, arr_columns} from "./_modals/columns/_columns";
import {ModSession} from "@providers/session/_modals";
import {CustomHeaderColumn} from "./_modals/columns/CustomHeaderColumn";
import {CustomRow} from "./_modals/columns/CustomRow";
import {Pagination} from "../../../../modules/Pagination";
import {observer} from "mobx-react";
import {autopayStore} from "@store/autopay";
import {statuses_contracts} from "@utils/constants";
import ArrayToSpanFormatter from "@utils/components/ArrayToSpanFormatter";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {ContractsService} from "@providers/autopay/requests/contracts.service";







const ContractsListInner: FC = observer(() => {
    const {companyId, type} = useParams()
    const [contractsData, setContractsData] = useState<any[]>([])
    const [amountContract, setAmountContract] = useState<{contracts:number, sum:number}>({
        contracts: 0,
        sum: 0
    })
    const data = useMemo(() => contractsData, [contractsData])



    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [searchContract, setSearchContract] = useState<string>('')

    const {error, setError} = useErrors()
    const intl = useIntl()


    const [filters, setFilters] = useState<any>({
        from_date: isDemo()?'2022-11-04':moment().subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss'),
        to_date: isDemo()?'2022-12-04':moment().format('YYYY-MM-DD HH:mm:ss'),
        statuses: statuses_contracts
    })
    const [filtersDay, setFiltersDay] = useState('month')
    const [filtersCount, setFiltersCount] = useState(0)
    const [pagination, setPagination] = useState<any>(null)
    const [showFilter, setShowFilter] = useState(false)
    const [showTableSort, setShowTableSort] = useState(false)
    const [tableSort, setTableSort] = useState<any[]>([])

    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang
    const columns = useMemo(() => contractColumnsGet(tableSort), [contractsData, tableSort])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })


    async function getContracts(page?: string, filters?:any, searchClear:boolean=false){
        try{
            const sortBy = autopayStore.getSortingContract.sortBy
            let data:any = {}
            if(searchContract.trim().length&&!searchClear){
                data = await ContractsService.seacrhContracts(searchContract.replace(/\s+/g, ''), page, "20", sortBy, filters )
            }else{
                data = await MerchantService.getMerchantContracts(companyId||'', page, "20", sortBy, filters )
            }

            if(!data?.data.success) throw new Error(data.message)

            const dataResponse = data?.data.pages
            setContractsData((dataResponse?.data ||[]).map((item: any, index: number) => {
                if (dataResponse.currentPage && dataResponse.currentPage > 2) {
                    item['num'] = ((dataResponse.currentPage - 1) * 20) + index + 1
                } else if (dataResponse.currentPage == 2) {
                    item['num'] = 21 + index
                } else {
                    item['num'] = index + 1
                }
                return item
            }))
            setAmountContract({contracts: dataResponse.totalRows, sum: data?.data.totalSum/100})
            setPagination({currentPage: dataResponse.currentPage,
                pages: dataResponse.totalPages,
                page: dataResponse.currentPage,
                last: dataResponse.currentPage===dataResponse.totalPages,
                first: dataResponse.currentPage===1,
                items:dataResponse.totalRows})
        }catch (err:any){
            setError({message: err?.response?.data?.message, isError: true, status: err?.response?.status})
        }finally {
            setIsLoading(false)
        }

    }






    useEffect(() => {
        setIsLoading(true)
        setTableSort(arr_columns)
        const initFilters = {...filters, statuses: type==='all'?statuses_contracts:['OUTDATED', 'TERMINATED']}
        setFilters(initFilters)

        getContracts('1', initFilters)
    }, [type])



    useEffect(()=>{
        if(document){
            const scrollElem = document.querySelector('.table-responsive')

            if(scrollElem){
                scrollElem.addEventListener('scroll', ()=>{
                    const elem_shadow = Array.from(document.querySelectorAll('.last-td'))
                    if(scrollElem.scrollLeft>36) elem_shadow.forEach((item:any)=>item.classList.add('shadow'))
                    else elem_shadow.forEach((item:any)=>item.classList.remove('shadow'))

                })
            }
        }
    }, [])



    function replaceFormat(date:any, formatFrom:string, formatTo:string){
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }

    return (
        <>
            <div className='d-flex justify-content-between mb-5' data-kt-user-table-toolbar='base'>
                <Link to={`/${companyId}/autopay/contracts/create`}>
                    <button type='button' className='btn btn-primary'>
                        <KTSVG path='/media/icons/duotune/arrows/customarr.svg' className='svg-icon-2'/>
                        {intl.formatMessage({id: "ADD_BUTTON"})}
                    </button>
                </Link>

                <form onSubmit={(e) => {
                    e.preventDefault()
                }} className='d-flex align-items-center position-relative my-1'>
                    {/*<KTSVG*/}
                    {/*    path='/media/icons/duotune/general/gen021.svg'*/}
                    {/*    className='svg-icon-1 position-absolute ms-4'*/}
                    {/*/>*/}
                    <input
                        style={{border: "none", borderRadius: "6px", height: "44px", paddingRight:'80px'}}
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control w-400px'
                        placeholder='Поиск по номеру телефона'
                        value={searchContract}
                        onChange={(e)=> {
                            if(e.currentTarget.value.length&&e.currentTarget.value.match(/^([0-9]\s?)+$/g)){
                                setSearchContract(e.currentTarget.value)
                            }else if(!e.currentTarget.value.length){
                                setSearchContract(e.currentTarget.value)
                            }

                        }}
                    />
                    {searchContract.length > 0 &&
                        <span className='position-absolute end-0 me-8 pe-8' onClick={() => {
                            setSearchContract("")
                            getContracts('1', filters, true)
                        }}>
                            <KTSVG
                                path='/media/icons/duotune/general/gen034.svg'
                                className='svg-icon-1 text-hover-primary'
                            />
                        </span>}
                    <button type="submit"
                            onClick={(e:any)=> {
                                e.preventDefault()
                                getContracts('1', filters)
                            }}
                            style={{borderTopRightRadius: "6px", borderBottomRightRadius: "6px", width: "44px"}}
                            className='border-0 text-hover-secondary cursor-pointer position-absolute end-0 bg-primary h-100 d-flex justify-content-center align-items-center'>
                        <KTSVG
                            path='/media/icons/duotune/general/gen021.svg'
                            className='svg-icon-1 text-white'
                        />
                    </button>
                </form>
            </div>
            <KTCard>

                <KTCardBody>
                    <div className='toolbar toolbar-filter justify-content-end px-5 align-items-center'>
                        <div className='filter_buttons_group'>
                            <button
                                disabled={isDemo()}
                                className={`btn btn-sm btn-active-light-primary ${filtersDay==='day' ? 'btn-active' : "fw-normal"}`}
                                onClick={() => {
                                    if (filtersDay !== 'day') {
                                        const fromDate = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
                                        const toDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
                                        setFilters((prevState: any) => {return {...prevState, from_date: fromDate, to_date: toDate}})
                                        setFiltersDay('day')
                                        getContracts('1', {...filters, from_date: fromDate, to_date: toDate})
                                    }

                                }}>
                                {intl.formatMessage({id: 'filter_by_day'})}
                            </button>
                            <button
                                disabled={isDemo()}
                                className={`btn btn-sm btn-active-light-primary ${filtersDay==='week' ? 'btn-active' : "fw-normal"}`}
                                onClick={() => {
                                    if (filtersDay !== 'week') {
                                        const fromDate = moment().subtract(1, 'week').startOf('day').format('YYYY-MM-DD HH:mm:ss')
                                        const toDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
                                        setFilters((prevState: any) => {return {...prevState, from_date: fromDate, to_date: toDate}})
                                        setFiltersDay('week')

                                        getContracts('1', {...filters, from_date: fromDate, to_date: toDate})
                                    }

                                }}>
                                {intl.formatMessage({id: 'filter_by_week'})}
                            </button>
                            <button

                                className={`btn btn-sm btn-active-light-primary ${filtersDay==='month' ? 'btn-active' : 'fw-normal'}`}
                                onClick={() => {
                                    if (filtersDay !== 'month') {
                                        const fromDate = moment().subtract(1, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
                                        const toDate = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
                                        setFilters((prevState: any) => {return {...prevState, from_date: fromDate, to_date: toDate}})

                                        setFiltersDay('month')
                                        getContracts('1', {...filters, from_date: fromDate, to_date: toDate})
                                    }

                                }}>
                                {intl.formatMessage({id: 'filter_by_month'})}
                            </button>
                        </div>
                        <div className="mx-4" style={{backgroundColor: "rgba(235, 237, 242, 1)", width: "1px", height: "40%"}}/>
                        <div>
                            <DatePicker.RangePicker
                                style={{border: "none"}}
                                separator={<MinusOutlined/>}
                                locale={lang === 'ru' ? localeRu : localeEn}
                                placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                                key={`${filters.from_date}-${filters.to_date}`}
                                className='datepicker_form form-control-lg form-control-solid'
                                defaultValue={[replaceFormat(filters.from_date, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                                    replaceFormat(filters.to_date, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                                format='DD-MM-YYYY'
                                disabledDate={current => {
                                    return isDemo() || current && current > moment().endOf('day');
                                }}
                                //@ts-ignore
                                onChange={(dates: [any, any], dateStrings: [string, string]) => {
                                    if (dates) {
                                        const fromDate = replaceFormat(dates[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss')
                                        const toDate = replaceFormat(dates[1], 'DD-MM-YYYY', 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD HH:mm:ss')

                                        setFilters((prevState: any) => {return {...prevState, from_date: fromDate, to_date: toDate}})

                                        getContracts('1', {...filters, from_date: fromDate, to_date: toDate})

                                        setFiltersDay('')

                                    }

                                }}
                            />
                        </div>
                    </div>
                    <div className='table-responsive'>
                        <div  style={{paddingTop:26, paddingBottom:26}}>
                            <div className='d-flex justify-content-between align-items-center position-absolute' style={{left:0, right: 0, top:0}}>
                                <div className='d-flex align-items-center py-6 px-4'>
                                    <div className='filter_buttons_group my-4 me-4'>

                                        <a
                                            href='#'
                                            className='badge bg-light bg-active-primary fw-medium text-primary px-4 py-2'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                            data-kt-menu-flip='top-end'
                                            onClick={() => setShowFilter(!showFilter)}
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen031.svg'
                                                className='svg-icon-5 text-primary me-1'
                                            />
                                            {filtersCount===0?"Фильтр":filtersCount > 1 ? "Применено" : "Применен"} {filtersCount||''} {filtersCount===0?'':filtersCount > 1 ? "фильтра" : filtersCount === 1 ? "фильтр" : "фильтров"}
                                        </a>

                                        <Modal size="sm" show={showFilter} onHide={() => setShowFilter(false)}>
                                            <FilterDropDown
                                                show={showFilter} filters={filters} updateFilter={(val: any) => {
                                                setFilters(val)
                                                getContracts(pagination.currentPage+'', val)
                                                setShowFilter(false)
                                            }} handleClose={() => setShowFilter(false)}/>
                                        </Modal>
                                    </div>
                                    <div className='filter_buttons_group my-4'>

                                        <a
                                            href='#'
                                            className='badge bg-light bg-active-primary fw-medium text-primary px-4 py-2'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                            data-kt-menu-flip='top-end'
                                            onClick={() => setShowTableSort(!showTableSort)}
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/general/icon-settings.svg'
                                                className='svg-icon-5 text-primary'
                                            />


                                        </a>

                                        <Modal size="sm" show={showTableSort} onHide={() => setShowTableSort(false)}>
                                            <TableSort
                                                show={showTableSort} columns={arr_columns} updateFilter={(val: any) => {
                                                setTableSort(val)
                                                setShowTableSort(false)

                                            }} columns_state={tableSort} handleClose={() => setShowTableSort(false)}/>
                                        </Modal>


                                    </div>
                                    {!isProd() && <span className="badge px-4 py-2 ms-4"
                                                        style={{border: "1px solid #F5F8FA", color: "rgba(76, 76, 102, 1)"}}><span
                                        style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'STATUS'})}: &nbsp;</span> {
                                        statuses_contracts.every((item: any) => (filters?.statuses||[]).includes(item.toUpperCase()))?'Все':
                                        <ArrayToSpanFormatter items={filters.statuses.map((item:any) => intl.formatMessage({id:item.toUpperCase()}))}/>}</span>}
                                </div>
                                <div className='d-flex justify-content-end py-6 px-4'>
                                    <p className='fs-6 fw-semibold text-gray-600 me-10'>{intl.formatMessage({id:'contracts'})}: <span className='text-gray-800 fs-3'>{amountContract?.contracts||0}</span></p>
                                    <p className='fs-6 fw-semibold text-gray-600'>{intl.formatMessage({id:'total_amount'})}: <span className='text-gray-800 fs-3'>{amountContract?.sum?NumReplace(amountContract?.sum+'')+' UZS':'0 UZS'}</span></p>
                                </div>
                            </div>
                        </div>
                        {
                            isLoading? <span className="d-block" style={{padding: "50px 0px", textAlign:'center'}}>
        Загрузка... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
    </span>:(<>{
                                columns.length?<table
                                    id='kt_table_contracts'
                                    className='table fw-normal align-middle table-row-bordered fs-6 gy-5 dataTable no-footer'
                                    style={{overflowY:'clip'}}
                                    {...getTableProps()}
                                >
                                    <thead>
                                    <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                                        {headers.map((column: ColumnInstance<ModSession>) => {

                                                return (
                                                    <CustomHeaderColumn key={column.id} column={column}/>
                                                )
                                            }
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody className='text-gray-600 fw-normal' {...getTableBodyProps()}>
                                    {rows.length > 0 ? (
                                        rows.map((row: Row<ModSession>, i) => {
                                            prepareRow(row)
                                            return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                    {intl.formatMessage({id: 'NO_MATCHING'})}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>:<div style={{height: "55vh"}} className="d-flex justify-content-center align-items-center">
                                    <a href="https://demo.globalpay.uz" target="_blank" className="btn btn-success text-white fs-3">
                                        {intl.formatMessage({id: "WATCH_DEMO_COMPANY1"})}
                                        <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-3 ms-3' />
                                    </a>
                                </div>
                            }</>)
                        }

                    </div>
                    {
                        rows.length? <Pagination withInfo={true} position='end' infoText='контрактов' pagination={pagination} updatePage={(values)=>{
                            getContracts(values.currentPage+'', filters)
                        }
                        }/>:''
                    }
                </KTCardBody>

            </KTCard>
        </>
    );
})

const ContractsList: FC = () => {
    const intl = useIntl()
    return (
        <ErrorProvider>
            <PageTitleCustom title={intl.formatMessage({id: "contract_list"})} breadCrumbs={[]}/>
            <ContractsListInner/>
        </ErrorProvider>
    );
};

export default ContractsList;