
import {FC} from 'react'
import {EmployeeM} from "@providers/employee/_models";
import {Link, useParams} from "react-router-dom";
import {ReplacePhone} from "@utils/index";

type Props = {
    user: EmployeeM
}

const InfoPhone: FC<Props> = ({user}) => {
    const {companyId} = useParams()
    return (
        <div className='d-flex align-items-center'>

            <div className='d-flex flex-column'>
                <Link to={`/${companyId}/employees/view/${user.id}`} className='text-gray-800 text-hover-primary mb-1'>
                    {user?.phone}
                </Link>
            </div>
        </div>
    )
}

export {InfoPhone}
