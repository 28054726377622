import {makeAutoObservable, toJS} from "mobx";

class Company{
    company:any[] = []
    me:any={}
    pagination:any= {}

    constructor() {
        makeAutoObservable(this)
    }

    setCompany(data:any[]){
        this.company = data
    }

    setPagination(data:any){
        this.pagination = data
    }

    get getCompany(){
        return toJS(this.company)
    }

    get getPagination(){
        return toJS(this.pagination)
    }

    setMe(data:any){
        this.me = data
    }

    get getMe(){
        return toJS(this.me)
    }
}



export const company = new Company()