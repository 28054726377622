/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {useIntl} from "react-intl";
import {Tooltip} from 'antd'
import {statuses_card, statuses_const} from "@utils/constants";
import '../../../../_metronic/assets/sass/style.scss';
import {useNavigate, useParams} from "react-router-dom";

type Props = {
    className: string
    color: string
    data: {
        title: string
        sum: string[]
        type: string,
        details: {
            sum: string
            count: string
            icon: number,
        }[]

    }
    tooltip?: string
    dataChartsRender: (filter: string[], showBar?: boolean) => void
    children?: any
    isAvailable: boolean
    cards: Array<string>
}

const ChartWidgetMixedAdmin: React.FC<Props> = ({
                                               className,
                                               color,
                                               data,
                                               dataChartsRender,
                                               tooltip,
                                               children,
                                               isAvailable,
                                               cards
                                           }) => {
    const intl = useIntl()
    console.log(data.sum)

    return (
        <div className={`card ${className}`}>
            {/* begin::Body */}
            <div className='card-body p-0 pb-5 d-flex flex-column justify-content-between'>
                <div className='position-relative'>
                    {/* begin::Header */}
                    <div className={`bg-${color} w-100`}
                         style={{height: "8px", position: "absolute", top: "0", borderRadius: "12px 12px 0 0"}}></div>
                    <div className={`px-9 pt-7 card-rounded h-275px w-100`}>
                        {/* begin::Heading */}
                        <div className='d-flex flex-stack'>
                            <h3 className='m-0 fw-bold fs-3 d-flex justify-content-between w-100 align-items-center'>{intl.formatMessage({id: data?.title})}
                                {
                                    tooltip && (<Tooltip color='var(--kt-body-bg)' placement="topLeft" title={() => <span
                                        style={{color: 'var(--kt-text-gray-800)'}}>{tooltip}</span>}>
                                        <i className="fa-sharp fa-solid fa-circle-info"/>
                                    </Tooltip>)
                                }
                            </h3>
                        </div>
                        {/* end::Heading */}
                        {/* begin::Balance */}
                        <div className='d-flex text-center flex-column pt-8'>
                            <span className='fw-semobold fs-7 text-gray-500'>{intl.formatMessage({id: data?.type==='company-statistic'?'amount-merchant':'amount_sum'})}</span>
                            {data.sum.map((item, index) => {
                                return (
                                    <span key={index} className='dash_sum text-grey-800 fw-bolder fs-2 pt-1'>{item}</span>
                                )
                            })}
                        </div>
                        {/* end::Balance */}
                    </div>
                    {/* end::Header */}

                    <div
                        className=' card-rounded mx-3 mb-9 px-2 py-9 position-relative z-index-1 bg-body'
                        style={{marginTop: '-150px', boxShadow: '0px 1.3px 9.75px rgba(0,0,0,0.05)'}}
                    >

                        {
                            (data?.details || []).map((item: any, index) => {

                                return (
                                    <div className='d-flex align-items-center mb-6' key={index}>
                                        {/* begin::Symbol */}
                                        <div className='symbol symbol-45px w-40px me-5'>
                                      <span className='symbol-label bg-lighten p-3'>
                                       <img src={item.icon} className='w-100 h-100'
                                            style={{objectFit: 'contain'}}/>
                                      </span>
                                        </div>
                                        <div className='d-flex align-items-center flex-wrap w-100'>
                                            {/* begin::Title */}
                                            <div className='mb-1 pe-3 flex-grow-1'>
                                                <a href='#'
                                                   className='fs-6 text-gray-800 fw-bold dash_count'>
                                                    {item.sum}
                                                </a>
                                                <div className='text-gray-400 fw-semobold fs-7'
                                                     style={{color: "#B5B5C3"}}>{data.type==='company-statistic'?'':'Кол-во:'} {item.count}</div>
                                            </div>


                                            <button type='button'
                                                    className='btn btn-sm btn-icon btn-active-light-primary'
                                                    onClick={() => {

                                                        // if (data?.status === 'ALL') {
                                                        //     let cards = [item.card]
                                                        //     dataChartsRender([`statuses[${statuses_const.map(item => item.toUpperCase()).join(',')}]`, `ppc_types[${cards.join(',')}]`], true)
                                                        // } else {
                                                        //     dataChartsRender([`statuses[${data.status}]`, `ppc_types[${item.card}]`], true)
                                                        // }

                                                    }
                                                    }>
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr001.svg'
                                                    className='svg-icon-5 svg-icon-success ms-1'
                                                />
                                            </button>

                                        </div>
                                    </div>
                                )
                            })
                        }



                    </div>
                </div>
                <button className='btn-sm btn mx-auto d-block button_type fw-bold' onClick={() => {

                    // dataChartsRender([data?.status === 'ALL' ? `statuses[${statuses_const.map(item => item.toUpperCase()).join(',')}]` : `statuses[${data.status.toUpperCase()}]`, `ppc_types[${cards.join(',')}]`], true)
                }}>{intl.formatMessage({id: 'view_all'})}</button>
            </div>

        </div>
    )
}

export {ChartWidgetMixedAdmin}
