import {FC} from 'react'
import moment from "moment";

type Props = {
  created_date?: string
}

const UserTwoStepsCell: FC<Props> = ({created_date}) => (
  <> {created_date && <div className='fw-bolder'>{moment(created_date).format('YYYY-MM-DD HH:mm')}</div>}</>
)

export {UserTwoStepsCell}
