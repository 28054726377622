import {makeAutoObservable, toJS} from "mobx";
import {OWNER} from "@utils/constants";

class EmployeeStore{
    employee = []
    role='USER'
    employeeFilial = []

    constructor() {
        makeAutoObservable(this)
    }

    setEmployee(data){
        this.employee = data
    }

    setEmployeeFilial(data){
        this.employeeFilial = data
    }

    setRole(role){
        this.role = role
    }

    get getEmployee(){
        return toJS(this.employee)
    }

    get getEmployeeFilial(){
        return toJS(this.employeeFilial)
    }

    get getRole(){
        return toJS(this.role)
    }
}



export const employeeStore = new EmployeeStore()