/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ModSession} from "@providers/session/_modals";
import moment from "moment";


type Props = {
    date: any
}


const TableDate: FC<Props> = ({date}) => {

    const dt = new Date(date)
    const calendar = dt.toLocaleDateString().split('.').join('/');

    return (
        <div className='d-flex align-items-center justify-content-start'>

            <div className='d-flex flex-column'>
                {/*<p className='text-gray-800 mb-1'>{moment(date).format('YYYY-DD-MM')}</p>*/}
                <p className='text-gray-800 mb-1 text-left'>
                    <div>{calendar}</div>
                    <div>{`${dt.getHours() > 9 ? dt.getHours() : `0${dt.getHours()}`}:${dt.getMinutes() > 9 ? dt.getMinutes() : `0${dt.getMinutes()}`}:${dt.getSeconds() > 9 ? dt.getSeconds() : `0${dt.getSeconds()}`}`}</div>
                </p>
            </div>
        </div>
    )
}

export {TableDate}
