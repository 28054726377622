/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {ID, KTSVG, QUERIES} from '@metronic/helpers'
import {Link, useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {Modal, Tooltip } from "antd";
import {TransactionsInfo} from "@providers/adminTransactions/_request";
import { useErrors } from '@providers/context/ErrorsProvider';
import {NumReplace, roleRouting, roleRoutingAdmin} from "@utils/index";
import {Statistic} from "@providers/statistic/_request";
import {IStatistic} from "@providers/statistic/_modals";
import {filial} from "@store/filial";
import {transactions} from "@store/transactions/transactions";
import {employeeStore} from "@store/employee";

type Props = {
    transactionData: any
    filialData:boolean
}


const RevertTransactionsActions:FC<Props> = observer(({transactionData, filialData}) => {

    // const intl = useIntl()
    const {companyId, id} = useParams()
    const [amountSum, setAmountSum] = useState<string>('')

    const [isModalOpen, setIsModalOpen] = useState(false);
    const {setError} = useErrors()



    function submitRevert(){
        const dataRevert = {
            "id": transactionData?.id,
            "external_id": transactionData?.external_id,
            "amount": transactionData?.ppc_type==='HUMO'?transactionData?.amount:
                amountSum.trim().length?+amountSum*100:0
        }


        if(filialData){
            if(companyId){
                TransactionsInfo.revertTransactions(dataRevert, companyId)
                    .then(()=>{
                        setIsModalOpen(false)
                        updateData()
                    })
                    .catch((err)=>{

                        setError({
                            status: err?.response?.status,
                            message: err?.response?.data?.message,
                            isError: true
                        })
                    })
            }

        }else{
            if(id){
                TransactionsInfo.revertTransactions(dataRevert, id)
                    .then(()=>{
                        setIsModalOpen(false)
                        updateData()
                    })
                    .catch((err)=>{

                        setError({
                            status: err?.response?.status,
                            message: err?.response?.data?.message,
                            isError: true
                        })
                    })
            }
        }
    }



    async function updateData(){
        const dataRequest: IStatistic = {
            cashboxes: filialData?[id]:filial.getFilial.map((item)=>item.id),
            page: transactions.getPagination?.currentPage ?? 1,
            count: 20,
            statuses: transactions.getFilters.statuses,
            ppc_types: transactions.getFilters.ppc_types,
            from_date: transactions.getFilters.from_date,
            to_date: transactions.getFilters.to_date,
            sort_by: transactions.getTransactionSortBy?.sortBy,
            sort_order: transactions.getTransactionSortBy?.sortFromMax ? "ASC" : "DESC"
        }

        if(filialData){
            if(companyId){
                await Statistic.getTransactions(companyId, dataRequest)
                    .then((result)=>{
                        let data = result?.data?.result || []
                        data.forEach((item: any) => {
                            item.branch_id = item?.branch_name
                        })
                        if (data.length > 0) {
                            data.map((item: any, index: number) => {
                                if (dataRequest.page && dataRequest.page > 2) {
                                    item['num'] = ((dataRequest.page - 1) * 20) + index + 1
                                } else if (dataRequest.page == 2) {
                                    item['num'] = 21 + index
                                } else {
                                    item['num'] = index + 1
                                }
                                return item
                            })
                            transactions.setTransactionsData(data)
                        }

                    }).catch((err)=>{

                        setError({
                            status: err?.response?.status,
                            message: err?.response?.data?.message,
                            isError: true
                        })
                    })


            }
        }else{
            if(id){
                await Statistic.getTransactions(id, dataRequest)
                    .then((result)=>{
                        let data = result?.data?.result || []
                        data.forEach((item: any) => {
                            item.branch_id = filial.getFilial.find(value => value.id === item.branch_id).name
                        })
                        if (data.length > 0) {
                            data.map((item: any, index: number) => {
                                if (dataRequest.page && dataRequest.page > 2) {
                                    item['num'] = ((dataRequest.page - 1) * 20) + index + 1
                                } else if (dataRequest.page == 2) {
                                    item['num'] = 21 + index
                                } else {
                                    item['num'] = index + 1
                                }
                                return item
                            })
                            transactions.setTransactionsData(data)
                        }

                    }).catch((err)=>{

                        setError({
                            status: err?.response?.status,
                            message: err?.response?.data?.message,
                            isError: true
                        })
                    })


            }
        }

    }





    return (
        <>
            <Modal title="" open={isModalOpen} footer={null} className='modal-revert-transactions'>
                {
                    transactionData?.ppc_type==='UZCARD'||transactionData?.ppc_type==='VM'?(<div className='text-center'>
                        <span>
                            <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="5" y="5" width="56" height="56" rx="28" fill="#FEE4E2"/>
                                <path d="M40 26L26 40M26 26L40 40" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <rect x="5" y="5" width="56" height="56" rx="28" stroke="#FEF3F2" stroke-width="10"/>
                            </svg>

                            <p className='fw-bold fs-3 text-gray-800 mb-0 mt-10'>Отменить транзакцию?</p>
                            <p className='fw-semobold fs-5 text-gray-600 mb-0'>Это действие нельзя отменить. Деньги будут возвращены в аккаунт клиента.</p>

                            <div style={{marginTop:'32px'}} className='d-flex flex-column align-items-start'>
                                <label className='text-left fw-bold text-gray-800 mb-2 fs-5'>Сумма возврата</label>
                                <input className='form-control form-control-lg form-control-solid'
                                       value={amountSum}
                                       onChange={(e)=>{

                                           if(e.target.value.length&&e.target.value.match(/^\d*[\.]?\d*$/)){
                                               setAmountSum(e.target.value)
                                           }else{
                                               if(!e.target.value.length) setAmountSum('')
                                           }

                                       }}/>

                                {
                                    +amountSum>transactionData?.amount/100?( <p className='fs-5 text-danger mb-0 mt-3'>Нельзя вернуть больше изначальной суммы</p>)
                                        :(<p className='fs-5 text-gray-600 mb-0 mt-3'>{`${NumReplace((transactionData?.amount/100)+'')} UZS сумма полной транзакции`}</p>)
                                }

                            </div>

                             <div style={{marginTop:'50px'}}>
                                <button className='btn fw-bold btn-revert' disabled={+amountSum>transactionData?.amount/100||+amountSum===0} onClick={()=>submitRevert()}>Отменить</button>
                                <button className='btn  text-gray-600 fw-bold'  onClick={()=>{
                                    setIsModalOpen(false)
                                    setAmountSum('')
                                }}>Оставить</button>
                            </div>

                        </span>
                    </div>):(<div className='text-center'>
                        <span >
                            <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="5" y="5" width="56" height="56" rx="28" fill="#FEE4E2"/>
                                <path d="M40 26L26 40M26 26L40 40" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <rect x="5" y="5" width="56" height="56" rx="28" stroke="#FEF3F2" stroke-width="10"/>
                            </svg>

                            <p className='fw-bold fs-3 text-gray-800 mb-0 mt-10'>Отменить транзакцию?</p>
                            <p className='fw-semobold fs-5 text-gray-600 mb-0'>Это действие нельзя отменить. Деньги будут возвращены в аккаунт клиента.</p>

                            <div style={{marginTop:'50px'}}>
                                <button className='btn fw-bold btn-revert' onClick={()=>submitRevert()}>Отменить</button>
                                <button className='btn  text-gray-600 fw-bold' onClick={()=>setIsModalOpen(false)}>Оставить</button>
                            </div>
                        </span>
                    </div>)
                }
            </Modal>
            <div className='d-flex justify-content-end'>
                <Tooltip placement="topLeft" title='Фискальный чек'>
                    <img src='/media/svg/qr-code-payments.svg'/>
                </Tooltip>

                {

                    roleRoutingAdmin('action', employeeStore.getRole, 'revert-payments')?(<Tooltip
                        placement="topLeft"
                        title='Отменить транзакцию'
                        overlayClassName='tooltip-payments'

                    >
                        <a href="#" onClick={(e)=>{
                            e.preventDefault()
                            if(transactionData?.status!=='REVERT'){
                                setIsModalOpen(true)
                            }

                        }} style={transactionData?.status==='REVERT'?{opacity:'0.5'}:{}} className="btn btn-icon-gray-500 btn-active-icon-danger p-5">
                            <KTSVG path='/media/svg/close-icon.svg'
                                   className="svg-icon-2 svg_icon_delete"/></a></Tooltip>):''
                }



            </div>
        </>
    )
})

export {RevertTransactionsActions}
