import {KTCard, KTSVG} from "@metronic/helpers";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Company} from "@providers/company/_request";
import {Dropdown, Menu, Tooltip} from "antd";
import {Nav} from "react-bootstrap";
import {useIntl} from "react-intl";
import {CompanyResponseOne} from "@providers/company/_models";
import {Settings} from "./_tabs/Settings";
import {Transactions} from "./_tabs/Transactions";
import {Employees} from "./_tabs/Employees";
import {Dashboard} from "./_tabs/Dashboard";
import {FilialTab} from "./_tabs/Filial";
import {UserEditModal} from "../_modals/user-edit-modal/UserEditModal";
import {ListViewProvider, useListView} from "../_modals/providers/ListViewProvider";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {Statistic} from "@providers/statistic/_request";
import {Filial} from "@providers/filial/_request";
import {NumReplace, ReplacePhone, roleRoutingAdmin} from "@utils/index";
import {PageLink} from "@metronic/layout/core";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {statuses_card, statuses_const} from "@utils/constants";
import moment from "moment";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";


const classStatus = {
    DRAFT: 'secondary',
    ACTIVE: 'light-success',
    DISABLED: 'light-danger',
}

const iconsStatus = {
    DRAFT: "/media/svg/failed.svg",
    ACTIVE: "/media/svg/approved.svg",
    DISABLED: "/media/svg/revert.svg",
}


const createCompanyBreadCrumbs: Array<PageLink> = [
    {
        title: 'Компании',
        path: '/companies',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const CompanyView = observer(() => {
    const {itemIdForUpdate} = useListView()
    const {id} = useParams()
    const [companyData, setCompany] = useState<CompanyResponseOne|null>(null)
    const [activeKey, setActiveKey] = useState<string|null>('dashboard')
    const [type, setType] = useState('')
    const intl = useIntl()
    const {setError} = useErrors()
    const [staticticInfo, setStatisticInfo] = useState<any>({})
    const [dateGeneralStatistic, setGeneralStatistic] = useState({
        from_date: moment('2020-01-01').format(),
        to_date:moment().format()
    })
    const [statusChecked, setStatusChecked] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [cashboxes, setCashboxes] = useState([])
    const [ready,setReady] = useState(false)

    useEffect(()=>{
        if(id) {
            Promise.all([getCompany(id),
                getFilials()])
                .then(()=>setReady(true))
        }
    }, [id])

    useEffect(()=>{
        let page = document.querySelector('.page')
        if(page){
            page.addEventListener('click', ()=>{
                setModalIsOpen(false)
            })
        }

        return ()=>{
            if(page){
                page.removeEventListener('click', ()=>{
                    setModalIsOpen(false)
                })
            }
        }

    }, [])

    useEffect(()=>{
        if(companyData)getInfo()
    }, [companyData])




    const widgetMenu = (
        <Menu className='p-7 change_status_group'>
            <p className='text-gray-800 fw-bold'>Изменить статус</p>
            <div >

                <div className={`item_check_status d-flex align-items-center justify-content-between border border-gray-300 mb-3
               ${statusChecked==='ACTIVE'?'active':''}`}

                     onClick={()=>{
                         setStatusChecked('ACTIVE')
                     }}
                >
                    <div className='d-flex  align-items-center'>
                       <span className='me-5'>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="6" fill="#ECFDF3"/>
                            <path d="M18 11L12.5 16.5L10 14" stroke="#12B76A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>

                       </span>
                        <p className='fw-bold fs-6 text-gray-800 mb-0'>Активный</p>
                    </div>
                    <div>
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input"
                                   type="radio"
                                   checked={statusChecked==='ACTIVE'}

                            />
                        </div>
                    </div>
                </div>
                <div className={`item_check_status d-flex align-items-center justify-content-between border border-gray-300 
               ${statusChecked==='DISABLED'?'active':''}`}
                     onClick={()=>{
                         setStatusChecked('DISABLED')
                     }}
                >
                    <div className='d-flex  align-items-center'>
                       <span className='me-5'>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="6" fill="#FEF3F2"/>
                            <path d="M17 11L11 17M11 11L17 17" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>


                       </span>
                        <p className='fw-bold fs-6 text-gray-800 mb-0'>Неактивный</p>
                    </div>
                    <div>
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input"
                                   type="radio"
                                   checked={statusChecked==='DISABLED'}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-8'>
                <button className='btn btn-light fw-bold me-3' onClick={()=>setModalIsOpen(false)}>
                    Отмена
                </button>
                <button className='btn btn-primary fw-bold' onClick={()=>changeStatusSubmit()}>
                    Применить
                </button>
            </div>
        </Menu>
    );

    async function changeStatusSubmit(){
        setModalIsOpen(false)
        if(statusChecked.length){
            await Company.changeStatusRequest(id?+id:0, {status:statusChecked})
                .then(()=>{
                    updateData()
                })
                .catch((err)=>{

                })
        }

    }

    async function getCompany(id:string){
        await Company.getById(id)
            .then(result=>{
                const {status} =result?.data?.result
                setStatusChecked(status)
                setCompany(result?.data?.result)
            })

    }

    async function getFilials(){

        await Filial.getFilial(id||'')
            .then(async(response)=>{
                setCashboxes(response?.data?.result)
                await getTransactionGeneral(response?.data?.result)
            })
            .catch(err=>{
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
    }

    async function getTransactionGeneral(data:any[]){
        await Statistic.getStatisticsGeneral(id||'', {
            cashboxes: data.map(item=>item.id),
            ppc_types: statuses_card.map((item:string)=>item.toUpperCase()),
            statuses: statuses_const.map((item:string)=>item.toUpperCase()),
            ...dateGeneralStatistic
        }).then(result=>{
            setStatisticInfo(result?.data?.result)
        }).catch(err=>{
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        })
    }

    async function getInfo(){
        await Company.getCompanyTypes()
            .then(result=>{
                let type = result?.data?.result.find((item:any)=>item.id===companyData?.type)
                setType(type?type.name.replace(/\(.+\)/, ''):'')
            })

    }


    async function updateData(){
        await getCompany(id||'')
        // await Company.getCompany().then(result=>{
        //     company.setCompany(result?.data?.result)
        // })
    }


    function renderTabs(){
        switch(activeKey){
            case 'settings':
                if(companyData){
                    return <Settings updateData={updateData} companyData={companyData}/>
                }
                return
            case 'transactions':
                return <Transactions general={staticticInfo}/>
            case 'employee':
                return <Employees />
            case 'dashboard':
                return <Dashboard cashboxes={cashboxes} />
            case 'cashboxes':
                return <FilialTab cashboxes={cashboxes} />
            default:
                return
        }
    }



    return (
        <>
            <PageTitleCustom title={companyData?.name||''} breadCrumbs={createCompanyBreadCrumbs}/>

            <KTCard className='mb-5'>

                <div className='card mb-5 mb-xl-10'>
                    <div className='card-body pt-9 pb-0'>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                            <div className='me-7 mb-4'>
                                <div className='p-3 d-flex flex-column justify-content-center symbol logo-project bg-secondary symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                    {
                                        ready?<>{
                                            !companyData?.logo_file?(<p style={{fontSize: '4rem'}} className='text-center mb-0 fw-bolder text-primary'>{(companyData?.name||'').split(' ').map((item:string)=>item.length?item[0].toUpperCase():item).join('')}</p>)
                                                :(<img className='w-100 h-100' style={{objectFit:'contain'}} src={Company.getFiles(companyData.logo_file)} alt='' />)
                                        }</>:''
                                    }

                                </div>
                            </div>

                            <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <p className='text-gray-800 fs-2 fw-bolder me-1 mb-0'>
                                                {companyData?.name}
                                            </p>
                                            {/*@ts-ignore*/}
                                            <div className={` mx-5`}>


                                                {companyData?.status?(
                                                    <>
                                                        {
                                                            roleRoutingAdmin('action', employeeStore.getRole, 'change-status')?(<Dropdown overlay={widgetMenu}  open={modalIsOpen} >
                                                                <a onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setModalIsOpen(!modalIsOpen)
                                                                }}>
                                                                    {/*@ts-ignore*/}
                                                                    <div  className={`badge badge-${classStatus[companyData?.status||'']} fw-bolder`}>
                                                                        {/*@ts-ignore*/}
                                                                        <KTSVG path={iconsStatus[companyData?.status]} className={`svg-icon-${classStatus[companyData?.status]} svg-icon-2hx me-2`} />
                                                                        {intl.formatMessage({id:companyData?.status})}
                                                                    </div>
                                                                </a>
                                                            </Dropdown>):(<>
                                                                {/*@ts-ignore*/}
                                                                <div  className={`badge badge-${classStatus[companyData?.status||'']} fw-bolder`}>
                                                                    {/*@ts-ignore*/}
                                                                    <KTSVG path={iconsStatus[companyData?.status]} className={`svg-icon-${classStatus[companyData?.status]} svg-icon-2hx me-2`} />
                                                                    {intl.formatMessage({id:companyData?.status})}
                                                                </div>
                                                            </>)
                                                        }

                                                    </>
                                                    ) :''}

                                            </div>
                                        </div>

                                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                            <span
                                                className='d-flex align-items-center text-gray-400 me-5 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/build.svg'
                                                    className='svg-icon-4 me-1'
                                                />

                                                {type}
                                            </span>
                                            <a
                                                href={companyData?.phone?`tel:${companyData?.phone}`:'/'}
                                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/phone.svg'
                                                    className='svg-icon-4 me-1'
                                                />

                                                {ReplacePhone(companyData?.phone||'')}
                                            </a>

                                            {
                                                companyData?.email?( <a
                                                    href={companyData?.email?`mailto:${companyData?.email}`:'/'}
                                                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                                >
                                                    <KTSVG
                                                        path='/media/icons/email.svg'
                                                        className='svg-icon-4 me-1'
                                                    />

                                                    {companyData?.email}
                                                </a>):''
                                            }


                                        </div>
                                    </div>

                                </div>

                                <div className='d-flex flex-wrap flex-stack'>
                                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                                        <div className='d-flex flex-wrap'>
                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='fs-2 fw-bolder me-5'>{staticticInfo?.count||0}</div>
                                                    <Tooltip  color='var(--kt-body-bg)'  placement="topLeft" title={()=><span style={{color: 'var(--kt-text-gray-800)'}}>{intl.formatMessage({id:'transactions_info_count'})}</span>}>
                                                        <i style={{fontSize:"16px"}} className="fa-sharp fa-solid fa-circle-info"/>
                                                    </Tooltip>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Все транзакции</div>
                                            </div>

                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='fs-2 fw-bolder me-5'>{NumReplace(staticticInfo?.amount?staticticInfo?.amount/100+'':'0')} UZS</div>
                                                    <Tooltip  color='var(--kt-body-bg)'  placement="topLeft" title={()=><span style={{color: 'var(--kt-text-gray-800)'}}>{intl.formatMessage({id:'amount_from_register'})}</span>}>
                                                        <i style={{fontSize:"16px"}} className="fa-sharp fa-solid fa-circle-info"/>
                                                    </Tooltip>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Общая сумма</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='d-flex overflow-auto h-55px'>

                            <Nav fill variant="tabs" className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"  onSelect={(e)=>setActiveKey(e)}>

                                <Nav.Item>
                                    <Nav.Link eventKey="dashboard" className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey==='dashboard'?'active':''}`}>{intl.formatMessage({id:"DASHBOARD_MERCHANT"})}</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="transactions" className={`nav-link text-active-primary text-gray-800 me-6 ${activeKey==='transaction'?'active':''}`}>{intl.formatMessage({id:"trans"})}</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="cashboxes" className={`nav-link text-active-primary text-gray-800 me-6 ${activeKey=== 'cashboxes'?'active':''}`}>{intl.formatMessage({id:"cashboxes"})}</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="employee" className={`nav-link text-active-primary text-gray-800 me-6 ${activeKey==='employee'?'active':''}`}>{intl.formatMessage({id:"employee"})}</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="settings" className={`nav-link text-active-primary text-gray-800 me-6 ${activeKey==='settings'?'active':''}`}>{intl.formatMessage({id:"settings"})}</Nav.Link>
                                </Nav.Item>

                            </Nav>
                        </div>

                    </div>
                </div>
            </KTCard>

            <div>
                {renderTabs()}
            </div>

            {itemIdForUpdate !== undefined && <UserEditModal />}

        </>
    )
})




const CompanyViewWrapper = () => (
    <ListViewProvider>
        <ErrorProvider>
            <CompanyView />
        </ErrorProvider>
    </ListViewProvider>
)

export {CompanyViewWrapper}



