import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage, FormikProps} from 'formik'
import {CreateCompany} from "@providers/company/_models";
import AsyncSelect from 'react-select/async';
import {customStyles} from "@metronic/assets/ts/select.style";
import {useIntl} from "react-intl";

interface IProps extends FormikProps<CreateCompany> {
  handleChange(arg: any): void,
    values: any,
  setFieldValue(name:string, val:any):void
  fields: {name:string, id:number, children: []}[] | []
}



const Step4: FC<IProps>  = (props) => {
  const [parentField, setParentField] = useState(0)
    const [fields, setFields] = useState<any[]>([])
    const [fieldsChild, setFieldsChild] = useState<any[]>([])
    const [defaultChildField, setDefaultChildField] = useState({})
    const intl = useIntl()


    useEffect(()=>{
        props.setFieldValue('field_of_activity', undefined)
        props.setFieldValue('sub_field_of_activity', undefined)
    }, [])

    useEffect(()=>{
        setFields(props.fields.map(item=>({value: item.id, label: item.name})))
    }, [props])

    useEffect(()=>{
        const children = props.fields.find(item=>item.id === parentField)?.children || []
        setFieldsChild(children.map((item:any)=>({value: item.id, label: item.name})))
    }, [parentField])


    const filterFields = (inputValue: string, values:any[]) => {
        return values.filter((i:any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }




    const loadOptionsFields = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {callback(filterFields(inputValue, fields))}

    const loadOptionsFieldsChild = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {callback(filterFields(inputValue, fieldsChild))}

  return (
    <div className='w-100'>
        <div className="pb-14">
            <h1 className="fw-bold text-dark">{intl.formatMessage({id: 'BRAND_NAME'})}</h1>
        </div>

      <div className='mb-8 fv-row'>
        <label className='form-label mb-3 required'>{intl.formatMessage({id:'brand_name'})}</label>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='brand_name'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='brand_name' />
        </div>
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label required'>{intl.formatMessage({id:'field_of_activity'})}</label>
        <Field
          component={AsyncSelect}
          styles={customStyles}
          defaultOptions={fields}
          loadOptions={loadOptionsFields}
          cacheOptions
          value={fields.filter(item=>item.value===props.values['field_of_activity'])}
          placeholder={intl.formatMessage({id:"select"})}
          name='field_of_activity'
          onChange={(e:any)=>{
              props.setFieldValue('field_of_activity', e.value)
            setParentField(+e.value)
              props.setFieldValue('sub_field_of_activity', undefined)
              //@ts-ignore
              setDefaultChildField({})

          }}
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='field_of_activity' />
        </div>
      </div>
      <div className='fv-row mb-12'>
          <label className='form-label required'>{intl.formatMessage({id:'sub_field_of_activity'})}</label>
          <Field
              component={AsyncSelect}
              styles={customStyles}
              defaultOptions={fieldsChild}
              value={fieldsChild.filter(item=>item.value===props.values['sub_field_of_activity'])}
              placeholder={intl.formatMessage({id:"select"})}
              loadOptions={loadOptionsFieldsChild}
              cacheOptions
              name='sub_field_of_activity'
              onChange={(e:any)=>{
                  setDefaultChildField(fieldsChild.find((item:any)=>item.value===e.value))
                  props.setFieldValue('sub_field_of_activity', e.value)
              }}
          />

        <div className='text-danger mt-2'>
          <ErrorMessage name='sub_field_of_activity' />
        </div>
      </div>
    </div>
  )
}

export {Step4}
