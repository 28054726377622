import {makeAutoObservable, toJS} from "mobx";

type TransactionSortParams = { sortBy: string, sortFromMax: boolean }

class Transactions {
    transactions: TransactionSortParams = {sortBy: "CREATED_AT", sortFromMax: false}
    pagination:any = {}
    filters:any = {}
    transactionsData:any[] = []

    constructor() {
        makeAutoObservable(this)
    }

    setTransactionSortBy(data: TransactionSortParams) {
        this.transactions = data
    }
    setPagination(data: any) {
        this.pagination = data
    }
    setFilters(data: any) {
        this.filters = data
    }
    setTransactionsData(data: any) {
        this.transactionsData = data
    }

    get getTransactionSortBy() {
        return toJS(this.transactions)
    }
    get getPagination() {
        return toJS(this.pagination)
    }
    get getFilters() {
        return toJS(this.filters)
    }
    get getTransactions() {
        return toJS(this.transactionsData)
    }
}


export const transactions = new Transactions()