import * as Yup from 'yup'
import {CreateCompany, CreateCompanyFiles} from "@providers/company/_models";
import moment from "moment/moment";
import {REGEXP_PHONE} from "@utils/constants";
import {useIntl} from "react-intl";


function CompanyFilesSchema() {
    const intl = useIntl()
    const createCompanyFilesSchemas = [
        Yup.object({
            ceo_passport_file: Yup.mixed()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            license_file: Yup.mixed()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            logo_file: Yup.mixed()
                // .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        }),
    ]

    return createCompanyFilesSchemas
}

const filesInits: CreateCompanyFiles = {
    ceo_passport_file: '',
    logo_file: '',
    license_file: ''
};


function CompanySchema() {
    const intl = useIntl()
    let createCompanySchemas:any[]=[]

    createCompanySchemas =  [
        Yup.object({
            ceo_first_name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            ceo_last_name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),

            ceo_passport_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .min(9, 'Не достаточно символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            ceo_passport_date: Yup.date().max(moment().subtract(1, 'days').format('YYYY-MM-DD'), intl.formatMessage({id: 'max_date'})).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            phone: Yup.string().matches(
                REGEXP_PHONE,
                intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'})
            ).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            email: Yup.string()
                .email('Не верный формат Email'),

        }),
        Yup.object({
            name: Yup.string().max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            legal_name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            address: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            postal_code: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .min(6, 'Не достаточно символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            inn: Yup.string()
                .min(9, 'Не достаточно символов')
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            oked: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .min(5, 'Не достаточно символов'),
            pinfl: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .min(14, 'Не достаточно символов'),
            nds: Yup.string()
                .min(12, 'Не достаточно символов'),
            mfo: Yup.string()
                .required('Обязательное поле')
                .min(5, 'Не достаточно символов')
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            bank: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            bank_filial: Yup.string()
                .max(50, 'Слишком много символов'),
            ikpu: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов'),
            type: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            bank_account_id: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .min(20, 'Не достаточно символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
        }),
        Yup.object({
            ceo_passport_file: Yup.mixed()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            license_file: Yup.mixed()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            logo_file: Yup.mixed()
        }),
        Yup.object({
            brand_name: Yup.string()
                .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            field_of_activity: Yup.number().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            sub_field_of_activity: Yup.number().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        })
    ]


    return createCompanySchemas
}


const inits: CreateCompany = {
    ceo_first_name: '',
    ceo_last_name: '',
    ikpu: '',
    ceo_passport_id: '',
    // ceo_passport_org: '',
    ceo_passport_date: '',
    phone: '',
    email: "",
    type: 0,
    name: '',
    nds: undefined,
    postal_code: '',
    legal_name: '',
    address: '',
    inn: '',
    oked: '',
    pinfl: '',
    mfo: '',
    bank: '',
    bank_filial: '',
    bank_account_id: '',
    brand_name: '',
    field_of_activity: 0,
    sub_field_of_activity: 0,
    ceo_passport_file: '',
    logo_file: '',
    license_file: ''
};




export {CompanySchema, inits, CompanyFilesSchema, filesInits}
