import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {StepperComponent} from '@metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'

import {CompanyFilesSchema, CompanySchema, filesInits, inits} from './CreateAccountWizardHelper'

import {CreateCompany, CreateCompanyFiles} from "@providers/company/_models";
import {Company} from "@providers/company/_request";
import Alert from 'react-bootstrap/Alert';
import {useNavigate} from "react-router-dom";
import {company} from "@store/company";
import {observer} from "mobx-react";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {filterObject, isDemo, localErrorsMessage} from "@utils/index";
import {Tooltip} from "antd";


const Vertical: FC = observer(() => {
    const [selfEmployee, setSelfEmployee] = useState(false)
    const companySchema = CompanySchema()
    const companyFilesSchema = CompanyFilesSchema()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(companySchema[0])
    const [initValues, setInitValues] = useState<CreateCompany>(inits)
    const [filesInitValues, setFilesInitValues] = useState<CreateCompanyFiles>(filesInits)
    const [alert, setAlert] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [type, setType] = useState([])
    const [fields, setFields] = useState([])
    const {setError} = useErrors()
    const intl = useIntl()




    const [completedSteps, setCompletedSteps] = useState(0)
    useEffect(() => {
        setInitValues({
            ...initValues,
            phone: (company.getMe?.phone || '').replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5')
        })
    }, [company.getMe])


    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        stepper.current.goPrev()

        setCurrentSchema(companySchema[stepper.current.currentStepIndex - 1])
    }


    const submitStep = async (values: CreateCompany, actions: FormikValues) => {
        if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) return

        if (!stepper.current) {
            return
        }

        setCurrentSchema(companySchema[stepper.current.currentStepIndex])

        if (stepper.current.currentStepIndex === 4) {
            setLoading(true)

            await createCompany(values)
            setLoading(false)

        }

        if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
            setCompletedSteps(stepper.current?.currentStepIndex)
            stepper.current.goNext()
        } else {
            stepper.current.goto(1)
            actions.resetForm()
            setCompletedSteps(0)
        }
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])

    useEffect(() => {
        companyTypes()
        companyFields()
    }, [])

    async function createCompany(values: CreateCompany) {
        const checkValue = values.phone.replace(/ /g, '').replace('+', '')
        let values_replace: any = {field_of_activity: values.sub_field_of_activity, phone: checkValue, type: values.type}


        for(let key in values){
            //@ts-ignore
            if(values[key]&&values[key].length&&key!=='phone'){
                //@ts-ignore
                values_replace[key] = values[key]
            }
        }


        delete values_replace.sub_field_of_activity

        const files: any = {
            logo_file: values.logo_file,
            license_file: values.license_file,
            ceo_passport_file: values.ceo_passport_file,
        }

        if(selfEmployee){
            delete values_replace.oked
            delete values_replace.nds
        }
        if(!selfEmployee){
            delete values_replace.pinfl
        }
        delete values_replace.logo_file
        delete values_replace.license_file
        delete values_replace.ceo_passport_file




        const formData = new FormData()
        for (let val in files) {
            formData.append(val, files[val])
        }


        // await Company.createCompany(formData)
        await Company.createCompany(values_replace)
            .then((res) => {
                Company.createCompanyFiles(formData, res.data.result.id.toString())
            }).then(() => {
                setAlert(intl.formatMessage({id: 'ADD_COMPANY_SUCCESS'}))
                Company.getCompany().then(response => company.setCompany(response?.data?.result || []))
                setTimeout(() => {
                    navigate('/companies')
                }, 3000)
            }).catch(err => {
                let message = ''
                if(err?.response?.data?.message==='Validation failed'){
                    err?.response?.data?.fields.forEach((item:any)=>{
                        message+=`${item.name} - ${item.error}\n`
                    })
                }else{
                    message = localErrorsMessage('post-company', 'message-match', err?.response?.data?.message, intl)
                }
                setError({status: err?.response?.status, message: message , isError: true})

            })
    }

    const companyTypes = async () => {
        await Company.getCompanyTypes()
            .then((response) => {
                setType(response?.data?.result || [])
            })
    }

    const companyFields = async () => {
        await Company.getCompanyFields()
            .then((response) => {
                setFields(response?.data?.result || [])
            })
    }

    const customValidate = (e: any, values: any) => {
        const isNotRequired = ['email', 'nds', 'bank_filial', 'logo_file']
        const errors = filterObject(values, (val: any, key: string) => isNotRequired.includes(key) ? false : val === "" || val === null || val === 0)

        let err_message = ''
        if (Object.keys(errors).length) {
            const firstErrKey = Object.keys(errors)[0]
            //@ts-ignore
            const firstErrorSchemaIndex = currentSchema.findIndex(schema => {
                const arrSchemaKeys = Object.keys(schema.fields)
                return arrSchemaKeys.includes(firstErrKey)
            })
            stepper.current?.goto(firstErrorSchemaIndex + 1)
            setCurrentSchema(currentSchema[firstErrorSchemaIndex])
            for (let err in errors) {
                //@ts-ignore
                err_message += `* ${intl.formatMessage({id: err})} - ${intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})} ` + "\n"
            }
            setError({status: '', message: err_message, isError: true})
            e.preventDefault()
            return false
        }
        return true
    }


    return (
        <>
            <div
                ref={stepperRef}
                className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_create_account_stepper'
            >
                {alert && (
                    <Alert className='position-absolute' style={{left: '60%'}} key={'success'} variant={'success'}>
                        {alert}
                    </Alert>
                )}
                <div
                    className='d-flex justify-content-center bg-body rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                    <div className='px-6 px-lg-10 px-xxl-15 py-20'>
                        <div className='stepper-nav'>
                            <div className='stepper-item current' data-kt-stepper-element='nav'>

                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(companySchema[0])

                                    stepper.current?.goto(1)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>1</span>
                                    </div>

                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'INFO_CEO'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'PASSPORT_DATA'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-line h-40px'></div>
                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(companySchema[1])

                                    stepper.current?.goto(2)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>2</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'INFO_COMPANY'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'BANK_REK'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-line h-40px'></div>
                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(companySchema[2])

                                    stepper.current?.goto(3)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>3</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'UPLOADING_DOCUMENTS'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'span_step_uploading'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-line h-40px'></div>
                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(companySchema[3])

                                    stepper.current?.goto(4)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>4</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'BRAND_NAME'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'NAME_TORG'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
                    <Formik enableReinitialize={true} validationSchema={currentSchema} initialValues={initValues}
                            onSubmit={submitStep}>
                        {(props) => {
                            const {
                                values,
                                errors,
                                handleChange,
                                setFieldValue,
                                setFieldTouched,
                                submitForm,
                                setErrors,
                                validateField,
                            } = props

                            return (

                                <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                                    <div className='current' data-kt-stepper-element='content'>
                                        {/*@ts-ignore*/}
                                        <Step1 values={values} handleChange={handleChange} setFieldValue={setFieldValue}
                                               errors={errors} />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        {/*@ts-ignore*/}
                                        <Step2
                                            values={values}
                                            handleChange={handleChange}
                                            type={type}
                                            setFieldValue={setFieldValue}
                                            setErrors={setErrors}
                                            setSelfEmployee={setSelfEmployee}
                                            setFieldTouched={setFieldTouched}
                                            selfEmployee={selfEmployee}
                                        />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        {/*@ts-ignore*/}
                                        <Step3 handleChange={handleChange} setFieldValue={setFieldValue}
                                               values={values}/>
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        {/*@ts-ignore*/}
                                        <Step4 fields={fields} handleChange={handleChange}
                                               setFieldValue={setFieldValue} values={values}/>
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <Step5/>
                                    </div>

                                    <div className='d-flex flex-stack'>
                                        <div className='mr-2'>
                                            <button
                                                onClick={prevStep}
                                                type='button'
                                                className='btn btn-lg btn-light-primary me-3'
                                                data-kt-stepper-action='previous'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr063.svg'
                                                    className='svg-icon-4 me-1'
                                                />
                                                {intl.formatMessage({id: 'BACK'})}
                                            </button>
                                        </div>
                                        <Tooltip
                                            zIndex={(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) ? 1 : -1}
                                            color='var(--kt-body-bg)' title={() => <span
                                            style={{color: 'var(--kt-text-gray-600)'}}>{(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) ? intl.formatMessage({id: "DEMO_DISABLED"}) : ""}</span>}>
                                            <div>
                                                <button onClick={(e) => {
                                                    if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) return
                                                    // if (stepper.current?.currentStepIndex && stepper.current?.currentStepIndex >= completedSteps) return

                                                    // if (stepper.current?.currentStepIndex === 4) {
                                                    //     try {
                                                    //         const val = await currentSchema.validate(values)
                                                    //     } catch (e) {
                                                    //     }
                                                    // }

                                                    if (stepper.current?.currentStepIndex === 4) {
                                                        const canContinue = customValidate(e, values)
                                                        if (!canContinue) return
                                                    }

                                                    let err_message = ''
                                                    if (Object.keys(errors).length) {

                                                        for (let err in errors) {
                                                            //@ts-ignore
                                                            err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                        }
                                                        setError({status: '', message: err_message, isError: true})
                                                    }
                                                }
                                                } type='submit' className='btn btn-lg btn-primary me-3'
                                                        disabled={loading || (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo())}>
                                                <span className='indicator-label'>
                                            {stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber ? intl.formatMessage({id: 'CREATE'}) : stepper.current?.currentStepIndex !==
                                                stepper.current?.totatStepsNumber! - 1 && intl.formatMessage({id: 'THEN'})}
                                                    {stepper.current?.currentStepIndex ===
                                                        stepper.current?.totatStepsNumber! - 1 && intl.formatMessage({id: 'SEND'})}
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr064.svg'
                                                        className='svg-icon-3 ms-2 me-0'
                                                    />
                                                </span>
                                                </button>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </Form>
                            )
                        }
                        }
                    </Formik>
                </div>
            </div>
        </>
    )
})

export {Vertical}
