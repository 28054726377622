import React, {useEffect, useMemo, useState} from "react";
import {employeeStore} from "@store/employee";
import {ADMIN, MODERATOR, OWNER, SUPER_ADMIN} from "@utils/constants";
import {usersColumns, usersColumnsOwner} from "../../../employees/_modals/columns/_columns";
import {ColumnInstance, Row, useTable} from "react-table";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {Admins} from "@providers/admins/_request";
import {Employee} from "@providers/employee/_request";
import {KTCard, KTCardBody, KTSVG} from "@metronic/helpers";
import {CreateEmployee} from "@providers/employee/_models";
import {CustomHeaderColumn} from "../../../employees/_modals/columns/CustomHeaderColumn";
import {CustomRow} from "../../../employees/_modals/columns/CustomRow";
import {useParams} from "react-router-dom";
import {employeeColumnsAdmin} from "../../_modals/columns/_columns";
import {observer} from "mobx-react";
import {useListView} from "../../_modals/providers/ListViewProvider";


export const  Employees = observer(()=>{
    const {setItemIdForUpdate} = useListView()
    const data = useMemo(() => employeeStore.getEmployee.map((item, index)=>{
        return {...item, num: index+1}
    }), [employeeStore.getEmployee])
    const columns = useMemo(() => employeeColumnsAdmin, [employeeStore.getEmployee])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const {setError} = useErrors()
    const intl = useIntl()
    const {id} = useParams()
    const [searchText, setSearchText] = useState('')
    const [searchTextController, setSearchTextController] = useState('')



    useEffect(()=>{
        if(!!searchText.length){
            searchByPhone()
        }else{
            if(id){
                getEmployees()
            }
        }
    }, [searchText])



    const getEmployees = ()=>{
        Employee.getEmployee(id??'')
            .then(result=>{
                employeeStore.setEmployee(result?.data?.result??[])
            }).catch(err=>{setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})})
    }


    function searchByPhone(){
        let srch = data.filter(item=>item.phone.match(searchText))
        employeeStore.setEmployee(srch)
    }

    return (
        <>
            <div className='d-flex justify-content-between'>
                <button type='button' className='btn btn-primary mb-5' onClick={()=>setItemIdForUpdate(null)}>
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    {intl.formatMessage({id:"ADD_BUTTON"})}
                </button>
                <div className='col-lg-5'>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        setSearchText(searchTextController.trim())
                    }} className='d-flex align-items-center position-relative my-1 mb-5'>

                        <input
                            style={{border: "none", borderRadius: "6px", height: "44px"}}
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control pe-5'
                            placeholder='Поиск по номеру телефона'
                            value={searchTextController}
                            onChange={(e) => setSearchTextController(e.target.value)}
                        />
                        {searchTextController.length > 0 &&
                            <span className='position-absolute end-0 me-8 pe-8' onClick={() => {
                                setSearchTextController("")
                                setSearchText("")
                            }}>
                            <KTSVG
                                path='/media/icons/duotune/general/gen034.svg'
                                className='svg-icon-1 text-hover-primary'
                            />
                        </span>}
                        <button type="submit"
                                style={{borderTopRightRadius: "6px", borderBottomRightRadius: "6px", width: "44px"}}
                                className='border-0 text-hover-secondary cursor-pointer position-absolute end-0 bg-primary h-100 d-flex justify-content-center align-items-center'>
                            <KTSVG
                                path='/media/icons/duotune/general/gen021.svg'
                                className='svg-icon-1 text-white'
                            />
                        </button>
                    </form>
                </div>
            </div>
            <KTCard>
                <KTCardBody className='py-4'>
                    <div className='table-responsive'>
                        <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                            {...getTableProps()}
                        >
                            <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                {headers.map((column: ColumnInstance<CreateEmployee>) => (
                                    <CustomHeaderColumn key={column.id} column={column} />
                                ))}
                            </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                            {rows.length > 0 ? (
                               <>
                                   {
                                       rows.map((row: Row<CreateEmployee>, i) => {
                                           prepareRow(row)
                                           return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                                       })
                                   }

                               </>
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            {intl.formatMessage({id:"NOT_MATCHING"})}
                                        </div>
                                    </td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                        {
                            rows.length>0?(<span className='fs-6 fw-semibold text-gray-700'>Показаны от 1 до {data.length} Сотрудников</span>):''
                        }
                    </div>

                </KTCardBody>
            </KTCard>
        </>
    )
})