/* eslint-disable react/jsx-no-target-blank */
import React, {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from '../AsideMenuItemWithSub'
import {AsideMenuItem} from '../AsideMenuItem'
import {Link, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {
    OWNER,
    JUNIOR_CASHIER,
    CEO,
    CASHIER,
    ACCOUNTANT,
    ANALYST,
    ADMIN,
    MODERATOR,
    SUPER_ADMIN
} from "@utils/constants";
import {roleRouting} from "@utils/index";
import clsx from "clsx";
import {KTSVG} from "@metronic/helpers";
import {Modal} from "react-bootstrap";
import {company} from "@store/company";

export const AsideMenuMainAdmin = observer(() => {
    const [showModal, setShowModal] = useState(false)
    const intl = useIntl()
    const {companyId} = useParams()

    const renderLinks = () => {

        if (employeeStore.getRole) {

            switch (employeeStore.getRole) {
                case ADMIN:
                    return (<>

                        <AsideMenuItem
                            to={`/companies`}
                            icon='/media/icons/build.svg'
                            title={intl.formatMessage({id: "COMPANIES"})}
                            fontIcon='bi-app-indicator'
                        />


                        <AsideMenuItem fontIcon='bi-app-indicator'
                                       icon='/media/icons/duotune/communication/com014.svg'
                                       to={`/employees/admins`}
                                       title={intl.formatMessage({id: "EMPLOYEES_MODERATORS"})}/>

                        <AsideMenuItem fontIcon='bi-app-indicator'
                                       icon='/media/icons/transactions.svg'
                                       to={`/payments/transactions`}
                                       title={intl.formatMessage({id: "trans"})}/>


                        <AsideMenuItem
                            to={`/dashboard`}
                            icon='/media/icons/duotune/graphs/gra001.svg'
                            title={intl.formatMessage({id: "DASHBOARD_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />



                    </>)
                case MODERATOR:
                    return (<>

                        <AsideMenuItem
                            to={`/companies`}
                            icon='/media/icons/build.svg'
                            title={intl.formatMessage({id: "COMPANIES"})}
                            fontIcon='bi-app-indicator'
                        />




                    </>)

                case SUPER_ADMIN:
                    return (<>

                        <AsideMenuItem
                            to={`/companies`}
                            icon='/media/icons/build.svg'
                            title={intl.formatMessage({id: "COMPANIES"})}
                            fontIcon='bi-app-indicator'
                        />


                        <AsideMenuItem fontIcon='bi-app-indicator'
                                       icon='/media/icons/duotune/communication/com014.svg'
                                       to={`/employees/admins`}
                                       title={intl.formatMessage({id: "EMPLOYEES_MODERATORS"})}/>

                        <AsideMenuItem fontIcon='bi-app-indicator'
                                       icon='/media/icons/transactions.svg'
                                       to={`/payments/transactions`}
                                       title={intl.formatMessage({id: "trans"})}/>


                        <AsideMenuItem
                            to={`/dashboard`}
                            icon='/media/icons/duotune/graphs/gra001.svg'
                            title={intl.formatMessage({id: "DASHBOARD_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />
                        <AsideMenuItemWithSub
                            to={`/agents`}
                            icon='/media/icons/agent.svg'
                            title={intl.formatMessage({id: "AGENTS"})}
                            fontIcon='bi-app-indicator'>
                            <AsideMenuItem to={`/agents/statistic`}
                                           title={intl.formatMessage({id: "agent-title"})} hasBullet={true}/>
                            <AsideMenuItem to={`/agents/individual`}
                                           title={intl.formatMessage({id: "AGENTS-INDIVIDUAL"})} hasBullet={true}/>
                            <AsideMenuItem to={`/agents/company`}
                                           title={intl.formatMessage({id: "AGENTS-YURIDIC"})} hasBullet={true}/>
                        </AsideMenuItemWithSub>



                    </>)

                default:
                    return ''
            }
        }
    }


    return (
        <>

            {renderLinks()}

            <SoonModal shouldShow={showModal} onCancel={() => setShowModal(false)}/>
        </>
    )
})


type SoonModal = {
    onCancel: Function
    shouldShow: boolean,
}

const SoonModal: FC<SoonModal> = ({shouldShow, onCancel}) => {

    const handleClose = () => {
        onCancel()
    }

    return <Modal show={shouldShow} onHide={handleClose}>
        <Modal.Header closeButton>
            <h2>Скоро</h2>
        </Modal.Header>
        <Modal.Body>
            <div>Скоро</div>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
            <button type="button" className="swal2-confirm btn btn-secondary" aria-label=""
                    style={{display: 'inline-block'}} onClick={handleClose}>
                OK
            </button>
        </Modal.Footer>
    </Modal>
}
