import {UserEditModalForm} from './UserEditModalForm'
import {useListView} from '../providers/ListViewProvider'
import {useEffect, useState} from "react";
import {Employee} from "@providers/employee/_request";
import {useParams} from "react-router-dom";
import {useErrors} from "@providers/context/ErrorsProvider";

const UserEditModalFormWrapper = () => {
  const {itemIdForUpdate} = useListView()
  const {error,setError} = useErrors()
  const [isLoading, setLoading] = useState(false)
  const [employee, setEmployee] = useState(null)




  const param = useParams()


  useEffect(()=>{

    if(param.id&&itemIdForUpdate){
      setLoading(true)
      Employee.getEmployeeById(param.id, itemIdForUpdate)
          .then(result=>{
            setLoading(false)
            setEmployee(result?.data?.result)
          }).catch(err=>{
            setLoading(false)
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
          })
    }

  }, [itemIdForUpdate])




  if (!itemIdForUpdate) {
    return <UserEditModalForm isUserLoading={isLoading} user={{id: undefined}} />
  }


  if (!isLoading && !Object.keys(error).length && employee) {
    return <UserEditModalForm isUserLoading={isLoading} user={employee} />
  }




  return null
}

export {UserEditModalFormWrapper}
