import clsx from 'clsx'
import React, {FC, useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {
    HeaderNotificationsMenu,
    HeaderUserMenu,
    Search,
    ThemeModeSwitcher, UserAvatarPlaceholder,
} from '../../../partials'
import {useLayout} from '../../core'
import {CompanySwitcher} from "../../../../app/modules/header/Company-switcher";
import {Company} from "@providers/company/_request";
import {company} from "@store/company";
import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import {roleRoutingAdmin} from "@utils/index";
import {employeeStore} from "@store/employee";

const toolbarButtonMarginClass = 'ms-1 ms-lg-5',
    toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
    toolbarButtonHeightClass1 = 'h-30px h-md-40px',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = observer(() => {
    const {config} = useLayout()
    const {companyId} = useParams()
    const navigate = useNavigate()


    useEffect(() => {
        if (!companyId&&!company.getCompany.length) {
            getData()
        }
    }, [])

    // useEffect(() => {
    //     if (!companyId) {
    //         getData()
    //     }
    // }, [companyId])


    function getData() {
        Company.getCompany({count:20})
            .then((response) => {
                if (response.data.result) {
                    company.setCompany(response.data?.result)
                    company.setPagination({...response.data?.meta, currentPage:1})
                    if (response.data.result.length == 1) {
                        navigate(`${response.data.result[0].id}/dashboard-merchant`)
                    }
                }
            })
    }

    return (
        <div className='d-flex align-items-center flex-shrink-0'>
            {/*/!* Search *!/*/}
            {/*<div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>*/}
            {/*    <Search />*/}
            {/*</div>*/}


            {/*/!* NOTIFICATIONS *!/*/}
            {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
            {/*    /!* begin::Menu- wrapper *!/*/}
            {/*    <div*/}
            {/*        className={clsx(*/}
            {/*            'btn btn-icon btn-active-light-primary btn-custom',*/}
            {/*            toolbarButtonHeightClass*/}
            {/*        )}*/}
            {/*        data-kt-menu-trigger='click'*/}
            {/*        data-kt-menu-attach='parent'*/}
            {/*        data-kt-menu-placement='bottom-end'*/}
            {/*        data-kt-menu-flip='bottom'*/}
            {/*    >*/}
            {/*        <KTSVG*/}
            {/*            path='/media/icons/duotune/general/gen022.svg'*/}
            {/*            className={toolbarButtonIconSizeClass}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <HeaderNotificationsMenu />*/}
            {/*    /!* end::Menu wrapper *!/*/}
            {/*</div>*/}


            {/* begin::Theme mode */}
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                <ThemeModeSwitcher
                    toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
                />
            </div>
            {/* end::Theme mode */}

            {
                companyId && !roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ?  (<>
                    {/* begin:: Companies switcher */}

                    <div className={clsx('d-flex align-items-center', toolbarButtonHeightClass1)}>
                        <CompanySwitcher
                            toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass1)}
                        />
                    </div>

                    {/* end:: Companies switcher */}
                </>) : ''
            }

            {/* begin::User */}
            <div
                className={clsx('d-flex align-items-center user-list__block', toolbarButtonMarginClass)}
                id='kt_header_user_menu_toggle'
            >
                {/* begin::Toggle */}
                {/*<div*/}
                {/*    className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}*/}
                {/*    data-kt-menu-trigger='click'*/}
                {/*    data-kt-menu-attach='parent'*/}
                {/*    data-kt-menu-placement='bottom-end'*/}
                {/*    data-kt-menu-flip='bottom'*/}
                {/*>*/}
                {/*    <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='metronic'/>*/}
                {/*</div>*/}
                <HeaderUserMenu/>
                {/* end::Toggle */}
            </div>
            {/* end::User */}

            {/* begin::Aside Toggler */}
            {/*{config.header.left === 'menu' && (*/}
            {/*    <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>*/}
            {/*        <div*/}
            {/*            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'*/}
            {/*            id='kt_header_menu_mobile_toggle'*/}
            {/*        >*/}
            {/*            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    )
})

export {Topbar}
