import {UserEditModalForm} from './UserEditModalForm'
import {useListView} from '../providers/ListViewProvider'
import {useEffect, useState} from "react";
import {useErrors} from "@providers/context/ErrorsProvider";
import {Agent} from "@providers/agents/_request";

const UserEditModalFormWrapper = () => {
  const {itemIdForUpdate} = useListView()
  const {error,setError} = useErrors()
  const [isLoading, setLoading] = useState(false)
  const [agent, setAgent] = useState(null)



  useEffect(()=>{

    if(itemIdForUpdate){
      setLoading(true)
      Agent.getAgentById(itemIdForUpdate)
          .then(result=>{
            setLoading(false)
            setAgent(result?.data?.result)
          }).catch(err=>{
            setLoading(false)
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
          })
    }

  }, [itemIdForUpdate])




  if (!itemIdForUpdate) {
    return <UserEditModalForm isUserLoading={isLoading} user={{id: undefined}} />
  }



  if (!isLoading && !Object.keys(error).length && agent) {
    return <UserEditModalForm isUserLoading={isLoading} user={agent} />
  }




  return null
}

export {UserEditModalFormWrapper}
