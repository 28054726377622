import * as Yup from 'yup'
import {CreateCashbox} from "@providers/filial/_models";
import moment from "moment";
import {useIntl} from "react-intl";
import {REGEXP_PHONE} from "@utils/constants";


function FilialCreateSchema(){

  const intl =  useIntl()

  const createFilialSchemas = [
    Yup.object({
      name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
          .max(50, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'})).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
      legal_name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
          .max(50, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'})).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
      max_amount: Yup.string()
          .max(10, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'}))
          .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
      min_amount: Yup.string()
          .max(10, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'}))
          .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
      gnk_spic: Yup.string()
          .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
      url: Yup.string().url(intl.formatMessage({id: 'invalid_url'}))
          .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
      response_fields: Yup.array()
          .of(
              Yup.object().shape({
                field_name: Yup.string(),
              })
          ),
      request_fields: Yup.array()
          .of(
              Yup.object().shape({
                name: Yup.string()
              })
          )
    }),
    Yup.object({
      logo_file  : Yup.mixed()
    }),
  ]

  return createFilialSchemas
}




const inits: CreateCashbox = {
  name: '',
  legal_name: '',
  logo_file: undefined,
  eposes: [],
  min_amount: undefined,
  max_amount: undefined,
  url: '',
  gnk_spic: '',
  response_fields: [
    {
      "order": 3,
      "name": "amount",
      "field_name": "amount"
    }
  ],
  request_fields: [
    {
      "field_type": "static",
      "field_name": "amount",
      "field_control": "control-01",
      "name": "amount",
      "order": 1,
      "required": true,
      "value": "static_amount_value"
    },
    {
      "field_type": "static",
      "field_name": "currency",
      "field_control": "control-02",
      "name": "currency",
      "order": 2,
      "required": true,
      "value": "static_currency_value"
    }
  ]
};




export {FilialCreateSchema, inits}
