import {instantse} from "@providers/request";
import {CompanyResponse, CreateCompany} from "@providers/company/_models";

export const Company = {
    getCompany(params={}){
        return instantse.get('/v1/company', {params})
            .then(result=>result)
    },
    createCompany(data:any){
        return instantse.post('/v1/company', data)
            .then(result=>result)
    },
    createCompanyFiles(data:any, companyId: string){
        return instantse.post(`/v1/company/${companyId}/upload`, data, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(result=>result)
    },
    deleteCompany(id:number){
        return instantse.delete(`/v1/company/${id}`)
            .then(result=>result)
    },
    editCompany(id:string,data:any){
        return instantse.put(`/v1/company/${id}`, data, {
            // headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(result=>result)
    },
    changeStatusRequest(company_id:number,data:any){
        return instantse.put(`/v1/company/${company_id}/status`, data)
            .then(result=>result)
    },
    editCommission(id:string,data:any){
        return instantse.put(`/v1/company/${id}/commission/`, data)
            .then(result=>result)
    },
    getById(id:string){
        return instantse.get(`/v1/company/${id}`)
            .then(result=>result)
    },
    getCompanyTypes(){
        return instantse.get('/v1/list/types')
    },
    getCompanyFields(){
        return instantse.get('/v1/list/activity-fields')
    },
    getFiles(fileId:string){
        return `${process.env.REACT_APP_PUBLIC_URL}/file/${fileId}`
    }

}