import {observer} from "mobx-react";
import React, {useEffect, useMemo, useState} from "react";
import {company} from "@store/company";
import {companyColumnsAdmin, filialColumnsAdmin} from "../../_modals/columns/_columns";
import {ColumnInstance, Row, useTable} from "react-table";
import {useIntl} from "react-intl";
import {Company} from "@providers/company/_request";
import {KTCard, KTCardBody, KTSVG} from "@metronic/helpers";
import {TransactionsCompanyResp} from "@providers/adminTransactions/_models";
import {CustomHeaderColumn} from "../../_modals/columns/CustomHeaderColumn";
import {CustomRow} from "../../_modals/columns/CustomRow";
import {Pagination} from "../../../../modules/Pagination";
import {filial} from "@store/filial";
import {Filial} from "@providers/filial/_request";
import {useNavigate, useParams} from "react-router-dom";
import {useErrors} from "@providers/context/ErrorsProvider";
import {employeeStore} from "@store/employee";



type Iprops = {
    cashboxes: any[]
}

export const FilialTab = observer((props:Iprops) => {


    const [cashboxes, setCashboxes] = useState<any[]>(props.cashboxes)

    const data = useMemo(() => cashboxes.map((item,index)=>{
        return {...item, num: index+1}
    }), [cashboxes])
    const columns = useMemo(() => filialColumnsAdmin, [data])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const [searchText, setSearchText] = useState('')
    const [searchTextController, setSearchTextController] = useState('')
    const {id} = useParams()
    const {setError} = useErrors()
    const navigate = useNavigate()


    useEffect(()=>{
        if(searchText.length){
            filialSearch()
        }
    }, [searchText])




    const intl = useIntl()

    async function updatePage(){
        Filial.getFilial(id||'')
            .then(response=>{
                setCashboxes(response?.data?.result)
            })
            .catch(err=>{
                setError({status: err?.response?.status, message: err?.response?.status, isError: true})
            })

    }


    function filialSearch(){

        let srch = data.filter(item=>{
            return (item.name||'').toLowerCase().match(searchText.toLowerCase())
        })
        setCashboxes(srch)
    }







    return (

        <>
            <div className='d-flex justify-content-between'>
                <button type='button' className='btn btn-primary mb-5' onClick={()=>navigate(`/${id}/cashboxes/create`)}>
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    {intl.formatMessage({id:"add_cashbox"})}
                </button>
                <div className='col-lg-6'>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        setSearchText(searchTextController.trim())
                    }} className='d-flex align-items-center position-relative my-1 mb-5'>

                        <input
                            style={{border: "none", borderRadius: "6px", height: "44px"}}
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control pe-5'
                            placeholder='Поиск по наименованию'
                            value={searchTextController}
                            onChange={(e) => setSearchTextController(e.target.value)}
                        />
                        {searchTextController.length > 0 &&
                            <span className='position-absolute end-0 me-8 pe-8' onClick={() => {
                                setSearchTextController("")
                                setSearchText("")
                                updatePage()
                            }}>
                            <KTSVG
                                path='/media/icons/duotune/general/gen034.svg'
                                className='svg-icon-1 text-hover-primary'
                            />
                        </span>}
                        <button type="submit"
                                style={{borderTopRightRadius: "6px", borderBottomRightRadius: "6px", width: "44px"}}
                                className='border-0 text-hover-secondary cursor-pointer position-absolute end-0 bg-primary h-100 d-flex justify-content-center align-items-center'>
                            <KTSVG
                                path='/media/icons/duotune/general/gen021.svg'
                                className='svg-icon-1 text-white'
                            />
                        </button>
                    </form>
                </div>
            </div>

            <KTCard>
                <KTCardBody className='py-4'>
                    {columns.length ? <div className='table-responsive'>
                        <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                            {...getTableProps()}
                        >
                            <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                {headers.map((column: ColumnInstance<TransactionsCompanyResp>) => (
                                    <CustomHeaderColumn key={column.id} column={column}/>
                                ))}
                            </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                            {rows.length > 0 ? (
                                rows.map((row: Row<TransactionsCompanyResp>, i) => {
                                    prepareRow(row)
                                    return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                                })
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            {intl.formatMessage({id: 'NO_MATCHING'})}
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <span className='fs-6 fw-semibold text-gray-700'>Показаны {data.length} Касс</span>
                    </div> : <div style={{height: "55vh"}} className="d-flex justify-content-center align-items-center">
                        <a href="https://demo.globalpay.uz" target="_blank" className="btn btn-success text-white fs-3">
                            {intl.formatMessage({id: "WATCH_DEMO_COMPANY1"})}
                            <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-3 ms-3' />
                        </a>
                    </div>}


                </KTCardBody>
            </KTCard>
        </>
    )
})