import * as Yup from 'yup'
import {CreateCompany} from "@providers/company/_models";
import moment from "moment/moment";
import {useIntl} from "react-intl";
import {FILE_SIZE, SUPPORTED_FORMATS, SUPPORTED_FORMATS_IMAGES} from "@utils/constants";

function SettingsMerchantSchema() {
    const intl = useIntl()

    return Yup.object({

        logo_file: Yup.mixed().test('fileSize', intl.formatMessage({id: 'file_size'}), value => {
            if (value !== null && typeof value === 'object') {
                const size_kb = +(value.size / 1024).toFixed(1)
                return size_kb <= FILE_SIZE
            }
            return true

        }).test('fileType', intl.formatMessage({id: 'type_file'}), value => {
            if (value !== null && typeof value === 'object') {
                return value ? SUPPORTED_FORMATS.includes(value.type) : true
            }
            return true
        }),
        type: Yup.string().required('Обязательное поле'),
        name: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),
        postal_code: Yup.string().required('Обязательное поле')
            .min(6, 'Не достаточно символов').required('Обязательное поле'),

        address: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),

        field_of_activity: Yup.string().required('Обязательное поле'),
        sub_field_of_activity: Yup.string().required('Обязательное поле'),
        phone: Yup.string().required('Обязательное поле'),
        email: Yup.string().email('Не верный формат Email'),
        ceo_first_name: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),
        ceo_last_name: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),

        ceo_passport_id: Yup.string().required('Обязательное поле'),
        ceo_passport_date: Yup.date().max(moment().subtract(1, 'days').format('YYYY-MM-DD')).required('Обязательное поле'),
        ceo_passport_file: Yup.mixed().test('fileSize', intl.formatMessage({id: 'file_size'}), value => {
            if (value) {
                const size_kb = +(value?.size / 1024).toFixed(1)
                if (isNaN(size_kb)) return true;
                return size_kb <= FILE_SIZE
            }
            return true
        }).test('fileType', intl.formatMessage({id: 'type_file'}), value => value && value.type ? SUPPORTED_FORMATS.includes(value.type) : true),
        license_file: Yup.mixed().test('fileSize', intl.formatMessage({id: 'file_size'}), value => {
            if (value !== null && typeof value === 'object') {
                const size_kb = +(value?.size / 1024).toFixed(1)
                if (isNaN(size_kb)) return true;
                return size_kb <= FILE_SIZE
            }
            return true
        }).test('fileType', intl.formatMessage({id: 'type_file'}), value => {
            if(value !== null && typeof value === 'object')  return value && value.type ? SUPPORTED_FORMATS_IMAGES.includes(value.type) : true
            return true
        }),

        inn: Yup.string()
            .min(9, 'Не достаточно символов').required('Обязательное поле'),

        ikpu: Yup.string()
            .max(50, 'Слишком много символов'),

        mfo: Yup.string().required('Обязательное поле')
            .min(5, 'Не достаточно символов').required('Обязательное поле'),
        bank: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),
        bank_filial: Yup.string()
            .max(50, 'Слишком много символов'),
        bank_account_id: Yup.string().required('Обязательное поле')
            .min(20, 'Не достаточно символов').required('Обязательное поле'),
        commission: Yup.string()
    })




}


const inits: CreateCompany = {
    ceo_first_name: '',
    ceo_last_name: '',
    ikpu: '',
    ceo_passport_id: '',
    // ceo_passport_org: '',
    ceo_passport_date: '',
    type: 0,
    name: '',
    phone: "",
    email: "",
    nds: '',
    postal_code: '',
    legal_name: '',
    address: '',
    inn: '',
    pinfl: '',
    oked: '',
    mfo: '',
    bank: '',
    bank_filial: '',
    bank_account_id: '',
    brand_name: '',
    field_of_activity: 0,
    logo_file: undefined,
    license_file: undefined,
    ceo_passport_file: undefined,
    sub_field_of_activity: 0,
    commission: 0,
}


export {SettingsMerchantSchema, inits}


