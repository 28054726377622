
import {KTSVG} from "@metronic/helpers";
import {useListView} from "../../providers/ListViewProvider";
import {useIntl} from "react-intl";




const UsersListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
      setItemIdForUpdate(null)
  }
  const intl = useIntl()
  return (
    <div className='d-flex justify-content-start' data-kt-user-table-toolbar='base'>

            {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/general/gen041.svg' className='svg-icon-2' />
          {intl.formatMessage({id:"ADD_BUTTON"})}
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar}
