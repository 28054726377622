import React, {useState} from 'react';
import {useErrors} from "@providers/context/ErrorsProvider";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {
    UsersListLoading
} from "../../../../../../modules/apps/user-management/users-list/components/loading/UsersListLoading";
import {KTSVG} from "@metronic/helpers";
import {Modal} from "react-bootstrap";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {initialValues, validationSchema} from "../paymentSchedule/validationSchema";
import {ContractStatus, IPaymentCash, ISchedule, ISchedulePay} from "@providers/autopay/models/_models";
import {contract} from "@store/contract/contract";
import {ContractsService} from "@providers/autopay/requests/contracts.service";
import {ScheduleService} from "@providers/autopay/requests/schedule.service";
import {divideByHundred, multiplyByHundred} from "@utils/numbersOperations";
import {isDisabledContract} from "@utils/isDisabledContract";
import moment from "moment";
import {dateFormat} from "../../editContractHelper";

interface props {
    status: ContractStatus
    schedules: ISchedule[]
}

const CashPaymentModal: React.FC<props> = ({
                                               status,
                                               schedules
                                           }) => {
    const onHide = () => setShow(false)

    const {entity, loaded} = contract.getContract
    const intl = useIntl()
    const [show, setShow] = useState<boolean>(false)

    const {setError} = useErrors()

    const {contractId} = useParams()

    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

    const oneTimePaymentLimit = schedules.length ? divideByHundred(schedules[0].paymentSum) : 0


    const getContactDetails = async () => {
        if (contractId) {
            try {
                let {data} = await ContractsService.getContractById(contractId)
                contract.setContract(data.data)
            } catch (e: any) {
                if (e.response.status === 404) {
                    setError({message: intl.formatMessage({id: 'contract_not_found'}), isError: true, status: 404})
                }
                if (e?.response?.data?.error || e?.response?.data?.message) {
                    setError({
                        message: e?.response?.data?.error || e?.response?.data?.message,
                        isError: true,
                        status: e?.response?.status
                    })
                    contract.setContractError(e.response.data.error || e?.response?.data?.message)
                }
            }
        }
    }


    const onPay = async (data: ISchedulePay[]) => {
        if (contractId) {
            setConfirmLoading(true)
            try {
                const response = await ScheduleService.postCashPayment(contractId, data).then((response) => response.data)
                getContactDetails()
                onHide()
            } catch (e: any) {
                setError({
                    message: e?.response?.data?.error || e?.response?.data?.message,
                    isError: true,
                    status: e?.response?.status
                })
            } finally {
                setConfirmLoading(false)
            }
        }
    }


    const onSubmit = (values: IPaymentCash) => {
         const spacesRegEx = /\s/g

        if (values.paymentSum) {
            const sum = values.paymentSum.toString().replace(spacesRegEx, '')
            const data: ISchedulePay[] = [
                {
                    paymentSum: multiplyByHundred(Number(sum)),
                    scheduleId: schedules[0].id
                }
            ]
            onPay(data)
        }

    }

    if (!loaded) return <UsersListLoading/>


    const onShow = () => setShow(true)
    return (
        <>
            {confirmLoading && <UsersListLoading/>}
            <button disabled={confirmLoading || isDisabledContract(status)} onClick={onShow}
                    className={`btn btn-primary ms-auto d-flex align-items-center ${confirmLoading || isDisabledContract(status) ? 'disabled' : ''}`}>
                <KTSVG path={'/media/icons/duotune/general/addCard.svg'}/>
                {intl.formatMessage({id: 'addCashPayment'})}
            </button>
            <Modal
                show={show}
                onHide={onHide}
            >
                <Modal.Header closeButton>
                    <h2>{intl.formatMessage({id: "cashPayment"})}</h2>
                </Modal.Header>

                <div className={'card-body'}>
                    <Formik initialValues={initialValues}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema(oneTimePaymentLimit)}>
                        {({
                              handleChange,
                              setFieldValue
                          }) => (
                            <Form>
                                <Modal.Body>
                                    <div className='fv-row mb-10'>
                                        <label
                                            className='form-label mb-3 required'>{intl.formatMessage({id: "DATE_SPIS"})}</label>
                                        <div
                                            className={'form-control form-control-lg form-control-solid d-flex align-items-center disabled'}>
                                            {schedules.length ? moment(schedules[0].scheduleDate).format(dateFormat) : ''}
                                        </div>
                                    </div>
                                    <div className='fv-row'>
                                        <label
                                            className='form-label mb-3 required'>{intl.formatMessage({id: "SUMM_PAY"})}</label>
                                        <Field
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            name='paymentSum'
                                            onChange={(e: React.ChangeEvent<any>) => {
                                                setFieldValue('paymentSum', e.target.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
                                            }}
                                        />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='paymentSum'/>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className='justify-content-end'>
                                    <div
                                        className={`swal2-confirm btn btn-secondary ${confirmLoading ? 'disabled' : ''}`}
                                        style={{display: 'inline-block'}} onClick={onHide}>
                                        {intl.formatMessage({id: "CANCEL_BUTTON"})}
                                    </div>
                                    <button type={'submit'} disabled={confirmLoading || isDisabledContract(status)}
                                            className={`swal2-confirm btn btn-primary ${confirmLoading || isDisabledContract(status) ? 'disabled' : ''}`}
                                            style={{display: 'inline-block'}}>
                                        {intl.formatMessage({id: "addPayment"})}
                                    </button>
                                </Modal.Footer>
                            </Form>


                        )
                        }
                    </Formik>
                </div>

            </Modal>
        </>
    )
        ;
};

export default CashPaymentModal;