/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from "react-intl";


type Props = {
    status: string
}
const classStatus = {
    FAILED: "badge-light-dark",
    APPROVED: "badge-light-success",
    REVERT: "badge-light-danger",
    INIT: "badge-light-dark",
}


const TableStatus: FC<Props> = ({status}) => {

    const intl = useIntl()

    return (
        <div>
            {/*@ts-ignore*/}
            <div className={`badge rounded-4 ${classStatus[status]} justify-content-start text-left align-content-center`} style={{fontSize: "12px", fontWeight: 500}}>
                <img src={`/media/svg/${status.toLowerCase()}.svg`} className="me-1" style={{objectFit: 'contain'}}/>
                <span className={status === "FAILED" ? "opacity-75" : ""} style={{fontWeight:'500'}}>{intl.formatMessage({id: status})}</span>
            </div>
        </div>
    )
}

export {TableStatus}
