import {FC} from 'react'
import moment from "moment";

type Props = {
    created_date?: string
}

const LastTransaction: FC<Props> = ({created_date}) => (
    <> {created_date && <div className='fw-semibold'>
        <p className='mb-0 text-gray-800 fs-6'>{moment(created_date).format('DD/MM/YYYY')}</p>
        <p className='mb-0 text-gray-600 fs-6'>{moment(created_date).format('HH:mm')}</p>
    </div>}</>
)

export {LastTransaction}
