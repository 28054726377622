/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useMemo, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {Account} from "@providers/account/_request";
import {AccountMe} from "@providers/account/_models";
import {useIntl} from "react-intl";
import {observer} from "mobx-react";
import {company} from "@store/company";
import {Modal} from "react-bootstrap";
import clsx from "clsx";
import {roleRoutingAdmin} from "@utils/index";

const HeaderUserMenu: FC = observer(() => {
    const {logout} = useAuth()
    const user = company.getMe
    const {companyId} = useParams()
    const intl = useIntl()
    const [hasPassModal, setHasPassModal] = useState(false)
    const toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'


    useEffect(() => {
        const regex = new RegExp("^\\/\\d+\\/account\\/settings", "i")
        const pathIsSettings = regex.test(window.location.pathname) || window.location.pathname === '/account/settings'
        const {has_password} = company.getMe
        if (!pathIsSettings && has_password === false) {
            setHasPassModal(true)
        }
    }, [window.location.pathname, user])

    return (
        <>
            <div
                className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='bottom'
            >
                <UserAvatarPlaceholder name={user?.first_name||user?.role}/>
                {/*<img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='metronic'/>*/}
            </div>
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
                data-kt-menu='true'
            >
                <div className='menu-item px-3'>
                    <div className='menu-content d-flex align-items-center px-3'>
                        <div className='symbol symbol-50px me-5'>
                            <UserAvatarPlaceholder name={user?.first_name||user?.role}/>
                            {/*<img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')}/>*/}
                        </div>

                        <div className='d-flex flex-column w-75'>
                            <div style={{width: "100%"}}>
                                <p className='fw-bolder fs-5'
                                   style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                                    {user?.first_name||''}
                                </p>
                            </div>
                            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                                {user?.phone}
                            </a>
                        </div>
                    </div>
                </div>

                <div className='separator my-2'></div>


                <div className='separator my-2'></div>

                {roleRoutingAdmin('action', user.role, 'is-admin')?'':  <Languages/>}



                <div className='menu-item px-5 my-1'>
                    <Link to={companyId ? `${companyId}/logs/sessions` : '/logs/sessions'} className='menu-link px-5'>
                        {intl.formatMessage({id: 'SESSIONS'})}
                    </Link>
                </div>


                <div className='menu-item px-5 my-1'>
                    <Link to={companyId ? `${companyId}/account/settings` : '/account/settings'}
                          className='menu-link px-5'>
                        {intl.formatMessage({id: 'ACCOUNT_SETTINGS'})}
                    </Link>
                </div>

                <div className='menu-item px-5'>
                    <a onClick={logout} className='menu-link px-5'>
                        {intl.formatMessage({id: 'SIGN_OUT'})}
                    </a>
                </div>
            </div>

            <SetPasswordModal shouldShow={hasPassModal} onCancel={() => setHasPassModal(false)}/>
        </>
    )
})

type SetPasswordModal = {
    onCancel: Function
    shouldShow: boolean,
}

const SetPasswordModal: FC<SetPasswordModal> = observer(({shouldShow, onCancel}) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const {companyId} = useParams()
    const data = useMemo(() => company.getCompany, [company.getCompany])

    const handleClose = () => {
        if (data.length > 0) {
            navigate(`/${companyId ?? data[0].id}/account/settings`)
        } else {
            navigate(`/account/settings`)
        }
        onCancel()
    }

    return <Modal show={shouldShow}>
        <Modal.Header>
            <h2>{intl.formatMessage({id: 'PASSWORD'})}</h2>
        </Modal.Header>
        <Modal.Body>
            <div className='fs-3'>
                {intl.formatMessage({id: 'SETUP_PASSWORD'})}. {intl.formatMessage({id: 'PRESS_ON_SETUP'})}
            </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
            <button type="button" className="swal2-confirm btn btn-primary"
                    style={{display: 'inline-block'}} onClick={handleClose}>
                {intl.formatMessage({id: 'SETUP'})}
            </button>
        </Modal.Footer>
    </Modal>
})

export {HeaderUserMenu}

type UserAvatarPlaceholder = {
    name?: string
}

export const UserAvatarPlaceholder: FC<UserAvatarPlaceholder> = ({name}) => {
    const getHashOfString = (str: string) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        hash = Math.abs(hash);
        return hash;
    };

    const normalizeHash = (hash: number, min: number, max: number) => {
        return Math.floor((hash % (max - min)) + min);
    };

    const hRange = [0, 360];
    const sRange = [50, 75];
    const lRange = [25, 60];

    const generateHSL = (name: string) => {
        const hash = getHashOfString(name);
        const h = normalizeHash(hash, hRange[0], hRange[1]);
        const s = normalizeHash(hash, sRange[0], sRange[1]);
        const l = normalizeHash(hash, lRange[0], lRange[1]);
        return [h, s, l];
    };

    const HSLtoString = (hsl: any, opacity: number) => {
        return `hsla(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%, ${opacity})`;
    };

    const makeBackgroundColors = (name: string): string => {
        const hsl = generateHSL(name);
        return HSLtoString(hsl, 0.2)
    }

    const makeTextColors = (name: string): string => {
        const hsl = generateHSL(name);
        return HSLtoString(hsl, 1)
    }

    if (!name) {
        return <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')}/>

    }

    return <div style={{backgroundColor: makeBackgroundColors(name), color: makeTextColors(name)}}
                className="google_like_avatar_placeholder">
        <span>{name![0].toUpperCase()}</span>
    </div>

}
