import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {FormikValues, useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../providers/ListViewProvider'
import {
    ACCOUNTANT,
    ADMIN,
    ANALYST,
    CASHIER,
    CEO,
    JUNIOR_CASHIER,
    MODERATOR,
    OWNER,
    REGEXP_PHONE
} from "@utils/constants";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {useIntl} from "react-intl";
import Select from "react-select";
import {customStyles} from "@metronic/assets/ts/select.style";
import {Filial} from "@providers/filial/_request";
import InputMask from "react-input-mask";
import {useErrors} from "@providers/context/ErrorsProvider";
import {AdminRequest, AdminResponse, Roles} from "@providers/admins/_models";
import {Admins} from "@providers/admins/_request";
import {admins} from "@store/admins";
import {company} from "@store/company";
import {localErrorsMessage} from "@utils/index";

type Props = {
    isUserLoading: boolean
    user: any
}




const AdminModalForm: FC<Props> = observer(({user, isUserLoading}) => {
    const {setItemIdForUpdate} = useListView()
    const {setError} = useErrors()
    const intl = useIntl()
    const {id} = company.getMe


    const initialEmployee:AdminRequest = {
        phone: '',
        role: Roles.moderator
    }

    const initialEmployeeEdit:AdminRequest = {
        first_name: '',
        last_name: '',
        middle_name: '',
        role: Roles.moderator
    }

    useEffect(()=>{
        let elem:any = document.body
        elem.addEventListener('click', (e:any)=>{
            if(e.target.getAttribute('id')==='kt_modal_add_user'){
                setItemIdForUpdate(undefined)
            }


        })
    },[])

    const positionsOptions = [
        {
            value: ADMIN,
            label:intl.formatMessage({id:ADMIN})
        },
        {
            value: MODERATOR,
            label:intl.formatMessage({id:MODERATOR})
        }
    ]


    const createAdminSchema = Yup.object().shape({
        phone: Yup.string()
            .matches(
                REGEXP_PHONE,
                intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'})
            )
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        role: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
    })

    const editAdminSchema = Yup.object().shape({
        first_name: Yup.string().max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        last_name: Yup.string().max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        middle_name: Yup.string().max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        role: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
    })



    const [userForEdit] = useState<AdminRequest>({
        ...user,
        role: user.role || initialEmployeeEdit.role,
        first_name: user.first_name || initialEmployeeEdit.first_name,
        last_name: user.last_name || initialEmployeeEdit.last_name,
        middle_name: user.middle_name || initialEmployeeEdit.middle_name,
        phone: user.phone || initialEmployee.phone
    })


    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            Admins.getAdmins().then(response=>admins.setAdmins(response?.data?.result||[]))
        }
        setItemIdForUpdate(undefined)
    }



    const formik = useFormik({
        initialValues: userForEdit,
        validationSchema: user?.id?editAdminSchema:createAdminSchema,
        enableReinitialize: true,
        onSubmit: async (values, {setSubmitting}) => {
            if(!user?.id){
                await createAdmin(values)
            }else{
                await editAdmin(values)
            }
            setSubmitting(true)
        },
    })


    async function createAdmin(values:FormikValues){
        const checkValue = (values?.phone||'').replace(/ /g,'').replace('+','')
        const dataCreate:AdminRequest = {
            phone: checkValue,
            role: values.role??''
        }
        await Admins.createAdmin(dataCreate)
            .then(result=>{
                cancel(true)
            }).catch(err=>{
                setError({status: err?.response?.status, message: err?.response?.data?.code === "METHOD_NOT_ALLOWED"?intl.formatMessage({id: 'not_role'}):localErrorsMessage('post-moderator', 'message-match', err?.response?.data?.message, intl), isError: true})
            })
    }


    async function editAdmin(values:FormikValues){
        //@ts-ignore
        const dataEdit:AdminRequest = {
            first_name: values.first_name,
            last_name: values.last_name,
            middle_name: values.middle_name,
            role:values.role
        }



        await Admins.editAdmin(user?.id, dataEdit)
            .then(result=>{
                cancel(true)
            }).catch(err=>{
                setError({status: err?.response?.status, message: err?.response?.data?.code === "METHOD_NOT_ALLOWED"?intl.formatMessage({id: 'not_role'}):err?.response?.data?.message, isError: true})
            })
    }




    return (
        <>

            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >
                    {/* begin::Input group */}

                    <>
                        {
                            !user?.id&&( <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'phone'})}</label>
                                {/* end::Label */}

                                {/* begin::Input */}

                                <InputMask
                                    {...formik.getFieldProps('phone')}
                                    mask="+\9\9\8 99 999 99 99"
                                    placeholder='+998 90 999 99 99'
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.phone && formik.errors.phone},
                                        {
                                            'is-valid': formik.touched.phone && !formik.errors.phone,
                                        }
                                    )}
                                    type='text'
                                    name='phone'
                                    autoComplete='off'
                                    disabled={formik.isSubmitting || isUserLoading}

                                />
                                {/* end::Input */}
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.phone}</span>
                                    </div>
                                )}
                            </div>)
                        }
                    </>

                    {
                        user?.id&&(<>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ceo_first_name'})}</label>
                                {/* end::Label */}

                                {/* begin::Input */}

                                <input
                                    {...formik.getFieldProps('first_name')}
                                    placeholder=''
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.first_name && formik.errors.first_name},
                                        {
                                            'is-valid': formik.touched.first_name && !formik.errors.first_name,
                                        }
                                    )}
                                    type='text'
                                    name='first_name'
                                    onChange={(e)=>{
                                        formik.setFieldValue('first_name', e.target.value.replace(/\d/g, ''))

                                    }}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting || isUserLoading}

                                />
                                {/* end::Input */}
                                {formik.touched.first_name && formik.errors.first_name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.first_name}</span>
                                    </div>
                                )}
                            </div>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ceo_last_name'})}</label>
                                {/* end::Label */}

                                {/* begin::Input */}

                                <input
                                    {...formik.getFieldProps('last_name')}
                                    placeholder=''
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.last_name && formik.errors.last_name},
                                        {
                                            'is-valid': formik.touched.last_name && !formik.errors.last_name,
                                        }
                                    )}
                                    type='text'
                                    name='last_name'
                                    autoComplete='off'
                                    onChange={(e)=>{
                                        formik.setFieldValue('last_name', e.target.value.replace(/\d/g, ''))

                                    }}
                                    disabled={formik.isSubmitting || isUserLoading}

                                />
                                {/* end::Input */}
                                {formik.touched.last_name && formik.errors.last_name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.last_name}</span>
                                    </div>
                                )}
                            </div>

                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'ceo_middle_name'})}</label>
                                {/* end::Label */}

                                {/* begin::Input */}

                                <input
                                    {...formik.getFieldProps('middle_name')}
                                    placeholder=''
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                        {'is-invalid': formik.touched.middle_name && formik.errors.middle_name},
                                        {
                                            'is-valid': formik.touched.middle_name && !formik.errors.middle_name,
                                        }
                                    )}
                                    type='text'
                                    name='middle_name'
                                    onChange={(e)=>{
                                        formik.setFieldValue('middle_name', e.target.value.replace(/\d/g, ''))

                                    }}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting || isUserLoading}

                                />
                                {/* end::Input */}
                                {formik.touched.middle_name && formik.errors.middle_name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.middle_name}</span>
                                    </div>
                                )}
                            </div>
                        </>)
                    }


                    {
                        user.id!==id?( <div className='mb-7'>
                            {/* begin::Label */}
                            <label className='required fw-bold fs-6 mb-5'>{intl.formatMessage({id: 'role'})}</label>
                            <Select
                                {...formik.getFieldProps('role')}
                                value={positionsOptions.find((item:any)=>formik.values['role']===item.value)}
                                onChange={(e)=>{formik.setFieldValue('role', e?.value)}}
                                name='role'
                                styles={customStyles}
                                isMulti={false}
                                placeholder={intl.formatMessage({id: 'select'})}
                                options={positionsOptions}
                            />

                        </div>):''
                    }

                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-left pt-5'>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>{intl.formatMessage({id: user?.id?'EDIT_BUTTON':'CREATE_BUTTON'})}</span>
                        {(formik.isSubmitting || isUserLoading) && (
                            <span className='indicator-progress'>
                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isUserLoading) && ''}

        </>
    )
})



export {AdminModalForm}
