/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import LoginWrapper from './components/Login'
import {toAbsoluteUrl} from '@metronic/helpers'
import "../../../_metronic/assets/sass/layout/aside/_mobile.scss"

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-100 bgi-attachment-fixed'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/bg4-dark.jpg')})`,
      // }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-column-reverse flex-column-fluid flex-lg-row justify-content-center'>
        <div
            className="d-flex flex-center flex-column col-lg-5 pt-15 pt-lg-0 px-10 bg_auth pb-10"
            style={{backgroundColor:"#E9532F",position:"relative",overflow:"hidden"}}
        >
          <div className="bg-auth_top"></div>
          <div className="d-flex flex-column">
            <a href="/" className="mb-7 link_logo" style={{width:"230px",height:"75px"}}>
              <img alt="Logo" style={{width:"100%",height:"100%"}}  src="/media/auth/Global Pay_logo_white.svg"/>
            </a>
          </div>
          <div className="bg-auth_bot"></div>
          <p className="text-auth">Безопасные платежи для вашего бизнеса</p>
        </div>
        {/* begin::Wrapper */}
        <div className='d-flex align-items-center col-lg-7 p-10 flex-column justify-content-between auth_cabinet'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<LoginWrapper />} />
    </Route>
  </Routes>
)

export {AuthPage}
