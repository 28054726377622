/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useParams} from "react-router-dom";
import {CompanyResponse} from "@providers/company/_models";
import {observer} from "mobx-react";
import {image_default, JUNIOR_CASHIER} from "@utils/constants";
import {employeeStore} from "@store/employee";
import {Company} from "@providers/company/_request";
import {roleRoutingAdmin} from "@utils/index";
import {FilialResponse} from "@providers/filial/_models";

type Props = {
    filial: FilialResponse
}

const FilialNameAdmin: FC<Props> = ({filial}) => {

    const {id} = useParams()

    return (
        <div className='d-flex align-items-center'>
            <div className='me-3'>

                <Link to={`/${id}/cashboxes/${filial.id}`}>
                    <div className='d-flex align-items-center'>
                        <div className='logo-table-admin'
                             style={{backgroundImage: `url(${filial?.logo_file?Company.getFiles(filial?.logo_file):`/${image_default}`})`}}/>

                        <span className='fs-6 text-gray-600 fw-semibold'>{filial.name}</span>
                    </div>

                </Link>
            </div>
        </div>
    )
}

export {FilialNameAdmin}
