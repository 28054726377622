/* eslint-disable react/jsx-no-target-blank */
import React, {FC, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {Link, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {OWNER, JUNIOR_CASHIER, CEO, CASHIER, ACCOUNTANT, ANALYST, AGENT} from "@utils/constants";
import {roleRouting} from "@utils/index";
import clsx from "clsx";
import {KTSVG} from "@metronic/helpers";
import {Modal} from "react-bootstrap";
import {company} from "@store/company";

export const AsideMenuMain = observer(() => {
    const [showModal, setShowModal] = useState(false)
    const intl = useIntl()
    const {companyId} = useParams()
    const roleUser = useMemo(()=>employeeStore.getRole, [employeeStore.getRole])

    const data = useMemo(() => company.getCompany, [company.getCompany])

    const renderLinks = () => {

        if (roleUser) {

            switch (roleUser) {
                case OWNER:
                    return (<>

                        <AsideMenuItem
                            to={`${companyId}/dashboard-merchant`}
                            icon='/media/icons/duotune/graphs/gra001.svg'
                            title={intl.formatMessage({id: "DASHBOARD_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItem fontIcon='bi-app-indicator' icon='/media/icons/duotune/ecommerce/ecm004.svg'
                                       to={`${companyId}/payments/transactions`}
                                       title={intl.formatMessage({id: "trans"})}/>
                        {/*</AsideMenuItemWithSub>*/}

                        <AsideMenuItem
                            to={`${companyId}/analytics/analytic`}
                            icon='/media/icons/duotune/finance/fin007.svg'
                            title={intl.formatMessage({id: "ANALYTIC"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => setShowModal(true)}
                        />

                        {/*AUTOPAY SERVICE*/}
                        <AsideMenuItemWithSub
                            to={`/${companyId}/autopay/`}
                            title={intl.formatMessage({id: "AUTO_PAYMENTS"})}
                            icon='/media/icons/duotune/files/fil019.svg'
                            fontIcon='bi-app-indicator'
                            onClick={process.env.REACT_APP_PUBLIC_URL !== "https://merchant-api-dev.globalpay.uz" ? () => setShowModal(true) : undefined}
                        >
                            {/*<AsideMenuItem to={`${companyId}/autopay/contracts/create`} title={"Создать контракт"} hasBullet={true}/>*/}
                            {process.env.REACT_APP_PUBLIC_URL !== "https://merchant-api-dev.globalpay.uz"
                                ? <></>
                                : <>
                                    <AsideMenuItem to={`/${companyId}/autopay/contracts/list/all`}
                                                   title={intl.formatMessage({id: "AUTO_PAYMENTS_CONTRACTS"})}
                                                   hasBullet={true}/>
                                    {/*<AsideMenuItem to={`/${companyId}/autopay/subscriptions`}*/}
                                    {/*               title={intl.formatMessage({id: "AUTO_PAYMENTS_SUBSCRIPTION"})}*/}
                                    {/*               hasBullet={true}/>*/}
                                    <AsideMenuItem to={`/${companyId}/autopay/clients`}
                                                   title={intl.formatMessage({id: "AUTO_PAYMENTS_CUSTOMERS"})}
                                                   hasBullet={true}/>
                                    <AsideMenuItem to={`/${companyId}/autopay/contracts/list/archive`}
                                                   title={intl.formatMessage({id: "archive"})}
                                                   hasBullet={true}/>
                                </>}
                        </AsideMenuItemWithSub>



                        <AsideMenuItem
                            to={`${companyId}/services`}
                            icon='/media/icons/duotune/abstract/abs027.svg'
                            title={intl.formatMessage({id: "SERVICES"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => setShowModal(true)}
                        />

                        <AsideMenuItem
                                to={`${companyId}/cashboxes`}
                            icon='/media/icons/duotune/ecommerce/ecm004.svg'
                            title={intl.formatMessage({id: "cashboxes"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItemWithSub
                            to={`${companyId}/employees`}
                            icon='/media/icons/duotune/communication/com014.svg'
                            title={intl.formatMessage({id: "EMPLOYEES"})}
                            fontIcon='bi-app-indicator'>
                            <AsideMenuItem to={`${companyId}/employees/all`}
                                           title={intl.formatMessage({id: "list_employee"})} hasBullet={true}/>
                            <AsideMenuItem to={`${companyId}/employees/invited`}
                                           title={intl.formatMessage({id: "invited_employee"})} hasBullet={true}/>
                        </AsideMenuItemWithSub>

                        <AsideMenuItem
                            to={`${companyId}/settings-merchant`}
                            icon='/media/icons/duotune/coding/cod001.svg'
                            title={intl.formatMessage({id: "SETTINGS_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        >
                            {/*<AsideMenuItem to='/settings-merchant/privilege' title='Привилегии' hasBullet={true } />*/}
                        </AsideMenuItem>



                        <AsideMenuItem
                            to={`/support`}
                            externalLink={"https://global-solutions.atlassian.net/servicedesk/customer/portal/4"}
                            icon='/media/icons/duotune/communication/com007.svg'
                            title={intl.formatMessage({id: "SUPPORT"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => {
                                window.open("https://global-solutions.atlassian.net/servicedesk/customer/portal/4", '_blank')
                            }}
                        >
                            {/*<AsideMenuItem to='/settings-merchant/privilege' title='Привилегии' hasBullet={true } />*/}
                        </AsideMenuItem>

                        <AsideMenuItem
                            to={`global-solutions`}
                            externalLink={"https://global.uz/"}
                            icon='/media/icons/duotune/coding/cod001.svg'
                            title={intl.formatMessage({id: "GLOBAL_SOLUTIONS"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            // onClick={() => setShowModal(true)}
                            onClick={() => window.open('https://global.uz', '_blank')}
                            className={"mt-10"}
                        >
                            {/*<AsideMenuItem to='/settings-merchant/privilege' title='Привилегии' hasBullet={true } />*/}
                        </AsideMenuItem>

                    </>)
                case CEO:
                    return (<>

                        <AsideMenuItem
                            to={`${companyId}/dashboard-merchant`}
                            icon='/media/icons/duotune/graphs/gra001.svg'
                            title={intl.formatMessage({id: "DASHBOARD_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItem fontIcon='bi-app-indicator' icon='/media/icons/duotune/ecommerce/ecm004.svg'
                                       to={`${companyId}/payments/transactions`}
                                       title={intl.formatMessage({id: "trans"})}/>

                        <AsideMenuItem
                            to={`${companyId}/analytics/analytic`}
                            icon='/media/icons/duotune/finance/fin007.svg'
                            title={intl.formatMessage({id: "ANALYTIC"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => setShowModal(true)}
                        />

                        <AsideMenuItemWithSub
                            to={'/autopay'}
                            title={intl.formatMessage({id: "AUTO_PAYMENTS"})}
                            icon='/media/icons/duotune/files/fil019.svg'
                            fontIcon='bi-app-indicator'
                            onClick={process.env.REACT_APP_PUBLIC_URL !== "https://merchant-api-dev.globalpay.uz" ? () => setShowModal(true) : undefined}
                        >
                            {process.env.REACT_APP_PUBLIC_URL !== "https://merchant-api-dev.globalpay.uz"
                                ? <></>
                                : <>
                                    <AsideMenuItem to={`${companyId}/autopay/contracts/list/all`}
                                                   title={intl.formatMessage({id: "AUTO_PAYMENTS_CONTRACTS"})}
                                                   hasBullet={true}/>
                                    {/*<AsideMenuItem to={`${companyId}/autopay/subscriptions`}*/}
                                    {/*               title={intl.formatMessage({id: "AUTO_PAYMENTS_SUBSCRIPTION"})}*/}
                                    {/*               hasBullet={true}/>*/}
                                    <AsideMenuItem to={`${companyId}/autopay/clients`}
                                                   title={intl.formatMessage({id: "AUTO_PAYMENTS_CUSTOMERS"})}
                                                   hasBullet={true}/>
                                    <AsideMenuItem to={`/${companyId}/autopay/contracts/list/archive`}
                                                   title={intl.formatMessage({id: "archive"})}
                                                   hasBullet={true}/>
                                </>}
                        </AsideMenuItemWithSub>

                        <AsideMenuItem
                            to={`${companyId}/services`}
                            icon='/media/icons/duotune/abstract/abs027.svg'
                            title={intl.formatMessage({id: "SERVICES"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => setShowModal(true)}
                        />

                        <AsideMenuItem
                            to={`${companyId}/filial`}
                            icon='/media/icons/duotune/ecommerce/ecm004.svg'
                            title={intl.formatMessage({id: "FILIAL_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItemWithSub
                            to={`${companyId}/employees`}
                            icon='/media/icons/duotune/communication/com014.svg'
                            title={intl.formatMessage({id: "EMPLOYEES"})}
                            fontIcon='bi-app-indicator'>
                            <AsideMenuItem to={`${companyId}/employees/all`}
                                           title={intl.formatMessage({id: "list_employee"})} hasBullet={true}/>
                            <AsideMenuItem to={`${companyId}/employees/invited`}
                                           title={intl.formatMessage({id: "invited_employee"})} hasBullet={true}/>
                        </AsideMenuItemWithSub>

                        <AsideMenuItem
                            to={`${companyId}/settings-merchant`}
                            icon='/media/icons/duotune/coding/cod001.svg'
                            title={intl.formatMessage({id: "SETTINGS_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItem
                            to={`/support`}
                            externalLink={"https://global-solutions.atlassian.net/servicedesk/customer/portal/4"}
                            icon='/media/icons/duotune/communication/com007.svg'
                            title={intl.formatMessage({id: "SUPPORT"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => {
                                window.open("https://global-solutions.atlassian.net/servicedesk/customer/portal/4", '_blank')
                            }}
                        />

                        <AsideMenuItem
                            to={`global-solutions`}
                            externalLink={"https://global.uz/"}
                            icon='/media/icons/duotune/coding/cod001.svg'
                            title={intl.formatMessage({id: "GLOBAL_SOLUTIONS"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => window.open('https://global.uz', '_blank')}
                            className={"mt-10"}
                        />



                    </>)
                case ACCOUNTANT:
                    return (<>

                        <AsideMenuItem
                            to={`${companyId}/dashboard-merchant`}
                            icon='/media/icons/duotune/graphs/gra001.svg'
                            title={intl.formatMessage({id: "DASHBOARD_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItem fontIcon='bi-app-indicator' icon='/media/icons/duotune/ecommerce/ecm004.svg'
                                       to={`${companyId}/payments/transactions`}
                                       title={intl.formatMessage({id: "trans"})}/>

                        <AsideMenuItem
                            to={`${companyId}/analytics/analytic`}
                            icon='/media/icons/duotune/finance/fin007.svg'
                            title={intl.formatMessage({id: "ANALYTIC"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => setShowModal(true)}
                        />



                        <AsideMenuItem
                            to={`${companyId}/filial`}
                            icon='/media/icons/duotune/ecommerce/ecm004.svg'
                            title={intl.formatMessage({id: "FILIAL_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItem
                            to={`/support`}
                            externalLink={"https://global-solutions.atlassian.net/servicedesk/customer/portal/4"}
                            icon='/media/icons/duotune/communication/com007.svg'
                            title={intl.formatMessage({id: "SUPPORT"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => {
                                window.open("https://global-solutions.atlassian.net/servicedesk/customer/portal/4", '_blank')
                            }}
                        />

                        <AsideMenuItem
                            to={`global-solutions`}
                            externalLink={"https://global.uz/"}
                            icon='/media/icons/duotune/coding/cod001.svg'
                            title={intl.formatMessage({id: "GLOBAL_SOLUTIONS"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => window.open('https://global.uz', '_blank')}
                            className={"mt-10"}
                        />


                    </>)
                case ANALYST:
                    return (<>

                        <AsideMenuItem
                            to={`${companyId}/dashboard-merchant`}
                            icon='/media/icons/duotune/graphs/gra001.svg'
                            title={intl.formatMessage({id: "DASHBOARD_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItem fontIcon='bi-app-indicator' icon='/media/icons/duotune/ecommerce/ecm004.svg'
                                       to={`${companyId}/payments/transactions`}
                                       title={intl.formatMessage({id: "trans"})}/>

                        <AsideMenuItem
                            to={`${companyId}/analytics/analytic`}
                            icon='/media/icons/duotune/finance/fin007.svg'
                            title={intl.formatMessage({id: "ANALYTIC"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => setShowModal(true)}
                        />

                        <AsideMenuItem
                            to={`${companyId}/services`}
                            icon='/media/icons/duotune/abstract/abs027.svg'
                            title={intl.formatMessage({id: "SERVICES"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => setShowModal(true)}
                        />

                        <AsideMenuItem
                            to={`${companyId}/filial`}
                            icon='/media/icons/duotune/ecommerce/ecm004.svg'
                            title={intl.formatMessage({id: "FILIAL_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />



                        <AsideMenuItem
                            to={`/support`}
                            externalLink={"https://global-solutions.atlassian.net/servicedesk/customer/portal/4"}
                            icon='/media/icons/duotune/communication/com007.svg'
                            title={intl.formatMessage({id: "SUPPORT"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => {
                                window.open("https://global-solutions.atlassian.net/servicedesk/customer/portal/4", '_blank')
                            }}
                        />

                        <AsideMenuItem
                            to={`global-solutions`}
                            externalLink={"https://global.uz/"}
                            icon='/media/icons/duotune/coding/cod001.svg'
                            title={intl.formatMessage({id: "GLOBAL_SOLUTIONS"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => window.open('https://global.uz', '_blank')}
                            className={"mt-10"}
                        />


                    </>)
                case CASHIER:
                    return (<>

                        <AsideMenuItem
                            to={`${companyId}/dashboard-merchant`}
                            icon='/media/icons/duotune/graphs/gra001.svg'
                            title={intl.formatMessage({id: "DASHBOARD_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItem fontIcon='bi-app-indicator' icon='/media/icons/duotune/ecommerce/ecm004.svg'
                                       to={`${companyId}/payments/transactions`}
                                       title={intl.formatMessage({id: "trans"})}/>

                        <AsideMenuItem
                            to={`${companyId}/analytics/analytic`}
                            icon='/media/icons/duotune/finance/fin007.svg'
                            title={intl.formatMessage({id: "ANALYTIC"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => setShowModal(true)}
                        />

                        <AsideMenuItem
                            to={`${companyId}/filial`}
                            icon='/media/icons/duotune/ecommerce/ecm004.svg'
                            title={intl.formatMessage({id: "FILIAL_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItem
                            to={`/support`}
                            externalLink={"https://global-solutions.atlassian.net/servicedesk/customer/portal/4"}
                            icon='/media/icons/duotune/communication/com007.svg'
                            title={intl.formatMessage({id: "SUPPORT"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => {
                                window.open("https://global-solutions.atlassian.net/servicedesk/customer/portal/4", '_blank')
                            }}
                        />

                        <AsideMenuItem
                            to={`global-solutions`}
                            externalLink={"https://global.uz/"}
                            icon='/media/icons/duotune/coding/cod001.svg'
                            title={intl.formatMessage({id: "GLOBAL_SOLUTIONS"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => window.open('https://global.uz', '_blank')}
                            className={"mt-10"}
                        />


                    </>)
                case JUNIOR_CASHIER:
                    return (<>

                        <AsideMenuItem
                            to={`${companyId}/dashboard-merchant`}
                            icon='/media/icons/duotune/graphs/gra001.svg'
                            title={intl.formatMessage({id: "DASHBOARD_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItem fontIcon='bi-app-indicator' icon='/media/icons/duotune/ecommerce/ecm004.svg'
                                       to={`${companyId}/payments/transactions`}
                                       title={intl.formatMessage({id: "trans"})}/>

                        <AsideMenuItem
                            to={`${companyId}/analytics/analytic`}
                            icon='/media/icons/duotune/finance/fin007.svg'
                            title={intl.formatMessage({id: "ANALYTIC"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => setShowModal(true)}
                        />

                        <AsideMenuItem
                            to={`${companyId}/filial`}
                            icon='/media/icons/duotune/ecommerce/ecm004.svg'
                            title={intl.formatMessage({id: "FILIAL_MERCHANT"})}
                            fontIcon='bi-app-indicator'
                        />

                        <AsideMenuItem
                            to={`/support`}
                            externalLink={"https://global-solutions.atlassian.net/servicedesk/customer/portal/4"}
                            icon='/media/icons/duotune/communication/com007.svg'
                            title={intl.formatMessage({id: "SUPPORT"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => {
                                window.open("https://global-solutions.atlassian.net/servicedesk/customer/portal/4", '_blank')
                            }}
                        />

                        <AsideMenuItem
                            to={`global-solutions`}
                            externalLink={"https://global.uz/"}
                            icon='/media/icons/duotune/coding/cod001.svg'
                            title={intl.formatMessage({id: "GLOBAL_SOLUTIONS"})}
                            fontIcon='bi-app-indicator'
                            disable={true}
                            onClick={() => window.open('https://global.uz', '_blank')}
                            className={"mt-10"}
                        />



                    </>)

                case AGENT:

                    return (
                        <>
                            <AsideMenuItem
                                to={`/agent/statistic`}
                                icon='/media/icons/duotune/ecommerce/ecm008.svg'
                                title={intl.formatMessage({id: "DASHBOARD_CHART_TITLE"})}
                                fontIcon='bi-app-indicator'
                            />

                            <AsideMenuItem fontIcon='bi-app-indicator'
                                           icon='/media/icons/duotune/ecommerce/ecm004.svg'
                                           to={`/agent/merchant`}
                                           title={intl.formatMessage({id: "agent_merchants_tab"})}/>

                            <AsideMenuItem
                                to={`/support`}
                                externalLink={"https://global-solutions.atlassian.net/servicedesk/customer/portal/4"}
                                icon='/media/icons/duotune/communication/com007.svg'
                                title={intl.formatMessage({id: "SUPPORT"})}
                                fontIcon='bi-app-indicator'
                                disable={true}
                                onClick={() => {
                                    window.open("https://global-solutions.atlassian.net/servicedesk/customer/portal/4", '_blank')
                                }}
                            />

                            <AsideMenuItem
                                to={`global-solutions`}
                                externalLink={"https://global.uz/"}
                                icon='/media/icons/duotune/coding/cod001.svg'
                                title={intl.formatMessage({id: "GLOBAL_SOLUTIONS"})}
                                fontIcon='bi-app-indicator'
                                disable={true}
                                onClick={() => window.open('https://global.uz', '_blank')}
                                className={"mt-10"}
                            />
                        </>
                    )
                default:
                    return ''
            }
        }
    }


    return (
        <>
            {data.length != 1 && companyId ? (<AsideMenuItem
                to='/back'
                icon='/media/icons/duotune/arrows/arr021.svg'
                title={intl.formatMessage({id: 'RETURN-BACK'})}
                fontIcon='bi-app-indicator'
            />) : ''}

            {
                !companyId && roleUser!==AGENT ?(<AsideMenuItem
                    to={companyId ? `${companyId}/companies` : '/companies'}
                    icon='/media/icons/duotune/finance/fin001.svg'
                    title={intl.formatMessage({id: 'COMPANIES'})}
                    fontIcon='bi-app-indicator'
                />):''
            }


            {
                companyId||roleUser===AGENT ? (<>{renderLinks()}</>) : ''
            }

            <SoonModal shouldShow={showModal} onCancel={() => setShowModal(false)}/>
        </>
    )
})


type SoonModal = {
    onCancel: Function
    shouldShow: boolean,
}

const SoonModal: FC<SoonModal> = ({shouldShow, onCancel}) => {

    const handleClose = () => {
        onCancel()
    }

    return <Modal show={shouldShow} onHide={handleClose}>
        <Modal.Header closeButton>
            <h2>Скоро</h2>
        </Modal.Header>
        <Modal.Body>
            <div>Скоро</div>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
            <button type="button" className="swal2-confirm btn btn-secondary" aria-label=""
                    style={{display: 'inline-block'}} onClick={handleClose}>
                OK
            </button>
        </Modal.Footer>
    </Modal>
}
