/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '@metronic/assets/ts/components'
import { KTSVG} from '@metronic/helpers'
import {useListView} from '../providers/ListViewProvider'
import {observer} from "mobx-react";
import {Employee} from "@providers/employee/_request";
import {useNavigate, useParams} from "react-router-dom";
import {employeeStore} from "@store/employee";
import {useIntl} from "react-intl";
import {useErrors} from "@providers/context/ErrorsProvider";
import {confirmAlert} from "react-confirm-alert";
import {ADMIN, MODERATOR, SUPER_ADMIN} from "@utils/constants";
import {roleRouting, roleRoutingAdmin} from "@utils/index";
import {Admins} from "@providers/admins/_request";
import {admins} from "@store/admins";
import {company} from "@store/company";


type Props = {
  id: number
}

const UserActionsCell: FC<Props> = observer(({id}) => {
  const {setItemIdForUpdate} = useListView()
  const {setError} = useErrors()
    const intl = useIntl()
    const navigate = useNavigate()
    const role = employeeStore.getRole
    const {companyId} = useParams()

    const me = company.getMe

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }



  const deleteItem = async ()=>{
    if(roleRoutingAdmin('action', role, 'admin-delete')||roleRouting('employees/delete', role)){

        confirmAlert({
            title: intl.formatMessage({id: 'WARNING'}),
            message: intl.formatMessage({id:'WARNING_DELETE_EMPLOYEE'}),
            buttons: [
                {
                    label: intl.formatMessage({id: 'ACTIONS_YES'}),
                    onClick: async () => {
                        if(roleRoutingAdmin('action', role, 'is-admin')){
                            await Admins.deleteAdmin(id)
                                .then(()=>{
                                    admins.setAdmins(admins.getAdmins.filter(item=>item.id!==id))
                                }).catch(err=>{
                                    setError({status: err?.response?.status, message: err?.response?.data?.code === "METHOD_NOT_ALLOWED"?intl.formatMessage({id: 'not_employee'}):err?.response?.data?.message, isError: true})
                                })
                        }else{
                            await Employee.deleteEmployee(companyId||'', id)
                                .then(()=>{
                                    employeeStore.setEmployee(employeeStore.getEmployee.filter(item=>item.id!==id))
                                }).catch(err=>{
                                    setError({status: err?.response?.status, message: err?.response?.data?.code === "METHOD_NOT_ALLOWED"?intl.formatMessage({id: 'not_employee'}):err?.response?.data?.message, isError: true})
                                })
                        }

                    }
                },
                {
                    label: intl.formatMessage({id: 'ACTIONS_NO'}),
                    onClick: async () => {
                       return
                    }
                }
            ]
        })

    }
  }


  return (
      <div className='d-flex justify-content-end'>
          {
              ((roleRoutingAdmin('action', role, 'admin-edit')&&me.id!==id)||roleRouting('employees/edit', role))?(<a href="#" onClick={(e)=>{
                  e.preventDefault()
                  openEditModal()
              }} className="btn btn-icon-gray-500 btn-active-icon-primary p-5">
                  <KTSVG path='/media/icons/duotune/general/gen055.svg'
                         className="svg-icon-2 svg_icon_edit"
                  /></a>):''
          }


          {
              ((roleRoutingAdmin('action',role,'admin-delete')&&me.id!==id)||roleRouting('employees/delete', role))?(<a href="#" onClick={(e)=>{
                  e.preventDefault()
                  deleteItem()
              }} className="btn btn-icon-gray-500 btn-active-icon-danger p-5">
                  <KTSVG path='/media/icons/duotune/general/gen027.svg'
                         className="svg-icon-2 svg_icon_delete"/></a>):''
          }
      </div>
  )
})

export {UserActionsCell}
