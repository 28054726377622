import clsx from 'clsx'
import {FC, PropsWithChildren, useEffect, useMemo, useState} from 'react'
import {HeaderProps} from 'react-table'
import {initialQueryState} from '@metronic/helpers'
import {useQueryRequest} from '@providers/QueryRequestProvider'
import {User} from '@providers/users/_models'
import {useIntl} from "react-intl";
import {observer} from "mobx-react";
import {company} from "@store/company";
import {transactions} from "@store/transactions/transactions";

type Props = {
    className?: string
    title?: string
    tableProps: PropsWithChildren<HeaderProps<User>>
}
const TableCustomHeader: FC<Props> = observer(({className, title, tableProps}) => {
    const sortBy = useMemo(() => transactions.getTransactionSortBy, [transactions.getTransactionSortBy])
    const [sortFromMax, setSortFromMax] = useState(false)
    const id = tableProps.column.id
    const {state, updateState} = useQueryRequest()
    const intl = useIntl()

    const isSelectedForSorting = useMemo(() => {
        return state.sort && state.sort === id
    }, [state, id])
    const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

    const sortColumn = () => {

        if (["AMOUNT", "STATUS", "CREATED_AT"].some(item => item.toLowerCase() === id)) {
            setSortFromMax(prevState => !prevState)

            transactions.setTransactionSortBy({sortBy: id.toUpperCase()!, sortFromMax: !sortFromMax})
        }
        // avoid sorting for these columns
        if (id === 'actions' || id === 'selection') {
            return
        }

        if (!isSelectedForSorting) {
            // enable sort asc
            updateState({sort: id, order: 'asc', ...initialQueryState})
            return
        }

        if (isSelectedForSorting && order !== undefined) {
            if (order === 'asc') {
                // enable sort desc
                updateState({sort: id, order: 'desc', ...initialQueryState})
                return
            }

            // disable sort
            updateState({sort: undefined, order: undefined, ...initialQueryState})
        }
    }

    return (
        <th
            {...tableProps.column.getHeaderProps()}
            className={`${className} ${sortBy.sortBy.toLowerCase() === id ? sortBy.sortFromMax ? "table-sort-desc text-primary" : "table-sort-asc text-primary" : ""} text-left`}
            style={{cursor: 'pointer', textTransform: "capitalize"}}
            onClick={sortColumn}
        >
            {title?.length?intl.formatMessage({id: title}):''}

        </th>
    )
})

export {TableCustomHeader}
