import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {companyColumns, companyColumnsAdmin, companyColumnsOwner} from './columns/_columns'
import {KTCardBody, KTSVG} from '@metronic/helpers'
import {Company} from "@providers/company/_request";
import {CompanyResponse} from "@providers/company/_models";
import {observer} from "mobx-react";
import {company} from "@store/company";
import {employeeStore} from "@store/employee";
import {OWNER} from "@utils/constants";
import {useIntl} from "react-intl";
import {roleRouting, roleRoutingAdmin} from "@utils/index";
import {Pagination} from "../../../modules/Pagination";
import {TransactionsInfo} from "@providers/adminTransactions/_request";
import moment from "moment";
import {TransactionsCompanyResp} from "@providers/adminTransactions/_models";
import {useErrors} from "@providers/context/ErrorsProvider";



const AdminTable = observer(() => {

    const data = useMemo(() => company.getCompany, [company.getCompany])
    const [pagination, setPagination] = useState({})
    const columns = useMemo(() => companyColumnsAdmin, [company.getCompany])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })

    useEffect(()=>{
        updatePage({})
    }, [])

    const {setError} = useErrors()



    const intl = useIntl()

    async function updatePage(props:any){
        await Company.getCompany({page:props?.currentPage||1, count:20})
            .then(result=>{
                company.setCompany(result?.data?.result)
                setPagination({...result?.data?.meta, currentPage:props?.currentPage||1})
                window.scroll({
                    top:0,
                    behavior: 'smooth'
                })
            }).catch(e=>{
                setError({status: e?.response?.status, message: e?.response?.data?.message, isError:true})
            })

    }



    return (
        <KTCardBody className='py-4'>
            {columns.length ? <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<TransactionsCompanyResp>) => (
                            <CustomHeaderColumn key={column.id} column={column}/>
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<TransactionsCompanyResp>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: 'NO_MATCHING'})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div> : <div style={{height: "55vh"}} className="d-flex justify-content-center align-items-center">
                <a href="https://demo.globalpay.uz" target="_blank" className="btn btn-success text-white fs-3">
                    {intl.formatMessage({id: "WATCH_DEMO_COMPANY1"})}
                    <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-3 ms-3' />
                </a>
            </div>}
            <Pagination withInfo={true} position={'end'} infoText='Компаний' pagination={pagination} updatePage={updatePage}/>

        </KTCardBody>
    )
})

export {AdminTable}
