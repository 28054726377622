// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {TableCustomHeader} from './TableCustomHeader'
import {ModSession} from "@providers/session/_modals";
import {TableStatus} from "./TableStatus";
import {TableDate} from "./TableDate";
import {TableSum} from "./TableSum";
import {statuses_platforms} from "@utils/constants";
import {TableIcon} from "./TableIcon";
import {TransactionsCompanyResp} from "@providers/adminTransactions/_models";
import {CompanyNameAdmin} from "../../../../companies/_modals/columns/CompanyNameAdmin";
import {UserLastLoginCell} from "../../../../companies/_modals/columns/UserLastLoginCell";
import {UserTwoStepsCell} from "../../../../companies/_modals/columns/UserTwoStepsCell";
import {TableText} from "./TableText";
import {NumReplace} from "@utils/index";
import {LastTransaction} from "./LastTransaction";
import {RevertTransactionsActions} from "./RevertTransactionsActions";
import {ActionsButtons} from "../../../../companies/_modals/columns/ActionsButtons";


const companyColumnsAdmin: ReadonlyArray<Column<TransactionsCompanyResp>> = [

    {
        Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-50px' />,
        id: 'num',
        Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} translate={false} />,
    },

    {
        Header: (props) => <TableCustomHeader tableProps={props} title='total_count' className='min-w-100px' />,
        id: 'total_count',
        Cell: ({...props}) => <TableText text={props.data[props.row.index]?.total_count} translate={false} />,
    },


    {
        Header: (props) => <TableCustomHeader tableProps={props} title='company_name_table' className='min-w-100px' />,
        id: 'name',
        Cell: ({...props}) => <CompanyNameAdmin company={props.data[props.row.index]} />,
    },

    {
        Header: (props) => <TableCustomHeader tableProps={props} title='total_amount' className='min-w-100px' />,
        id: 'total_amount',
        Cell: ({...props}) => <TableText text={props.data[props.row.index]?.total_amount?NumReplace(props.data[props.row.index]?.total_amount/100+'')+' UZS':'0 UZS'} translate={false} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='last_transaction_date' className='min-w-125px' />
        ),
        id: 'last_transaction_date',
        Cell: ({...props}) => <LastTransaction created_date={props.data[props.row.index]?.last_transaction_date} />,
    },
]


const demoColumns: ReadonlyArray<Column<ModSession>> = [
    {
        Header: (props) => <TableCustomHeader tableProps={props} title='NUM' className='min-w-25px'/>,
        id: 'num',
        Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].num}/>,
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='TRANSACTION_ID' className='min-w-150px'/>
        ),
        id: 'id',
        Cell: ({...props}) => <UserInfoCell className="transaction-id-overflow" data={props.data[props.row.index].id}/>
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='CREATED_DATE' className='min-w-150px'/>
        ),
        id: 'created_at',
        Cell: ({...props}) => <TableDate date={props.data[props.row.index].created_at}/>

    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='CARD_NUMBER' className='min-w-150px'/>
        ),
        id: 'card_number',
        Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].card_number}
                                            icon={props.data[props.row.index].ppc_type}/>
    },


    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='SUM' className='min-w-150px'/>
        ),
        id: 'amount',
        Cell: ({...props}) => <TableSum sum={props.data[props.row.index].amount}/>
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='STATUS' className='min-w-150px'/>
        ),
        id: 'status',
        Cell: ({...props}) => <TableStatus status={props.data[props.row.index].status}/>
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='QR_CODE' className='min-w-75px'/>
        ),
        id: 'QR_CODE',
        Cell: ({...props}) => <TableIcon shouldShow={props.data[props.row.index].status === "SUCCESSFUL"} />
    },

    {
        Header: (props) => <TableCustomHeader tableProps={props} title='FILIAL_ID' className='min-w-150px'/>,
        id: 'branch_id',
        Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].branch_id} isBranchName/>,
    },
    // {
    //     Header: (props) => (
    //         <TableCustomHeader tableProps={props} title='PPC_TYPE' className='min-w-150px'/>
    //     ),
    //     id: 'ppc_type',
    //     Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].ppc_type}/>
    //
    // },
]


const usersColumns: ReadonlyArray<Column<ModSession>> = [
    {
        Header: (props) => <TableCustomHeader tableProps={props} title='NUM' className='min-w-25px'/>,
        id: 'num',
        Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].num}/>,
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='TRANSACTION_ID' className='min-w-150px'/>
        ),
        id: 'id',
        Cell: ({...props}) => <UserInfoCell className="transaction-id-overflow" data={props.data[props.row.index].id}/>
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='CREATED_DATE' className='min-w-150px'/>
        ),
        id: 'created_at',
        Cell: ({...props}) => <TableDate date={props.data[props.row.index].created_at}/>

    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='CARD_NUMBER' className='min-w-150px'/>
        ),
        id: 'card_number',
        Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].card_number}
                                            icon={props.data[props.row.index].ppc_type}/>
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='SYSTEM' className='min-w-150px'/>
        ),
        id: 'platform',
        Cell: ({...props}) => {
            const item = statuses_platforms[props.row.index % statuses_platforms.length]
            const data = item[0].toUpperCase() + item.substring(1).toLowerCase()
            return <UserInfoCell data={data} icon={data.toLowerCase()}/>
        }
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='SUM' className='min-w-150px'/>
        ),
        id: 'amount',
        Cell: ({...props}) => <TableSum sum={props.data[props.row.index].amount}/>
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='STATUS' className='min-w-150px'/>
        ),
        id: 'status',
        Cell: ({...props}) => <TableStatus status={props.data[props.row.index].status} className='text-left'/>
    },

    // {
    //     Header: (props) => (
    //         <TableCustomHeader tableProps={props} title='QR_CODE' className='min-w-75px'/>
    //     ),
    //     id: 'QR_CODE',
    //     Cell: ({...props}) => <TableIcon shouldShow={props.data[props.row.index].status === "APPROVED"} />
    // },

    {
        Header: (props) => <TableCustomHeader tableProps={props} title='cashbox' className='min-w-150px'/>,
        id: 'cashbox_id',
        Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].cashbox_id}  isBranchName/>,
    },
    {
        Header: (props) => <TableCustomHeader tableProps={props} title='' className='min-w-150px'/>,
        id: 'actions',
        Cell: ({...props}) => <RevertTransactionsActions filialData={true} transactionData={props.data[props.row.index]}  />,
    },
]


const usersColumnsCompany: ReadonlyArray<Column<ModSession>> = [
    {
        Header: (props) => <TableCustomHeader tableProps={props} title='NUM' className='min-w-25px'/>,
        id: 'num',
        Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].num}/>,
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='TRANSACTION_ID' className='min-w-150px'/>
        ),
        id: 'id',
        Cell: ({...props}) => <UserInfoCell className="transaction-id-overflow" data={props.data[props.row.index].id}/>
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='CREATED_DATE' className='min-w-150px'/>
        ),
        id: 'created_at',
        Cell: ({...props}) => <TableDate date={props.data[props.row.index].created_at}/>

    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='CARD_NUMBER' className='min-w-150px'/>
        ),
        id: 'card_number',
        Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].card_number}
                                            icon={props.data[props.row.index].ppc_type}/>
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='SYSTEM' className='min-w-150px'/>
        ),
        id: 'platform',
        Cell: ({...props}) => {
            const item = statuses_platforms[props.row.index % statuses_platforms.length]
            const data = item[0].toUpperCase() + item.substring(1).toLowerCase()
            return <UserInfoCell data={data} icon={data.toLowerCase()}/>
        }
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='SUM' className='min-w-150px'/>
        ),
        id: 'amount',
        Cell: ({...props}) => <TableSum sum={props.data[props.row.index].amount}/>
    },

    {
        Header: (props) => (
            <TableCustomHeader tableProps={props} title='STATUS' className='min-w-150px'/>
        ),
        id: 'status',
        Cell: ({...props}) => <TableStatus status={props.data[props.row.index].status} className='text-left'/>
    },

    // {
    //     Header: (props) => (
    //         <TableCustomHeader tableProps={props} title='QR_CODE' className='min-w-75px'/>
    //     ),
    //     id: 'QR_CODE',
    //     Cell: ({...props}) => <TableIcon shouldShow={props.data[props.row.index].status === "APPROVED"} />
    // },

    {
        Header: (props) => <TableCustomHeader tableProps={props} title='cashboxes' className='min-w-150px'/>,
        id: 'cashbox_id',
        Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].cashbox_id}  company/>,
    },

    {
        Header: (props) => <TableCustomHeader tableProps={props} title='' className='min-w-150px'/>,
        id: 'actions',
        Cell: ({...props}) => <RevertTransactionsActions filialData={false}  transactionData={props.data[props.row.index]}  />,
    },
]

export {usersColumns, demoColumns, companyColumnsAdmin, usersColumnsCompany}
