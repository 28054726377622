/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {statuses_card, statuses_const, statuses_platforms} from "@utils/constants";
import {useIntl} from "react-intl";
import {isProd} from "@utils/index";

type FilterProps = {
    show: boolean
    updateFilter: (val: boolean) => void
    filters: any
    disableStatuses?: boolean
    handleClose: VoidFunction
}

export function FilterDropDown({show, updateFilter, filters, disableStatuses, handleClose}: FilterProps) {

    const intl = useIntl()


    const [filterValues, setFilterValues] = useState(filters)



    function filtersHandler(type: string, values: any) {
        const update_filters: any = {}
        update_filters[type] = values
        setFilterValues({...filterValues, ...update_filters})
    }

    useEffect(() => {
        setFilterValues(filters)
    }, [filters])
    return (
        <div className={`menu menu-sub filter-dropdown menu-sub-dropdown w-250px w-md-350px w-sm-300 ${show && 'show'}`}
             data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-4 text-dark fw-bold'>{intl.formatMessage({id: 'Filters'})}</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>

                <div className='mb-6'>
                    <label className='fw-bold fs-7'>{intl.formatMessage({id: 'STATUS'})}:</label>

                    <div className='d-flex flex-wrap'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5 mt-3'>
                            <input className='form-check-input' type='checkbox'
                                   checked={statuses_const.every((item: any) => (filterValues?.statuses||[]).includes(item.toUpperCase()))}
                                   disabled={disableStatuses}
                                   value="all"
                                   onChange={(e) => {
                                       if (!e.target.checked) {
                                           filtersHandler('statuses', filterValues.statuses.filter((item: string) => false))
                                           // filtersHandler('statuses', filterValues.statuses.filter((item: string) => item !== e.target.value))
                                       } else {
                                           filtersHandler('statuses', statuses_const.map(item => item.toUpperCase()))
                                           // filtersHandler('statuses', [...filterValues.statuses, e.target.value])
                                       }

                                   }}
                            />
                            <span className='form-check-label'>{intl.formatMessage({id: 'all'})}</span>
                        </label>
                        {
                            statuses_const.map((item: string, index) => {

                                if(item!=='init'){
                                    return (
                                        <label key={index}
                                               className='form-check form-check-sm form-check-custom form-check-solid me-5 mt-3'>
                                            <input className='form-check-input' type='checkbox'
                                                   checked={(filterValues?.statuses||[]).includes(item.toUpperCase())}
                                                   value={item.toUpperCase()}
                                                   disabled={disableStatuses}
                                                   onChange={(e) => {
                                                       if (!e.target.checked) {
                                                           filtersHandler('statuses', (filterValues?.statuses||[]).filter((item: string) => item !== e.target.value))
                                                       } else {
                                                           filtersHandler('statuses', [...filterValues?.statuses, e.target.value])
                                                       }

                                                   }}
                                            />
                                            <span
                                                className='form-check-label'>{intl.formatMessage({id: item.toUpperCase()})}</span>
                                        </label>
                                    )
                                }


                            })
                        }
                    </div>
                </div>


                <div className='mb-6'>
                    <label className='fw-bold fs-7'>{intl.formatMessage({id: 'Cards'})}:</label>

                    <div className='d-flex flex-wrap'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5 mt-3'>
                            <input className='form-check-input' type='checkbox'
                                   checked={filterValues.ppc_types.length === 4}
                                   value="all"
                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                       if (!e.target.checked) {
                                           filtersHandler('ppc_types', [])
                                       } else {
                                           filtersHandler('ppc_types', ['UZCARD', 'HUMO', 'VISA', 'MASTERCARD'])
                                       }
                                   }}
                            />
                            <span className='form-check-label'>{intl.formatMessage({id: 'all'})}</span>
                        </label>
                        {
                            statuses_card.map((item: string, index) => {
                                return (
                                    <label key={index}
                                           className='form-check form-check-sm form-check-custom form-check-solid me-5 mt-3'>
                                        <input className='form-check-input' type='checkbox'
                                               checked={(filterValues?.ppc_types||[]).includes(item.toUpperCase())}
                                               value={item.toUpperCase()}
                                               onChange={(e) => {
                                                   if (!e.target.checked) {
                                                       filtersHandler('ppc_types', (filterValues?.ppc_types||[]).filter((item: string) => item !== e.target.value))
                                                   } else {
                                                       filtersHandler('ppc_types', [...filterValues.ppc_types, e.target.value])
                                                   }

                                               }}
                                        />
                                        <span
                                            className='form-check-label'>{item==='vm'?'Visa/Mastercard':item[0].toUpperCase() + item.substring(1)}</span>
                                    </label>
                                )
                            })
                        }


                    </div>
                </div>


                {!isProd() && <div className='mb-6'>
                    <label className='fw-bold fs-7'>{intl.formatMessage({id: 'PLATFORMS'})}:</label>

                    <div className='d-flex flex-wrap'>
                        <label className='form-check form-check-sm form-check-custom form-check-solid me-5 mt-3'>
                            <input className='form-check-input' type='checkbox'
                                   checked={true}
                                   disabled
                                   value="all"
                            />
                            <span className='form-check-label'>{intl.formatMessage({id: 'all'})}</span>
                        </label>
                        {
                            statuses_platforms.map((item: string, index) => {

                                return (
                                    <label key={index}
                                           className='form-check form-check-sm form-check-custom form-check-solid me-5 mt-3'>
                                        <input className='form-check-input' type='checkbox'
                                               checked={true}
                                               value={item.toUpperCase()}
                                               disabled
                                               onChange={(e) => {
                                                   if (!e.target.checked) {
                                                       filtersHandler('statuses', filterValues.statuses.filter((item: string) => item !== e.target.value))
                                                   } else {
                                                       filtersHandler('statuses', [...filterValues.statuses, e.target.value])
                                                   }

                                               }}
                                        />
                                        <span
                                            className='form-check-label'>{intl.formatMessage({id: item.toUpperCase()})}</span>
                                    </label>
                                )
                            })
                        }
                    </div>
                </div>}


                <div className='d-flex justify-content-between'>

                    <button type='submit' className='btn btn-primary text-white fw-bolder' data-kt-menu-dismiss='true'
                            onClick={() => {
                                updateFilter(filterValues)
                            }}>
                        {intl.formatMessage({id: 'Apply'})}
                    </button>
                    <button type='submit' className='btn btn-secondary text-muted fw-bolder' data-kt-menu-dismiss='true'
                        // onClick={() => handleClose()}>
                            onClick={() => {
                                const updateStatuses: any = {}
                                const updateCards: any = {}
                                updateStatuses['statuses'] = statuses_const.map(item => item.toUpperCase())
                                updateCards['ppc_types'] = statuses_card.map(item => item.toUpperCase())
                                updateFilter({...filterValues, ...updateStatuses, ...updateCards})
                            }}>
                        {intl.formatMessage({id: 'reset'})}
                    </button>
                </div>
            </div>
        </div>
    )
}
