import {useEffect, useMemo, useState} from 'react'
import {Outlet, useNavigate, useParams} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {
    DrawerMessenger,
    Main,
    InviteUsers,
    UpgradePlan,
    ThemeModeProvider,
} from '../partials'
import {ActivityDrawer} from "../../app/pages/dashboard-merchant/_modals/ActivityDrawer";
import {MenuComponent} from '../assets/ts/components'
import {observer} from "mobx-react";
import {Company} from "@providers/company/_request";
import {employeeStore} from "@store/employee";
import {ADMIN, JUNIOR_CASHIER, OWNER, MODERATOR, SUPER_ADMIN} from "@utils/constants";
import {CompanyResponse} from "@providers/company/_models";
import {useErrors} from "@providers/context/ErrorsProvider";
import {company} from "@store/company";
import {Account} from "@providers/account/_request";
import {AsideAdmin} from "@metronic/layout/components/aside/admin/AsideAdmin";
import {roleRoutingAdmin, showToolBar} from "@utils/index";

const MasterLayout = observer(() => {

    const {companyId} = useParams()
    const [role, setRole] = useState<string | null>(null)
    const {setError} = useErrors()
    const navigate = useNavigate()


    const location = useLocation()
    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [location.key])




    useEffect(() => {
        getDataCustomer()
    }, [companyId])


    async function getDataCustomer(){
        let timeout: any

        await Account.getMe()
            .then(result => {
                employeeStore.setRole(result?.data?.result?.role)
                setRole(result?.data?.result?.role)
                company.setMe(result?.data?.result)
            })

        if(!roleRoutingAdmin('action', employeeStore.getRole, 'is-admin')){

            if (companyId) {

                let find_role = (company.getMe?.company_roles||[]).find((item:any)=>item.company_id===+companyId)
                employeeStore.setRole(find_role?.role)
                setRole(find_role?.role)
                await Company.getCompany({count:20})
                    .then(result => {
                        if (result.data.result) {
                            company.setCompany(result.data.result)
                            company.setPagination({...result.data?.meta, currentPage:1})

                            if (result.data.result.every((item: any) => item.id !== +companyId)) {
                                setError({
                                    status: "Несуществующая компания",
                                    message: result.data.result.length > 0 ? 'Вы выбрали несуществующую компанию и автоматически будете перенаправлены на свою компанию через 5 секунд' : 'У вас нет созданных компаний, поэтому через 5 секунд вы автоматически будете перенаправленны на страницу создания компании',
                                    isError: true
                                })
                                if (result.data.result.length > 0) {
                                    timeout = setTimeout(() => {
                                        navigate(`${result.data.result[0].id}/dashboard-merchant`)
                                    }, 5000)
                                } else {
                                    timeout = setTimeout(() => {
                                        navigate(`/companies/add`)
                                    }, 5000)

                                }
                            }
                        }
                        const currentPosition = (result?.data?.result || []).find((item: CompanyResponse) => item.id === +companyId)?.position
                        employeeStore.setRole(currentPosition ?? null)
                        setRole(currentPosition)
                    })

            }
        }

        return () => {
            clearTimeout(timeout);
        };
    }




    return (
        <PageDataProvider>
            <ThemeModeProvider>
                <div className='page d-flex flex-row flex-column-fluid'>

                    {
                        employeeStore.getRole?(<>
                            {
                                roleRoutingAdmin('action', employeeStore.getRole, 'is-admin')?(<AsideAdmin/>):<AsideDefault/>
                            }
                        </>):''
                    }


                    <div className={`${showToolBar(location.pathname)?'':'no-toolbar'} wrapper d-flex flex-column flex-row-fluid`} id='kt_wrapper'>
                        <HeaderWrapper/>

                        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                            {showToolBar(location.pathname)?<Toolbar/>:''}

                            <div className='post d-flex flex-column-fluid' id='kt_post'>
                                <Content>
                                    {(!companyId || companyId && role) && <Outlet context={{role}}/>}
                                </Content>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>


                <ScrollTop/>
            </ThemeModeProvider>
        </PageDataProvider>
    )
})

export {MasterLayout}
