import {Statistic} from "@providers/statistic/_request";
import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {isDemo, NumReplace} from "@utils/index";
import moment from "moment";
import {ChartWidgetMixedAdmin} from "./ChartWidgetMixed";
import {ActivityDrawer} from "../_modals/ActivityDrawer";
import {ActivityDrawerAdmin} from "./_modals/ActivityDrawerAdmin";
import {WarningAlert} from "../../../modules/alerts/WarningAlert";
import {DatePicker} from "antd";
import {toIsoStringHelper} from "../../autopay/dateHelper";
import 'moment/locale/ru';
import 'moment/locale/uz-latn';
import 'moment/locale/en-au';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import localeEn from 'antd/es/date-picker/locale/en_US';
import {MinusOutlined} from "@ant-design/icons";
import {TransactionsInfo} from "@providers/adminTransactions/_request";
import {ChartWidgetMixed} from "../_modals/ChartWidgetMixed";
import {CANCELED, DECLINED, HUMO, statuses_card, statuses_const, SUCCESSFUL, UZCARD} from "@utils/constants";

interface CustomizedState {
    statuses?: Array<string>
    ppc_types?: Array<string>
    from_date?: string
    to_date?: string
}


const iconsData = {
    'active':'/media/icons/success-check.svg',
    'all':'',
    'disabled':'/media/icons/icon-disabled.svg',
    'draft':'/media/icons/yellow-arrow.svg',
    'UZCARD':'/media/svg/Uzcard (2).svg',
    'HUMO':'/media/svg/Humo.svg',
    'VISA':'/media/svg/visa.svg',
    'VM':'/media/svg/vm.svg',
    'MASTERCARD':'/media/svg/mastercard.svg',
    'TELEGRAM':'/media/svg/Telegram.svg',
}

export default function AdminDashboard() {
    const location = useLocation();
    let state = location.state as CustomizedState;
    const [shouldGetFromLocation, setShouldGetFromLocation] = useState<boolean>(state !== null)
    const [loading, setLoading] = useState(true)
    const {setError} = useErrors()
    const [statistic_data, setStatistic_data] = useState<any[]>([])
    const [currentCard, setCurrentCard] = useState<string[]>([])
    const [renderData, setRenderData] = useState<any | null>(null)
    const [warningAlert, setWarningAlert] = useState<string | null>(null)
    const [dataFilters, setDataFilters] = useState<any>(null)
    const [companyStatistic, setCompanyStatistic] = useState<any>([])
    const [incomeGs, setIncomeGs] = useState<any>([])
    const [statisticDate, setStatisticDate] = useState(isDemo()?'2022-11-04':shouldGetFromLocation && state ? state.from_date! : moment().subtract(1, 'month').format('YYYY-MM-DD'))
    const [statisticDateTo, setStatisticDateTo] = useState(isDemo()?'2022-12-04':shouldGetFromLocation && state ? state.to_date! : moment().format('YYYY-MM-DD'))
    const [filters, setFilters] = useState<any>({
        from_date: toIsoStringHelper(new Date(statisticDate)),
        to_date: toIsoStringHelper(moment(statisticDateTo).endOf('day').toDate()),
    })

    const [currentDate, setCurrentDate] = useState('month')

    const intl = useIntl()


    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang

    if (lang === "uz") {
        localeEn.lang.locale = "uz-latn"
    }


    useEffect(() => {
        window.history.replaceState({}, document.title)
    }, [])


    useEffect(()=>{
        getData()
    }, [filters])






    async function getData(values:any={}, statistic=true) {


            setLoading(true)

            const data = {
                from_date: toIsoStringHelper(new Date(values?.from_date||filters?.from_date)),
                to_date: toIsoStringHelper(moment(values?.to_date||filters?.to_date).endOf('day').toDate()),
            }


            const dataStatistic = {
                from_date: toIsoStringHelper(new Date(values?.from_date||filters?.from_date)),
                to_date: toIsoStringHelper(moment(values?.to_date||filters?.to_date).endOf('day').toDate()),
                statuses: values?.statuses ?? [CANCELED, DECLINED, SUCCESSFUL],
                ppc_types: (values?.ppc_types || filters.ppc_types) || [HUMO, UZCARD, 'VM'],
            }

            await TransactionsInfo.getCompanyStatistic(data)
                .then(result=>{
                    setCompanyStatistic(result?.data?.result)
                }).catch(err => setError({
                    status: err?.response?.stat,
                    message: err?.response?.data?.message,
                    isError: true
                }))


        if(!statistic) setDataFilters(dataStatistic)


        await Statistic.getStatisticsAdmin(data)
            .then(result=>{
                setStatistic_data(result?.data?.result)


                let series: any[] = []

                statuses_const.forEach((item: string) => {
                    const name = item
                    let data
                    if (result?.data?.result.length) {
                        data = result?.data?.result.map((res: any) => {
                            return res.statuses[item]?.sum ? res.statuses[item]?.sum / 100 : 0
                        })
                    } else {
                        data = [0]
                    }

                    series.push({name, data})
                })

                const categories: any[] = result?.data?.result.map((item: any) => item.date)

                setRenderData({series, categories})
            }).catch(err => setError({
                status: err?.response?.stat,
                message: err?.response?.data?.message,
                isError: true
            }))


        await Statistic.getIncomeGs(data)
            .then(result=>{
                setIncomeGs(result?.data?.result)
            }).catch(err => setError({
                status: err?.response?.stat,
                message: err?.response?.data?.message,
                isError: true
            }))

            setLoading(false)

    }



    function getCountStatuses(type: string = 'company_statistic', currency = 'UZS') {
        let result: any = []


            if (type === 'company_statistic') {


                for(let key in companyStatistic){
                    if(key!=='all'){
                        let sum = 0,
                            count = ''
                        sum = companyStatistic[key]
                        count = intl.formatMessage({id:key})
                        //@ts-ignore
                        result.push({sum, count, icon: iconsData[key+'']})
                    }
                }


            } else if(type==='gs-transactions') {
                let income_arr = []
                for(let key in incomeGs?.details){
                    income_arr.push({
                        sum: NumReplace(incomeGs.details[key]?.amount/100+'')+' UZS',
                        count: incomeGs.details[key]?.count,
                        //@ts-ignore
                        icon: iconsData[key.toUpperCase()]
                    })
                }

                result = income_arr


            }else if(type==='telegram') {
                result = [
                    {
                        sum: 0,
                        count: '0 UZS',
                        icon: iconsData['UZCARD']
                    },
                    {
                        sum: 0,
                        count: '0 UZS',
                        icon: iconsData['HUMO']
                    },
                    {
                        sum: 0,
                        count: '0 UZS',
                        icon: iconsData['MASTERCARD']
                    },
                    {
                        sum: 0,
                        count: '0 UZS',
                        icon: iconsData['VISA']
                    }
                ]
            }else if(type==='visa-mastercard') {
                result = [
                    {
                        sum: 0,
                        count: '0 UZS',
                        icon: iconsData['MASTERCARD']
                    },
                    {
                        sum: 0,
                        count: '0 UZS',
                        icon: iconsData['VISA']
                    }
                ]
            }else{
                if (statistic_data.length) {

                    if (type === 'all') {
                        statuses_card.forEach((item: any) => {
                            let sum = 0,
                                count = 0
                            if (statistic_data.length > 1) {
                                sum = statistic_data.reduce((previousValue: any, currentValue: any) => {

                                    if (Object.keys(currentValue.details).includes(item)) {
                                        if (typeof previousValue === 'object') {
                                            return previousValue.details[item].amount + currentValue?.details[item].amount
                                        }

                                        return previousValue + currentValue?.details[item].amount
                                    }

                                    return 0


                                })

                                count = statistic_data.reduce((previousValue: any, currentValue: any) => {

                                    if (Object.keys(currentValue.details).includes(item)) {
                                        if (typeof previousValue === 'object') {
                                            return previousValue.details[item].count + currentValue?.details[item].count
                                        }

                                        return previousValue + currentValue?.details[item].count
                                    }

                                    return 0


                                })
                            } else {
                                if (statistic_data.length && Object.keys(statistic_data[0].details).includes(item)) {
                                    sum = statistic_data[0].details[item].amount
                                    count = statistic_data[0].details[item].count
                                }

                            }

                            result.push({
                                card: item.toUpperCase(),
                                sum: sum ? NumReplace(sum / 100 + '') + ' ' + currency : 0,
                                count

                            })

                        })
                    } else {
                        statuses_card.forEach((item: any) => {

                            let sum = 0,
                                count = 0

                            if (statistic_data.length > 1) {
                                sum = statistic_data.reduce((previousValue: any, currentValue: any) => {

                                    if (Object.keys(currentValue.details).includes(item)) {
                                        if (typeof previousValue === 'object') {
                                            return previousValue.details[item].statuses[type].amount + currentValue?.details[item].statuses[type].amount
                                        }

                                        return previousValue + currentValue?.details[item].statuses[type].amount
                                    }

                                    return 0

                                })

                                count = statistic_data.reduce((previousValue: any, currentValue: any) => {
                                    if (Object.keys(currentValue.details).includes(item)) {

                                        if (typeof previousValue === 'object') {
                                            return previousValue.details[item].statuses[type].count + currentValue?.details[item].statuses[type].count
                                        }

                                        return previousValue + currentValue?.details[item].statuses[type].count
                                    }

                                    return 0

                                })
                            } else {
                                if (statistic_data.length && Object.keys(statistic_data[0].details).includes(item)) {
                                    sum = statistic_data.length ? statistic_data[0].details[item].statuses[type].sum : 0
                                    count = statistic_data.length ? statistic_data[0].details[item].statuses[type].count : 0
                                }
                            }


                            result.push({
                                card: item.toUpperCase(),
                                sum: sum ? NumReplace(sum / 100 + '') + ' ' + currency : 0,
                                count

                            })


                        })
                    }

                } else {

                    result = statuses_card.map((item: any) => {
                        return {
                            card: item.toUpperCase(),
                            sum: 0,
                            count: 0
                        }
                    })
                }
            }

        return result

    }


    const sumAmount = (status: string) => {
        if (statistic_data.length) {
            if (statistic_data.length > 1) {
                if (status === 'all') {
                    return statistic_data.reduce((previousValue: any, currentValue: any) => {

                        if (typeof previousValue === 'object') {
                            return previousValue.amount + currentValue.amount
                        }

                        return previousValue + currentValue.amount
                    }) / 100
                }

                return statistic_data.reduce((previousValue: any, currentValue: any) => {
                    if (typeof previousValue === 'object') {
                        return previousValue.statuses[status].amount + currentValue.statuses[status].amount
                    }

                    return previousValue + currentValue.statuses[status].amount
                }) / 100
            }

            return status !== 'all' ? statistic_data[0]?.statuses[status].amount / 100 : statistic_data[0]?.amount / 100


        }
    }


    const dataChartsRender = async (filter: string[] = [], show=false) => {
        console.log(filter)
        const params_filter: any = {}
        // if (!filter.find(item => item.match(/telegram|visa|mastercard/i))) {
        if (!filter.find(item => item.match(/тех. поддержку/i))) {

            const from_date = filter.find(item => item.match('from_date'))
            const to_date = filter.find(item => item.match('to_date'))
            params_filter['from_date'] = from_date ? from_date.split('=')[1] : statisticDate
            params_filter['to_date'] = to_date ? to_date.split('=')[1] : statisticDateTo

            filter.forEach(item => {

                let key, values

                if (item.match(/from_date|to_date/)) {
                    const parse = item.split('=')
                    key = parse[0]
                    values = parse[1]
                } else {
                    key = item.replace(/\[.+\]/, '')
                    //@ts-ignore
                    values = item.match(/[^[\]]+(?=])/) ? item.match(/[^[\]]+(?=])/)[0].split(',') : []
                }

                params_filter[key] = values

            })

            await getData(params_filter, !show)


        } else {

            setWarningAlert(filter[0])

        }
    }


    function countDays(){
        let a = moment(filters.from_date),
            b = moment(filters.to_date)
        return b.diff(a, 'days')
    }


    function replaceFormat(date:any, formatFrom:string, formatTo:string){
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }



    return (
        <div>
            <div className='toolbar toolbar-filter justify-content-end px-10 align-items-center'>
                <p className='mb-0'>{intl.formatMessage({id: 'filter_by'})}</p>

                <div className='filter_buttons_group'>
                    <button
                        disabled={isDemo()}
                        className={`btn btn-sm btn-active-light-primary ${currentDate==='day' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (countDays()>0) {
                                setShouldGetFromLocation(false)
                                state = {}
                                setCurrentDate('day')
                                const from_date = moment().format('YYYY-MM-DD')
                                const to_date = moment().format('YYYY-MM-DD')
                                setStatisticDate(from_date)
                                setStatisticDateTo(to_date)
                                //dataChartsRender([`from_date=${from_date}`, `to_date=${to_date}`])

                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: from_date}
                                    const toDate = {to_date: to_date}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_day'})}
                    </button>
                    <button
                        disabled={isDemo()}
                        className={`btn btn-sm btn-active-light-primary ${currentDate==='week' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (countDays()!==7) {
                                setShouldGetFromLocation(false)
                                state = {}
                                setCurrentDate('week')
                                const from_date = moment().subtract(1, 'week').format('YYYY-MM-DD')
                                const to_date = moment().format('YYYY-MM-DD')
                                setStatisticDate(from_date)
                                setStatisticDateTo(to_date)
                                //dataChartsRender([`from_date=${from_date}`, `to_date=${to_date}`])

                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: from_date}
                                    const toDate = {to_date: to_date}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_week'})}
                    </button>
                    <button
                        className={`btn btn-sm btn-active-light-primary ${currentDate==='month' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (countDays()!==30||countDays()!==31) {
                                setShouldGetFromLocation(false)
                                state = {}
                                setCurrentDate('month')
                                const from_date = moment().subtract(1, 'month').format('YYYY-MM-DD')
                                const to_date = moment().format('YYYY-MM-DD')

                                setStatisticDate(from_date)
                                setStatisticDateTo(to_date)
                                //dataChartsRender([`from_date=${from_date}`, `to_date=${to_date}`])

                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: from_date}
                                    const toDate = {to_date: to_date}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_month'})}
                    </button>
                </div>
                <div className="mx-4" style={{backgroundColor: "rgba(235, 237, 242, 1)", width: "1px", height: "40%"}}/>
                <div>
                    <DatePicker.RangePicker
                        style={{border: "none"}}
                        separator={<MinusOutlined/>}
                        locale={lang === 'ru' ? localeRu : localeEn}
                        placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                        key={statisticDate}
                        className='datepicker_form form-control-lg form-control-solid'
                        defaultValue={[replaceFormat(statisticDate, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                            replaceFormat(statisticDateTo, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                        format='DD-MM-YYYY'
                        disabledDate={current => {
                            return current && current > moment().endOf('day');
                        }}
                        onKeyDown={(val) => {
                            if (val.code.toLowerCase().includes("key")) {
                                val.preventDefault()
                                val.stopPropagation()
                                return
                            }
                        }}
                        //@ts-ignore
                        onChange={(dates: [any, any], dateStrings: [string, string]) => {
                            if (dates) {
                                setCurrentDate('')
                                setShouldGetFromLocation(false)
                                state = {}
                                let from_date = replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                                let to_date = replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                                setStatisticDate(from_date)
                                setStatisticDateTo(to_date)
                                dataChartsRender([`from_date=${from_date}`, `to_date=${to_date}`])


                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: toIsoStringHelper(new Date(from_date))}
                                    const toDate = {to_date: toIsoStringHelper(moment(to_date).endOf('day').toDate())}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}
                    />
                </div>
            </div>
            <ActivityDrawerAdmin open={!!dataFilters}
                                 dataStatistic={statistic_data}
                                 params={dataFilters}
                                 setDataCharts={(val: any) => setDataFilters(val)}
                            onDateChange={(dates: [any, any], dateStrings: [string, string]) => {
                                if (dates) {
                                    let from_date = replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                                    let to_date = replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                                    setStatisticDate(from_date)
                                    setCurrentDate('')
                                    setStatisticDateTo(to_date)
                                    getData({...dataFilters, from_date:from_date, to_date:to_date}, false)

                                }
                            }}/>
            <WarningAlert isAlert={!!warningAlert} message={warningAlert} setShow={(val: any) => setWarningAlert(val)}/>

            <div className='row g-5 g-xl-8'>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={true}
                                      tooltip={intl.formatMessage({id: 'tooltip_all'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='dark-blue' data={{
                        status: 'ALL',
                        sum: [`${sumAmount('all') ? NumReplace(sumAmount('all') + '') : 0} UZS`],
                        details: getCountStatuses('all')
                    }} cards={currentCard}/>
                </div>


                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={true}
                                      tooltip={intl.formatMessage({id: 'tooltip_success'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='success' data={{
                        status: 'APPROVED',
                        sum: [`${sumAmount('approved') ? NumReplace(sumAmount('approved') + '') : 0} UZS`],
                        details: getCountStatuses('approved')
                    }} cards={currentCard}/>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={true}
                                      tooltip={intl.formatMessage({id: 'tooltip_declined'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='warning' data={{
                        status: 'FAILED',
                        sum: [`${sumAmount('failed') ? NumReplace(sumAmount('failed') + '') : 0} UZS`],
                        details: getCountStatuses('failed')
                    }} cards={currentCard}/>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={true}
                                      tooltip={intl.formatMessage({id: 'tooltip_canceled'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='danger' data={{
                        status: 'REVERT',
                        sum: [`${sumAmount('revert') ? NumReplace(sumAmount('revert') + '') : 0} UZS`],
                        details: getCountStatuses('revert')
                    }} cards={currentCard}/>
                </div>



                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixedAdmin isAvailable={true}
                                      tooltip='Доход Global Solutions с каждой успешной транзакции'
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='success' data={{
                        title: 'gs-transaction',
                        type: 'gs-transactions',
                        sum: [NumReplace((incomeGs?.amount?incomeGs?.amount/100:0)+'')+' UZS'],
                        details: getCountStatuses('gs-transactions')
                    }} cards={currentCard}/>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixedAdmin isAvailable={true}
                                      tooltip='Количество мерчантов в системе'
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter)}
                                      className='card-xl-stretch mb-xl-8' color='brown' data={{
                        title: 'COMPANIES',
                        type: 'company-statistic',
                        sum: [companyStatistic?.all?companyStatistic?.all+'':'0'],
                        details: getCountStatuses()
                    }} cards={currentCard}/>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixedAdmin isAvailable={true}
                                      tooltip='Кол-во транзакции по Телеграм'
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter)}
                                      className='card-xl-stretch mb-xl-8' color='primary' data={{
                        title: 'telegram',
                        type: 'telegram',
                        sum: ['0'],
                        details: getCountStatuses('telegram')
                    }} cards={currentCard}/>
                </div>

                {/*<div className='col-xl-3 col-lg-4 col-md-4'>*/}
                {/*    <ChartWidgetMixedAdmin isAvailable={true}*/}
                {/*                      tooltip='Пока нет текста'*/}
                {/*                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter)}*/}
                {/*                      className='card-xl-stretch mb-xl-8' color='dark-blue' data={{*/}
                {/*        title: 'platform_visa',*/}
                {/*        type: 'visa-mastercard',*/}
                {/*        sum: ['0'],*/}
                {/*        details: getCountStatuses('visa-mastercard')*/}
                {/*    }} cards={currentCard}/>*/}
                {/*</div>*/}



            </div>

        </div>
    )
}