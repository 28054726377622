import React, {useState} from 'react';
import {CreateCashbox, IEpos, IEposSend} from "@providers/filial/_models";
import {KTCardBody} from "@metronic/helpers";
import {useIntl} from "react-intl";
import {Form, Formik, FormikHelpers} from "formik";
import EposesFieldsArray from "./eposes-fields-array";
import {useParams} from "react-router-dom";
import {Filial} from "@providers/filial/_request";
import {filial} from "@store/filial";
import {AxiosResponse} from "axios";
import {useErrors} from "@providers/context/ErrorsProvider";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import * as Yup from "yup";

interface props {
    eposes: IEpos[]
    onRead: () => void
    getFilial: () => void
}

const EposesForm: React.FC<props> = ({
                                         eposes,
                                         onRead,
                                         getFilial
                                     }) => {

    const intl = useIntl()

    const {setError} = useErrors()

    const {companyId, id} = useParams()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)


    const [inits, setInits] = useState<IEpos[]>(eposes)

    const onEposHandler = async (data: IEposSend) => {
        setConfirmLoading(true)
        try {
            const response: CreateCashbox | undefined = await Filial.eposesCrud(data).then((response: AxiosResponse<{
                result: CreateCashbox
            }>) => {
                if (response?.data?.result) {
                    return response.data.result
                }
            })
            if (response) {
                filial.setFilialOne(response)
                getFilial()
                onRead()
            }
        } catch (err: any) {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})

        } finally {
            setConfirmLoading(false)
        }
    }

    const onSubmit = (values: { eposes: IEpos[] }, helpers: FormikHelpers<{ eposes: IEpos[] }>) => {
        if (companyId && id) {
            const data = {
                ...values,
                company_id: companyId,
                branch_id: id
            }
            onEposHandler(data)
        }
    }


    const validationSchema = Yup.object().shape({
        eposes: Yup.array()
            .of(Yup.object().shape({
                id: Yup.number(),
                merchant_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})).matches(/^[0-9]+$/, intl.formatMessage({id: 'ERROR_VALIDATION_NUMBERS'})).test('len', intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'}), (value) => value && value.length > 50 ? false : true),
                processing_type: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                terminal_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})).matches(/^[0-9]+$/, intl.formatMessage({id: 'ERROR_VALIDATION_NUMBERS'})).test('len', intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'}), (value) => value && value.length > 50 ? false : true)
            }))
    })


    return (
        <>
            {confirmLoading && <UsersListLoading/>}

            <Formik
                initialValues={{eposes: inits}}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {({values, setFieldValue}) => {
                    return (
                        <Form>
                            <KTCardBody>
                                <EposesFieldsArray values={values} setFieldValue={setFieldValue}/>
                            </KTCardBody>
                            <div className='card-footer py-5 px-10 d-flex justify-content-end'>
                                <button disabled={confirmLoading}
                                        className={`btn btn-light me-3 ${confirmLoading ? 'disabled' : ''}`}
                                        onClick={onRead}>{intl.formatMessage({id: 'Cancel'})}</button>
                                <button disabled={confirmLoading}
                                        className={`btn btn-primary ${confirmLoading ? 'disabled' : ''}`}
                                        type='submit'>{intl.formatMessage({id: 'SAVE_EDITION'})}</button>
                            </div>
                        </Form>
                    )
                }}

            </Formik>
        </>
    );
};

export default EposesForm;