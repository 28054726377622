import React from 'react';
import {IEpos, processingTypes} from "@providers/filial/_models";
import {ErrorMessage, Field, FieldArray} from "formik";
import {useIntl} from "react-intl";
import {cardIcons, cardTypes} from "./eposes-helper";
import moment from "moment";

interface props {
    values: { eposes: IEpos[] }
    setFieldValue: (field: string, value: any) => void
}

const EposesFieldsArray: React.FC<props> = ({
                                                values,
                                                setFieldValue
                                            }) => {
    const intl = useIntl()

    const pattern = /[a-zA-ZА-Яа-я]/

    return (
        <FieldArray
            name="eposes"
        >
            {({push, remove}) => {
                return (
                    <div>
                        {values.eposes && values.eposes.length > 0 ? values.eposes.map(({
                                                                                            id,
                                                                                            processing_type,
                                                                                            terminal_id
                                                                                        }, index) => (
                            <div style={{borderRadius: '12px'}} key={index}
                                 className={'mb-6 p-3 border border-gray-300 d-flex align-items-start justify-content-between'}>
                                <p className={'form-label mb-0'}>
                                    EPOS {index + 1}
                                </p>
                                <div style={{
                                    flex: '0 0 70%'
                                }}>
                                    <div
                                        className={'d-flex align-items-start justify-content-between flex-wrap mb-6'}

                                    >
                                        <input type={'hidden'} name={`eposes[${index}].id`}/>
                                        <div
                                            style={{
                                                flex: '0 0 31%',
                                                position: 'relative'
                                            }}>
                                            <img alt={''} height={24} width={24} src={cardIcons[processing_type]}
                                                 className={'card-type-icon'}
                                            />

                                            <div>
                                                <label className={'form-label'}>
                                                    {intl.formatMessage({id: 'epos_type'})}
                                                </label>
                                                <Field
                                                    name={`eposes[${index}].processing_type`}
                                                    as={'select'}
                                                    className='form-control form-control-lg padding-left'
                                                >
                                                    {cardTypes.map((type) => (
                                                        <option
                                                            disabled={values.eposes.some(({processing_type}) => processing_type === type)}
                                                            key={type} value={type}>
                                                            {type}
                                                        </option>
                                                    ))}
                                                </Field>
                                                <div className='text-danger mt-2'>
                                                    <ErrorMessage name={`eposes[${index}].processing_type`}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                flex: '0 0 31%'
                                            }}
                                        >
                                            <label className={'form-label'}>
                                                {intl.formatMessage({id: 'terminal_id'})}
                                            </label>
                                            <Field className='form-control form-control-lg'
                                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                       if (!pattern.test(e.target.value)) {
                                                           setFieldValue(`eposes[${index}].terminal_id`, e.target.value)
                                                       }
                                                   }}
                                                   name={`eposes[${index}].terminal_id`}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name={`eposes[${index}].terminal_id`}/>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                flex: '0 0 31%'
                                            }}
                                        >
                                            <label className={'form-label'}>
                                                {intl.formatMessage({id: 'merchant_id'})}
                                            </label>
                                            <Field className='form-control form-control-lg'
                                                   style={{
                                                       flex: '1 0 60%'
                                                   }}
                                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                       if (!pattern.test(e.target.value)) {
                                                           setFieldValue(`eposes[${index}].merchant_id`, e.target.value)
                                                       }
                                                   }}
                                                   name={`eposes[${index}].merchant_id`}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name={`eposes[${index}].merchant_id`}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'d-flex justify-content-end'}>
                                       <span onClick={() => remove(index)}
                                             className={'epos-remove'}
                                       >
                                       <svg width="18" height="20" viewBox="0 0 18 20" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                           <path
                                               d="M12.3333 5.00008V4.33341C12.3333 3.39999 12.3333 2.93328 12.1517 2.57676C11.9919 2.26316 11.7369 2.00819 11.4233 1.8484C11.0668 1.66675 10.6001 1.66675 9.66667 1.66675H8.33333C7.39991 1.66675 6.9332 1.66675 6.57668 1.8484C6.26308 2.00819 6.00811 2.26316 5.84832 2.57676C5.66667 2.93328 5.66667 3.39999 5.66667 4.33341V5.00008M7.33333 9.58342V13.7501M10.6667 9.58342V13.7501M1.5 5.00008H16.5M14.8333 5.00008V14.3334C14.8333 15.7335 14.8333 16.4336 14.5608 16.9684C14.3212 17.4388 13.9387 17.8212 13.4683 18.0609C12.9335 18.3334 12.2335 18.3334 10.8333 18.3334H7.16667C5.76654 18.3334 5.06647 18.3334 4.53169 18.0609C4.06129 17.8212 3.67883 17.4388 3.43915 16.9684C3.16667 16.4336 3.16667 15.7335 3.16667 14.3334V5.00008"
                                               stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                                               strokeLinejoin="round"/>
                                       </svg>
                                   </span>
                                    </div>
                                </div>
                            </div>
                        )) : null}
                        {values.eposes.length === 2 ? null : <div onClick={() => push({
                            terminal_id: '',
                            merchant_id: '',
                            processing_type: values.eposes.some(({processing_type}) => processing_type === 'HUMO') ? 'UZCARD' : 'HUMO'
                        })} className={'btn btn-primary'}>
                            {intl.formatMessage({id: 'ADD_BUTTON'})}
                        </div>}
                    </div>
                )
            }}
        </FieldArray>
    );
};

export default EposesFieldsArray;