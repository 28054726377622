import React, {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {companyColumnsAdmin} from './columns/_columns'
import {KTCardBody, KTSVG} from '@metronic/helpers'
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import {isDemo, NumReplace} from "@utils/index";
import {Pagination} from "../../../../modules/Pagination";
import {TransactionsInfo} from "@providers/adminTransactions/_request";
import moment from "moment";
import {DatePicker} from "antd";
import {MinusOutlined} from "@ant-design/icons";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import {toIsoStringHelper} from "../../../autopay/dateHelper";
import {useErrors} from "@providers/context/ErrorsProvider";
import {transactions} from "@store/transactions/transactions";


const AdminTable = observer(() => {

    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({})
    const columns = useMemo(() => companyColumnsAdmin, [data])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const [fromDate, setFromDate] = useState(moment().subtract(2, 'year').format("YYYY-MM-DD"))
    const [transactionGeneral, setTransactionsGeneral] = useState<any>({})
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"))
    const intl = useIntl()
    const [currentDate, setCurrentDate] = useState('month')
    const {setError} = useErrors()




    useEffect(()=>{
        updatePage({})
        getTransactionsGeneral()
    }, [fromDate, toDate])


    async function getTransactionsGeneral(){

        await TransactionsInfo.getGeneralTransactions({
            from_date: toIsoStringHelper(new Date(fromDate)),
            to_date: toIsoStringHelper(moment(toDate).endOf('day').toDate()),
        }).then(result=>{

            setTransactionsGeneral(result?.data?.result)
        }).catch(err=>{
            setError({status:err?.response?.status, message: err?.response?.data?.message, isError:true})
        })
    }



    async function updatePage(props:any){
        await TransactionsInfo.getTransactionsCompany({
            "from_date":  toIsoStringHelper(new Date(fromDate)),
            "to_date":  toIsoStringHelper(moment(toDate).endOf('day').toDate()),
            "page": props?.currentPage||1,
            "count": 15,
            "sort_by": 'ID',
            "sort_order": "ASC",
        }).then(result=>{
            if (result?.data?.result.length > 0) {
                result?.data?.result.map((item: any, index: number) => {
                    if (props?.currentPage > 2) {
                        item['num'] = ((props?.currentPage - 1) * 15) + index + 1
                    } else if (props?.currentPage == 2) {
                        item['num'] = 16 + index
                    } else {
                        item['num'] = index + 1
                    }
                    return item
                })
            }
                setData(result?.data?.result)
                setPagination({...result?.data?.meta, currentPage:props?.currentPage||1, pageSize: 15})
                window.scroll({
                    top:0,
                    behavior: 'smooth'
                })
            }).catch(err=>{
            setError({status:err?.response?.status, message: err?.response?.data?.message, isError:true})
        })

    }

    function countDays(){
        let a = moment(fromDate),
            b = moment(toDate)
        return b.diff(a, 'days')
    }


    function replaceFormat(date:any, formatFrom:string, formatTo:string){
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }



    return (
        <>
            <div className='toolbar toolbar-filter justify-content-end px-10 align-items-center'>
                <p className='mb-0'>{intl.formatMessage({id: 'filter_by'})}</p>


                <div className='filter_buttons_group'>
                    <button
                        className={`btn btn-sm btn-active-light-primary ${currentDate==='day' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if(currentDate!=='day'){
                                setCurrentDate('day')
                                setFromDate(moment().format('YYYY-MM-DD'))
                                setToDate(moment().format('YYYY-MM-DD'))
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_day'})}
                    </button>
                    <button
                        className={`btn btn-sm btn-active-light-primary ${currentDate==='week' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (currentDate!=='week') {
                                setCurrentDate('week')
                                setFromDate(moment().subtract(1, 'week').format('YYYY-MM-DD'))
                                setToDate(moment().format('YYYY-MM-DD'))
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_week'})}
                    </button>
                    <button
                        className={`btn btn-sm btn-active-light-primary ${currentDate==='month' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (currentDate!=='month') {
                                setCurrentDate('month')
                                setFromDate(moment().subtract(1, 'month').format('YYYY-MM-DD'))
                                setToDate(moment().format('YYYY-MM-DD'))
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_month'})}
                    </button>
                </div>
                <div className="mx-4" style={{backgroundColor: "rgba(235, 237, 242, 1)", width: "1px", height: "40%"}}/>
                <div>
                    <DatePicker.RangePicker
                        key={`${fromDate}-${toDate}`}
                        style={{border: "none"}}
                        separator={<MinusOutlined/>}
                        locale={localeRu}
                        placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                        className='datepicker_form form-control-lg form-control-solid'
                        defaultValue={[replaceFormat(fromDate, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                            replaceFormat(toDate, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                        format='DD-MM-YYYY'
                        disabledDate={current => {
                            return isDemo() || current && current > moment().endOf('day');
                        }}
                        onKeyDown={(val) => {
                            if (val.code.toLowerCase().includes("key")) {
                                val.preventDefault()
                                val.stopPropagation()
                                return
                            }
                        }}
                        //@ts-ignore
                        onChange={(dates: [any, any], dateStrings: [string, string]) => {
                            if (dates) {
                                setCurrentDate('')
                                setFromDate(replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format())
                                setToDate(replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format())
                            }

                        }}
                    />
                </div>
            </div>
            <KTCardBody className='py-4'>
                <div className='d-flex justify-content-end py-5'>
                    <p className='fs-6 fw-semibold text-gray-600 me-10'>{intl.formatMessage({id:'transactions'})}: <span className='text-gray-800 fs-3'>{transactionGeneral?.total_count||0}</span></p>
                    <p className='fs-6 fw-semibold text-gray-600'>{intl.formatMessage({id:'total_amount'})}: <span className='text-gray-800 fs-3'>{transactionGeneral?.total_amount?NumReplace(transactionGeneral?.total_amount/100+'')+' UZS':'0 UZS'}</span></p>
                </div>
                {columns.length ? <div className='table-responsive'>
                    <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        {...getTableProps()}
                    >
                        <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            {headers.map((column: ColumnInstance<any>) => (
                                <CustomHeaderColumn key={column.id} column={column}/>
                            ))}
                        </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                        {rows.length > 0 ? (
                            rows.map((row: Row<any>, i) => {
                                prepareRow(row)
                                return <CustomRow row={row} key={`row-${i}-${row.id}`} onClick={()=>false}/>
                            })
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        {intl.formatMessage({id: 'NO_MATCHING'})}
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div> : <div style={{height: "55vh"}} className="d-flex justify-content-center align-items-center">
                    <a href="https://demo.globalpay.uz" target="_blank" className="btn btn-success text-white fs-3">
                        {intl.formatMessage({id: "WATCH_DEMO_COMPANY1"})}
                        <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-3 ms-3' />
                    </a>
                </div>}
                <Pagination withInfo={true} position='end' infoText='Транзакций' pagination={pagination} updatePage={updatePage}/>

            </KTCardBody>
        </>
    )
})

export {AdminTable}
