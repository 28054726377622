import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage, FormikProps} from 'formik'
import {CreateCompany} from "@providers/company/_models";
import AsyncSelect from 'react-select/async';
import {customStyles} from "@metronic/assets/ts/select.style";
import {useIntl} from "react-intl";
import moment from "moment/moment";
import {MerchantService} from "@providers/autopay/requests/merchant.service";
import {useParams} from "react-router-dom";

interface IProps extends FormikProps<CreateCompany> {
    handleChange(arg: any): void
    values:any,
    setFieldValue(name: string, val: any): void
    setErrors:(val:any)=>void
}

const Step2: FC<IProps> = (props) => {
    
    const {setErrors, setFieldValue} = props
    const [services, setServices] = useState<any[]>([])
    const {companyId} = useParams()

    const intl = useIntl()

    useEffect(() => {
        getServiceList()
    }, [])



    async function getServiceList(){
        await MerchantService.getMerchantServices(companyId||'')
            .then(result=>{
                const {data} = result?.data
                setServices(data.map((item:any)=>{
                    return {
                        label: item.name,
                        value: item.user_service_id
                    }
                }))
            })
            .catch((err:any)=>{

            })
    }


    const filterType = (inputValue: string) => {
        return services.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }


    const loadOptions = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {
        callback(filterType(inputValue))
    }


    return (
        <div className='w-100'>
            <div className="pb-14">
                <h1 className="fw-bold text-dark">{intl.formatMessage({id: 'step2_contract_form'})}</h1>
            </div>


            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'contractNumber'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='contractNumber'
                    placeholder='00000000000000'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='contractNumber' />
                </div>
            </div>

            {/*<div className='mb-8 fv-row'>*/}
            {/*    <label className='form-label mb-3 required'>{intl.formatMessage({id:'contractDate'})}</label>*/}
            {/*    <Field*/}
            {/*        type='date'*/}
            {/*        max={moment().subtract(1, 'days').format('YYYY-MM-DD')}*/}
            {/*        className='form-control form-control-lg form-control-solid'*/}
            {/*        name='contractDate'*/}
            {/*    />*/}
            {/*    <div className='text-danger mt-2'>*/}
            {/*        <ErrorMessage name='contractDate' />*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'productName'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='productName'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='productName'/>
                </div>
            </div>
            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'ikpu'})}</label>
                <Field
                    type='text'
                    placeholder='00000000000000000'
                    className='form-control form-control-lg form-control-solid'
                    name='ikpu'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (!e.currentTarget.value.length||e.currentTarget.value.match(/^\d{0,50}$/g)) props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='ikpu'/>
                </div>
            </div>
            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'merchantFilial'})}</label>
                <Field
                    component={AsyncSelect}
                    name='serviceId'
                    value={services.filter((item=>item.value===props.values['serviceId']))}
                    styles={customStyles}
                    defaultOptions={services}
                    loadOptions={loadOptions}
                    placeholder={intl.formatMessage({id: "select"})}
                    onChange={(e: any) => {
                        props.setFieldValue('serviceId', e.value)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='serviceId'/>
                </div>
            </div>


        </div>
    )
}

export {Step2}
