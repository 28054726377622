import {useListView} from '../../providers/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import {useParams} from "react-router-dom";



const UsersListHeader = () => {
  const {selected} = useListView()
    const {type} = useParams()


  return (
    <div className='card-header border-0 pt-6'>
      {/*<UsersListSearchComponent />*/}
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : type==='all'&&<UsersListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
