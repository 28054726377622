/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from "@metronic/partials";
import {getCSS, getCSSVariableValue} from "@metronic/assets/ts/_utils";
import {moneyFormat, NumReplace} from "@utils/index";
import {ThemeModeComponent} from "@metronic/assets/ts/layout";
import {useIntl} from "react-intl";

type Props = {
    className: string,
    data: {
        series?: { name: string, data: number[] }[]
        categories?: string[]
    }
}

const ChartDashboard: React.FC<Props> = ({className, data}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const {mode} = useThemeMode()
    const intl = useIntl()


    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, data, intl))
        if (chart) {
            chart.render()
        }

        window.dispatchEvent(new Event('resize'))


        return chart
    }

    useEffect(() => {

        const chart = refreshChart()


        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode])



    return (
        <div
            ref={chartRef}
            id='kt_charts_widget_8_chart'
            style={{height: '350px'}}
        />
    )
}

export {ChartDashboard}

type IData = {
    series?: { name: string, data: number[] }[]
    categories?: string[]

}

function getChartOptions(height: number, data: IData, intl:any): ApexOptions {

    let lineColorsArr: Array<string> = []
    let series = data.series

    if (data.series != null) {
        series = series?.map(item => {
            return {...item, data: item.data.map(val => val / 100)}
        })
    }

    // const categories = data.categories?.map(item => new Date(item.split('/').reverse().join('/')).getTime()).sort((a, b) => (a < b ? 1 : -1)).reverse()
    const currentMode = ThemeModeComponent.getMode()
    const categories = data.categories
    series?.forEach(item => {

        if (item.name.includes(intl.formatMessage({id:'APPROVED'}))) {
            lineColorsArr.push('#50CD89')
        }
        if (item.name.includes(intl.formatMessage({id:'INIT'}))) {
            lineColorsArr.push('#0D47A1')
        }
        if (item.name.includes(intl.formatMessage({id:'FAILED'}))) {
            lineColorsArr.push('#FFA621')
        }
        if (item.name.includes(intl.formatMessage({id:'REVERT'}))) {
            lineColorsArr.push('#F1416C')
        }
    })


    return {
        chart: {
            zoom: {
                enabled: false,
                type: 'x',
                autoScaleYaxis: true,
                zoomedArea: {
                    fill: {
                        color: '#90CAF9',
                        opacity: 0.4
                    },
                    stroke: {
                        color: '#0D47A1',
                        opacity: 0.4,
                        width: 1
                    }
                }
            },
            defaultLocale: 'ru',
            locales: [{
                name: 'ru',
                options: {
                    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                    days: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
                    shortDays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                }
            }, {
                name: 'uz',
                options: {
                    months: ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'Iyun', 'Iyul', 'Avgust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr'],
                    shortMonths: ['Yan', 'Fev', 'Mar', 'Apr', 'May', 'Iyu', 'Iyu', 'Avg', 'Sen', 'Okt', 'Noy', 'Dek'],
                    days: ['Dushanba', 'Seshanba', 'Chorshanba', 'Payshanba', 'Juma', 'Shanba', 'Yakshanba'],
                    shortDays: ['Dush', 'Sesh', 'Chor', 'Pay', 'Jum', 'Sha', 'Yak'],
                }
            }],
            toolbar: {
                show: false,
                tools: {
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    download: false,
                    selection: false,
                    pan: false,
                    reset: false,
                },

            },
            height: 350,
            type: "area",
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        colors: lineColorsArr,
        // series: data?.series,
        series: series,
        stroke: {
            curve: "smooth",
            width: [4, 4, 4],
            colors: lineColorsArr
        },
        plotOptions: {
            bar: {
                columnWidth: "20%"
            }
        },
        xaxis: {
            sorted: true,
            categories: categories,
            type: "datetime",
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                },
                style: {
                    colors: currentMode === 'light' ? "#000000" : "#fff"
                }
            },
            tooltip: {
                enabled: false,
            },
        },
        yaxis: [
            {
                axisBorder: {
                    show: true,
                    color: currentMode === 'light' ? "#000000" : "#fff"
                },
                labels: {
                    formatter: function (value) {
                        // return NumReplace(Math.round(value).toString());
                        const formattedVal = moneyFormat(Math.round(value).toString());
                        return formattedVal.toString();
                    },
                    style: {
                        colors: currentMode === 'light' ? "#000000" : "#fff"
                    }
                },
            },
        ],
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0,
                opacityTo: 0,
            }
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return NumReplace(val.toString()) + " сум"
                },
            },
        },
        legend: {
            show: false,
            showForSingleSeries: true,
            horizontalAlign: "left",
            position: "top",
            offsetX: 40,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
    }
}