import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'


import {usersColumns, usersColumnsOwner} from './columns/_columns'
import {KTCardBody} from '@metronic/helpers'
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import {Employee} from "@providers/employee/_request";
import {CreateEmployee} from "@providers/employee/_models";
import {employeeStore} from "@store/employee";
import {ADMIN, MODERATOR, OWNER, SUPER_ADMIN} from "@utils/constants";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useListView} from "./providers/ListViewProvider";
import {useIntl} from "react-intl";
import {Admins} from "@providers/admins/_request";
import {ReplacePhone} from "@utils/index";

const Table = observer(() => {
    const {clearSelected} = useListView()
    const {companyId, type} = useParams()
    const data = useMemo(() => employeeStore.getEmployee.map(item=>{
        return {
            ...item,
            phone: ReplacePhone(item?.phone||'')
        }
    }), [employeeStore.getEmployee])
    const columns = useMemo(() => type==='all'&&employeeStore.getRole===OWNER?usersColumnsOwner:usersColumns, [employeeStore.getEmployee])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const {setError} = useErrors()
    const intl = useIntl()
    const role = employeeStore.getRole


    useEffect(()=>{
        if(companyId){
            getEmployees()
            clearSelected()
        }else if(role===ADMIN||role===MODERATOR||role===SUPER_ADMIN){
            Admins.getAdmins()
                .then(result=>{

                })
                .catch(err=>{
                    setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
                })
        }
    }, [companyId, type])

    const getEmployees = ()=>{
        if(type==='all'){
            Employee.getEmployee(companyId??'')
                .then(result=>{
                    employeeStore.setEmployee((result?.data?.result??[]).map((item:any)=>{
                        return {
                            ...item,
                            phone: ReplacePhone(item?.phone||'')
                        }
                    }))
                }).catch(err=>{setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})})
        }else if(type==='invited'){
            Employee.getEmployeeInvited(companyId??'')
                .then(result=>{
                    employeeStore.setEmployee((result?.data?.result??[]).map((item:any)=>{
                        return {
                            ...item,
                            phone: ReplacePhone(item?.phone||'')
                        }
                    }))
                }).catch(err=>{setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})})
        }
    }

    return (
        <KTCardBody className='py-4'>
            <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<CreateEmployee>) => (
                            <CustomHeaderColumn key={column.id} column={column} />
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<CreateEmployee>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id:"NOT_MATCHING"})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>

        </KTCardBody>
    )
})

export {Table}
