import React from 'react';
import {IEpos} from "@providers/filial/_models";
import {KTCardBody, KTSVG} from "@metronic/helpers";
import {cardIcons} from "../eposes-form/eposes-helper";
import {useIntl} from "react-intl";

interface props {
    eposes: IEpos[]
}

const EposesView: React.FC<props> = ({
                                         eposes
                                     }) => {

    const intl = useIntl()

    return (
        <KTCardBody>
            {eposes.map(({
                             id,
                             terminal_id,
                             merchant_id,
                             processing_type
                         }, index) => (
                <div key={id} className='d-flex  align-items-start mb-10'>
                    <p className='mb-0 text-gray-600 fs-6 col-lg-4'>EPOS {index + 1}</p>
                    <div>
                        <div className='d-flex flex-row align-items-start mb-0 text-gray-800 fw-bold fs-5'><img
                            className={'me-4'}
                            height={24}
                            width={24}
                            src={cardIcons[processing_type as keyof any]}
                            alt={''}/>
                            <div>
                                <p className={'mb-3'}
                                style={{
                                    fontWeight: '400'
                                }}>
                                    <span className={'fw-bold'}>
                                        {intl.formatMessage({id: 'terminal_id'})}
                                    </span> - {terminal_id}
                                </p>
                                <p className={'mb-0'}
                                style={{
                                    fontWeight: '400'
                                }}>
                                    <span className={'fw-bold'}>{intl.formatMessage({id: 'merchant_id'})}</span> - {merchant_id}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            ))}
        </KTCardBody>
    );
};

export default EposesView;