import {useListView} from '../../providers/ListViewProvider'
import {Employee} from "@providers/employee/_request";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {useIntl} from "react-intl";
import {confirmAlert} from "react-confirm-alert";
import {useErrors} from "@providers/context/ErrorsProvider";

const UsersListGrouping = observer(() => {
    const {selected, clearSelected} = useListView()
    const {setError} = useErrors()
    const {companyId} = useParams()
    const intl = useIntl()

    const deleteSelectedItems = ()=>{
        if(companyId){
            confirmAlert({
                title: intl.formatMessage({id: 'WARNING'}),
                message: intl.formatMessage({id: selected.length>1?'WARNING_DELETE_EMPLOYEES':'WARNING_DELETE_EMPLOYEE'}),
                buttons: [
                    {
                        label: intl.formatMessage({id: 'ACTIONS_YES'}),
                        onClick: async () => {
                            selected.forEach(async(item)=>{
                                await Employee.deleteEmployee(companyId, item)
                                    .then(()=>{
                                        employeeStore.setEmployee(employeeStore.getEmployee.filter(emp=>emp.id!==item))
                                    })
                                    .catch(e=>{
                                        setError({status: e.response?.status, message: e.response?.data?.message, isError: true})
                                    })
                            })
                            clearSelected()

                        }
                    },
                    {
                        label: intl.formatMessage({id: 'ACTIONS_NO'}),
                        onClick: async () => {
                            clearSelected()
                        }
                    }
                ]
            })

        }

    }

    return (
        <div className='d-flex justify-content-end align-items-center'>
            <div className='fw-bolder me-5'>
                <span className='me-2'>{selected.length}</span> {intl.formatMessage({id: 'selected'})}
            </div>

            <button
                type='button'
                className='btn btn-danger'
                onClick={() => deleteSelectedItems()}
            >
                {intl.formatMessage({id: 'delete'})}
            </button>
        </div>
    )
})

export {UsersListGrouping}
