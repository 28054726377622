import React from 'react';
import {useIntl} from "react-intl";
import CashPaymentModal from "../cashPaymentModal/cashPaymentModal";
import {ContractStatus} from "@providers/autopay/models/_models";
import {contract} from "@store/contract/contract";
import {priceformatter} from "@utils/priceformatter";
import PaymentsTable from "../paymentsTable/paymentsTable";
import {divideByHundred} from "@utils/numbersOperations";
import moment from "moment";
import {dateFormat} from "../../editContractHelper";

interface props {
    status: ContractStatus
}

const PaymentSchedule: React.FC<props> = ({
                                              status
                                          }) => {

    const intl = useIntl()

    const {entity} = contract.getContract


    const headerInfo = [
        {
            label: 'price_credit',
            value: entity ? priceformatter(divideByHundred((entity.contractVersion.productPrice - entity.contractVersion.discountPrice - entity.contractVersion.prepaymentPrice))) : 0
        },
        {
            label: 'date_final_contract',
            value: entity?.contractVersion.schedules.length ?  moment(entity?.contractVersion.schedules[entity?.contractVersion.schedules.length - 1].scheduleDate).format(dateFormat) : ''
        }
    ]


    return (
        <div className={'card  mt-8'}
        >
            <div className="card-header d-flex ps-9 align-items-center">
                <h2 className="mb-0">
                    {intl.formatMessage({id: 'GRAF_PAY'})}
                </h2>
                <CashPaymentModal status={status} schedules={entity!.contractVersion.schedules}/>
            </div>
            <div className={'card-body'}>
                {headerInfo.map(({
                                     label,
                                     value
                                 }) => (
                    <div key={label} className={'d-flex align-items-center justify-content-between mb-3'}>
                        <p className={'mb-0 text-gray-600 fs-6'}>
                            {intl.formatMessage({id: label})}
                        </p>
                        <h3>
                            {value}
                        </h3>
                    </div>
                ))}
                <PaymentsTable/>
            </div>
        </div>
    );
};

export default PaymentSchedule;