import {Navigate, Route, Routes, useOutletContext} from 'react-router-dom'
import {MasterLayout} from '@metronic/layout/MasterLayout'
import DashboardsMerchant from "../pages/dashboard-merchant/dashboardMerchant";
import EmployeesCompany from "../pages/employees/employees";
import SessionsLogs from "../pages/logs/sessions";
import Analytics from "../pages/analytics/analytic";
import Reports from "../pages/repots";
import CompanyForm from "../pages/companies/company-form";
import {ProductsListWrapper} from "../pages/companies";
import FilialsWrapper from "../pages/fillials-merchant/filials";
import FilialView from "../pages/fillials-merchant/filials/filial-view";
import FilialFormWrapper from "../pages/fillials-merchant/filials/forms";
import SettingMerch from "../pages/settings-merchant";
import EmployeeViewWrapper from "../pages/employees/employee-view";
import {observer} from "mobx-react";
import {AccountWrapper} from "../pages/account/settings";
import ContractsList from "../pages/autopay/contracts/contractsList/contractsList";
import SubscriptionsList from "../pages/autopay/subscriptions/subscriptionsList";
import ClientsList from "../pages/autopay/clients/clientsList/clientsList";
import EditContract from "../pages/autopay/contracts/editContract/editContract";
import PaymentsTransactions from "../pages/payments/transactions";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {CompanyViewWrapper} from "../pages/companies/admin/company-view";
import {AgentListWrapper} from "../pages/agents";
import AgentFormWrapper from "../pages/agents/forms/agent-form";
import {AgentStatisticsWrapper} from "../pages/agents/statistics";
import {AgentStatisticPage} from '../pages/agent-statistic';
import {AgentView} from "../pages/agents/agent-page";
import ContractFormWrapper from "../pages/autopay/contracts/createContract";
import Offer from "../pages/autopay/offer/offer";
import {ClientView} from "../pages/autopay/clients/mainInfoClients/mainClientsInfo";

const PrivateRoutes = observer(() => {

  return (
      <ErrorProvider>
        <Routes>
          <Route element={<MasterLayout/>}>


              {/* Redirect to Dashboard after success login/registartion */}
              <Route path='auth/*' element={<Navigate to='/companies'/>}/>
              <Route path='back' element={<Navigate to='/companies'/>}/>
              {/* Pages */}
              <Route path='/:companyId/companies' element={<ProductsListWrapper/>}/>
              <Route path='/companies/:id' element={<CompanyViewWrapper/>}/>
              <Route path='/companies/add' element={<CompanyForm/>}/>
              <Route path='companies' element={<ProductsListWrapper/>}/>


              <Route path='/:companyId/settings-merchant' element={<SettingMerch/>}/>
              <Route path='/:companyId/settings-merchant/:id' element={<SettingMerch/>}/>

              <Route path='/:companyId/cashboxes' element={<FilialsWrapper/>}/>
              <Route path='/:companyId/cashboxes/:id' element={<FilialView/>}/>
              <Route path='/:companyId/cashboxes/create' element={<FilialFormWrapper/>}/>

              <Route path='/:companyId/dashboard-merchant' element={<DashboardsMerchant/>}/>
              <Route path='/dashboard' element={<DashboardsMerchant/>}/>


              <Route path='/employees/admins' element={<EmployeesCompany/>}/>
              <Route path='/:companyId/employees/view/:id' element={<EmployeeViewWrapper/>}/>
              <Route path='/:companyId/employees/:type' element={<EmployeesCompany/>}/>



              <Route path='/:companyId/logs/sessions' element={<SessionsLogs/>}/>
              <Route path='/logs/sessions' element={<SessionsLogs/>}/>

              <Route path='/:companyId/analytics/analytic' element={<Analytics/>}/>

              <Route path='/:companyId/payments/transactions' element={<PaymentsTransactions/>}/>
              <Route path='/payments/transactions' element={<PaymentsTransactions/>}/>


              <Route path='/:companyId/reports' element={<Reports/>}/>
              <Route path='reports' element={<Reports/>}/>
              <Route path='/:companyId/account/settings' element={<AccountWrapper/>}/>
              <Route path='/account/settings' element={<AccountWrapper/>}/>

              <Route path='/agents/statistic' element={<AgentStatisticsWrapper/>}/>
              <Route path='/agents/:type' element={<AgentListWrapper/>}/>
              <Route path='/agents/add/:type' element={<AgentFormWrapper/>}/>
              <Route path='/agents/view/:id' element={<AgentView/>}/>


              <Route path='/agent/:path' element={<AgentStatisticPage/>}/>


              {/* AUTOPAY SERVICE */}
              <Route path='/:companyId/autopay/contracts/create' element={<ContractFormWrapper/>}/>
              <Route path='/:companyId/autopay/contracts/:contractId' element={<EditContract/>}/>
              <Route path='/:companyId/autopay/contracts/list/:type' element={<ContractsList/>}/>
              <Route path='/:companyId/autopay/subscriptions' element={<SubscriptionsList/>}/>
              <Route path='/:companyId/autopay/clients/:pinfl' element={<ClientView/>}/>
              <Route path='/:companyId/autopay/clients' element={<ClientsList/>}/>

              <Route path={'/:companyId/autopay/contracts/offer'} element={<Offer/>}/>
            {/* Page Not Found */}
            <Route path='*' element={<Navigate to='/error/404'/>}/>
          </Route>
        </Routes>
      </ErrorProvider>
  )
})


function WrapRouter(){
  const context = useOutletContext();
console.log(context)
  return(
      <></>
  )
}


export {PrivateRoutes}
