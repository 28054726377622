/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {MenuComponent} from '@metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '@metronic/helpers'
import {Link, useNavigate, useParams} from "react-router-dom";
import {Company} from "@providers/company/_request";
import {observer} from "mobx-react";
import {company} from "@store/company";
import {useIntl} from "react-intl";
import {useListView} from "../providers/ListViewProvider";
import {useErrors} from "@providers/context/ErrorsProvider";
import {employeeStore} from "@store/employee";
import {localErrorsMessage, roleRoutingAdmin} from "@utils/index";
import {confirmAlert} from "react-confirm-alert";
import {Admins} from "@providers/admins/_request";
import {admins} from "@store/admins";
import {Employee} from "@providers/employee/_request";
import {Menu, MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import {Field} from "formik";
import {Filial} from "@providers/filial/_request";

type Props = {
    elemId: number,
    status:string,
    branch?: boolean
}


const ChangeStatus:FC<Props> = observer(({elemId, status, branch=false}) => {

    const [statusChecked, setStatusChecked] = useState(status)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const {updateData} = useListView()
    const {id} = useParams()
    const {setError} = useErrors()
    const intl = useIntl()



    useEffect(()=>{
        let page = document.querySelector('.page')
        if(page){
            page.addEventListener('click', ()=>{
                setModalIsOpen(false)
                setStatusChecked(status)
            })
        }

        return ()=>{
            if(page){
                page.removeEventListener('click', ()=>{
                    setModalIsOpen(false)
                    setStatusChecked(status)
                })
            }
        }

    }, [])



    const widgetMenu = (
        <Menu className='p-7 change_status_group'>
            <p className='text-gray-800 fw-bold'>Изменить статус</p>
           <div >

               <div className={`item_check_status d-flex align-items-center justify-content-between border border-gray-300 mb-3
               ${statusChecked==='ACTIVE'?'active':''}`}

                    onClick={()=>{
                        setStatusChecked('ACTIVE')
                    }}
               >
                   <div className='d-flex  align-items-center'>
                       <span className='me-5'>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="6" fill="#ECFDF3"/>
                            <path d="M18 11L12.5 16.5L10 14" stroke="#12B76A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>

                       </span>
                       <p className='fw-bold fs-6 text-gray-800 mb-0'>Активный</p>
                   </div>
                   <div>
                       <div className="form-check form-check-custom form-check-solid">
                           <input className="form-check-input"
                                  type="radio"
                                  checked={statusChecked==='ACTIVE'}

                           />
                       </div>
                   </div>
               </div>
               <div className={`item_check_status d-flex align-items-center justify-content-between border border-gray-300 
               ${statusChecked==='DISABLED'?'active':''}`}
                    onClick={()=>{
                        setStatusChecked('DISABLED')
                    }}
               >
                   <div className='d-flex  align-items-center'>
                       <span className='me-5'>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="6" fill="#FEF3F2"/>
                            <path d="M17 11L11 17M11 11L17 17" stroke="#F04438" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>


                       </span>
                       <p className='fw-bold fs-6 text-gray-800 mb-0'>Неактивный</p>
                   </div>
                   <div>
                       <div className="form-check form-check-custom form-check-solid">
                           <input className="form-check-input"
                                  type="radio"
                                  checked={statusChecked==='DISABLED'}
                           />
                       </div>
                   </div>
               </div>
           </div>

            <div className='mt-8'>
                <button className='btn btn-light fw-bold me-3' onClick={()=>setModalIsOpen(false)}>
                    Отмена
                </button>
                <button className='btn btn-primary fw-bold' onClick={()=>changeStatusSubmit()}>
                    Применить
                </button>
            </div>
        </Menu>
    );

    async function changeStatusSubmit(){
        setModalIsOpen(false)
        if(branch&&id){

            await Filial.changeStatusRequest(+id, elemId,{status:statusChecked})
                .then(()=>{
                    updateData(+id)
                })
                .catch((err)=>{
                    const message = localErrorsMessage('change-status', 'message-match', err?.response?.data?.message, intl)
                    setError({status: err?.response?.status, message: message, isError: true})
                })
        }else{
            await Company.changeStatusRequest(elemId, {status:statusChecked})
                .then(()=>{
                    updateData()
                })
                .catch((err)=>{
                    const message = localErrorsMessage('change-status', 'message-match', err?.response?.data?.message, intl)
                    setError({status: err?.response?.status, message: message, isError: true})
                })
        }

    }






    return (
        <>
            {
                roleRoutingAdmin('action', employeeStore.getRole, 'change-status')?(<Dropdown overlay={widgetMenu}  open={modalIsOpen} >
                    <a onClick={(e) => {
                        e.preventDefault()
                        setModalIsOpen(!modalIsOpen)
                    }}>
                        <Space>
                            Изменить статус
                        </Space>
                    </a>
                </Dropdown>):''
            }
        </>
    )
})

export {ChangeStatus}
