import {instantse} from "@providers/request";
import {IStatistic, IStatisticsID} from "@providers/statistic/_modals";


export const Statistic = {

    getStatistic(company_id: string, data: IStatistic) {
        return instantse.post(`/v1/company/${company_id}/statistics`, data)
            .then(response => response)
    },

    getTransactions(companyId: string, data: IStatistic) {
        return instantse.post(`/v1/company/${companyId}/transactions`, data)
            .then(response => response)
    },

    getIncomeGs(data: any) {
        return instantse.post(`/v1/gs/company/statistics/income`, data)
            .then(response => response)
    },

    getTransactionsById(companyId: string, data: IStatistic) {
        return instantse.post(`/v1/company/${companyId}/transactions/by-id`, data)
            .then(response => response)
    },

    getTransactionFiscalCheque(companyId: string, transactionId: string) {
        return instantse.get(`/v1/company/${companyId}/transactions/${transactionId}/receipt`)
    },

    exportTransactions(companyId: string, data: IStatistic) {
        return instantse.post(`/v1/company/${companyId}/transactions/export`, data, {
            responseType: 'arraybuffer',
        })
            .then(response => response)
    },

    getStatisticsGeneral(companyId: string, data: IStatistic) {
        return instantse.post(`/v1/company/${companyId}/statistics/general`, data)
            .then(response => response)
    },

    getStatisticsAdmin(data: any) {
        return instantse.post(`/v1/gs/company/transactions/detailed`, data)
            .then(response => response)
    }
}