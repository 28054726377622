/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useMemo, useState} from 'react'
import {ModSession} from "@providers/session/_modals";
import {useIntl} from "react-intl";
import {observer} from "mobx-react";
import {filial} from "@store/filial";
import {Company} from "@providers/company/_request";
import {useParams} from "react-router-dom";
import {Filial} from "@providers/filial/_request";


type Props = {
    data: any
    className?: string
    icon?: any
    isBranchName?: boolean
    company?: boolean
}


const UserInfoCell: FC<Props> = observer(({data, company, className, icon, isBranchName}) => {
    const intl = useIntl()
    const {id, companyId} = useParams()
    const filialDataOne:any = useMemo(() => filial.getFilialOne, [filial.getFilialOne])
    const filialDataAll:any = useMemo(() => filial.getFilial, [filial.getFilial])
    const [filialIcon, setFilialIcon] = useState<string>("")
    let iconStr;
    if (icon) {
        if (icon === "UZCARD") {
            iconStr = "/media/svg/Uzcard (2).svg"
        } else if (icon === "HUMO") {
            iconStr = "/media/svg/Humo.svg"
        } else if(data.match(/^5+/)){
            iconStr = "/media/svg/mastercard.svg"
        } else if(data.match(/^4+/)){
            iconStr = "/media/svg/visa.svg"
        }else{
            iconStr = `/media/svg/${icon}.svg`
        }
    }

    useEffect(() => {

        if(!filialDataAll.length) return
        // if (!filialDataOne||!Object.keys(filialDataOne).length) return
        let logoFile = filialDataAll.find((item:any)=>item.name===data&&item.logo_file)?.logo_file

        setFilialIcon(logoFile)
    }, [filialDataOne])



    return (
        <div className="d-flex align-items-center justify-content-between">

            <div className='d-flex flex-column'>
                <p className={`text-gray-800 text-hover-primary mb-1 text-left d-flex align-items-start ${className ? className : ""}`}>
                    {(icon || isBranchName ||company) &&
                        <img src={isBranchName||company ? (filialIcon?Company.getFiles(filialIcon):iconStr) : iconStr} className="me-4"
                             style={{
                                 objectFit: "cover",
                                 width: "32px",
                                 height: "32px",
                             }}/>}
                    <span style={{textAlign: "left", textOverflow:'ellipsis', overflow:'hidden'}}>{data ?? intl.formatMessage({id: "NO_DATA"})}</span>
                </p>
            </div>
        </div>
    )
})

export {UserInfoCell}
