/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useParams} from "react-router-dom";
import {CompanyResponse} from "@providers/company/_models";
import {observer} from "mobx-react";
import {image_default, JUNIOR_CASHIER} from "@utils/constants";
import {employeeStore} from "@store/employee";
import {Company} from "@providers/company/_request";
import {roleRoutingAdmin} from "@utils/index";

type Props = {
    company: CompanyResponse
}

const CompanyNameAdmin: FC<Props> = ({company}) => {


    return (
        <div className='d-flex align-items-center'>
            <div className='me-3'>

                <Link to={`/companies/${company.id}`}>
                    <div className='d-flex align-items-center w-200px'>
                        <div className='logo-table-admin'
                             style={{backgroundImage: `url(${company?.logo_file?Company.getFiles(company?.logo_file):`/${image_default}`})`}}/>

                        <span className='fs-6 text-gray-600 fw-semibold' style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{company.name}</span>
                    </div>

                </Link>
            </div>
        </div>
    )
}

export {CompanyNameAdmin}
