import React from "react";
import {KTCard} from "@metronic/helpers";
import {AdminTable} from "../_modals/AdminTable";


export function AdminTransactions(){




    return (
        <>
            <div className='d-flex justify-content-between mb-5' data-kt-user-table-toolbar='base'>

            </div>
            <KTCard>
                <AdminTable />
            </KTCard>

        </>
    )
}