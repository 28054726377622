import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage, FormikProps} from 'formik'
import {CreateCompany} from "@providers/company/_models";
import AsyncSelect from 'react-select/async';
import {customStyles} from "@metronic/assets/ts/select.style";
import {useIntl} from "react-intl";

interface IProps extends FormikProps<CreateCompany> {
    handleChange(arg: any): void
    values:any,
    setFieldValue(name: string, val: any): void
    type: { id: number, name: string }[] | []
    setSelfEmployee:(val:boolean)=>void,
    setErrors:(val:any)=>void,
    setFieldTouched:(val:any)=>void,
    selfEmployee: boolean
}

const Step2: FC<IProps> = (props) => {

    const [types, setTypes] = useState<any[]>([])
    const {setSelfEmployee, selfEmployee, setErrors, setFieldValue} = props

    const intl = useIntl()

    useEffect(() => {
        props.setFieldValue('type', undefined)
    }, [])

    useEffect(() => {
        setTypes(props.type.map(item => ({value: item.id, label: item.name})))
    }, [props])


    const filterType = (inputValue: string) => {
        return types.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }


    const loadOptions = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {
        callback(filterType(inputValue))
    }


    return (
        <div className='w-100'>
            <div className="pb-14">
                <h1 className="fw-bold text-dark">{intl.formatMessage({id: 'INFO_COMPANY'})}</h1>
            </div>

            <div className='fv-row mb-10'>
                <label className='form-label required'>{intl.formatMessage({id: 'type'})}</label>

                <Field
                    component={AsyncSelect}
                    name='type'
                    cacheOptions
                    value={types.filter((item=>item.value===props.values['type']))}
                    styles={customStyles}
                    defaultOptions={types}
                    loadOptions={loadOptions}
                    placeholder={intl.formatMessage({id: "select"})}
                    onChange={(e: any) => {
                    if(e.label!=='Самозанятый'){
                        setFieldValue('pinfl', '00000000000000')
                        setFieldValue('oked', '')
                    }else{
                        setFieldValue('pinfl', '')
                        setFieldValue('oked', '00000')
                    }
                        setSelfEmployee(e.label==='Самозанятый')
                        props.setFieldValue('type', e.value)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='type'/>
                </div>
            </div>

            {
                selfEmployee?(<div className='mb-8 fv-row'>
                    <label className='form-label mb-3 required'>{intl.formatMessage({id: 'pinfl'})}</label>
                    <Field
                        type='text'
                        placeholder=''
                        className='form-control form-control-lg form-control-solid'
                        name='pinfl'
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            if (e.currentTarget.value.match(/^\d{0,14}$/)) props.handleChange(e)
                        }}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='pinfl'/>
                    </div>
                </div>):''
            }

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='name'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='name'/>
                </div>
            </div>
            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'legal_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='legal_name'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='legal_name'/>
                </div>
            </div>
            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'address'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='address'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='address'/>
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'postal_code'})}</label>
                <Field
                    type='text'
                    placeholder='100100'
                    className='form-control form-control-lg form-control-solid'
                    name='postal_code'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,6}$/)) props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='postal_code'/>
                </div>
            </div>



            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'ikpu'})}</label>
                <Field
                    type='text'
                    placeholder='123456789'
                    className='form-control form-control-lg form-control-solid'
                    name='ikpu'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (!e.currentTarget.value.length||e.currentTarget.value.match(/^\d{0,50}$/)) props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='ikpu'/>
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'inn'})}</label>
                <Field
                    type='text'
                    placeholder='123456789'
                    className='form-control form-control-lg form-control-solid'
                    name='inn'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,9}$/)) props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='inn'/>
                </div>
            </div>


            {
                !selfEmployee?(<>
                    <div className='mb-8 fv-row'>
                        <label className='form-label required mb-3'>{intl.formatMessage({id: 'oked'})}</label>
                        <Field
                            type='text'
                            placeholder='12345'
                            className='form-control form-control-lg form-control-solid'
                            name='oked'
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                if (e.currentTarget.value.match(/^\d{0,5}$/)) props.handleChange(e)
                            }}
                        />
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='oked'/>
                        </div>
                    </div>
                    <div className='mb-8 fv-row'>
                    <label className='form-label mb-3'>{intl.formatMessage({id: 'nds'})}</label>
                    <Field
                        type='text'
                        placeholder='1234567895612'
                        className='form-control form-control-lg form-control-solid'
                        name='nds'
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            if (!e.currentTarget.value.length||e.currentTarget.value.match(/^\d{0,12}$/)) props.handleChange(e)
                        }}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='nds'/>
                    </div>
                </div>
                    </>):''
            }

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'mfo'})}</label>
                <Field
                    type='text'
                    placeholder='12345'
                    className='form-control form-control-lg form-control-solid'
                    name='mfo'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,5}$/)) props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='mfo'/>
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'bank'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='bank'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='bank'/>
                </div>
            </div>

            <div className='mb-12 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'bank_account_id'})}</label>
                <Field
                    type='text'
                    placeholder='12345123451234512345'
                    className='form-control form-control-lg form-control-solid'
                    name='bank_account_id'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,20}$/)) props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='bank_account_id'/>
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3'>{intl.formatMessage({id: 'bank_filial'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='bank_filial'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='bank_filial'/>
                </div>
            </div>


        </div>
    )
}

export {Step2}
