import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row, useSortBy} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'


import {demoColumns, usersColumns} from './columns/_columns'
import {initialQueryState, KTCardBody, KTSVG, useDebounce} from '@metronic/helpers'
import {observer} from "mobx-react";
import {sessionStore} from "@store/session";
import {ModSession} from "@providers/session/_modals";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {Statistic} from "@providers/statistic/_request";
import {useLocation, useParams} from "react-router-dom";
import {Filial} from "@providers/filial/_request";
import {HUMO, statuses_const, statuses_platforms, UZCARD} from "@utils/constants";
import moment from "moment";
import {Pagination} from "../../../../modules/Pagination";
import {DatePicker} from "antd";
import {FilterDropDown} from "./FilterDropDown";
import {subtractMonths, toIsoStringHelper} from "../../../autopay/dateHelper";
import {filial} from "@store/filial";
import {IStatistic, IStatisticsID} from "@providers/statistic/_modals";
import {Modal} from "react-bootstrap";
import {transactions} from "@store/transactions/transactions";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import localeEn from "antd/es/date-picker/locale/en_US";
import {isDemo, isProd} from "@utils/index";
import {MinusOutlined} from "@ant-design/icons";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";

type TableProps = {
    onNavigate: (data: {
        statuses: Array<string>
        ppc_types: Array<string>
        from_date: string
        to_date: string
    }) => void
    onExportData: Function
    setGeneralDataRequest: Function
    searchText: string
}

interface CustomizedState {
    statuses?: Array<string>
    ppc_types?: Array<string>
    from_date?: string
    to_date?: string
}

const Table: FC<TableProps> = observer(({onNavigate, searchText, onExportData, setGeneralDataRequest}) => {
    const location = useLocation();
    const state = location.state as CustomizedState; // Type Casting, then you can get the params passed via router

    const [filtersDay, setFiltersDay] = useState('month')
    const [sortByCategory, setSortByCategory] = useState('CREATED_AT')
    const [sortFromMax, setSortFromMax] = useState(true)
    const [transactionFiscalData, setTransactionFiscalData] = useState([])
    const [transactionFiscalDataModalShow, setTransactionFiscalDataModalShow] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    const [cashboxes, setCashboxes] = useState([])
    const columns = useMemo(() => isDemo() ? demoColumns : usersColumns, [sessionStore.getSession])
    const initialState = {hiddenColumns: []}
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow, setHiddenColumns} = useTable({
        columns,
        data, initialState
    })


    const [loaded, setLoaded] = useState<boolean>(false)
    const [filtersCount, setFiltersCount] = useState(3)


    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang

    const {companyId} = useParams()
    const {setError} = useErrors()
    const intl = useIntl()
    const debouncedSearchTerm = useDebounce(searchText, 300)

    const [pagination, setPagination] = useState<any>(null)
    const [filters, setFilters] = useState<any>({
        statuses: state?.statuses ?? ['APPROVED', 'FAILED', 'REVERT'],
        ppc_types: state?.ppc_types ?? [HUMO, UZCARD, 'VISA', 'MASTERCARD'],
        // from_date: state?.from_date ?? moment().subtract(1, 'month').format('YYYY-MM-DD'),
        from_date: isDemo() ? '2023-01-01' : state?.from_date ?? moment().subtract(1, 'month').format('YYYY-MM-DD'),
        // to_date: state?.to_date ?? moment().format('YYYY-MM-DD')
        to_date: isDemo() ? '2024-01-01' : state?.to_date ?? moment().format('YYYY-MM-DD')
    })
    const [showFilter, setShowFilter] = useState(false)
    const sortBy = useMemo(() => transactions.getTransactionSortBy, [transactions.getTransactionSortBy])

    useEffect(() => {
        setSortByCategory(sortBy.sortBy)
        setSortFromMax(sortBy.sortFromMax)
        getSortedTransactions({pagination}, sortBy.sortBy, sortBy.sortFromMax)
    }, [sortBy, cashboxes])


    useEffect(() => {
        getFilial()
    }, [])

    useEffect(() => {
        if (cashboxes.length && (debouncedSearchTerm === "" || debouncedSearchTerm === undefined)) {
            getTransaction({pagination})
        }
        onNavigate(filters)
    }, [cashboxes, filters, debouncedSearchTerm])

    function countDays() {
        let a = moment(filters.from_date),
            b = moment(filters.to_date)

        return b.diff(a, 'days')
    }


    const getFilial = async () => {
        if (companyId) {
            await Filial.getFilial(companyId)
                .then(result => {
                    filial.setFilial(result?.data?.result)
                    setCashboxes((result?.data?.result || []).map((item: any) => item.id))
                }).catch(err => {
                    setError({
                        status: err?.response?.status,
                        message: err?.response?.data?.message,
                        isError: true
                    })
                }).finally(() => setIsLoading(false))
        }
    }

    const getTransactionFiscalChequeData = async (transactionId: string) => {
        try {
            const {request: {response: res}} = await Statistic.getTransactionFiscalCheque(companyId!, transactionId)
            const result = JSON.parse(res).result
            setTransactionFiscalData(result)
            setTransactionFiscalDataModalShow(true)
        } catch (err: any) {
            if (err?.response?.data?.message === "Receipt of this transaction is not found") {
                setError({status: err?.response?.stat, message: "Фискальный чек не существует", isError: true})
            } else {
                setError({status: err?.response?.stat, message: err?.response?.data?.message, isError: true})
            }
        }
    }

    const getSortedTransactions = async (params: any = {}, cat: any, max: boolean) => {
        if (companyId && cashboxes.length) {
            setLoaded(false)
            const dataRequest: IStatistic = {
                cashboxes: cashboxes,
                page: params.pagination?.currentPage ?? 1,
                count: 20,
                statuses: filters.statuses,
                ppc_types: filters.ppc_types,
                from_date: toIsoStringHelper(new Date(filters.from_date)),
                to_date: toIsoStringHelper(moment(filters?.to_date).endOf('day').toDate()),
                sort_by: cat,
                sort_order: max ? "ASC" : "DESC"
            }

            if (searchText.trim() !== "") {
                dataRequest.query = searchText.replace(/\s+/g, '')
            }


            await Statistic.getTransactions(companyId, dataRequest)
                .then(result => {
                    const hiddenColumns = []
                    const fls = filial.getFilial
                    setPagination({...result?.data?.meta, currentPage: dataRequest.page})
                    if (isProd()) {
                        hiddenColumns.push("platform")
                    }
                    if (result?.data?.result.length === 0) {
                        hiddenColumns.push("branch_id")
                    }
                    setHiddenColumns(hiddenColumns)
                    let data = result?.data?.result || []
                    if (!isDemo()) {
                        data.forEach((item: any) => {
                            item.cashbox_id = fls.find(value => value.id === item.cashbox_id).name
                        })
                    }
                    if (data.length > 0) {
                        data.map((item: any, index: number) => {
                            if (dataRequest.page && dataRequest.page > 2) {
                                item['num'] = ((dataRequest.page - 1) * 20) + index + 1
                            } else if (dataRequest.page == 2) {
                                item['num'] = 21 + index
                            } else {
                                item['num'] = index + 1
                            }
                            return item
                        })
                    }
                    setData(data)


                }).catch(err => {
                    if (err?.response) {
                        setError({
                            status: err?.response?.status,
                            message: err?.response?.data?.message,
                            isError: true
                        })
                    }
                }).finally(() => setLoaded(true))

            setIsLoading(false)
        }

        setIsLoading(false)
    }

    const getTransactionById = useCallback(async (params: any = {}) => {
        if (companyId) {

            const dataRequest: IStatistic = {
                cashboxes: cashboxes,
                page: params.pagination?.currentPage ?? 1,
                count: 20,
                statuses: filters.statuses,
                ppc_types: filters.ppc_types,
                from_date: toIsoStringHelper(new Date(filters.from_date)),
                to_date: toIsoStringHelper(moment(filters?.to_date).endOf('day').toDate()),
                sort_by: sortByCategory,
                sort_order: sortFromMax ? "ASC" : "DESC"
            }

            if (searchText.trim() !== "") {
                dataRequest.query = searchText.replace(/\s+/g, '')
            }


            await Statistic.getTransactions(companyId, dataRequest)
                .then(result => {
                    const hiddenColumns = []
                    const fls = filial.getFilial
                    setPagination({...result?.data?.meta, currentPage: dataRequest.page})
                    if (isProd()) {
                        hiddenColumns.push("platform")
                    }
                    if (result?.data?.result.length === 0) {
                        hiddenColumns.push("branch_id")
                    }
                    setHiddenColumns(hiddenColumns)
                    let data = result?.data?.result || []
                    if (!isDemo()) {
                        data.forEach((item: any) => {
                            item.branch_id = fls.find(value => value.id === item.branch_id).name
                        })
                    }
                    if (data.length > 0) {
                        data.map((item: any, index: number) => {
                            if (dataRequest.page && dataRequest.page > 2) {
                                item['num'] = ((dataRequest.page - 1) * 20) + index + 1
                            } else if (dataRequest.page == 2) {
                                item['num'] = 21 + index
                            } else {
                                item['num'] = index + 1
                            }
                            return item
                        })
                    }
                    setData(data)

                    setGeneralDataRequest({
                        cashboxes: cashboxes,
                        statuses: filters.statuses,
                        ppc_types: filters.ppc_types,
                        from_date: toIsoStringHelper(new Date(filters.from_date)),
                        to_date: toIsoStringHelper(moment(filters?.to_date).endOf('day').toDate()),
                    })
                }).catch(err => {
                    if (err?.response) {
                        setError({
                            status: err?.response?.status,
                            message: err?.response?.data?.message,
                            isError: true
                        })
                    }
                }).finally(() => {
                    onExportData({
                        cashboxes: cashboxes,
                        statuses: filters.statuses,
                        ppc_types: filters.ppc_types,
                        from_date: filters.from_date,
                        to_date: filters.to_date,
                        page: params.pagination?.currentPage ?? 1,
                        count: 20,
                        sort_by: sortByCategory,
                        sort_order: sortFromMax ? "ASC" : "DESC"
                    })
                    setIsLoading(false)
                })

            setIsLoading(false)
        }

        setIsLoading(false)
    }, [searchText])

    useEffect(() => {
        if (debouncedSearchTerm !== undefined && searchText !== undefined && cashboxes.length && searchText.length > 3 && debouncedSearchTerm.length > 3) {
            const pagin = {...pagination, page: 1}
            getTransactionById({pagin})
        }
    }, [debouncedSearchTerm, cashboxes])

    const getTransaction = async (params: any = {}) => {

        if (companyId) {
            const dataRequest: IStatistic = {
                cashboxes: cashboxes,
                statuses: filters.statuses,
                ppc_types: filters.ppc_types,
                from_date: toIsoStringHelper(new Date(filters.from_date)),
                to_date: toIsoStringHelper(moment(filters?.to_date).endOf('day').toDate()),
                page: params.pagination?.currentPage ?? 1,
                count: 20,
                sort_by: sortByCategory,
                sort_order: sortFromMax ? "ASC" : "DESC"
            }

            if (searchText.trim() !== "") {
                dataRequest.query = searchText.replace(/\s+/g, '')
            }

            Statistic.getTransactions(companyId, dataRequest)
                .then(response => {
                    const hiddenColumns = []
                    const fls = filial.getFilial
                    setPagination({...response?.data?.meta, currentPage: dataRequest.page})
                    if (isProd()) {
                        hiddenColumns.push("platform")
                    }
                    if (response?.data?.result.length === 0) {
                        hiddenColumns.push("cashbox_id")
                    }
                    setHiddenColumns(hiddenColumns)
                    let data = response?.data?.result || []
                    if (!isDemo()) {
                        data.forEach((item: any) => {
                            item.cashbox_id = fls.find(value => value.id === item.cashbox_id).name
                        })
                    }
                    if (data.length > 0) {
                        data.map((item: any, index: number) => {
                            if (dataRequest.page && dataRequest.page > 2) {
                                item['num'] = ((dataRequest.page - 1) * 20) + index + 1
                            } else if (dataRequest.page == 2) {
                                item['num'] = 21 + index
                            } else {
                                item['num'] = index + 1
                            }
                            return item
                        })
                    }
                    setData(data)

                    setGeneralDataRequest({
                        cashboxes: cashboxes,
                        statuses: filters.statuses,
                        ppc_types: filters.ppc_types,
                        from_date: toIsoStringHelper(new Date(filters.from_date)),
                        to_date: toIsoStringHelper(moment(filters?.to_date).endOf('day').toDate()),
                    })
                }).catch(err => {
                if (err?.response) {
                    setError({
                        status: err?.response?.status,
                        message: err?.response?.data?.message,
                        isError: true
                    })
                }
            }).finally(() => {
                onExportData({
                    cashboxes: cashboxes,
                    statuses: filters.statuses,
                    ppc_types: filters.ppc_types,
                    from_date: toIsoStringHelper(new Date(filters.from_date)),
                    to_date: toIsoStringHelper(moment(filters?.to_date).endOf('day').toDate()),
                    page: params.pagination?.currentPage ?? 1,
                    count: 20,
                    sort_by: sortByCategory,
                    sort_order: sortFromMax ? "ASC" : "DESC"
                })
                setLoaded(true)
                setIsLoading(false)
            })

        }
    }


    function replaceFormat(date: any, formatFrom: string, formatTo: string) {
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }


    if (!loaded) return <UsersListLoading/>


    return (
        <KTCardBody className='py-4'>
            <div className='toolbar toolbar-filter justify-content-end px-5 align-items-center'>
                <div className='filter_buttons_group'>
                    <button
                        disabled={isDemo()}
                        className={`btn btn-sm btn-active-light-primary ${filtersDay === 'day' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (filtersDay !== 'day') {
                                setFilters((prevState: any) => {
                                    const fromDate = toIsoStringHelper(moment().startOf('day').toDate())
                                    // const toDate = toIsoStringHelper(new Date(new Date().setTime(new Date().getTime() + (new Date().getTimezoneOffset() * 60000) + (3600000 * 24) - 1000)))
                                    const toDate = toIsoStringHelper(moment().endOf('day').toDate())
                                    const val = {...prevState, from_date: fromDate, to_date: toDate}
                                    // return {...filters, from_date: dates[0], to_date: dates[1]}
                                    return val
                                })
                                setFiltersDay('day')
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_day'})}
                    </button>
                    <button
                        disabled={isDemo()}
                        className={`btn btn-sm btn-active-light-primary ${filtersDay === 'week' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (filtersDay !== 'week') {
                                setFilters((prevState: any) => {
                                    const fromDate = toIsoStringHelper(moment().subtract(1, 'week').startOf('day').toDate())
                                    // const toDate = toIsoStringHelper(new Date(new Date().setTime(new Date().getTime() + (new Date().getTimezoneOffset() * 60000) + (3600000 * 24) - 1000)))
                                    const toDate = toIsoStringHelper(moment().endOf('day').toDate())
                                    const val = {...prevState, from_date: fromDate, to_date: toDate}
                                    // return {...filters, from_date: dates[0], to_date: dates[1]}
                                    return val
                                })
                                setFiltersDay('week')
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_week'})}
                    </button>
                    <button

                        className={`btn btn-sm btn-active-light-primary ${filtersDay === 'month' ? 'btn-active' : 'fw-normal'}`}
                        onClick={() => {
                            if (filtersDay !== 'month') {
                                setFilters((prevState: any) => {
                                    // const fromDate = toIsoStringHelper(new Date(new Date().setDate(new Date().getDate() - 7)))
                                    const fromDate = toIsoStringHelper(subtractMonths(moment().startOf('day').toDate(), 1))
                                    // const toDate = toIsoStringHelper(new Date(new Date().setTime(new Date().getTime() + (new Date().getTimezoneOffset() * 60000) + (3600000 * 24) - 1000)))
                                    const toDate = toIsoStringHelper(moment().endOf('day').toDate())
                                    const val = {...prevState, from_date: fromDate, to_date: toDate}
                                    // return {...filters, from_date: dates[0], to_date: dates[1]}
                                    return val
                                })

                                setFiltersDay('month')
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_month'})}
                    </button>
                </div>
                <div className="mx-4" style={{backgroundColor: "rgba(235, 237, 242, 1)", width: "1px", height: "40%"}}/>
                <div>
                    <DatePicker.RangePicker
                        style={{border: "none"}}
                        separator={<MinusOutlined/>}
                        locale={lang === 'ru' ? localeRu : localeEn}
                        placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                        key={`${filters.from_date}-${filters.to_date}`}
                        className='datepicker_form form-control-lg form-control-solid'
                        defaultValue={[replaceFormat(filters.from_date, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                            replaceFormat(filters.to_date, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                        format='DD-MM-YYYY'
                        disabledDate={current => {
                            return isDemo() || current && current > moment().endOf('day');
                        }}
                        //@ts-ignore
                        onChange={(dates: [any, any], dateStrings: [string, string]) => {
                            if (dates) {
                                setPagination(null)
                                setFilters((prevState: any) => {
                                    const fromDate = toIsoStringHelper(new Date(replaceFormat(dates[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format()))
                                    // const toDate = toIsoStringHelper(new Date(new Date(dates[1]).setTime(new Date(dates[1]).getTime() + (new Date().getTimezoneOffset() * 60000) + (3600000 * 24) - 1000)))
                                    const toDate = toIsoStringHelper(replaceFormat(dates[1], 'DD-MM-YYYY', 'YYYY-MM-DD').endOf('day').toDate())
                                    const val = {...prevState, from_date: fromDate, to_date: toDate}
                                    // return {...filters, from_date: dates[0], to_date: dates[1]}
                                    return val
                                })

                                setFiltersDay('')

                            }

                        }}
                    />
                </div>
            </div>

            <div className='table-responsive'>
                <div className='filter_buttons_group my-4'>

                    <a
                        href='#'
                        className='badge bg-light bg-active-primary fw-medium text-primary px-4 py-2'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                        onClick={() => setShowFilter(!showFilter)}
                    >
                        <KTSVG
                            path='/media/icons/duotune/general/gen031.svg'
                            className='svg-icon-5 text-primary me-1'
                        />
                        {filtersCount > 1 ? "Применено" : "Применен"} {filtersCount} {filtersCount > 1 ? "фильтра" : filtersCount === 1 ? "фильтр" : "фильтров"}
                    </a>

                    <Modal size="sm" show={showFilter} onHide={() => setShowFilter(false)}>
                        <FilterDropDown
                            show={showFilter} filters={filters} updateFilter={(val: any) => {
                            setShowFilter(false)
                            setPagination(null)
                            let count = 1
                            if (val.ppc_types.length > 0) count++
                            if (val.statuses.length > 0) count++
                            setFiltersCount(count)
                            setFilters(val)
                        }} handleClose={() => setShowFilter(false)}/>
                    </Modal>

                    {!isProd() && <span className="badge px-4 py-2 ms-4"
                                        style={{border: "1px solid #F5F8FA", color: "rgba(76, 76, 102, 1)"}}><span
                        style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'PLATFORMS'})}: &nbsp;</span> {
                        <ArrayToSpanFormatter items={statuses_platforms.map(item => item.toUpperCase())}/>}</span>}
                    {filters.statuses.length > 0 && <span className="badge px-4 py-2 ms-4"
                                                          style={{
                                                              border: "1px solid #F5F8FA",
                                                              color: "rgba(76, 76, 102, 1)"
                                                          }}><span
                        style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'STATUS'})}: &nbsp;</span>
                        {<ArrayToSpanFormatter
                            items={statuses_const.every((item: any) => filters.statuses.includes(item.toUpperCase())) ? [intl.formatMessage({id: "all"})] : filters.statuses.map((item: any) => intl.formatMessage({id: item.toUpperCase()}))}/>}</span>}
                    {filters.ppc_types.length > 0 && <span className="badge px-4 py-2 ms-4"
                                                           style={{
                                                               border: "1px solid #F5F8FA",
                                                               color: "rgba(76, 76, 102, 1)"
                                                           }}><span
                        style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'Cards'})}: &nbsp;</span>
                        {<ArrayToSpanFormatter
                            items={filters.ppc_types.map((item: any) => intl.formatMessage({id: item.toUpperCase()}))}/>}</span>}

                </div>
                <table
                    id='kt_table_users'
                    className='table fw-normal align-middle table-row-bordered fs-6 gy-5 dataTable no-footer table-hover cursor-pointer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<ModSession>) => {
                                if (column.id === "branch_id" && cashboxes.length <= 1) return
                                if (isProd() && column.id === "platform") return
                                return (
                                    <CustomHeaderColumn key={column.id} column={column}/>
                                )
                            }
                        )}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-normal' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<ModSession>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`}
                                              onClick={() => getTransactionFiscalChequeData(row.original.id.toString())}/>
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: 'NO_MATCHING'})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {!isLoading && pagination ?
                <Pagination withInfo={true} position='end' infoText='Транзакций' pagination={pagination}
                            updatePage={(val: any) => {
                                getTransaction({pagination: val})
                            }}/> : ''}
            {isLoading && <UsersListLoading/>}

            <TransactionFiscalDataModal onCancel={() => setTransactionFiscalDataModalShow(false)}
                                        shouldShow={transactionFiscalDataModalShow}
                                        data={transactionFiscalData}/>
        </KTCardBody>
    )
})

type ArrayToSpanFormatter = {
    items: Array<string>
}

const ArrayToSpanFormatter = ({items}: ArrayToSpanFormatter) => {
    let data
    if (items.length > 3) {
        const newItems = items.slice(0, 3)
        data = newItems.join(", ") + ` и еще + ${items.length - newItems.length}`
    } else {
        data = items.join(", ")
    }
    return <span>{data}</span>
}

type TransactionFiscalDataModal = {
    onCancel: Function
    shouldShow: boolean
    data: any
}

const TransactionFiscalDataModal: FC<TransactionFiscalDataModal> = ({shouldShow, onCancel, data}) => {
    const intl = useIntl()

    const handleClose = () => {
        onCancel()
    }

    return <Modal show={shouldShow} onHide={handleClose}>
        <Modal.Header closeButton>
            <h2>{intl.formatMessage({id: 'TRANSACTION_DATA'})}</h2>
        </Modal.Header>
        <Modal.Body>
            {
                (data || []).map((item: any) => {
                    return (
                        <div key={item.transaction_id} className='mt-3'>
                            <div className="mb-3 w-100">
                                <div className='h2'>{intl.formatMessage({id: 'TRANSACTION_ID'})}:</div>
                                <div className="ms-4 fw-semibold fs-4">{item.transaction_id}</div>
                            </div>
                            <div className="mb-3 w-100">
                                <div className='h2'>{intl.formatMessage({id: 'FISCAL_SIGN'})}:</div>
                                <div className="ms-4 fw-semibold fs-4">{item.fiscal_sign}</div>
                            </div>
                            <div className="mb-3 w-100">
                                <div className='h2'>{intl.formatMessage({id: 'TERMINAL_ID'})}:</div>
                                <div className="ms-4 fw-semibold fs-4">{item.terminal_id}</div>
                            </div>
                            <div className="w-100 d-flex justify-content-center flex-column mt-8">
                                {/*<div className='h2'>{intl.formatMessage({id: 'QR_CODE_URL'})}:</div>*/}
                                {/*<img*/}
                                {/*    src={data.qr_code_url}*/}
                                {/*    alt="QR-CODE" className="w-100 text-center"/>*/}
                                <a href={item.qr_code_url} target='_blank'
                                   className="w-100 text-break text-center">{intl.formatMessage({id: 'QR_CODE_URL'})}</a>
                            </div>
                        </div>
                    )
                })
            }

        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
            <button type="button" className="swal2-confirm btn btn-secondary" aria-label=""
                    style={{display: 'inline-block'}} onClick={handleClose}>
                OK
            </button>
        </Modal.Footer>
    </Modal>
}

export {Table}
