import * as Yup from 'yup'
import {CreateCashbox} from "@providers/filial/_models";
import {useIntl} from "react-intl";
import {FILE_SIZE, SUPPORTED_FORMATS_IMAGES} from "@utils/constants";


function FilialSettingsSchema() {

    const intl = useIntl()

    const createFilialSettingsSchema = Yup.object({
        logo_file: Yup.mixed().test('fileSize', "File is too large", value => {
            if (value !== null && typeof value === 'object') {
                const size_kb = +(value.size / 1024).toFixed(1)
                return size_kb <= FILE_SIZE
            }
            return true

        }).test('fileType', "Your Error Message", value => {
            if (typeof value === 'object') {
                return value ? SUPPORTED_FORMATS_IMAGES.includes(value.type) : true
            }
            return true
        }),
        name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
            .max(50, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'})).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        nds_rate: Yup.number().min(0, intl.formatMessage({id: 'minNdsError'})).max(99, intl.formatMessage({id: 'maxNdsError'})),
        max_amount: Yup.string()
            .max(10, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'}))
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        min_amount: Yup.string()
            .max(10, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'}))
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        response_fields: Yup.array()
            .of(
                Yup.object().shape({
                    field_name: Yup.string(),
                })
            ),
        request_fields: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string()
                })
            ),
        gnk_spic: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
    })

    return createFilialSettingsSchema

}


const inits: CreateCashbox = {

    name: '',
    legal_name: '',
    logo_file: undefined,
    eposes: [],
    url: '',
    gnk_spic: '',
    min_amount: 0,
    max_amount: 0
}


export {FilialSettingsSchema, inits}