import {Table} from "./_modals/Table";
import {KTCard} from "@metronic/helpers";
import {UsersListHeader} from "./_modals/table-components/header/UsersListHeader";
import {TableHeader} from "./_modals/table-components/header/TableHeader";
import React, {useState} from "react";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {roleRoutingAdmin} from "@utils/index";
import {AdminTable} from "./_modals/AdminTable";
import {PageLink, PageTitle} from "@metronic/layout/core";
import {useIntl} from "react-intl";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {Navigate} from "react-router-dom";
import {ListViewProvider} from "./_modals/providers/ListViewProvider";


const CompaniesPage = () => {

  return (
      <>

            <KTCard>
                <UsersListHeader />
                <Table />
            </KTCard>

        </>
  )
}


const AdminCompaniesPage = () => {


    return (
        <>
            <UsersListHeader />
            <KTCard>
                <AdminTable />
            </KTCard>

        </>
    )
}



const ProductsListWrapper = observer(() => {
    const role = employeeStore.getRole
    const intl = useIntl()

    if(role==='AGENT'){
        return <Navigate to='/agent/statistic' />
    }
    return(
        <ListViewProvider>
            <ErrorProvider>
                <PageTitleCustom title={intl.formatMessage({id:'COMPANIES'})} breadCrumbs={[]}/>
                {roleRoutingAdmin('action', role, 'is-admin')?<AdminCompaniesPage />:<CompaniesPage />}
            </ErrorProvider>
        </ListViewProvider>
    )
})

export {ProductsListWrapper}



