import {instantse} from "@providers/request";
import {AccountPut} from "@providers/account/_models";


export const Account = {
    getMe() {
        return instantse.get(`/v1/account/me`)
            .then((response) => response)
    },
    putMe(values: AccountPut) {
        return instantse.put('/v1/account', values)
            .then((response) => response)
    },
    putPassword(password: string, token?: string) {
        return instantse.put('/v1/account/password', {password: password}, {headers: token ? {"Authorization": `Bearer ${token}`} : {}})
            .then((response) => response)
    }
}