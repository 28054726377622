import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {Step2} from './steps/Step2'
import {StepperComponent} from '@metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'

import {FilialCreateSchema, inits} from './CreateFilialWizardHelper'
import Alert from 'react-bootstrap/Alert';
import {useNavigate, useParams} from "react-router-dom";
import {CreateCashbox} from "@providers/filial/_models";
import {Filial} from "@providers/filial/_request";
import {Company} from "@providers/company/_request";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {Step3} from "./steps/Step3";
import {isDemo, localErrorsMessage} from "@utils/index";
import {Tooltip} from "antd";
import {multiplyByHundred} from "@utils/numbersOperations";


const Vertical: FC = () => {

    const filialCreateSchema = FilialCreateSchema()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(filialCreateSchema[0])
    const {setError, error} = useErrors()
    const [initValues, setInitValues] = useState<CreateCashbox>(inits)
    const [alert, setAlert] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {companyId} = useParams()
    const [type, setType] = useState([])
    const [fields, setFields] = useState([])
    const intl = useIntl()
    const [selfEmployee, setSelfEmployee] = useState(false)


    const setFilialInitValues = async () => {
        const {data: {result}} = await Company.getById(companyId ?? "0")
        setInitValues(prevState => ({
            ...prevState
        }))
    }

    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        stepper.current.goPrev()

        setCurrentSchema(filialCreateSchema[stepper.current.currentStepIndex - 1])
    }

    const submitStep = async (values: CreateCashbox, actions: FormikValues) => {
        if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) return

        if (!stepper.current) {
            return
        }

        setCurrentSchema(filialCreateSchema[stepper.current.currentStepIndex])


        if (companyId) {
            await CreateCashbox(values, actions)
        }
        setLoading(false)


    }



    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])

    useEffect(() => {
        setFilialInitValues()
    }, [])

    async function CreateCashbox(values: CreateCashbox, actions: any) {
        if (stepper.current) {
            if (stepper.current?.currentStepIndex === 2) {
                const max_amount: number = multiplyByHundred(values.max_amount!)
                const min_amount: number = multiplyByHundred(values.min_amount!)
                let values_replace: any = {
                    response_fields: values['response_fields'],
                    request_fields: values['request_fields'],
                    max_amount,
                    min_amount
                }

                let isNotAdded = ['max_amount', 'min_amount', 'logo_file']

                for (let key in values) {
                    //@ts-ignore
                    if (values[key] && values[key].length && !isNotAdded.includes(key)) {
                        //@ts-ignore
                        values_replace[key] = values[key]
                    }
                }


                const files: any = {
                    logo_file: values.logo_file
                }


                const form_data = new FormData()

                for (let val in files) {
                    if (files[val]) {
                        form_data.append(val, files[val])
                    }
                }


                let idRes: any = null
                setLoading(true)

                // await Filial.postFilial(companyId ?? '', form_data)
                await Filial.postFilial(companyId ?? '', values_replace)
                    .then(res => {
                        const {id} = res?.data?.result
                        idRes = id
                        Filial.postFilialFiles(companyId ?? "0", form_data, res.data.result.id.toString())
                    })
                    .then(() => {
                        setAlert('Касса успешна создана')
                        actions.resetForm()
                        stepper?.current?.goto(1)

                        if (idRes) {
                            setTimeout(() => {
                                navigate(`/${companyId}/cashboxes/${idRes}`)
                            }, 1000)
                        }

                    }).catch(err => {
                        let message = ''
                        if (err?.response?.data?.message === 'Validation failed') {
                            err?.response?.data?.fields.forEach((item: any) => {
                                message += `${item.name} - ${item.error}\n`
                            })
                        } else {
                            message = localErrorsMessage('post-company', 'message-match', err?.response?.data?.message, intl)
                        }
                        setError({
                            status: err?.response?.status,
                            message: localErrorsMessage('post-filial', 'message-match', message, intl),
                            isError: true
                        })
                    }).finally(() => setLoading(false))
            } else {
                if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
                    stepper.current?.goNext()
                }
            }
        }
    }


    return (
        <>
            <div
                ref={stepperRef}
                className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_create_account_stepper'
            >
                {alert && (
                    <Alert className='position-absolute' style={{left: '60%'}} key={'success'} variant={'success'}>
                        {alert}
                    </Alert>
                )}
                <div
                    className='d-flex justify-content-center bg-body rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                    <div className='px-6 px-lg-10 px-xxl-15 py-20'>
                        <div className='stepper-nav'>
                            <div className='stepper-item mb-6 current' data-kt-stepper-element='nav'>
                                <div className='stepper-line w-40px'></div>
                                <div className="d-flex align-items-center">
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>1</span>
                                    </div>

                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>{intl.formatMessage({id: "settings_business"})}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className='stepper-item mb-6' data-kt-stepper-element='nav'>
                                <div className='stepper-line w-40px'></div>
                                <div className="d-flex align-items-center">
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>2</span>
                                    </div>

                                    <div className='stepper-label'>
                                        <h3 className='stepper-title'>{intl.formatMessage({id: "cashbox_logo"})}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
                    <Formik enableReinitialize={true} validationSchema={currentSchema} initialValues={initValues}
                            onSubmit={submitStep}>
                        {(props) => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                isSubmitting,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                                setFieldValue
                            } = props

                            return (

                                <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>

                                    <div className='current' data-kt-stepper-element='content'>
                                        {/*@ts-ignore*/}
                                        <Step2
                                            error={error}
                                            values={values}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            type={type}
                                            setSelfEmployee={setSelfEmployee}
                                            selfEmployee={selfEmployee}
                                        />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        {/*@ts-ignore*/}
                                        <Step3 handleChange={handleChange} setFieldValue={setFieldValue}
                                               values={values}/>
                                    </div>
                                    <div className='d-flex flex-stack pt-10'>
                                        <div className='mr-2'>
                                            <button
                                                onClick={prevStep}
                                                type='button'
                                                className='btn btn-lg btn-light-primary me-3'
                                                data-kt-stepper-action='previous'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr063.svg'
                                                    className='svg-icon-4 me-1'
                                                />
                                                {intl.formatMessage({id: "BACK"})}
                                            </button>
                                        </div>

                                        <Tooltip
                                            zIndex={(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) ? 1 : -1}
                                            color='var(--kt-body-bg)' title={() => <span
                                            style={{color: 'var(--kt-text-gray-600)'}}>{(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) ? intl.formatMessage({id: "DEMO_DISABLED"}) : ""}</span>}>
                                            <div>
                                                <button onClick={() => {

                                                    if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) return

                                                    let err_message = ''

                                                    if (Object.keys(errors).length) {
                                                        for (let err in errors) {
                                                            //@ts-ignore
                                                            err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                        }
                                                        setError({status: '', message: err_message, isError: true})
                                                    }

                                                }} type='submit' className='btn btn-lg btn-primary me-3'
                                                        disabled={loading || (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo())}>
                                                    <span className='indicator-label'>
                                                      {stepper.current?.currentStepIndex ===
                                                          stepper.current?.totatStepsNumber && intl.formatMessage({id: "CREATE"})}
                                                        {stepper.current?.currentStepIndex !==
                                                            stepper.current?.totatStepsNumber && intl.formatMessage({id: "THEN"})}
                                                        <KTSVG
                                                            path='/media/icons/duotune/arrows/arr064.svg'
                                                            className='svg-icon-3 ms-2 me-0'
                                                        />
                                                    </span>
                                                </button>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </>
    )
}

export {Vertical}
