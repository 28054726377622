import {
    ACCOUNTANT,
    ADMIN,
    ANALYST,
    CASHIER,
    CEO,
    DEMO_URL,
    DEV_URL,
    JUNIOR_CASHIER, MODERATOR,
    OWNER,
    PROD_URL, SUPER_ADMIN
} from "@utils/constants";
import {Company} from "@providers/company/_request";



export const showToolBar = (path:string)=>{

    let new_path = path.replace(/(\/[0-9]+.)|(\/[0-9]+.\/)/, '/:id/')

    const path_accept = {
        '/dashboard':true,
        '/payments/transactions':true,
        '/companies/:id/':true,
        '/:id/dashboard-merchant':true,
        '/:id/payments/transactions':true,
        '/agent/statistic':true,
        '/agent/merchant':true,
        '/agents/individual':true,
        '/agents/company':true,
        '/agents/statistic':true,
        '/agents/view/:id/':true,
        '/:id/autopay/contracts/list/all':true,
        '/:id/autopay/contracts/list/archive':true,
    }
    //@ts-ignore
    return path_accept[new_path]
}


export const NumReplace = (n: string) => {
    let parts = n.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (parts[1] && parts[1].length === 1) parts[1] = `${parts[1]}0`
    return parts.join(".");
}

export const moneyFormat = (labelValue: string) => {
    // Twelve Zeroes for Trillions
    return Math.abs(Number(labelValue)) >= 1.0e+12

        ? Math.abs(Number(labelValue)) / 1.0e+12 + " трлн"
        // Nine Zeroes for Billions
        : Math.abs(Number(labelValue)) >= 1.0e+9

            ? Math.abs(Number(labelValue)) / 1.0e+9 + " млрд"
            // Six Zeroes for Millions
            : Math.abs(Number(labelValue)) >= 1.0e+6

                ? Math.abs(Number(labelValue)) / 1.0e+6 + " млн"
                // Three Zeroes for Thousands
                : Math.abs(Number(labelValue)) >= 1.0e+3

                    ? Math.abs(Number(labelValue)) / 1.0e+3 + " тыс"

                    : Math.abs(Number(labelValue));

}


export function roleRoutingAdmin(action='page', role='', type=''){

    let accept;
    if(action==='action'){
        const actions = {
            'employee-delete':[ADMIN, SUPER_ADMIN],
            'employee-edit':[ADMIN, MODERATOR, SUPER_ADMIN],
            'admin-create':[ADMIN, MODERATOR, SUPER_ADMIN],
            'admin-delete':[SUPER_ADMIN],
            'agent-delete':[SUPER_ADMIN],
            'admin-edit':[ADMIN, SUPER_ADMIN],
            'admin-logs':[ADMIN, SUPER_ADMIN],
            'is-admin':[ADMIN, SUPER_ADMIN, MODERATOR],
            'delete-company':[SUPER_ADMIN],
            'delete-filial':[SUPER_ADMIN],
            'employee-logs':[ADMIN,SUPER_ADMIN],
            'change-status':[SUPER_ADMIN,ADMIN],
            'revert-payments':[SUPER_ADMIN],
            'change-commission':[SUPER_ADMIN],
            'link-merchant':[SUPER_ADMIN, ADMIN],
            'link-agent':[SUPER_ADMIN, ADMIN],
            'edit-nds-rate': [SUPER_ADMIN, ADMIN],
            'edit-eposes': [SUPER_ADMIN, ADMIN]
        }

        //@ts-ignore
        accept = actions[type]?!!actions[type].find(item=>item===role):false


    }else{
        const pages = {
            'companies':[ADMIN, MODERATOR, SUPER_ADMIN],
            'company-view':[ADMIN, MODERATOR, SUPER_ADMIN],
            'employee':[ADMIN, SUPER_ADMIN],
            'settings-merchant':[ADMIN, MODERATOR, SUPER_ADMIN],
            'masterlayout':[ADMIN, MODERATOR, SUPER_ADMIN],
            'filial-form':[ADMIN, MODERATOR, SUPER_ADMIN],
            'filial/employee':[ADMIN, MODERATOR, SUPER_ADMIN],
            'filial/payments':[ADMIN, MODERATOR, SUPER_ADMIN],
            'filial/settings':[ADMIN, MODERATOR, SUPER_ADMIN],
            'filial/statistic':[ADMIN, MODERATOR, SUPER_ADMIN],
            'payments':[ADMIN, MODERATOR, SUPER_ADMIN],
            'dashboard':[ADMIN, MODERATOR, SUPER_ADMIN],
            'employees-view':[ADMIN, MODERATOR, SUPER_ADMIN],
        }

        //@ts-ignore
        accept = pages[type]?!!pages[type].find(item=>item===role):false
    }

    return accept
}

export function roleRouting(path: string, role: string) {

    const arr = {
        'companies': [JUNIOR_CASHIER, OWNER, CEO, ACCOUNTANT, CASHIER, ANALYST ],
        'companies-list/action': [OWNER],
        'company/add': [OWNER,CEO, JUNIOR_CASHIER, ANALYST, ACCOUNTANT, CASHIER],
        'settings-merchant': [OWNER, CEO],
        'filial/employee': [OWNER, CEO, ADMIN, MODERATOR, SUPER_ADMIN],
        'filial/payments': [OWNER, CEO, ACCOUNTANT, CASHIER, ANALYST, JUNIOR_CASHIER],
        'filial/statistic': [OWNER, CEO, ACCOUNTANT, ANALYST, CASHIER, JUNIOR_CASHIER],
        'filial/settings': [OWNER, CEO],
        'filial': [JUNIOR_CASHIER, ANALYST, CASHIER, ACCOUNTANT, OWNER, CEO],
        'filial/create': [OWNER, CEO],
        'filial/delete': [OWNER],
        'filial/edit/:id': [OWNER, CEO],
        'dashboard-merchant': [ANALYST, CASHIER, ACCOUNTANT, OWNER, CEO, JUNIOR_CASHIER],
        'dashboard': [ADMIN, MODERATOR, SUPER_ADMIN],
        'employees': [OWNER, CEO, ADMIN, MODERATOR, SUPER_ADMIN, ACCOUNTANT],
        'employees/view': [OWNER, CEO, ACCOUNTANT],
        'employees/edit': [OWNER, CEO, ACCOUNTANT],
        'employees/delete': [OWNER, CEO, ACCOUNTANT],
        'employee/logs': [ANALYST, OWNER, CEO, ACCOUNTANT],
        'analytics': [ANALYST, CASHIER, ACCOUNTANT, OWNER, CEO, JUNIOR_CASHIER],
        'payments': [ANALYST, CASHIER, ACCOUNTANT, OWNER, CEO, JUNIOR_CASHIER],
        'reports': [JUNIOR_CASHIER, OWNER, CEO, ACCOUNTANT, CASHIER, ANALYST],
        'autopay': [OWNER, CEO],
        'services': [OWNER, CEO, ANALYST],
        'statistic-agent': ['AGENT'],
        'merchants-agent': ['AGENT'],
        'revert-payments':[OWNER, CEO],
    }

    //@ts-ignore
    if (!arr[path]) return null
    //@ts-ignore
    return arr[path].find((item: any) => item === role)
}

export const isDemo = () => process.env.REACT_APP_PUBLIC_URL === DEMO_URL
export const isProd = () => process.env.REACT_APP_PUBLIC_URL === PROD_URL
export const isDev = () => process.env.REACT_APP_PUBLIC_URL === DEV_URL

export function filterObject(obj: Object, callback: Function) {
    return Object.fromEntries(Object.entries(obj).filter(([key, val]) => callback(val, key)));
}

export function localErrorsMessage(pathname='', byType='CODE_REQUEST', message='', intl:any){
    switch(pathname){
        case 'post-company':
            if(byType==='message-match'){
                if(message.match('legal name')){
                    return intl.formatMessage({id:'legal-name-error'})
                }else if(message.match('Company with this name already exists')){
                    return intl.formatMessage({id:'company-name-exist'})
                }
            }
            break
        case 'post-employee':
            if(byType==='message-match'){
                if(message.match('User already added')){
                    return intl.formatMessage({id:'ERROR_EMPLOYEE1'})
                }
            }
            break
        case 'post-moderator':
            if(byType==='message-match'){
                if(message.match('GS employee exists')){
                    return intl.formatMessage({id:'ERROR_EMPLOYEE2'})
                }
            }
            break
        case 'post-agent':
            if(byType==='message-match'){
                if(message.match('User already has another role')){
                    return intl.formatMessage({id:'user_has_role'})
                }
            }
            break
        case 'post-filial':
            if(byType==='message-match'){
                if(message.match('min_amount cannot be greater than max_amount')){
                    return intl.formatMessage({id:'msg_error_filial'})
                }else if(message.match('Branch with this name')){
                    return intl.formatMessage({id:'msg_error_filial2'})
                }
            }
            break
        case 'change-status':
            if(byType==='message-match'){
                if(message.match('Company needs to be active before this action')){
                    return intl.formatMessage({id:'company_needs_active'})
                }
            }
            break
    }

    return message
}


export function ReplacePhone(phone:string){
    return phone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3 $4 $5')
}

export const ConvertToBase64 = (image:File)=>new Promise((resolve, reject)=>{
    const reader = new FileReader()
    reader.readAsDataURL(image)
    reader.onload = function(){
        resolve(reader.result)
    }
    reader.onerror = function (err){
        reject(err)
    }
})


export function readJWTToken(token:string){
    if(token){
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
    return ''

}

