import clsx from "clsx";
import React, {FC, useEffect, useMemo, useState} from "react";


type PaginationProps = {
    pagination: any,
    updatePage: (val: any) => void,
    position?: string,
    withInfo?: boolean,
    infoText?: string,
}

const Pagination: FC<PaginationProps> = ({pagination, updatePage, infoText='', position='center', withInfo=false}) => {
    const [totalPagesCount, setTotalPagesCount] = useState<number>(pagination?.pages)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [pages, setPages] = useState<Array<string>>([])
    const size = useMemo(()=>pagination?.pageSize||20, [pagination])

    const pageUpdate = (page: number | null) => {
        if (!page || pagination.currentPage === page) {
            return
        }

        updatePage({...pagination, currentPage: page})

    }

    useEffect(() => {
        setTotalPagesCount(pagination.pages)
    }, [pagination])


    useEffect(() => {
        let pagesArr: Array<string> = [];
        if (totalPagesCount > 10) {
            if (currentPage > 6) {
                pagesArr.push("1", "...");
                for (let i = currentPage - 4; i <= currentPage + 5; i++) {
                    pagesArr.push(i.toString());
                    if (i === currentPage + 5) pagesArr.push("...", totalPagesCount.toString());
                    if (i === totalPagesCount) break;
                }
            } else {
                for (let i = 1; i <= 10; i++) {
                    pagesArr.push(i.toString());
                    if (i === 10) pagesArr.push("...", totalPagesCount.toString());
                    if (i === totalPagesCount) break;
                }
            }
        } else {
            for (let i = 1; i <= totalPagesCount; i++) {
                pagesArr.push(i.toString());
            }
        }

        setPages(pagesArr)
    }, [pagination, totalPagesCount])

    function positionPagination(){
        if(position==='center'){
            return 'justify-content-center'
        }else if(position==='end'&&!withInfo){
            return 'justify-content-end'
        }else if(position==='end'&&withInfo){
            return 'justify-content-between'
        }else if(position==='start'){
            return 'justify-content-start'
        }
    }




    return (
        <div className='row mt-4'>
            {/*<div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>*/}
            {/*<div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>*/}
            <div className={`d-flex align-items-center ${positionPagination()}`}>

                {
                    withInfo?(<>
                        <span className='fs-6 fw-semibold text-gray-700'>Показаны от {pagination.currentPage*size-(size-1)} до {pagination.last&&pagination.first?pagination?.items:(pagination?.items-(pagination.currentPage*size))>0?pagination.currentPage*size:pagination.currentPage*size+(pagination?.items-(pagination.currentPage*size))} {infoText} из {pagination?.items}</span>
                    </>):''
                }

                <div id='kt_table_users_paginate'>

                    <ul className="pagination mb-4">
                        {pages.map((p, index) => (
                            <li key={index} className={clsx('page-item', {
                                active: (pagination.currentPage||'').toString() === p
                            })}>
                                <a className={clsx('page-link')}
                                   style={{cursor: 'pointer'}}
                                   onClick={async () => {
                                       if (currentPage.toString() === p) return
                                       pageUpdate(Number(p))
                                       setCurrentPage(Number(p))
                                   }}
                                >
                                    {p}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export {Pagination}