/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '@metronic/assets/ts/components'
import { KTSVG} from '@metronic/helpers'
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import {useListView} from "../providers/ListViewProvider";
import {confirmAlert} from "react-confirm-alert";
import {roleRoutingAdmin} from "@utils/index";
import {Admins} from "@providers/admins/_request";
import {Employee} from "@providers/employee/_request";
import {employeeStore} from "@store/employee";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {Agent} from "@providers/agents/_request";

type Props = {
  id: number
}


const UserActionsCell:FC<Props> = observer(({id}) => {

    const {setItemIdForUpdate, updateData} = useListView()
    const {setError} = useErrors()
    const intl = useIntl()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    function deleteAgent(){
        confirmAlert({
            title: intl.formatMessage({id: 'WARNING'}),
            message: intl.formatMessage({id:'WARNING_DELETE_AGENT'}),
            buttons: [
                {
                    label: intl.formatMessage({id: 'ACTIONS_YES'}),
                    onClick: async () => {
                        await Agent.deleteAgent(id)
                            .then(()=>{
                                updateData()
                            }).catch(err=>{
                                setError({status: err?.response?.status, message: err?.response?.data?.code === "METHOD_NOT_ALLOWED"?intl.formatMessage({id: 'not_employee'}):err?.response?.data?.message, isError: true})
                            })

                    }
                },
                {
                    label: intl.formatMessage({id: 'ACTIONS_NO'}),
                    onClick: async () => {
                        return
                    }
                }
            ]
        })
    }




    return (
        <div className="d-flex justify-content-end">

            <div className='menu-item me-3 btn btn-light btn-icon'>
                <a href='/' onClick={(e)=>{
                    e.preventDefault()
                    console.log(id)
                    setItemIdForUpdate(id)
                }} className='menu-link px-3 menu-link-icon text-hover-primary'>
                    <KTSVG path="/media/svg/edit-icon.svg" className="svg-icon-muted svg-icon-2" />
                </a>
            </div>


            {/* begin::Menu item */}
            <div className='menu-item menu-link-icon btn btn-light btn-icon'>
                <Link to='' className='menu-link px-3 text-hover-danger' onClick={(e)=>{
                    e.preventDefault()
                    deleteAgent()
                }}>
                    <KTSVG path='/media/icons/duotune/general/gen027.svg'
                           className="svg-icon-2 svg_icon_delete"
                    />
                </Link>
            </div>
            {/* end::Menu item */}
        </div>
    )
})

export {UserActionsCell}
