import {Navigate, useOutletContext, useParams} from "react-router-dom";
import {PageLink, PageTitle} from "@metronic/layout/core";
import {Nav} from "react-bootstrap";
import React, {useEffect, useMemo, useState} from "react";

//Components
import {StatisticModule} from "../statistic";
import EmployeesMerchant from "../employee/employees";
import PaymentsMerchant from "../payments/payments";
import FilialSettingsMerchant from "../settings/settings";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {NumReplace, ReplacePhone, roleRouting, roleRoutingAdmin} from "@utils/index";
import {useIntl} from "react-intl";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {KTCard, KTSVG} from "@metronic/helpers";
import {Company} from "@providers/company/_request";
import {Dropdown, Menu, Tooltip} from "antd";
import {CompanyResponseOne} from "@providers/company/_models";
import {Statistic} from "@providers/statistic/_request";
import {Filial} from "@providers/filial/_request";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {filial} from "@store/filial";
import {statuses_card, statuses_const} from "@utils/constants";
import moment from "moment/moment";





const classStatus: any = {
    DRAFT: 'secondary',
    ACTIVE: 'light-success',
    DISABLED: 'light-danger',
}

const iconsStatus = {
    DRAFT: "/media/svg/failed.svg",
    ACTIVE: "/media/svg/approved.svg",
    DISABLED: "/media/svg/revert.svg",
}


const FilialView = observer(() => {
    const [activeKey, setActiveKey] = useState<string | null>('statistics')
    const {id, companyId} = useParams()
    const intl = useIntl()
    const companyData: any = useMemo(() => filial.getFilialOne, [filial.getFilialOne])
    const [companyInfo, setCompanyInfo] = useState<CompanyResponseOne | null>(null)
    const [staticticInfo, setStatisticInfo] = useState<any>({})
    const [type, setType] = useState('')
    const {setError} = useErrors()
    const [statusChecked, setStatusChecked] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [ready, setReady] = useState(false)


    const dateGeneralStatistic = {
        from_date: moment('2020-01-01').format(),
        to_date: moment().format()
    }

    const createCompanyBreadCrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id: 'cashboxes'}),
            path: `/${companyId}/cashboxes`,
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    const createCompanyBreadCrumbsAdmin: Array<PageLink> = [
        {
            title: 'Компании',
            path: `/companies`,
            isSeparator: false,
            isActive: false,
        },
        {
            title: companyInfo?.name || '',
            path: `/companies/${companyId}`,
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]


    useEffect(() => {
        if (id && companyId) {

            // getTransactionGeneral({
            //     branches: [+id],
            //     ppc_types: statuses_card.map((item:string)=>item.toUpperCase()),
            //     statuses: statuses_const.map((item:string)=>item.toUpperCase()),
            //     ...dateGeneralStatistic})
            // getFilial()
            // getInfo()
            Promise.all([getСompanyInfo(), getTransactionGeneral({
                cashboxes: [+id],
                ppc_types: statuses_card.map((item: string) => item.toUpperCase()),
                statuses: statuses_const.map((item: string) => item.toUpperCase()),
                ...dateGeneralStatistic
            }), getFilial(), getInfo()])
                .then(() => setReady(true))
        }
    }, [])

    const widgetMenu = (
        <Menu className='p-7 change_status_group'>
            <p className='text-gray-800 fw-bold'>Изменить статус</p>
            <div>

                <div className={`item_check_status d-flex align-items-center justify-content-between border border-gray-300 mb-3
               ${statusChecked === 'ACTIVE' ? 'active' : ''}`}

                     onClick={() => {
                         setStatusChecked('ACTIVE')
                     }}
                >
                    <div className='d-flex  align-items-center'>
                       <span className='me-5'>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="6" fill="#ECFDF3"/>
                            <path d="M18 11L12.5 16.5L10 14" stroke="#12B76A" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                          </svg>

                       </span>
                        <p className='fw-bold fs-6 text-gray-800 mb-0'>Активный</p>
                    </div>
                    <div>
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input"
                                   type="radio"
                                   checked={statusChecked === 'ACTIVE'}

                            />
                        </div>
                    </div>
                </div>
                <div className={`item_check_status d-flex align-items-center justify-content-between border border-gray-300 
               ${statusChecked === 'DISABLED' ? 'active' : ''}`}
                     onClick={() => {
                         setStatusChecked('DISABLED')
                     }}
                >
                    <div className='d-flex  align-items-center'>
                       <span className='me-5'>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="6" fill="#FEF3F2"/>
                            <path d="M17 11L11 17M11 11L17 17" stroke="#F04438" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                          </svg>


                       </span>
                        <p className='fw-bold fs-6 text-gray-800 mb-0'>Неактивный</p>
                    </div>
                    <div>
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input"
                                   type="radio"
                                   checked={statusChecked === 'DISABLED'}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-8'>
                <button className='btn btn-light fw-bold me-3' onClick={() => setModalIsOpen(false)}>
                    Отмена
                </button>
                <button className='btn btn-primary fw-bold' onClick={() => changeStatusSubmit()}>
                    Применить
                </button>
            </div>
        </Menu>
    );


    async function getFilial() {
        await Filial.getFilialById(companyId ?? '', id ?? '')
            .then(result => {
                setStatusChecked(result?.data?.result?.status)
                filial.setFilialOne(result?.data?.result)
            })
    }

    async function changeStatusSubmit() {
        setModalIsOpen(false)
        if (statusChecked.length) {
            await Filial.changeStatusRequest(companyId ? +companyId : 0, id ? +id : 0,
                {status: statusChecked})
                .then((result) => {
                    setStatusChecked(result?.data?.result?.status)
                    filial.setFilialOne(result?.data?.result)
                })
                .catch(err => {
                    setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
                })
        }

    }

    async function getСompanyInfo() {
        await Company.getById(companyId ?? '')
            .then(result => {
                setCompanyInfo(result?.data?.result)
            }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
    }


    async function getInfo() {
        await Company.getCompanyTypes()
            .then(result => {
                let type = result?.data?.result.find((item: any) => item.id === companyData?.type)
                setType(type ? type.name.replace(/\(.+\)/, '') : '')
            })

    }


    async function getTransactionGeneral(dataRequest: any = {}) {

        await Statistic.getStatisticsGeneral(companyId || '', dataRequest).then(result => {
            setStatisticInfo(result?.data?.result)
        }).catch(err => {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        })
    }


    const switchTabs = () => {
        switch (activeKey) {
            case 'statistics':
                return <StatisticModule/>
            case 'employee':
                return <EmployeesMerchant/>
            case 'payments':
                return <PaymentsMerchant/>
            case 'settings':
                return <FilialSettingsMerchant/>
        }
    }


    return (
        <>
            <PageTitleCustom title={companyData?.name || ''}
                             breadCrumbs={roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ? createCompanyBreadCrumbsAdmin : createCompanyBreadCrumbs}/>
            <KTCard className='mb-5'>

                <div className='card mb-5 mb-xl-10'>
                    <div className='card-body pt-9 pb-0'>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                            <div className='me-7 mb-4'>
                                <div
                                    className='p-3 d-flex flex-column justify-content-center symbol logo-project bg-secondary symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                    {
                                        ready ? <> {
                                            !companyData?.logo_file ? (<p style={{fontSize: '4rem'}}
                                                                          className='text-center mb-0 fw-bolder text-primary'>{(companyData?.name || '').split(' ').map((item: string) => item.length ? item[0].toUpperCase() : item).join('')}</p>)
                                                : (<img className='w-100 h-100' style={{objectFit: 'contain'}}
                                                        src={Company.getFiles(companyData.logo_file)} alt=''/>)
                                        }</> : ''
                                    }

                                </div>
                            </div>

                            <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <p className='text-gray-800 fs-2 fw-bolder me-1 mb-0'>
                                                {companyData?.name}
                                            </p>
                                            {/*@ts-ignore*/}
                                            <div
                                                className={`badge badge-${classStatus[companyData?.status || '']} mx-5`}>

                                                {companyData?.status ?
                                                    (

                                                        <>{roleRoutingAdmin('action', employeeStore.getRole, 'change-status')
                                                            ? (<Dropdown overlay={widgetMenu} open={modalIsOpen}>
                                                                <a onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setModalIsOpen(!modalIsOpen)
                                                                }}>
                                                                    {/*@ts-ignore*/}
                                                                    <div
                                                                        className={`badge badge-${classStatus[companyData?.status || '']} fw-bolder`}>
                                                                        {/*@ts-ignore*/}
                                                                        <KTSVG path={iconsStatus[companyData?.status]}
                                                                               className={`svg-icon-${classStatus[companyData?.status]} svg-icon-2hx me-2`}/>
                                                                        {intl.formatMessage({id: companyData?.status})}
                                                                    </div>
                                                                </a>
                                                            </Dropdown>) : (
                                                                /*@ts-ignore*/
                                                                <div
                                                                    className={`badge badge-${classStatus[companyData?.status || '']} fw-bolder`}>
                                                                    {/*@ts-ignore*/}
                                                                    <KTSVG path={iconsStatus[companyData?.status]}
                                                                           className={`svg-icon-${classStatus[companyData?.status]} svg-icon-2hx me-2`}/>
                                                                    {intl.formatMessage({id: companyData?.status})}
                                                                </div>)
                                                        }
                                                        </>
                                                    ) : ''}

                                            </div>
                                        </div>

                                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                            {companyData?.url ? <a
                                                href={companyData.url}
                                                target={'_blank'}
                                                rel={'noreferrer'}
                                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/svg/site.svg'
                                                    className='svg-icon-4 me-1'
                                                />
                                                {companyData.url}
                                            </a> : null}
                                        </div>
                                    </div>

                                </div>

                                <div className='d-flex flex-wrap flex-stack'>
                                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                                        <div className='d-flex flex-wrap'>
                                            <div
                                                className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div
                                                        className='fs-2 fw-bolder me-5'>{staticticInfo?.count || 0}</div>
                                                    <Tooltip color='var(--kt-body-bg)' placement="topLeft"
                                                             title={() => <span
                                                                 style={{color: 'var(--kt-text-gray-800)'}}>{intl.formatMessage({id: 'transactions_info_count'})}</span>}>
                                                        <i style={{fontSize: "16px"}}
                                                           className="fa-sharp fa-solid fa-circle-info"/>
                                                    </Tooltip>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Все транзакции</div>
                                            </div>

                                            <div
                                                className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div
                                                        className='fs-2 fw-bolder me-5'>{NumReplace(staticticInfo?.amount ? staticticInfo?.amount / 100 + '' : '0')} UZS
                                                    </div>
                                                    <Tooltip color='var(--kt-body-bg)' placement="topLeft"
                                                             title={() => <span
                                                                 style={{color: 'var(--kt-text-gray-800)'}}>{intl.formatMessage({id: 'amount_from_register'})}</span>}>
                                                        <i style={{fontSize: "16px"}}
                                                           className="fa-sharp fa-solid fa-circle-info"/>
                                                    </Tooltip>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Общая сумма</div>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='d-flex h-55px'>

                            <Nav fill variant="tabs"
                                 className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
                                 onSelect={(e) => setActiveKey(e)}>

                                {
                                    (roleRouting('filial/statistic', employeeStore.getRole)
                                        || roleRoutingAdmin('page', employeeStore.getRole, 'filial/statistic')) &&
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey === 'statistics' ? 'active' : ''}`}
                                            eventKey="statistics">{intl.formatMessage({id: "DASHBOARD_MERCHANT"})}</Nav.Link>
                                    </Nav.Item>
                                }

                                {
                                    (roleRouting('filial/employee', employeeStore.getRole) ||
                                        roleRoutingAdmin('page', employeeStore.getRole, 'filial/employee')) &&
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey === 'employee' ? 'active' : ''}`}
                                            eventKey="employee">{intl.formatMessage({id: "employee"})}</Nav.Link>
                                    </Nav.Item>
                                }

                                {
                                    (roleRouting('filial/payments', employeeStore.getRole) ||
                                        roleRoutingAdmin('page', employeeStore.getRole, 'filial/payments')) &&
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey === 'payments' ? 'active' : ''}`}
                                            eventKey="payments">{intl.formatMessage({id: "transactions"})}</Nav.Link>
                                    </Nav.Item>
                                }

                                {
                                    (roleRouting('filial/settings', employeeStore.getRole) ||
                                        roleRoutingAdmin('page', employeeStore.getRole, 'filial/settings')) &&
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey === 'settings' ? 'active' : ''}`}
                                            eventKey="settings">{intl.formatMessage({id: "settings"})}</Nav.Link>
                                    </Nav.Item>
                                }


                            </Nav>
                        </div>

                    </div>
                </div>
            </KTCard>

            <div>
                {switchTabs()}
            </div>

        </>
    )
})


export default function FilialViewWrapper() {
    //@ts-ignore
    const {role} = useOutletContext()


    if (roleRouting('filial', role) || roleRoutingAdmin('action', role, 'is-admin')) {
        return (
            <ErrorProvider>
                <FilialView/>
            </ErrorProvider>
        )
    }

    return <Navigate to={`/error/404`}/>

}