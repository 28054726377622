import React, {useState} from 'react';
import {KTCard, KTCardBody} from "@metronic/helpers";
import {useIntl} from "react-intl";
import {IEpos} from "@providers/filial/_models";
import EposesView from "../eposes-view/eposes-view";
import EposesForm from "../eposes-form/eposes-form";
import {roleRoutingAdmin} from "@utils/index";
import {employeeStore} from "@store/employee";
import {observer} from "mobx-react";

interface props {
    eposes: IEpos[] | undefined
    getFilial: () => void
}

const Eposes: React.FC<props> = observer(({
                                              eposes,
    getFilial
                                          }) => {

    const intl = useIntl()

    const [isEdit, setIsEdit] = useState<boolean>(false)

    const onEdit = () => setIsEdit(true)

    const onRead = () => setIsEdit(false)

    return (
        <KTCard className={'mt-10'}>
            <div className='card-header d-flex py-5 px-10 align-items-center'>
                <h3 className='mb-0'>EPOS</h3>
                {!isEdit && roleRoutingAdmin('action', employeeStore.getRole, 'delete-filial') ? <button onClick={onEdit} type='button' className='btn btn-md btn-primary'>
                    {intl.formatMessage({id: "EDIT_BUTTON"})}
                </button> : null}
            </div>
            {isEdit ? <EposesForm getFilial={getFilial} onRead={onRead} eposes={eposes!}></EposesForm> :
                eposes ? <EposesView eposes={eposes}/> : null}
        </KTCard>
    );
})

export default Eposes;