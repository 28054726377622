/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
//@ts-ignore
import InputMask from 'react-input-mask'
import OtpInput from 'react-otp-input';
import {Auth} from "@providers/auth/_request";
import Countdown, {zeroPad} from 'react-countdown';
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {REGEXP_PHONE} from "@utils/constants";
import {languages} from "@metronic/partials/layout/header-menus/Languages";
import {setLanguage, useLang} from "@metronic/i18n/Metronici18n";
import {Account} from "@providers/account/_request";
import {AuthModel} from "../core/_models";
import moment from "moment";
import {isDemo} from "@utils/index";


const initialValues = {
    phone: '',
    otp: ''
}


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/


function Login() {
    const [loading, setLoading] = useState(false)
    const [passwordSetupBlock, setPasswordSetupBlock] = useState(false)
    const [showPasswordError, setShowPasswordError] = useState(false)
    const [showPasswordLogin, setShowPasswordLogin] = useState(false)
    const [didForgotPassword, setDidForgotPassword] = useState(false)
    const [saveAuthResponse, setSaveAuthResponse] = useState<AuthModel>()
    const [hiddenPass, setHiddenPass] = useState(true)
    const {saveAuth} = useAuth()
    const [otp, setOtp] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState<string>('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [confirm, setConfirm] = useState(false)
    const [id, setId] = useState(null)
    const {error, setError} = useErrors()
    const [counter, setCounter] = useState(0)
    const [countErrOtp, setCountErrOtp] = useState(0)
    const [errorPhone, setErrorPhone] = useState('')
    const intl = useIntl()
    const [passError, setPassError] = useState('')
    const [otpError, setErrorOtp] = useState('')


    const loginSchema = Yup.object().shape({
        phone: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        otp: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
    })


    const dateExpire = React.useMemo(() => {
        return Date.now() + 60000;
    }, [counter])


    useEffect(() => {
        if (countErrOtp > 3) {
            setTimeout(() => {
                setCountErrOtp(0)
            }, 180000)

            return () => {
                clearTimeout(setTimeout(() => {
                    setCountErrOtp(0)
                }, 180000))
            }
        }
    }, [countErrOtp])


    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            await submitForm(values, setStatus, setSubmitting)
        },
    })


    const checkUser = (value: string) => {

        const checkValue = value.replace(/ /g, '').replace('+', '')
        const reg = new RegExp(/^[0-9]{12}$/)
        if (showPasswordLogin) {
            setShowPasswordLogin(false)
            setPassword("")
        }

        if (value.match(REGEXP_PHONE)) {
            setLoading(true)
            Auth.check(checkValue).then((result) => {

                if (result.data.result.exists) {
                    if (result.data.result.loginType === "OTP") {
                        loginByOTP(checkValue)
                    } else {
                        setShowPasswordLogin(true)
                        setLoading(false)
                    }
                } else {
                    registration(checkValue)
                }
            }).catch((error) => {
                setError({status: error?.response?.status, message: error?.response?.data?.message, isError: true})
                setLoading(false)
            })
        } else {
            if (checkValue.match(reg)) {
                setErrorPhone(intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'}))
            }

        }
    }

    useEffect(() => {
        if (isDemo()) {
            loginByPassword(null, "998900000000", "12345678")
        }
    }, [])


    function loginByOTP(phone: string) {
        Auth.login(phone)
            .then((response) => {
                setLoading(false)
                setConfirm(true)
                setCounter(counter + 1)
                const id = response.data.result.id
                setId(id)
            })
            .catch((error) => {
                setError({status: error?.response?.status, message: error?.response?.data?.message, isError: true})
                setLoading(false)
            })
    }

    function loginByPassword(e?: any, demoPhone?: string, demoPass?: string) {
        e?.preventDefault()
        const checkValue = demoPhone ?? phone.replace(/ /g, '').replace('+', '')
        const reg = new RegExp(/^[0-9]{12}$/)

        if ((checkValue.match(reg) && password?.length) || (demoPhone && demoPass)) {
            Auth.loginPassword(checkValue, demoPass ?? password)
                .then((response) => {
                    setLoading(false)
                    saveAuth(response.data.result)
                })
                .catch((error) => {
                    if (error?.response?.data?.code === 'BAD_REQUEST') {
                        setPassError(intl.formatMessage({id: 'password-auth-error'}))
                    } else {
                        setError({
                            status: error?.response?.status,
                            message: error?.response?.data?.message,
                            isError: true
                        })
                    }

                    setLoading(false)
                })
        } else {
            if (!password?.length) setPassError('Это поле обязательно к заполнению')
        }
    }

    function registration(phone: string) {
        Auth.registration(phone)
            .then((response) => {
                setConfirm(true)
                setLoading(false)
                setCounter(counter + 1)
                const id = response.data.result.id
                setId(id)
            })
            .catch((error) => {
                setError({status: error?.response?.status, message: error?.response?.data?.message, isError: true})
                setLoading(false)
            })
    }

    //@ts-ignore
    const submitForm = async (values, setStatus, setSubmitting) => {

    }

    const confirmOtp = async (value: string, confirmPass?: boolean) => {
        setLoading(true)
        try {
            if (id) {
                if (confirmPass === true) {
                    await setUserPassword()
                    saveAuth(saveAuthResponse)
                } else {
                    Auth.confirm(id, value)
                        .then(async (response) => {
                            setLoading(false)
                            // saveAuth(response.data.result)
                            // if (!saveAuthResponse) {
                            setSaveAuthResponse(response.data.result)
                            setPasswordSetupBlock(true)
                            // } else {
                            //     saveAuth(saveAuthResponse)
                            // }
                        }).catch(e => {
                        if (e?.response?.status === 400) {
                            setErrorOtp(intl.formatMessage({id: 'not-code'}))
                        } else {
                            setError({status: e?.response?.status, message: e?.response?.data?.message, isError: true})
                        }

                        setCountErrOtp(countErrOtp + 1)
                        setLoading(false)
                    })
                }
            }
        } catch (error) {
            saveAuth(undefined)
            setError({status: 0, message: 'Что-то пошло не так', isError: true})
            setLoading(false)
        }
    }

    const resendOtp = async () => {
        setCounter(counter + 1)
        Auth.resend(id ?? '')
            .then(() => {
                setLoading(false)
            }).catch(e => {
            if (e?.response?.status === 400) {
                let phone_repl = phone ? phone.replace(/ /g, '').replace('+', '') : ''
                registration(phone_repl)
            } else {
                setError({status: e?.response?.status, message: e?.response?.data?.message, isError: true})
                setLoading(false)
            }

        })
    }

    const Completionist = () =>
        <div className='text-center mt-3'>
            {countErrOtp > 3 ? <p className='mb-7'>{intl.formatMessage({id: 'WARNING_ERROR'})}</p> : ''}
            <button
                type='button'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={countErrOtp > 3}
                onClick={() => resendOtp()}
            >

                {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'RESEND'})}</span>}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                )}
            </button>

        </div>;
    //@ts-ignore
    const renderer = ({minutes, seconds, completed}) => {

        if (completed) {
            return <Completionist/>;
        } else {
            // Render a countdown
            return (
                <div style={{textAlign: "center", fontSize: "16px", marginTop: "10px"}}>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </div>
            );
        }
    };

    const setUserPassword = async () => {
        try {
            const res = await Account.putPassword(password, saveAuthResponse?.access_token)
        } catch (error: any) {
            setError({status: error?.response?.status, message: error?.response?.data?.message, isError: true})
        }
    }

    const confirmPassword = async (e: any) => {
        e.preventDefault()
        if (password !== passwordRepeat) {
            setShowPasswordError(true)
        } else if (password.length < 8) {
            setShowPasswordError(true)
        } else {
            await confirmOtp(otp, true)
        }
    }

    const forgotPassword = async () => {

        formik.resetForm()
        setPassword('')
        const checkValue = phone.replace(/ /g, '').replace('+', '')
        const reg = new RegExp(/^[0-9]{12}$/)

        if (checkValue.match(reg)) {
            try {
                setLoading(true)
                const response = await Auth.resetPassword(checkValue)
                const id = response.data.result.id
                setId(id)
                setConfirm(true)
                setDidForgotPassword(true)
                setShowPasswordLogin(false)
                setCounter(counter + 1)
            } catch (error: any) {
                setError({status: error?.response?.status, message: error?.response?.data?.message, isError: true})
            } finally {
                setLoading(false)
            }
        }
    }


    return (
        <>
            <div className="w-100 d-flex justify-content-end">
                <LanguageSelectDropdown/>
            </div>
            <div>
                <div className="auth_info">
                    <div>
                        <svg width="46" height="38" viewBox="0 0 46 38" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M8.34998 5.52999C17.7763 -2.49379 31.9602 -1.40083 40.0305 7.97133C47.4736 16.615 47.1169 29.2819 39.6217 37.4933L6.24208 37.4217C6.12512 37.2921 6.00899 37.1612 5.89438 37.0283C-2.17585 27.6561 -1.07643 13.5538 8.34998 5.52999Z"
                                  fill="#E9532F"/>
                        </svg>
                    </div>
                    <p className="text-center text-dark">Личный кабинет</p>
                </div>

                <form
                    className='card rounded-3 w-md-550px py-10'
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id='kt_login_signin_form'
                >

                    {!confirm ?
                        (<>
                            <div className='fv-row mb-10'>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                    {intl.formatMessage({id: 'phone'})}
                                </label>

                                <div className='d-flex align-items-center justify-content-end position-relative'>
                                    <InputMask mask="+\9\9\8 99 999 99 99" {...formik.getFieldProps('phone')}
                                               className={clsx('form-control form-control-lg form-control-solid',)}
                                               value={phone}
                                               onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                                   formik.handleChange(event)
                                                   setPhone(event.currentTarget.value)
                                                   if (!loading) {
                                                       checkUser(event.currentTarget.value)
                                                   }
                                               }}
                                               type="tel"
                                               disabled={loading}
                                    />
                                    {
                                        loading &&
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'
                                              style={{position: 'absolute', right: '15px'}}/>
                                    }
                                </div>

                                {(error?.message || (!!(errorPhone.length))) && (
                                    <div className='fv-plugins-message-container text-danger mt-3'>
                                        <span role='alert'>{error?.message || errorPhone}</span>
                                    </div>
                                )}


                            </div>
                            {showPasswordLogin &&
                                <div className='fv-row mb-10'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>
                                        {intl.formatMessage({id: 'PASSWORD'})}
                                    </label>

                                    <div className='d-flex align-items-center justify-content-end position-relative'>
                                        <input
                                            type="password"
                                            {...formik.getFieldProps('password')}
                                            value={password}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid'
                                            )}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                setPassword(e.currentTarget.value)
                                            }}
                                        />
                                        {
                                            loading &&
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'
                                                  style={{position: 'absolute', right: '15px'}}/>
                                        }
                                    </div>

                                    {(error?.message || passError.length) ? (
                                        <div className='fv-plugins-message-container text-danger mt-3'>
                                            <span role='alert'>{error?.message || passError}</span>
                                        </div>
                                    ) : ''}
                                </div>}
                        </>)
                        : <></>}

                    <div className='fv-row'>
                        {!passwordSetupBlock && confirm ? (<div className='fv-row mb-10'>
                            <div className='d-flex justify-content-between mt-n5'>
                                <div className='mb-10'>
                                    <h3 className='fw-bolder text-dark text-center'>{intl.formatMessage({id: 'SEND_CODE'})}</h3>
                                    {didForgotPassword && <h3
                                        className='fw-bolder text-dark text-center'>{intl.formatMessage({id: 'FORGOT_PASS_TITLE'})}</h3>}
                                </div>
                            </div>
                            <OtpInput
                                {...formik.getFieldProps('otp')}
                                value={otp}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid form-control-otp'
                                )}
                                shouldAutoFocus={true}
                                numInputs={8}
                                isInputNum={true}
                                onChange={async (otp: any) => {
                                    if (!otp.length || otp.match(/^\d/)) {
                                        setOtp(otp)
                                        await formik.setFieldValue('otp', otp)
                                        if (otp.length === 8 && countErrOtp <= 3) {
                                            // if (didForgotPassword) {
                                            await confirmOtp(otp)
                                            // } else {
                                            // setPasswordSetupBlock(true)
                                            // }
                                        }
                                    }
                                }}
                                separator={<span></span>}
                            />
                            {!!otpError.length && (
                                <div className='fv-plugins-message-container text-danger mt-3'>
                                    <span role='alert'>{otpError}</span>
                                </div>
                            )}
                            <Countdown date={dateExpire} renderer={renderer} key={counter}/>

                        </div>) : ''}
                    </div>

                    <div className='fv-row'>
                        {passwordSetupBlock && <div className='fv-row mb-10'>
                            <div className='d-flex justify-content-between mt-n5'>
                                <div className='mb-10'>
                                    <h3 className='fw-bolder text-dark text-center'>{intl.formatMessage({id: 'SETUP_PASSWORD'})}</h3>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                    {intl.formatMessage({id: 'ENTER_PASSWORD'})}
                                </label>
                                <div className='d-flex align-items-center justify-content-end position-relative'>
                                    <input
                                        type={hiddenPass ? "password" : "text"}
                                        {...formik.getFieldProps('password')}
                                        value={password}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid'
                                        )}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                            setPassword(e.currentTarget.value)
                                        }}
                                    />
                                    {
                                        loading &&
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'
                                              style={{position: 'absolute', right: '15px'}}/>
                                    }
                                </div>
                            </div>
                            <div>
                                <label className='form-label fs-6 fw-bolder text-dark'>
                                    {intl.formatMessage({id: 'REPEAT_PASSWORD'})}
                                </label>
                                <div className='d-flex align-items-center justify-content-end position-relative'>
                                    <input
                                        type={hiddenPass ? "password" : "text"}
                                        {...formik.getFieldProps('password')}
                                        value={passwordRepeat}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid'
                                        )}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                            setPasswordRepeat(e.currentTarget.value)
                                        }}
                                    />
                                    {
                                        loading ?
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'
                                                  style={{position: 'absolute', right: '15px'}}/> :
                                            <span className='bi-eye-fill align-middle ms-2 cursor-pointer'
                                                  style={{position: 'absolute', right: '-20px'}}
                                                  onMouseDown={() => setHiddenPass(false)}
                                                  onMouseUp={() => setHiddenPass(true)}/>
                                    }
                                </div>
                            </div>
                        </div>}
                    </div>

                    {showPasswordLogin &&
                        <div className="d-flex justify-content-between">
                            <span className="text-muted text-decoration-underline d-inline-block cursor-pointer"
                                  onClick={forgotPassword}>
                                {intl.formatMessage({id: 'FORGOT_PASS?'})}
                            </span>
                            <button className="btn btn-primary" onClick={(e) => loginByPassword(e)}>
                                {intl.formatMessage({id: 'LOGIN'})}
                            </button>
                        </div>}

                    {showPasswordError && passwordSetupBlock && (
                        <div className='fv-plugins-message-container text-danger mb-3'>
                            <span role='alert'>
                                {password !== passwordRepeat ?
                                    intl.formatMessage({id: 'PASSWORDS_NOT_SAME'}) :
                                    password.length < 8 ? intl.formatMessage({id: 'AT_LEAST_8_CHAR'}) : ""
                                }
                            </span>
                        </div>
                    )}

                    {passwordSetupBlock && <div className="d-flex justify-content-end">
                        {/*<div className="btn btn-secondary" onClick={() => confirmOtp(otp)}>*/}
                        {/*    {intl.formatMessage({id: 'LATER'})}*/}
                        {/*</div>*/}
                        <button disabled={password === "" || passwordRepeat === ""} className="btn btn-primary"
                                onClick={(e) => confirmPassword(e)}>
                            {intl.formatMessage({id: 'SETUP'})}
                        </button>
                    </div>}
                </form>

            </div>
            <div>
                Global Pay © {moment().format('YYYY')}
            </div>
        </>
    )
}

export const LanguageSelectDropdown = () => {
    const lang = useLang()
    const currentLanguage = languages.find((x) => x.lang === lang)

    return <div className="dropdown">
        <div
            className='btn btn-secondary dropdown-toggle fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {currentLanguage?.name}{' '}
            <img
                className='w-15px h-15px rounded-1 ms-2'
                src={currentLanguage?.flag}
                alt='metronic'
            />


        </div>
        <div
            className="menu menu-sub menu-sub-dropdown menu-column mt-8 menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px"
            data-kt-menu="true" data-popper-placement="bottom-end">
            {languages.map(l =>
                <div
                    className='menu-item px-3'
                    key={l.lang}
                    onClick={() => {
                        setLanguage(l.lang)
                    }}
                >
                    <a
                        href='#'
                        className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
                    >
                                        <span className='symbol symbol-20px me-4'>
                                            <img className='rounded-1' src={l.flag} alt='metronic'/>
                                        </span>
                        {l.name}
                    </a>
                </div>
            )}
        </div>
    </div>

}

export default function LoginWrapper() {
    return (
        <ErrorProvider>
            <Login/>
        </ErrorProvider>
    )
}